import {
  AuthActions,
  AuthFinishLoginActionType,
  AuthFailLoginActionType,
  AuthFinishLogoutActionType,
} from 'app/modules/auth/auth-actions'

import { AuthSuccess, AuthFailure } from 'review-app-shared/types/auth'

export enum AuthStatus { LoggedIn, LoggedOut, Error }

interface AuthLoggedIn {
  kind: AuthStatus.LoggedIn
  data: AuthSuccess
}
interface AuthLoggedOut {
  kind: AuthStatus.LoggedOut
}
interface AuthError {
  kind: AuthStatus.Error
  error: AuthFailure
}

export const getLoggedIn = (data: AuthSuccess): AuthLoggedIn => ({ kind: AuthStatus.LoggedIn, data })
export const getLoggedOut = (): AuthLoggedOut => ({ kind: AuthStatus.LoggedOut })
export const getError = (data: AuthFailure): AuthError => ({ kind: AuthStatus.Error, error: data })

export type AuthState = AuthLoggedIn | AuthLoggedOut | AuthError

const initialState: AuthState = getLoggedOut()

export const authReducer = (state: AuthState = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthFinishLoginActionType:
      return getLoggedIn(action.payload)
    case AuthFailLoginActionType:
      return getError(action.payload)
    case AuthFinishLogoutActionType:
      return getLoggedOut()
    default:
      return state
  }
}
