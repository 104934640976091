import { ActionType, createAction, getType } from 'typesafe-actions'
import { CollaboratorWithDuration } from 'review-app-shared/types/timesheet'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

export const timesheetStartGetTimesheets = createAction('timesheet/START_GET_TIMESHEETS',
  (actionCallback) => (reviewedUserId: number) => actionCallback(reviewedUserId))

export const timesheetFinishGetTimesheets = createAction('timesheet/FINISH_GET_TIMESHEETS',
  (actionCallback) => (timesheetCollaborators: CollaboratorWithDuration[]) => actionCallback(timesheetCollaborators))

export const timesheetErrorGetTimesheets = createAction('timesheet/ERROR_GET_TIMESHEETS',
  (actionCallback) => (error: Error) => actionCallback(error))

export type TimesheetActions =
    ActionType<typeof timesheetStartGetTimesheets | typeof timesheetFinishGetTimesheets | typeof timesheetErrorGetTimesheets >

export type TimesheetStartGetTimesheets = ActionType<typeof timesheetStartGetTimesheets>
export type TimesheetFinishGetTimesheets = ActionType<typeof timesheetFinishGetTimesheets>
export type TimesheetErrorGetTimesheets = ActionType<typeof timesheetErrorGetTimesheets>

export const TimesheetStartGetTimesheetsActionType = getType(timesheetStartGetTimesheets)
export const TimesheetFinishGetTimesheetsActionType = getType(timesheetFinishGetTimesheets)
export const TimesheetErrorGetTimesheetsActionType = getType(timesheetErrorGetTimesheets)
