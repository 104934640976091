import { createSelector } from 'reselect'

import { LoadStatus } from 'review-app-shared/types/fetchData'

import { AppState } from 'app/modules/root/root-reducer'
import { TimesheetState } from 'app/modules/timesheet/timesheet-reducer'

export const getTimesheetState = ((state: AppState): TimesheetState => state.timesheet)

export const getTimesheetCollaborators = createSelector(getTimesheetState, (timesheet) => timesheet.timesheetCollaborators)

export const getIsLoadingTimesheet = createSelector(
  getTimesheetCollaborators,
  (timesheets) => (timesheets.kind === LoadStatus.Loading),
)

export const getIsErrorTimesheet = createSelector(
  getTimesheetCollaborators,
  (timesheets) => (timesheets.kind === LoadStatus.Error),
)

export const getLoadedTimesheets = createSelector(getTimesheetCollaborators, (timesheetCollaborators) => {
  if (timesheetCollaborators.kind !== LoadStatus.Loaded) return {}
  return timesheetCollaborators
})
