import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { ResponseOptions, ResponseOptionsToCreate } from 'review-app-shared/types/response-options'

export const responseOptionsFormStartSend = createAction('response-options-form/START_SEND',
  (actionCallback) => (responseOptionsToCreate: ResponseOptionsToCreate) => actionCallback(responseOptionsToCreate))

export const responseOptionsFormFinishSend = createAction('response-options-form/FINISH_SEND',
  (actionCallback) => (responseOptions: ResponseOptions) => actionCallback(responseOptions))

export const responseOptionsFormStartDelete = createAction('response-option-form/START_DELETE',
  (actionCallback) => (responseOptionsId: number) => actionCallback(responseOptionsId))

export const responseOptionsFormFinishDelete = createAction('response-option-form/FINISH_DELETE',
  (actionCallback) => (responseOptionsId: number) => actionCallback(responseOptionsId))

export const responseOptionsFormError = createAction('response-options-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type ResponseOptionsFormActions = ActionType<typeof responseOptionsFormStartSend |
  typeof responseOptionsFormFinishSend | typeof responseOptionsFormStartDelete |
  typeof responseOptionsFormFinishDelete | typeof responseOptionsFormError>

export type ResponseOptionsFormStartSend = ActionType<typeof responseOptionsFormStartSend>
export type ResponseOptionsFormFinishSend = ActionType<typeof responseOptionsFormFinishSend>
export type ResponseOptionsFormStartDelete = ActionType<typeof responseOptionsFormStartDelete>
export type ResponseOptionsFormFinishDelete = ActionType<typeof responseOptionsFormFinishDelete>
export type ResponseOptionsFormError = ActionType<typeof responseOptionsFormError>

export const ResponseOptionsFormStartSendActionType = getType(responseOptionsFormStartSend)
export const ResponseOptionsFormFinishSendActionType = getType(responseOptionsFormFinishSend)
export const ResponseOptionsFormStartDeleteActionType = getType(responseOptionsFormStartDelete)
export const ResponseOptionsFormFinishDeleteActionType = getType(responseOptionsFormFinishDelete)
export const ResponseOptionsFormErrorActionType = getType(responseOptionsFormError)
