import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiTimesheet,
} from 'app/modules/timesheet/timesheet-effects'
import {
  timesheetFinishGetTimesheets,
  timesheetErrorGetTimesheets,
  TimesheetStartGetTimesheetsActionType,
  TimesheetFinishGetTimesheets,
  TimesheetErrorGetTimesheets,
  TimesheetStartGetTimesheets,
} from 'app/modules/timesheet/timesheet-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { CollaboratorWithDuration } from 'review-app-shared/types/timesheet'

type GetTimesheetsEffects = SelectEffect | CallEffect<AxiosResponse<unknown>> |
PutEffect<TimesheetFinishGetTimesheets> | PutEffect<TimesheetErrorGetTimesheets>

export function* getTimesheetsSaga(action: TimesheetStartGetTimesheets): Generator<GetTimesheetsEffects, void, string | AxiosResponse<unknown>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiTimesheet, action.payload, token)
      if (response && typeof response !== 'string') yield put(timesheetFinishGetTimesheets(response.data as CollaboratorWithDuration[]))
    }
  } catch (error) {
    console.error(error)
    yield put(timesheetErrorGetTimesheets(error))
  }
}

export function* timesheetSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(TimesheetStartGetTimesheetsActionType, getTimesheetsSaga)
}
