import React, { FC, ChangeEvent, KeyboardEvent } from 'react'
import Select from 'react-select'

import { SelectOption } from 'review-app-shared/types/selectOption'

interface WrapperProps {
  label: string
  error?: string
  id: string
}

interface FormFieldProps<FieldValue> extends WrapperProps {
  value?: FieldValue
  handleOnChange: (value: FieldValue) => void
}

const FieldWrapper: FC<WrapperProps> = ({
  label, id, error, children,
}) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    {children}
    {error && <p className="form-error">{error}</p>}
  </div>
)

const suppressSubmit = (event: KeyboardEvent<HTMLInputElement>): void => {
  if (event.key === 'Enter') event.preventDefault() // suppress form submit
}

export const FormSelectField: FC<FormFieldProps<SelectOption[]> & { options: SelectOption[] }> = ({
  value, options, id, handleOnChange, ...restProps
}) => (
  <FieldWrapper id={id} {...restProps}>
    <Select
      classNamePrefix="select"
      className="select-box"
      id={id}
      isMulti
      onChange={(selected): void => handleOnChange(selected as SelectOption[] || [])}
      value={value}
      options={options}
    />
  </FieldWrapper>
)

export const FormSingleSelectField: FC<FormFieldProps<number> & { options: SelectOption[] }> = ({
  value, options, id, handleOnChange, ...restProps
}) => (
  <FieldWrapper id={id} {...restProps}>
    <Select
      classNamePrefix="select"
      className="select-box"
      id={id}
      onChange={(selected): void => handleOnChange((selected as SelectOption).value)}
      value={options.find((option) => option.value === value)}
      options={options}
    />
  </FieldWrapper>
)

export const FormNumberField: FC<FormFieldProps<number> & { min?: number; max?: number }> = ({
  value, id, min, max, handleOnChange, ...restProps
}) => (
  <FieldWrapper id={id} {...restProps}>
    <input
      className="form-control"
      type="number"
      min={min}
      max={max}
      id={id}
      value={value}
      onChange={({ target }: ChangeEvent<HTMLInputElement>): void => handleOnChange(Number(target.value))}
      onKeyDown={suppressSubmit}
      autoComplete="off"
      required
    />
  </FieldWrapper>
)

export const FormInputField: FC<FormFieldProps<string> & { type: string }> = ({
  value, id, type, handleOnChange, ...restProps
}) => (
  <FieldWrapper id={id} {...restProps}>
    <input
      className="form-control"
      type={type}
      id={id}
      value={value}
      onChange={({ target }: ChangeEvent<HTMLInputElement>): void => handleOnChange(target.value)}
      onKeyDown={suppressSubmit}
      autoComplete="off"
      required
    />
  </FieldWrapper>
)

export const FormCheckboxField: FC<FormFieldProps<boolean>> = ({
  id, label, value, handleOnChange,
}) => (
  <label htmlFor={id}>
    <p className="checkbox-parent">
      {label}
      <input
        className="checkbox"
        type="checkbox"
        id={id}
        checked={value}
        onChange={({ target }: ChangeEvent<HTMLInputElement>): void => handleOnChange(target.checked)}
      />
    </p>
  </label>
)
