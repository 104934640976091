import { useState, useEffect } from 'react'

export const useQuery = (mediaQuery: string): boolean => {
  const [isQuery, setIsQuery] = useState(false)
  useEffect(() => {
    const mql = window.matchMedia(mediaQuery)
    const screenChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList): void => setIsQuery(mediaQueryList.matches)


    mql.addEventListener('change', screenChange)
    screenChange(mql)

    return (): void => mql.removeEventListener('change', screenChange)
  }, [mediaQuery])

  return isQuery
}
