import React, { FC } from 'react'

import { ReactComponent as BoldIcon } from 'assets/svg/text-bold.svg'
import { ReactComponent as ItalicIcon } from 'assets/svg/text-italic.svg'
import { ReactComponent as CodeIcon } from 'assets/svg/text-code.svg'
import { ReactComponent as UnderlineIcon } from 'assets/svg/text-underline.svg'
import { ReactComponent as StrikeThroughIcon } from 'assets/svg/text-strike-through.svg'
import { ReactComponent as BulletListIcon } from 'assets/svg/text-bulleted-list.svg'
import { ReactComponent as NumberListIcon } from 'assets/svg/text-numbered-list.svg'
import { ReactComponent as BlockQuoteIcon } from 'assets/svg/text-block-quote.svg'
import { inverseObject, isInMappingFactory, valueTypeCheckFactory } from 'app/helpers/typeHelpers'

// List of all supported text formats
export enum TextFormats {
  BulletList = 'bulleted-list',
  NumberList = 'numbered-list',
  ListItem = 'list-item',
  BlockQuote = 'block-quote',
  Heading1 = 'heading-one',
  Heading2 = 'heading-two',
  Heading3 = 'heading-three',
  Heading4 = 'heading-four',
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  StrikeThrough = 'strike-through',
  Code = 'code',

  Paragraph = 'paragraph',
  Span = 'span',
}
// Default text format used if no text format specified
export const DEFAULT_TEXT_FORMAT = TextFormats.Paragraph

// A map containing the HTML tag asociated with all defined text formats
export const FORMAT_MAPPING: { [format in TextFormats]: FC } = {
  [TextFormats.Paragraph]: ({ children, ...attributes }) => <p {...attributes}>{children}</p>,
  [TextFormats.Span]: ({ children, ...attributes }) => <span {...attributes}>{children}</span>,

  [TextFormats.ListItem]: ({ children, ...attributes }) => <li {...attributes}>{children}</li>,
  [TextFormats.BulletList]: ({ children, ...attributes }) => <ul {...attributes}>{children}</ul>,
  [TextFormats.NumberList]: ({ children, ...attributes }) => <ol {...attributes}>{children}</ol>,

  [TextFormats.Heading1]: ({ children, ...attributes }) => <h1 {...attributes}>{children}</h1>,
  [TextFormats.Heading2]: ({ children, ...attributes }) => <h2 {...attributes}>{children}</h2>,
  [TextFormats.Heading3]: ({ children, ...attributes }) => <h3 {...attributes}>{children}</h3>,
  [TextFormats.Heading4]: ({ children, ...attributes }) => <h4 {...attributes}>{children}</h4>,
  [TextFormats.BlockQuote]: ({ children, ...attributes }) => <blockquote {...attributes}>{children}</blockquote>,

  [TextFormats.Bold]: ({ children }) => <strong>{children}</strong>,
  [TextFormats.Italic]: ({ children }) => <em>{children}</em>,
  [TextFormats.Underline]: ({ children }) => <u>{children}</u>,
  [TextFormats.StrikeThrough]: ({ children }) => <s>{children}</s>,
  [TextFormats.Code]: ({ children }) => <code>{children}</code>,
}

// A map containing the icons asociated with all defined text formats
// All defined icons appear in the toolbar
export const ICONS = {
  [TextFormats.BulletList]: <BulletListIcon />,
  [TextFormats.NumberList]: <NumberListIcon />,
  [TextFormats.BlockQuote]: <BlockQuoteIcon />,
  [TextFormats.Heading1]: <div className="icon text-heading">H1</div>,
  [TextFormats.Heading2]: <div className="icon text-heading">H2</div>,
  [TextFormats.Heading3]: <div className="icon text-heading">H3</div>,
  [TextFormats.Heading4]: <div className="icon text-heading">H4</div>,
  [TextFormats.Bold]: <BoldIcon />,
  [TextFormats.Italic]: <ItalicIcon />,
  [TextFormats.Underline]: <UnderlineIcon />,
  [TextFormats.StrikeThrough]: <StrikeThroughIcon />,
  [TextFormats.Code]: <CodeIcon />,

  [TextFormats.Paragraph]: <div className="icon text-heading">P</div>,
} as const
export const hasIcon = isInMappingFactory(ICONS)

// List of all text formats that serve as wrappers for other formats
const FORMAT_WRAPPERS = [TextFormats.BulletList, TextFormats.NumberList] as const
// A map of corresponding wrapped element for given wrapper format
export const WRAPPER_MAPPING: { [format in typeof FORMAT_WRAPPERS[number]]: TextFormats } = {
  [TextFormats.BulletList]: TextFormats.ListItem,
  [TextFormats.NumberList]: TextFormats.ListItem,
}
export const isFormatWrapper = valueTypeCheckFactory(FORMAT_WRAPPERS)

// List of all text formats that are block scoped
export const BLOCK_FORMATS = [
  TextFormats.BulletList,
  TextFormats.NumberList,
  TextFormats.ListItem,

  TextFormats.Heading1,
  TextFormats.Heading2,
  TextFormats.Heading3,
  TextFormats.Heading4,
  TextFormats.BlockQuote,

  TextFormats.Paragraph,
  TextFormats.Span,
] as const
export const isBlockFormat = valueTypeCheckFactory(BLOCK_FORMATS)

// List of all text formats that are leaf scoped
export const LEAF_FORMATS = [
  TextFormats.Bold,
  TextFormats.Italic,
  TextFormats.Underline,
  TextFormats.StrikeThrough,
  TextFormats.Code,
] as const
export const isLeafFormat = valueTypeCheckFactory(LEAF_FORMATS)

// Shortcuts applied at the beggining of the line (aka block)
export const BLOCK_SHORTCUTS = {
  '*': TextFormats.NumberList,
  '-': TextFormats.BulletList,
  '+': TextFormats.BulletList,
  '>': TextFormats.BlockQuote,
  '#': TextFormats.Heading1,
  '##': TextFormats.Heading2,
  '###': TextFormats.Heading3,
  '####': TextFormats.Heading4,
} as const
export const isBlockShortcut = isInMappingFactory(BLOCK_SHORTCUTS)
export const INV_BLOCK_SHORTCUTS = inverseObject(BLOCK_SHORTCUTS)
export const hasBlockShortcut = isInMappingFactory(INV_BLOCK_SHORTCUTS)

// Shortcuts used to wrap inline elements (aka leafs)
export const LEAF_SHORTCUTS = {
  '`': TextFormats.Code,
  '~': TextFormats.StrikeThrough,
  _: TextFormats.Underline,
  '**': TextFormats.Bold,
  '*': TextFormats.Italic,
} as const
export const isLeafShortcut = isInMappingFactory(LEAF_SHORTCUTS)
export const INV_LEAF_SHORTCUTS = inverseObject(LEAF_SHORTCUTS)
export const hasLeafShortcut = isInMappingFactory(INV_LEAF_SHORTCUTS)

export const HOTKEYS = {
  ...((Object.keys(LEAF_SHORTCUTS))
    .filter<keyof typeof LEAF_SHORTCUTS>((key): key is keyof typeof LEAF_SHORTCUTS => key.length === 1)
    .reduce(
      (acc, key) => ({
        ...acc,
        [`mod+${key}`]: LEAF_SHORTCUTS[key],
      }),
      {},
    )),
  'mod+b': TextFormats.Bold,
  'mod+i': TextFormats.Italic,
  'mod+u': TextFormats.Underline,
}
export const isHotkey = isInMappingFactory(HOTKEYS)
