// Helper functions that generate simple visual elements used in several places
import React from 'react'

import iconTick from 'assets/svg/icon-tick.svg'
import iconDelete from 'assets/svg/icon-delete.svg'

export const booleanToIcon = (input: boolean): JSX.Element => {
  if (input) return <div className="icon-container" data-cy="true-icon"><img className="icon" src={iconTick} alt="true icon" /></div>
  return <div className="icon-container" data-cy="false-icon"><img className="icon" src={iconDelete} alt="false icon" /></div>
}
