import { ActionType, createAction, getType } from 'typesafe-actions'
import { ForecastApiResponse } from 'review-app-shared/types/forecast'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

export const forecastStartGet = createAction('forecast/START_GET_FORECAST',
  (actionCallback) => () => actionCallback())

export const forecastFinishGet = createAction('forecast/FINISH_GET_FORECAST',
  (actionCallback) => (result: ForecastApiResponse) => actionCallback(result))

export const forecastErrorGet = createAction('forecast/ERROR_GET_FORECAST',
  (actionCallback) => (result: Error) => actionCallback(result))

export const forecastStartUpdateUsers = createAction('forecast/START_UPDATE_USERS',
  (actionCallback) => () => actionCallback())

export const forecastFinishUpdateUsers = createAction('forecast/FINISH_UPDATE_USERS',
  (actionCallback) => () => actionCallback())

export type ForecastActions = ForecastStartGet | ForecastFinishGet | ForecastErrorGet
| ForecastStartUpdateUsers | ForecastFinishUpdateUsers

export type ForecastStartGet = ActionType<typeof forecastStartGet>
export type ForecastFinishGet = ActionType<typeof forecastFinishGet>
export type ForecastErrorGet = ActionType<typeof forecastErrorGet>
export type ForecastStartUpdateUsers = ActionType<typeof forecastStartUpdateUsers>
export type ForecastFinishUpdateUsers = ActionType<typeof forecastFinishUpdateUsers>

export const ForecastStartGetActionType = getType(forecastStartGet)
export const ForecastFinishGetActionType = getType(forecastFinishGet)
export const ForecastErrorGetActionType = getType(forecastErrorGet)
export const ForecastStartUpdateUsersActionType = getType(forecastStartUpdateUsers)
export const ForecastFinishUpdateUsersActionType = getType(forecastFinishUpdateUsers)
