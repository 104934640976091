import {
  ReviewActions,
  ReviewStartGetFeedbackActionType,
  ReviewFinishGetFeedbackActionType,
  ReviewErrorGetFeedbackActionType,
  ReviewStartGetFeedbackByIdActionType,
  ReviewFinishGetFeedbackByIdActionType,
  ReviewErrorGetFeedbackByIdActionType,
  ReviewStartGetReviewsActionType,
  ReviewFinishGetReviewsActionType,
  ReviewErrorGetReviewsActionType,
  ReviewStartGetQuestionnairesActionType,
  ReviewFinishGetQuestionnairesActionType,
  ReviewErrorGetQuestionnairesActionType,
  ReviewFinishSendSummaryActionType,
  ReviewStartGetSummaryActionType,
  ReviewFinishGetSummaryActionType,
  ReviewErrorGetSummaryActionType,
} from 'app/modules/review/review-actions'
import { AuthFinishLogout, AuthFinishLogoutActionType } from 'app/modules/auth/auth-actions'
import {
  CreateReviewFormFinishSend,
  CreateReviewFormFinishSendActionType,
  UpdateReviewFormFinishSend,
  UpdateReviewFormFinishSendActionType,
  UpdateReviewFormFinishDelete,
  UpdateReviewFormFinishDeleteActionType,
} from 'app/modules/review-form/review-form-actions'
import {
  QuestionnaireFormCreateFinishSend,
  QuestionnaireFormCreateFinishSendActionType,
  QuestionnaireFormUpdateFinishSend,
  QuestionnaireFormUpdateFinishSendActionType,
  QuestionnaireFormFinishDelete,
  QuestionnaireFormFinishDeleteActionType,
} from 'app/modules/questionnaire-form/questionnaire-form-actions'
import { FeedbackFormFinishSend, FeedbackFormFinishSendActionType } from 'app/modules/feedback-form/feedback-form-actions'

import { Feedback, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { Review } from 'review-app-shared/types/review'
import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'

import {
  FetchResult,
  getLoading,
  getLoaded,
  getError,
  LoadStatus,
} from 'review-app-shared/types/fetchData'
import { Summary } from 'review-app-shared/types/summary'

export type ReviewState = {
  feedback: FetchResult<Feedback[]>
  reviews: FetchResult<Review[]>
  questionnaires: FetchResult<QuestionnaireWithQuestionIds[]>
  summaryById: {
    [id: string]: FetchResult<Summary>
  }
  feedbackById: {
    [id: string]: FetchResult<FeedbackWithQuestionsAndAnswers>
  }
}

const initialState: ReviewState = {
  feedback: getLoading(),
  reviews: getLoading(),
  questionnaires: getLoading(),
  summaryById: {},
  feedbackById: {},
}

type AllowedActionTypes = ReviewActions | AuthFinishLogout | CreateReviewFormFinishSend |
UpdateReviewFormFinishSend | UpdateReviewFormFinishDelete | QuestionnaireFormCreateFinishSend |
QuestionnaireFormUpdateFinishSend | QuestionnaireFormFinishDelete | FeedbackFormFinishSend

export const reviewReducer = (state: ReviewState = initialState, action: AllowedActionTypes): ReviewState => {
  switch (action.type) {
    case AuthFinishLogoutActionType:
      return initialState
    case ReviewStartGetFeedbackActionType:
      return {
        ...state,
        feedback: getLoading(),
      }
    case ReviewFinishGetFeedbackActionType:
      return {
        ...state,
        feedback: getLoaded(action.payload),
      }
    case ReviewErrorGetFeedbackActionType:
      return {
        ...state,
        feedback: getError(action.payload.message),
      }
    case ReviewStartGetFeedbackByIdActionType:
      return {
        ...state,
        feedbackById: {
          ...state.feedbackById,
          [action.payload]: getLoading(),
        },
      }
    case ReviewFinishGetFeedbackByIdActionType:
      return {
        ...state,
        feedbackById: {
          ...state.feedbackById,
          [action.payload.id]: getLoaded(action.payload),
        },
      }
    case ReviewErrorGetFeedbackByIdActionType:
      return {
        ...state,
        feedbackById: {
          ...state.feedbackById,
          [action.payload.id]: getError(action.payload.error.message),
        },
      }
    case ReviewStartGetReviewsActionType:
      return {
        ...state,
        reviews: getLoading(),
      }
    case ReviewFinishGetReviewsActionType:
      return {
        ...state,
        reviews: getLoaded(action.payload),
      }
    case ReviewErrorGetReviewsActionType:
      return {
        ...state,
        reviews: getError(action.payload.message),
      }
    case ReviewStartGetQuestionnairesActionType:
      return {
        ...state,
        questionnaires: getLoading(),
      }
    case ReviewFinishGetQuestionnairesActionType:
      return {
        ...state,
        questionnaires: getLoaded(action.payload),
      }
    case ReviewErrorGetQuestionnairesActionType:
      return {
        ...state,
        questionnaires: getError(action.payload.message),
      }
    case QuestionnaireFormCreateFinishSendActionType: { // add new questionnaire
      if (state.questionnaires.kind !== LoadStatus.Loaded) return state
      const newQuestionnaires = [...state.questionnaires.data, action.payload]
      return {
        ...state,
        questionnaires: getLoaded(newQuestionnaires),
      }
    }
    case QuestionnaireFormUpdateFinishSendActionType: { // *replace* questionnaire
      if (state.questionnaires.kind !== LoadStatus.Loaded) return state
      const newQuestionnaires = [...state.questionnaires.data
        .filter((questionnaire) => questionnaire.name !== action.payload.name), action.payload]
      return {
        ...state,
        questionnaires: getLoaded(newQuestionnaires),
      }
    }
    case QuestionnaireFormFinishDeleteActionType: { // delete questionnaire from list
      if (state.questionnaires.kind !== LoadStatus.Loaded) return state
      const newQuestionnaires = state.questionnaires.data
        .filter((questionnaire) => questionnaire.id !== action.payload)
      return {
        ...state,
        questionnaires: getLoaded(newQuestionnaires),
      }
    }
    case CreateReviewFormFinishSendActionType: { // add new review to list
      if (state.reviews.kind !== LoadStatus.Loaded) return state
      return {
        ...state,
        reviews: getLoaded([...(state.reviews).data, action.payload]),
      }
    }
    case ReviewFinishSendSummaryActionType: {
      return {
        ...state,
        summaryById: {
          ...state.summaryById,
          [action.payload.id]: getLoaded(action.payload),
        },
      }
    }
    case ReviewStartGetSummaryActionType:
      return {
        ...state,
        summaryById: {
          ...state.summaryById,
          [action.payload]: getLoading(),
        },
      }
    case ReviewFinishGetSummaryActionType:
      return {
        ...state,
        summaryById: {
          ...state.summaryById,
          [action.payload.id]: getLoaded(action.payload),
        },
      }
    case ReviewErrorGetSummaryActionType:
      return {
        ...state,
        summaryById: {
          ...state.summaryById,
          [action.payload.id]: getError(action.payload.error.message),
        },
      }
    case UpdateReviewFormFinishSendActionType: { // update review details in list
      if (state.reviews.kind !== LoadStatus.Loaded) return state
      if (state.feedback.kind !== LoadStatus.Loaded) return state
      const currentReviewSet = [...(state.reviews).data]
      const updatedReview = new Review(
        action.payload.id,
        action.payload.timeCreated,
        action.payload.timeReviewed,
        action.payload.deadline,
        action.payload.userReviewedId,
        action.payload.questionnaireId,
      )
      const newReviewSet = currentReviewSet.map((review) => {
        if (review.id === action.payload.id) return updatedReview
        return review
      })
      // need to update feedback as well, for when the only change is adding or removing reviewers
      const currentFeedback = [...(state.feedback).data]
      const thisReviewFeedback = action.payload.feedback
      const newFeedback = [
        ...currentFeedback.filter((feedback) => feedback.reviewId !== action.payload.id),
        ...thisReviewFeedback,
      ]
      return {
        ...state,
        reviews: getLoaded(newReviewSet),
        feedback: getLoaded(newFeedback),
      }
    }
    case UpdateReviewFormFinishDeleteActionType: { // remove deleted review from list
      if (state.reviews.kind !== LoadStatus.Loaded) return state
      const currentReviewSet = [...(state.reviews).data]
      const newReviewSet = currentReviewSet.filter((review) => review.id !== Number(action.payload))
      return {
        ...state,
        reviews: getLoaded(newReviewSet),
      }
    }
    case FeedbackFormFinishSendActionType: { // update feedback details
      if (state.feedback.kind !== LoadStatus.Loaded) return state
      const currentFeedback = [...(state.feedback).data]
      const updatedFeedback = new Feedback(
        action.payload.id,
        action.payload.reviewerUserId,
        action.payload.reviewId,
        action.payload.dateSubmitted,
        action.payload.state,
      )
      const newFeedback = currentFeedback.map((feedback) => {
        if (feedback.id === action.payload.id) return updatedFeedback
        return feedback
      })
      return {
        ...state,
        feedback: getLoaded(newFeedback),
      }
    }
    default:
      return state
  }
}
