import React, {
  FC, useState, ChangeEvent, FormEvent,
} from 'react'

import { UserGroup, UserGroupToCreate } from 'review-app-shared/types/user-group'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { UserGroupFormEdit } from 'app/components/forms/UserGroupFormEdit'
import { UserGroupFormView } from 'app/components/forms/UserGroupFormView'

interface UserGroupFormProps {
  createUserGroup: (userGroupToCreate: UserGroupToCreate) => void
  deleteUserGroup: (userGroupId: number) => void
  userGroupIdsInUse: Set<number>
  userGroups: UserGroup[]
}

// The UserGroupForm component enables user groups to be viewed, created and deleted
export const UserGroupForm: FC<UserGroupFormProps> = ({
  createUserGroup,
  deleteUserGroup,
  userGroupIdsInUse,
  userGroups,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedUserGroup, setSelectedUserGroup] = useState<SelectOption | null>(null)
  const [name, setName] = useState('')
  const [errorName, setErrorName] = useState<string | null>(null)

  const namesInUse = userGroups.map((userGroup) => userGroup.name)
  const userGroupsForSelect: SelectOption[] = userGroups.map((userGroup) => ({
    value: userGroup.id,
    label: userGroup.name.concat((userGroupIdsInUse.has(userGroup.id) ? ' [in use]' : '')),
  })).sort((a, b) => a.label.localeCompare(b.label))

  const validateName = (candidateName: string): boolean => {
    if (!candidateName) {
      setErrorName('The user group must have a name.')
      return false
    }
    if (namesInUse.includes(candidateName)) {
      setErrorName(`User group names must be unique, ${candidateName} is already in use.`)
      return false
    }
    setErrorName(null)
    return true
  }

  const validateForm = (): boolean => validateName(name)

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
    validateName(event.target.value)
  }

  const handleSelectUserGroup = (selected: SelectOption): void => setSelectedUserGroup(selected)

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (validateForm()) {
      const userGroupToCreate = new UserGroupToCreate(name)
      createUserGroup(userGroupToCreate)
      setName('')
      setIsEditing(false)
      setErrorName(null)
    }
  }

  const handleCancelButtonClick = (): void => {
    setName('')
    setIsEditing(false)
    setErrorName(null)
  }

  const handleCreateButtonClick = (): void => {
    setIsEditing(true)
  }

  const handleDeleteButtonClick = (): void => {
    if (selectedUserGroup) {
      deleteUserGroup(selectedUserGroup.value)
      setSelectedUserGroup(null)
    }
  }

  return (
    (isEditing) ? (
      <UserGroupFormEdit
        name={name}
        errorName={errorName}
        handleCancelButtonClick={handleCancelButtonClick}
        handleChangeName={handleChangeName}
        handleSubmit={handleSubmit}
      />
    ) : (
      <UserGroupFormView
        handleCreateButtonClick={handleCreateButtonClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        handleSelectUserGroup={handleSelectUserGroup}
        userGroupIdsInUse={userGroupIdsInUse}
        userGroups={userGroups}
        userGroupsForSelect={userGroupsForSelect}
        selectedUserGroup={selectedUserGroup}
      />
    )
  )
}
