import { Feedback } from './feedback'

interface IReview {
  readonly timeReviewed: Date
  readonly userReviewedId: number
  readonly questionnaireId: number
}

export class Review implements IReview {
  constructor(
    readonly id: number,
    readonly timeCreated: Date,
    readonly timeReviewed: Date,
    readonly deadline: Date,
    readonly userReviewedId: number,
    readonly questionnaireId: number,
  ) {
  }
}

export class ReviewWithFeedback extends Review {
  constructor(
    id: number,
    timeCreated: Date,
    timeReviewed: Date,
    deadline: Date,
    userReviewedId: number,
    questionnaireId: number,
    readonly feedback: Array<Feedback>,
  ) {
    super(id, timeCreated, timeReviewed, deadline, userReviewedId, questionnaireId)
  }

  get usersWhoHaventProvidedFeedback(): number[] {
    return this.feedback
      .filter((feedback) => !feedback.dateSubmitted)
      .map((feedback) => feedback.reviewerUserId)
  }
}

export class ReviewToCreate implements IReview {
  constructor(
    readonly timeReviewed: Date,
    readonly userReviewedId: number,
    readonly questionnaireId: number,
    readonly usersReviewing: Array<number>,
  ) {
  }
}

export class ReviewUpdateData {
  constructor(
    readonly timeReviewed: Date,
    readonly usersToAdd: Array<number>,
    readonly usersToRemove: Array<number>,
  ) {
  }
}
