import { createSelector } from 'reselect'

import { AppState } from 'app/modules/root/root-reducer'
import { FeedbackFormState } from 'app/modules/feedback-form/feedback-form-reducer'

export const getFeedbackFormState = ((state: AppState): FeedbackFormState => state.feedbackForm)

export const getFeedback = createSelector(
  getFeedbackFormState,
  (feedbackFormState) => feedbackFormState.feedback,
)
