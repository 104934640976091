import React, { FC } from 'react'

import { UserWithDetails } from 'review-app-shared/types/user'
import { Feedback } from 'review-app-shared/types/feedback'
import { FeedbackStatus as Status } from 'app/components/components-ui/feedback/FeedbackStatus'

interface FeedbacksStatusProps {
  feedbacks: Feedback[]
  users: UserWithDetails[]
}

export const FeedbacksStatus: FC<FeedbacksStatusProps> = ({ feedbacks, users }) => (
  <div className="alert alert-primary">
    <ul className="mb-0">
      {feedbacks.map((feedback) => {
        const { id, reviewerUserId } = feedback
        const reviewerDetails = users.find((user) => user.id === reviewerUserId)
        if (reviewerDetails) {
          return (
            <li key={id}>
              {reviewerDetails.username}
              {' - '}
              <Status feedback={feedback} />
            </li>
          )
        }
        return null
      })}
    </ul>
  </div>
)
