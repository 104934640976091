import { api, ApiResponse } from 'app/api/api-client'
import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

export const apiGetQuestions = async (token: string): ApiResponse<Question[]> => api.get('/questions', {
  headers: { Authorization: token },
})

export const apiGetResponseOptions = async (token: string): ApiResponse<ResponseOptions[]> => api.get('/response-options', {
  headers: { Authorization: token },
})
