import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, { ValueType } from 'react-select'

import { weekDays } from 'review-app-shared/helpers/date'

import { ReviewToCreate } from 'review-app-shared/types/review'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { findReviewsPreviousNext } from 'app/helpers/review'
import { getLoadedActiveUsers, getLoadedUserGroups, getCurrentUser } from 'app/modules/user/user-selectors'
import {
  getLoadedQuestionnaires,
  getManagedUserReviews,
  getAdminUserReviews,
} from 'app/modules/review/review-selectors'
import { homeUrl } from 'app/helpers/urlCreators'

import { ReviewFormUserGroupList } from 'app/components/forms/ReviewFormUserGroupList'
import { ReviewFormPreviousReviewList } from 'app/components/forms/ReviewFormPreviousReviewList'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'
import { InputDate } from 'app/components/components-ui/inputs/input-date'
import { UserWithDetails } from 'review-app-shared/types/user'
import { ReviewFormForecastReviewList } from './ReviewFormForecastReviewList'
import { ReviewFormTimesheetReviewList } from './ReviewFormTimesheetReviewList'

interface CreateReviewFormProps {
  fixedUserReviewedId?: number
  createReview: (reviewToCreate: ReviewToCreate) => void
}

interface Option {
  label: string
  value: number
}

// The CreateReviewForm component enables managers to create a new review
export const CreateReviewForm: FC<CreateReviewFormProps> = ({ fixedUserReviewedId, createReview }) => {
  const [timeReviewed, setTimeReviewed] = useState<Date | null>(null)
  const [userReviewed, setUserReviewed] = useState<SelectOption | null>(null)
  const [questionnaireId, setQuestionnaireId] = useState<SelectOption | null>(null)
  const [usersReviewing, setUsersReviewing] = useState<SelectOption[]>([])
  const [redirect, setRedirect] = useState(false)
  const [errorUserReviewedId, setErrorUserReviewedId] = useState<string | null>(null)
  const [errorQuestionnaireId, setErrorQuestionnaireId] = useState<string | null>(null)
  const [errorUsersReviewing, setErrorUsersReviewing] = useState<string | null>(null)
  const [errorDateReviewing, setErrorDateReviewing] = useState<string | null>(null)

  const currentUser = useSelector(getCurrentUser)
  const activeUsers = useSelector(getLoadedActiveUsers)
  const userGroups = useSelector(getLoadedUserGroups)
  const questionnaires = useSelector(getLoadedQuestionnaires)
    .filter((questionnaire) => questionnaire.active)
  const managedUserReviews = useSelector(getManagedUserReviews)
  const adminUserReviews = useSelector(getAdminUserReviews)

  if (!currentUser) return null
  const { isAdmin } = currentUser
  const selectableUsers = isAdmin ? activeUsers : currentUser.managedUsers.filter((user: UserWithDetails) => user.active)
  const userReviewedIdOptions: Option[] = selectableUsers
    .map((user: UserWithDetails) => ({
      value: user.id,
      label: user.username,
    }))
    .sort((a: Option, b: Option) => a.label.localeCompare(b.label))
  if (fixedUserReviewedId && !userReviewed) {
    const matchingOption = userReviewedIdOptions.find((option) => option.value === fixedUserReviewedId)
    if (matchingOption) setUserReviewed(matchingOption)
  }
  const questionnaireIdOptions = questionnaires.map((questionnaire) => ({
    value: questionnaire.id,
    label: questionnaire.name,
  }))
  const usersReviewingOptions = activeUsers
    .filter((user) => !userReviewed || user.id !== userReviewed.value)
    .map((user) => ({
      value: user.id,
      label: user.username,
    }))

  const handleChangeDate = (newTimeReviewed: Date): void => {
    setTimeReviewed(newTimeReviewed)
    setErrorDateReviewing(null)
  }

  const handleChangeUserReviewed = (selected: SelectOption): void => {
    setUserReviewed(selected)
    setQuestionnaireId(null)
    setUsersReviewing([])
    setErrorUserReviewedId(null)
    setErrorQuestionnaireId(null)
    setErrorUsersReviewing(null)
  }

  const handleChangeQuestionnaire = (selected: SelectOption): void => {
    setQuestionnaireId(selected)
    setErrorQuestionnaireId(null)
  }

  const handleChangeUsersReviewing = (selected: SelectOption[]): void => {
    if (!selected) {
      setUsersReviewing([])
    } else {
      setUsersReviewing(selected)
      if (selected.length > 0) setErrorUsersReviewing(null)
    }
  }

  // outside select control
  const handleAddUserReviewing = (userId: number): void => {
    const userToAdd = usersReviewingOptions.find((user) => user.value === userId)
    if (userToAdd && !usersReviewing.includes(userToAdd)) {
      setUsersReviewing([...usersReviewing, userToAdd])
    }
  }

  const handleAddUsersReviewing = (userIds: number[]): void => {
    const usersToAdd: SelectOption[] = []
    userIds.forEach((userId) => {
      const userToAdd = usersReviewingOptions.find((user) => user.value === userId)
      const currentReviewerUserIds = usersReviewing.map((userReviewing) => userReviewing.value)
      if (userToAdd && !currentReviewerUserIds.includes(userToAdd.value)) usersToAdd.push(userToAdd)
    })
    if (usersToAdd.length > 0) setUsersReviewing([...usersReviewing, ...usersToAdd])
  }

  // outside select control
  const handleRemoveUserReviewing = (userId: number): void => {
    const newUsersReviewing = usersReviewing
      .filter((userReviewing) => userReviewing.value !== userId)
    setUsersReviewing(newUsersReviewing)
  }

  const handleRemoveUsersReviewing = (userIds: number[]): void => {
    const newUsersReviewing = usersReviewing
      .filter((userReviewing) => !userIds.includes(userReviewing.value))
    setUsersReviewing(newUsersReviewing)
  }

  const validateForm = (): boolean => {
    let isValid = true
    if (!userReviewed) {
      setErrorUserReviewedId('No user to review selected.')
      isValid = false
    }
    if (!questionnaireId) {
      setErrorQuestionnaireId('No questionnaire selected.')
      isValid = false
    }
    if (usersReviewing.length === 0) {
      setErrorUsersReviewing('Feedback not requested from anyone.')
      isValid = false
    }
    if (timeReviewed === null) {
      setErrorDateReviewing('No date set.')
      isValid = false
    }
    return isValid
  }

  const handleSubmit = (event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>): void => {
    event.preventDefault()
    if (validateForm()) {
      const dataToSubmit = new ReviewToCreate(
        timeReviewed as Date,
        (userReviewed as SelectOption).value,
        (questionnaireId as SelectOption).value,
        usersReviewing.map((user) => user.value),
      )
      createReview(dataToSubmit)

      setRedirect(true)
    }
  }

  const handleCancelButtonClick = (): void => setRedirect(true)

  const userReviewedDetails = (userReviewed)
    && activeUsers.find((user) => user.id === userReviewed.value)
  const userReviewedUserGroups = userReviewedDetails && userReviewedDetails.userGroups
    .map((userGroupId) => userGroups.find((userGroup) => userGroup.id === userGroupId))
  const selectedUserIds = usersReviewing.map((userReviewing) => userReviewing.value)
  const availableUserReviews = isAdmin ? adminUserReviews : managedUserReviews
  const userReviewedAllReviews = availableUserReviews
    .filter((review) => userReviewed && review.userReviewedId === userReviewed.value)
  const { previous } = findReviewsPreviousNext(userReviewedAllReviews)

  if (redirect) return <Redirect to={homeUrl()} />
  return (
    <>
      <Hero
        title="Create a new performance review"
        tooltip="You can also select (or deselect) staff to request feedback from using the following lists."
      />
      <div className="main">
        <div className="grid">
          <form>
            <div>
              <label className="form-date" htmlFor="timeReviewed">
                <p>Date of review:</p>
                <DatePicker
                  id="timeReviewed"
                  selected={timeReviewed}
                  onChange={handleChangeDate}
                  customInput={<InputDate />}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeCaption="time"
                  dateFormat="d/M/yyyy H:mm"
                  minDate={new Date()}
                  fixedHeight
                  placeholderText="Select a date and time"
                  filterDate={weekDays}
                />
              </label>
              {errorDateReviewing && (
                <div className="form-error">{errorDateReviewing}</div>
              )}
              <label htmlFor="userReviewedId">
                <p>Review performance of:</p>
                <Select
                  classNamePrefix="select"
                  className="select-box"
                  id="userReviewedId"
                  onChange={(selected: ValueType<SelectOption>): void => handleChangeUserReviewed((selected as SelectOption))}
                  value={userReviewed}
                  options={userReviewedIdOptions}
                  isDisabled={Boolean(fixedUserReviewedId)}
                />
              </label>
              {errorUserReviewedId && (
                <div className="form-error">{errorUserReviewedId}</div>
              )}
              <label htmlFor="questionnaireId">
                <p>Questionnaire to use:</p>
                <Select
                  classNamePrefix="select"
                  className="select-box"
                  id="questionnaireId"
                  onChange={(selected: ValueType<SelectOption>): void => handleChangeQuestionnaire((selected as SelectOption))}
                  value={questionnaireId}
                  options={questionnaireIdOptions}
                />
              </label>
              {errorQuestionnaireId && (
                <div className="form-error">{errorQuestionnaireId}</div>
              )}
            </div>
            <div className="user-selection">
              <label htmlFor="usersReviewing">
                <p>Request feedback from:</p>
                <Select
                  classNamePrefix="select"
                  className="select-box"
                  id="usersReviewing"
                  isMulti
                  onChange={(selected: ValueType<SelectOption>): void => handleChangeUsersReviewing((selected as SelectOption[]))}
                  value={usersReviewing}
                  options={usersReviewingOptions}
                />
              </label>
              {errorUsersReviewing && (
                <div className="form-error">{errorUsersReviewing}</div>
              )}
            </div>
            <ButtonGroup
              buttons={[
                {
                  className: 'btn btn-clear',
                  label: 'Cancel',
                  onClick: handleCancelButtonClick,
                  type: 'button',
                },
                {
                  className: 'btn btn-primary',
                  label: 'Create review',
                  onClick: handleSubmit,
                  type: 'button',
                },
              ]}
            />
          </form>
          {(userReviewed) && (
            <div className="card">
              <p>You can also select (or deselect) staff to request feedback from using the following lists.</p>
              <ReviewFormTimesheetReviewList
                userToBeReviewedId={userReviewed.value}
                selectedUserIds={selectedUserIds}
                handleAddUserReviewing={handleAddUserReviewing}
                handleAddUsersReviewing={handleAddUsersReviewing}
                handleRemoveUserReviewing={handleRemoveUserReviewing}
                handleRemoveUsersReviewing={handleRemoveUsersReviewing}
              />
              <ReviewFormForecastReviewList
                userToBeReviewedId={userReviewed.value}
                selectedUserIds={selectedUserIds}
                handleAddUserReviewing={handleAddUserReviewing}
                handleAddUsersReviewing={handleAddUsersReviewing}
                handleRemoveUserReviewing={handleRemoveUserReviewing}
                handleRemoveUsersReviewing={handleRemoveUsersReviewing}
              />
              {previous && (
                <ReviewFormPreviousReviewList
                  review={previous}
                  users={activeUsers}
                  selectedUserIds={selectedUserIds}
                  handleAddUserReviewing={handleAddUserReviewing}
                  handleAddUsersReviewing={handleAddUsersReviewing}
                  handleRemoveUserReviewing={handleRemoveUserReviewing}
                  handleRemoveUsersReviewing={handleRemoveUsersReviewing}
                />
              )}
              {(userReviewedUserGroups && userReviewedUserGroups.map((userGroup) => userGroup && (
                <ReviewFormUserGroupList
                  key={userGroup.id}
                  userGroup={userGroup}
                  userReviewedId={userReviewed.value}
                  users={activeUsers}
                  selectedUserIds={selectedUserIds}
                  handleAddUserReviewing={handleAddUserReviewing}
                  handleAddUsersReviewing={handleAddUsersReviewing}
                  handleRemoveUserReviewing={handleRemoveUserReviewing}
                  handleRemoveUsersReviewing={handleRemoveUsersReviewing}
                />
              )))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
