import React, { FC } from 'react'

import { UserWithDetails } from 'review-app-shared/types/user'
import { UserGroup } from 'review-app-shared/types/user-group'

import { ReviewerSuggestionSubgroup } from './ReviewerSuggestionSubgroup'

interface ReviewFormUserGroupListProps {
  userGroup: UserGroup
  users: UserWithDetails[]
  userReviewedId: number
  selectedUserIds: number[]
  lockedUserIds?: number[]
  handleAddUserReviewing: (userId: number) => void
  handleAddUsersReviewing: (userIds: number[]) => void
  handleRemoveUserReviewing: (userId: number) => void
  handleRemoveUsersReviewing: (userIds: number[]) => void
}

// The ReviewFormUserGroupList component shows a manager who belongs to a common user
// group with the review subject, rendering the users as buttons to enable them
// to be added or removed from the list for the next review (individually or collectively).
export const ReviewFormUserGroupList: React.FC<ReviewFormUserGroupListProps> = ({
  userGroup,
  users,
  userReviewedId,
  selectedUserIds,
  lockedUserIds = [],
  handleAddUserReviewing,
  handleAddUsersReviewing,
  handleRemoveUserReviewing,
  handleRemoveUsersReviewing,
}) => {
  if (!userGroup) return null

  const usersToShow = users.filter((user) => user.userGroups
    .includes(userGroup.id) && user.id !== userReviewedId)

  if (!usersToShow.length) return null

  return (
    <ReviewerSuggestionSubgroup
      title={`Same user group (${userGroup.name}):`}
      users={usersToShow}
      selectedUserIds={selectedUserIds}
      lockedUserIds={lockedUserIds}
      handleAddUserReviewing={handleAddUserReviewing}
      handleAddUsersReviewing={handleAddUsersReviewing}
      handleRemoveUserReviewing={handleRemoveUserReviewing}
      handleRemoveUsersReviewing={handleRemoveUsersReviewing}
    />
  )
}
