import React, { FC } from 'react'

import { FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { MarkdownEditor } from '../editor/Editor'

interface FeedbackReportAnswerProps {
  questionId: number
  feedback: FeedbackWithQuestionsAndAnswers
}

// The FeedbackReportAnswer component renders the answer and comment for an
// individual question for a feedback report
export const FeedbackReportAnswer: FC<FeedbackReportAnswerProps> = ({
  questionId,
  feedback: { questions, answers },
}) => {
  const question = questions.find((eachQuestion) => eachQuestion.id === questionId)
  const answer = answers.find((eachAnswer) => eachAnswer.questionId === questionId)
  if (!answer) return null

  const { chosenAnswer, textComment } = answer
  const selectedResponse = question?.responseOptions?.options
    .find((option) => option.value === chosenAnswer)
  const responseText = (selectedResponse)
    ? selectedResponse.name
    : 'no option selected'
  return (
    <div className="card mt-40 mb-40">
      {(question?.optionsId) && (
        <p>
          Assessment:
          <br />
          {responseText}
        </p>
      )}
      {(textComment) && (
        <>
          <p>Comment:</p>
          <MarkdownEditor readonly initialValue={textComment} />
        </>
      )}
    </div>
  )
}
