import { UserGroup } from './user-group'

export class User {
  constructor(
    readonly id: number,
    readonly username: string,
    readonly isAdmin: boolean,
    readonly email: string,
    readonly active: boolean,
    readonly employmentStartYear?: number,
    readonly annualReviewMonth?: number,
  ) {
  }
}

export class UserWithDetails extends User {
  constructor(
    id: number,
    username: string,
    isAdmin: boolean,
    email: string,
    active: boolean,
    readonly managers: Array<User['id']>,
    readonly managedUsers: Array<User['id']>,
    readonly userGroups: Array<UserGroup['id']>,
    employmentStartYear?: number,
    annualReviewMonth?: number,
  ) {
    super(id, username, isAdmin, email, active, employmentStartYear, annualReviewMonth)
  }
}

export class UserToCreateOrUpdate {
  constructor(
    readonly username: string,
    readonly isAdmin: boolean,
    readonly email: string,
    readonly active: boolean,
    readonly managers: Array<User['id']>,
    readonly managedUsers: Array<User['id']>,
    readonly userGroups: Array<UserGroup['id']>,
    readonly employmentStartYear?: number,
    readonly annualReviewMonth?: number,
  ) {
  }
}
