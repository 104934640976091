import React, {
  FC,
  useState,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
} from 'react'

import { ResponseOptions, ResponseOptionsToCreate } from 'review-app-shared/types/response-options'
import { Option } from 'review-app-shared/types/option'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { ResponseOptionsFormEdit } from 'app/components/forms/ResponseOptionsFormEdit'
import { ResponseOptionsFormView } from 'app/components/forms/ResponseOptionsFormView'

interface ResponseOptionsFormProps {
  responseOptions: ResponseOptions[]
  responseOptionsIdsInUse: Set<number>
  createResponseOptions: (responseOptionsToCreate: ResponseOptionsToCreate) => void
  deleteResponseOptions: (responseOptionsId: number) => void
}

// The ResponseOptionsForm component enables response options to be viewed, created and deleted
export const ResponseOptionsForm: FC<ResponseOptionsFormProps> = ({
  responseOptions,
  responseOptionsIdsInUse,
  createResponseOptions,
  deleteResponseOptions,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedResponseOptions, setSelectedResponseOptions] = useState<SelectOption | null>(null)
  const [name, setName] = useState('')
  const [options, setOptions] = useState<string[]>([])
  const [optionToAdd, setOptionToAdd] = useState('')
  const [errorName, setErrorName] = useState<string | null>(null)
  const [errorOptions, setErrorOptions] = useState<string | null>(null)

  const namesInUse = responseOptions.map((option) => option.name)
  const responseOptionsForSelect: SelectOption[] = responseOptions.map((responseOption) => ({
    value: responseOption.id,
    label: responseOption.name.concat((responseOptionsIdsInUse.has(responseOption.id) ? ' [in use]' : '')),
  })).sort((a, b) => a.label.localeCompare(b.label))

  const validateName = (candidateName: string): boolean => {
    if (!candidateName) {
      setErrorName('The response options must have a name.')
      return false
    }
    if (namesInUse.includes(candidateName)) {
      setErrorName(`Names must be unique, ${candidateName} is already in use.`)
      return false
    }
    setErrorName(null)
    return true
  }

  const validateOption = (candidateOption: string): boolean => {
    if (!candidateOption) {
      setErrorOptions('The option must have a name.')
      return false
    }
    if (options.includes(candidateOption)) {
      setErrorOptions('Options must be unique')
      return false
    }
    setErrorOptions(null)
    return true
  }

  const validateOptions = (candidateOptions: string[]): boolean => {
    if (candidateOptions.length < 2) {
      setErrorOptions('There must be at least two options to choose from.')
      return false
    }
    return true
  }

  const validateForm = (): boolean => validateName(name) && validateOptions(options)

  const handleSelectResponseOptions = (selected: SelectOption): void => setSelectedResponseOptions(selected)

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
    validateName(event.target.value)
  }

  const handleChangeOptionToAdd = (event: ChangeEvent<HTMLInputElement>): void => setOptionToAdd(event.target.value)

  const handleOptionToAddButtonClick = (): void => {
    if (validateOption(optionToAdd)) {
      setOptions([...options, optionToAdd])
      setOptionToAdd('')
    }
  }

  const handleOptionToAddKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleOptionToAddButtonClick()
    }
  }

  const handleOptionMoveUpButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const position = options.indexOf(event.currentTarget.name)
    if (position > 0) {
      const newOptions = [...options]
      newOptions[position - 1] = options[position]
      newOptions[position] = options[position - 1]
      setOptions(newOptions)
    }
  }

  const handleOptionMoveDownButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const position = options.indexOf(event.currentTarget.name)
    if (position < options.length - 1) {
      const newOptions = [...options]
      newOptions[position + 1] = options[position]
      newOptions[position] = options[position + 1]
      setOptions(newOptions)
    }
  }

  const handleOptionEditButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setOptionToAdd(event.currentTarget.name)
    setOptions([...options].filter((option) => option !== event.currentTarget.name))
  }

  const handleOptionDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setOptions([...options].filter((option) => option !== event.currentTarget.name))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (validateForm()) {
      const optionsToSubmit = options.map((option, index) => new Option(option, index))
      const dataToSubmit = new ResponseOptionsToCreate(name, optionsToSubmit)
      createResponseOptions(dataToSubmit)

      setIsEditing(false)
    }
  }

  const handleCancelButtonClick = (): void => {
    setName('')
    setOptions([])
    setIsEditing(false)
    setErrorName(null)
    setErrorOptions(null)
  }

  const handleCreateButtonClick = (): void => setIsEditing(true)

  const handleCreateBasedOnCurrentButtonClick = (): void => {
    const matchingResponseOptions = selectedResponseOptions && responseOptions
      .find((option) => option.id === selectedResponseOptions.value)
    if (matchingResponseOptions) {
      setName(matchingResponseOptions.name.concat(' (updated)'))
      setOptions(matchingResponseOptions.options.map((option) => option.name))
    }
    setIsEditing(true)
  }

  const handleDeleteButtonClick = (): void => {
    if (selectedResponseOptions) {
      deleteResponseOptions(selectedResponseOptions.value)
    }
    setSelectedResponseOptions(null)
  }

  return (
    <>
      {(isEditing) ? (
        <ResponseOptionsFormEdit
          errorName={errorName}
          errorOptions={errorOptions}
          handleCancelButtonClick={handleCancelButtonClick}
          handleChangeName={handleChangeName}
          handleChangeOptionToAdd={handleChangeOptionToAdd}
          handleOptionDeleteButtonClick={handleOptionDeleteButtonClick}
          handleOptionEditButtonClick={handleOptionEditButtonClick}
          handleOptionMoveDownButtonClick={handleOptionMoveDownButtonClick}
          handleOptionMoveUpButtonClick={handleOptionMoveUpButtonClick}
          handleOptionToAddButtonClick={handleOptionToAddButtonClick}
          handleOptionToAddKeyDown={handleOptionToAddKeyDown}
          handleSubmit={handleSubmit}
          name={name}
          options={options}
          optionToAdd={optionToAdd}
        />
      ) : (
        <ResponseOptionsFormView
          handleCreateBasedOnCurrentButtonClick={handleCreateBasedOnCurrentButtonClick}
          handleCreateButtonClick={handleCreateButtonClick}
          handleDeleteButtonClick={handleDeleteButtonClick}
          handleSelectResponseOptions={handleSelectResponseOptions}
          responseOptions={responseOptions}
          responseOptionsForSelect={responseOptionsForSelect}
          responseOptionsIdsInUse={responseOptionsIdsInUse}
          selectedResponseOptions={selectedResponseOptions}
        />
      )}
    </>
  )
}
