// Helper functions to aid filtering of table data

// simplify a string for more effective filtering (eliminate upper case and diacritics)
export const simplifyString = (string: string): string => {
  if (!string) return ''
  let newString = string.trim().toLowerCase()
  newString = newString.replace(new RegExp('[áàäåâāăąã]', 'g'), 'a')
  newString = newString.replace(new RegExp('[čçćĉċ]', 'g'), 'c')
  newString = newString.replace(new RegExp('[ďđ]', 'g'), 'd')
  newString = newString.replace(new RegExp('[éěèêëēĕėę]', 'g'), 'e')
  newString = newString.replace(new RegExp('[ĝğġģ]', 'g'), 'g')
  newString = newString.replace(new RegExp('[ĥħ]', 'g'), 'h')
  newString = newString.replace(new RegExp('[íìîïĩīĭįı]', 'g'), 'i')
  newString = newString.replace(new RegExp('ĵ', 'g'), 'j')
  newString = newString.replace(new RegExp('ķ', 'g'), 'k')
  newString = newString.replace(new RegExp('[ĺļľŀł]', 'g'), 'l')
  newString = newString.replace(new RegExp('[ňńņñ]', 'g'), 'n')
  newString = newString.replace(new RegExp('[óöøòôōŏőõ]', 'g'), 'o')
  newString = newString.replace(new RegExp('[řŕŗ]', 'g'), 'r')
  newString = newString.replace(new RegExp('[šśŝş]', 'g'), 's')
  newString = newString.replace(new RegExp('[ťţŧ]', 'g'), 't')
  newString = newString.replace(new RegExp('[úůùüûũūŭűų]', 'g'), 'u')
  newString = newString.replace(new RegExp('[ŵẃẁẅ]', 'g'), 'w')
  newString = newString.replace(new RegExp('[ýŷỳÿ]', 'g'), 'y')
  newString = newString.replace(new RegExp('[žźż]', 'g'), 'z')
  return newString
}

// return true if target includes filter, simplifying as above
export const filterMatchString = (
  target: string,
  filter: string,
): boolean => simplifyString(target).includes(simplifyString(filter))

// return true if target date, when converted to DD/MM/YYYY, includes filter
export const filterMatchDate = (
  target: Date,
  filter: string,
): boolean => target.toLocaleDateString().includes(filter.trim())
