import { api, ApiResponse } from 'app/api/api-client'
import {
  Review,
  ReviewWithFeedback,
  ReviewToCreate,
  ReviewUpdateData,
} from 'review-app-shared/types/review'

export const apiGetReviewById = async (
  reviewId: string,
  token: string,
): ApiResponse<ReviewWithFeedback> => api.get(
  `/review/${reviewId}`,
  { headers: { Authorization: token } },
)

export const apiPostReviewForm = async (
  reviewToCreate: ReviewToCreate,
  token: string,
): ApiResponse<Review> => api.post(
  '/review',
  reviewToCreate,
  { headers: { Authorization: token } },
)

export const apiPutReviewForm = async (
  reviewId: string,
  reviewUpdateData: ReviewUpdateData,
  token: string,
): ApiResponse<ReviewWithFeedback> => api.put(
  `/review/${reviewId}`,
  reviewUpdateData,
  { headers: { Authorization: token } },
)

export const apiDeleteReview = async (
  reviewId: string,
  token: string,
): ApiResponse<void> => api.delete(
  `/review/${reviewId}`,
  { headers: { Authorization: token } },
)
