import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

export const questionStartGetQuestions = createAction('question/START_GET_QUESTIONS',
  (actionCallback) => () => actionCallback())

export const questionFinishGetQuestions = createAction('question/FINISH_GET_QUESTIONS',
  (actionCallback) => (questions: Question[]) => actionCallback(questions))

export const questionErrorGetQuestions = createAction('question/ERROR_GET_QUESTIONS',
  (actionCallback) => (error: Error) => actionCallback(error))

export const questionStartGetResponseOptions = createAction('question/START_GET_RESPONSE_OPTIONS',
  (actionCallback) => () => actionCallback())

export const questionFinishGetResponseOptions = createAction('question/FINISH_GET_RESPONSE_OPTIONS',
  (actionCallback) => (responseOptions: ResponseOptions[]) => actionCallback(responseOptions))

export const questionErrorGetResponseOptions = createAction('question/ERROR_GET_RESPONSE_OPTIONS',
  (actionCallback) => (error: Error) => actionCallback(error))

export type QuestionActions = ActionType<typeof questionStartGetQuestions | typeof questionFinishGetQuestions |
  typeof questionErrorGetQuestions | typeof questionStartGetResponseOptions |
  typeof questionFinishGetResponseOptions | typeof questionErrorGetResponseOptions>

export type QuestionStartGetQuestions = ActionType<typeof questionStartGetQuestions>
export type QuestionFinishGetQuestions = ActionType<typeof questionFinishGetQuestions>
export type QuestionErrorGetQuestions = ActionType<typeof questionErrorGetQuestions>
export type QuestionStartGetResponseOptions = ActionType<typeof questionStartGetResponseOptions>
export type QuestionFinishGetResponseOptions = ActionType<typeof questionFinishGetResponseOptions>
export type QuestionErrorGetResponseOptions = ActionType<typeof questionErrorGetResponseOptions>

export const QuestionStartGetQuestionsActionType = getType(questionStartGetQuestions)
export const QuestionFinishGetQuestionsActionType = getType(questionFinishGetQuestions)
export const QuestionErrorGetQuestionsActionType = getType(questionErrorGetQuestions)
export const QuestionStartGetResponseOptionsActionType = getType(questionStartGetResponseOptions)
export const QuestionFinishGetResponseOptionsActionType = getType(questionFinishGetResponseOptions)
export const QuestionErrorGetResponseOptionsActionType = getType(questionErrorGetResponseOptions)
