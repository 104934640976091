import React, { FC, MouseEvent, TouchEvent } from 'react'

export interface ButtonProps {
  label?: string
  type: 'submit' | 'reset' | 'button'
  className?: string
  disabled?: boolean
  onClick?: (event: MouseEvent| TouchEvent) => void
}

export const Button: FC<ButtonProps> = ({
  label, type, className, children, disabled, onClick,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button disabled={disabled} className={className} type={type} onClick={onClick} data-cy="button">
    {label}
    {children}
  </button>
)
