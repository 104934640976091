import React, { useMemo, FC, useCallback } from 'react'
import { Slate, Editable, withReact } from 'slate-react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'

import { Button } from 'app/components/components-ui/buttons/button'

import { useQuery } from 'app/hooks/useMobile'
import { useHotkey, useSerialization, withShortcuts } from './hooks'
import { Leaf, TextBlock } from './TextBlock'
import { Toolbar } from './Toolbar'

interface EditorProps {
  required?: boolean
  initialValue: string
  saveButton?: boolean
  readonly?: boolean
  updateValue?: (value: string) => void
}

export const MarkdownEditor: FC<EditorProps> = ({
  initialValue, updateValue, saveButton = false, readonly,
}) => {
  const [
    getDeserialized, setSerialized,
    getSerialized, setDeserialized,
  ] = useSerialization(initialValue)
  const saveValue = useCallback((): void => updateValue && updateValue(getSerialized()),
    [getSerialized, updateValue])

  const renderTextBlock = useCallback(({ children, ...props }) => <TextBlock {...props}>{children}</TextBlock>, [])
  const renderLeaf = useCallback(({ children, ...props }) => <Leaf {...props}>{children}</Leaf>, [])
  const editor = useMemo(
    () => withReact(withShortcuts(withHistory(createEditor()))),
    [],
  )

  const handleKeyDown = useHotkey(editor)

  // Workaround to Slate.js not working properly on Android devices
  const isMobile = useQuery('(max-width: 577px)')

  if (readonly || !updateValue) {
    return (
      <Slate
        editor={editor}
        value={getDeserialized()}
        onChange={setSerialized}
      >
        <Editable
          readOnly={readonly}
          className="card"
          renderElement={renderTextBlock}
          renderLeaf={renderLeaf}
          placeholder="Leave a comment"
        />
      </Slate>
    )
  }

  const SaveButton = () => (
    <>
      {saveButton
      && (
        <div className="h-right">
          <Button className="btn btn-primary mt-20" type="button" label="Save" onClick={saveValue} />
        </div>
      )}
    </>
  )

  if (isMobile) {
    return (
      <>
        <textarea
          className="card form-control"
          rows={20}
          cols={50}
          defaultValue={initialValue}
          onChange={(e) => {
            setDeserialized(e.target.value)
            if (!saveButton) {
              saveValue()
            }
          }}
        />
        <SaveButton />
      </>
    )
  }

  return (
    <>
      <Slate
        editor={editor}
        value={getDeserialized()}
        onChange={(v): void => {
          setSerialized(v)
          if (!saveButton) {
            saveValue()
          }
        }}
      >
        <Toolbar />
        <Editable
          readOnly={readonly}
          className="card"
          renderElement={renderTextBlock}
          renderLeaf={renderLeaf}
          placeholder="Leave a comment"
          spellCheck
          autoFocus
          onKeyDown={handleKeyDown}
        />
        <SaveButton />
      </Slate>
    </>
  )
}
