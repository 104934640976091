import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiPostQuestion,
  apiPutQuestion,
  apiDeleteQuestion,
} from 'app/modules/question-form/question-form-effects'
import { Question, QuestionToCreate } from 'review-app-shared/types/question'

import {
  questionFormFinishSend,
  questionFormFinishDelete,
  questionFormFinishEdit,
  questionFormError,
  QuestionFormStartSendActionType,
  QuestionFormStartSend,
  QuestionFormFinishSend,
  QuestionFormStartDeleteActionType,
  QuestionFormStartDelete,
  QuestionFormFinishDelete,
  QuestionFormStartEditActionType,
  QuestionFormStartEdit,
  QuestionFormFinishEdit,
  QuestionFormError,
  QuestionFormFinishSendActionType,
  QuestionFormFinishEditActionType,
  QuestionFormFinishDeleteActionType,
  QuestionFormErrorActionType,
} from 'app/modules/question-form/question-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getQuestionFormState } from './question-form-selectors'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleError(error: any): Generator<PutEffect<QuestionFormError>> {
  if ('response' in error && typeof error.response.data === 'string') {
    console.error(error.response)
    yield put(questionFormError(new Error(error.response.data)))
  } else {
    console.error(error)
    yield put(questionFormError(error))
  }
}

type CreateQuestionEffects = SelectEffect | CallEffect<AxiosResponse<QuestionToCreate> | void> |
PutEffect<QuestionFormFinishSend> | PutEffect<QuestionFormError>

export function* createQuestionSaga(
  action: QuestionFormStartSend,
): Generator<CreateQuestionEffects, void, string | AxiosResponse<Question>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostQuestion, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(questionFormFinishSend(response.data))
      }
    }
  } catch (error) {
    yield call(handleError, error)
  }
}

type DeleteQuestionEffects = SelectEffect | CallEffect<AxiosResponse<void> | void> |
PutEffect<QuestionFormFinishDelete> | PutEffect<QuestionFormError>

export function* deleteQuestionSaga(
  action: QuestionFormStartDelete,
): Generator<DeleteQuestionEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteQuestion, action.payload, token)
      if (response) yield put(questionFormFinishDelete(reviewId))
    }
  } catch (error) {
    yield call(handleError, error)
  }
}


type EditQuestionEffects = SelectEffect | CallEffect<AxiosResponse<Question> | void> |
PutEffect<QuestionFormFinishEdit> | PutEffect<QuestionFormError>

export function* editQuestionSaga(
  action: QuestionFormStartEdit,
): Generator<EditQuestionEffects, void, string | AxiosResponse<Question>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutQuestion, action.payload.id, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(questionFormFinishEdit(response.data))
      }
    }
  } catch (error) {
    yield call(handleError, error)
  }
}

export function* toastSaved(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getQuestionFormState>> {
  const formState: ReturnType<typeof getQuestionFormState> = yield select(getQuestionFormState)
  if (formState.kind === FormStatus.Saved || formState.kind === FormStatus.Edited) {
    yield call(toast.info, `You have successfully created or updated ${formState.data?.category}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastDelete(): Generator<CallEffect<string | number>> {
  yield call(toast.info, 'You have successfully deleted the question.', {
    position: toast.POSITION.TOP_CENTER,
  })
}

export function* toastError(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getQuestionFormState>> {
  const formState: ReturnType<typeof getQuestionFormState> = yield select(getQuestionFormState)
  if (formState.kind === FormStatus.Error) {
    yield call(toast.error, formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* questionFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(QuestionFormStartSendActionType, createQuestionSaga)
  yield takeEvery(QuestionFormStartDeleteActionType, deleteQuestionSaga)
  yield takeEvery(QuestionFormStartEditActionType, editQuestionSaga)
  yield takeEvery(QuestionFormFinishSendActionType, toastSaved)
  yield takeEvery(QuestionFormFinishEditActionType, toastSaved)
  yield takeEvery(QuestionFormFinishDeleteActionType, toastDelete)
  yield takeEvery(QuestionFormErrorActionType, toastError)
}
