// generic types applicable to any form

export enum FormStatus { Blank, Loading, Loaded, Saving, Saved, Deleting, Deleted, Editing, Edited, Error }

export interface BlankForm {
  kind: FormStatus.Blank
}
export interface LoadingData {
  kind: FormStatus.Loading
}
export interface LoadedData<TData> {
  kind: FormStatus.Loaded
  data: TData
}
export interface SavingData<TSubmit> {
  kind: FormStatus.Saving
  data: TSubmit
}
export interface SavedData<TData> {
  kind: FormStatus.Saved
  data: TData
}
export interface DeletingData {
  kind: FormStatus.Deleting
}
export interface DeletedData {
  kind: FormStatus.Deleted
}

export interface EditingData<TData> {
  kind: FormStatus.Editing
  data: TData
}
export interface EditedData<TData> {
  kind: FormStatus.Edited
  data: TData
}

export interface ErrorData {
  kind: FormStatus.Error
  errorMessage: string
}

export type FormDataResult<TData, TSubmit> = BlankForm | LoadingData | LoadedData<TData> |
SavingData<TSubmit> | SavedData<TData> | DeletingData | DeletedData | EditingData<TData> |
EditedData<TData> | ErrorData

export const getBlank = (): BlankForm => ({ kind: FormStatus.Blank })
export const getLoading = (): LoadingData => ({ kind: FormStatus.Loading })
export const getLoaded = <TData>(data: TData): LoadedData<TData> => ({ kind: FormStatus.Loaded, data })
export const getSaving = <TSubmit>(data: TSubmit): SavingData<TSubmit> => ({ kind: FormStatus.Saving, data })
export const getSaved = <TData>(data: TData): SavedData<TData> => ({ kind: FormStatus.Saved, data })
export const getDeleting = (): DeletingData => ({ kind: FormStatus.Deleting })
export const getDeleted = (): DeletedData => ({ kind: FormStatus.Deleted })
export const getEditing = <TData>(data: TData): EditingData<TData> => ({ kind: FormStatus.Editing, data })
export const getEdited = <TData>(data: TData): EditedData<TData> => ({ kind: FormStatus.Edited, data })
export const getError = (errorMessage: string): ErrorData => ({ kind: FormStatus.Error, errorMessage })
