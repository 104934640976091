import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { LoadStatus } from 'review-app-shared/types/fetchData'
import { getTimesheetCollaborators } from '../../modules/timesheet/timesheet-selectors'
import { timesheetStartGetTimesheets } from '../../modules/timesheet/timesheet-actions'
import { ReviewerSuggestionSubgroup } from './ReviewerSuggestionSubgroup'

interface ReviewFormTimesheetReviewListProps {
  userToBeReviewedId: number
  selectedUserIds: number[]
  lockedUserIds?: number[]
  handleAddUserReviewing: (userId: number) => void
  handleAddUsersReviewing: (userIds: number[]) => void
  handleRemoveUserReviewing: (userId: number) => void
  handleRemoveUsersReviewing: (userIds: number[]) => void
}

/** Uses the Salsita Timesheet app to show who the person worked with since their last review */
export const ReviewFormTimesheetReviewList: React.FC<ReviewFormTimesheetReviewListProps> = ({
  userToBeReviewedId,
  selectedUserIds,
  lockedUserIds = [],
  handleAddUserReviewing,
  handleAddUsersReviewing,
  handleRemoveUserReviewing,
  handleRemoveUsersReviewing,
}) => {
  const userList = useSelector(getTimesheetCollaborators)
  const dispatch = useDispatch()

  useEffect((): void => {
    dispatch(timesheetStartGetTimesheets(userToBeReviewedId))
  }, [dispatch, userToBeReviewedId])

  if (userList.kind === LoadStatus.Loading) {
    return <div>Loading timesheet suggestions...</div>
  }

  if (userList.kind === LoadStatus.NotStarted || userList.kind === LoadStatus.Error || userList?.data?.length === 0) {
    return null
  }

  return (
    <ReviewerSuggestionSubgroup
      title="Timesheet app suggests:"
      users={userList.data}
      selectedUserIds={selectedUserIds}
      lockedUserIds={lockedUserIds}
      handleAddUserReviewing={handleAddUserReviewing}
      handleAddUsersReviewing={handleAddUsersReviewing}
      handleRemoveUserReviewing={handleRemoveUserReviewing}
      handleRemoveUsersReviewing={handleRemoveUsersReviewing}
    />
  )
}
