import { fork, ForkEffect } from 'redux-saga/effects'

import { authSaga } from 'app/modules/auth/auth-saga'
import { questionSaga } from 'app/modules/question/question-saga'
import { reviewSaga } from 'app/modules/review/review-saga'
import { userSaga } from 'app/modules/user/user-saga'
import { feedbackFormSaga } from 'app/modules/feedback-form/feedback-form-saga'
import { questionFormSaga } from 'app/modules/question-form/question-form-saga'
import { questionnaireFormSaga } from 'app/modules/questionnaire-form/questionnaire-form-saga'
import { responseOptionsFormSaga } from 'app/modules/response-options-form/response-options-form-saga'
import { reviewFormSaga } from 'app/modules/review-form/review-form-saga'
import { userGroupFormSaga } from 'app/modules/user-group-form/user-group-form-saga'
import { userFormSaga } from 'app/modules/user-form/user-form-saga'
import { forecastSaga } from 'app/modules/forecast/forecast-saga'
import { timesheetSaga } from '../timesheet/timesheet-saga'

export function* rootSaga(): Generator<ForkEffect<void>, void, unknown> {
  yield fork(authSaga)
  yield fork(questionSaga)
  yield fork(reviewSaga)
  yield fork(userSaga)
  yield fork(feedbackFormSaga)
  yield fork(questionFormSaga)
  yield fork(questionnaireFormSaga)
  yield fork(responseOptionsFormSaga)
  yield fork(reviewFormSaga)
  yield fork(userGroupFormSaga)
  yield fork(userFormSaga)
  yield fork(forecastSaga)
  yield fork(timesheetSaga)
}
