import {
  ResponseOptionsFormActions,
  ResponseOptionsFormStartSendActionType,
  ResponseOptionsFormFinishSendActionType,
  ResponseOptionsFormStartDeleteActionType,
  ResponseOptionsFormFinishDeleteActionType,
  ResponseOptionsFormErrorActionType,
} from 'app/modules/response-options-form/response-options-form-actions'

import { ResponseOptions, ResponseOptionsToCreate } from 'review-app-shared/types/response-options'
import {
  FormDataResult,
  getBlank,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getError,
} from 'review-app-shared/types/formData'

export type ResponseOptionsFormState = FormDataResult<ResponseOptions, ResponseOptionsToCreate>

const initialState = getBlank()

export const responseOptionsFormReducer = (
  state: ResponseOptionsFormState = initialState,
  action: ResponseOptionsFormActions,
): ResponseOptionsFormState => {
  switch (action.type) {
    case ResponseOptionsFormStartSendActionType:
      return getSaving(action.payload)
    case ResponseOptionsFormFinishSendActionType:
      return getSaved(action.payload)
    case ResponseOptionsFormStartDeleteActionType:
      return getDeleting()
    case ResponseOptionsFormFinishDeleteActionType:
      return getDeleted()
    case ResponseOptionsFormErrorActionType:
      return getError(action.payload.message)
    default:
      return state
  }
}
