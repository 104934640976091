import React, { FC, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'

import { Table } from 'app/components/tables/Table'
import { TableHeaderUserList } from 'app/components/tables/TableHeaderUserList'
import { TableRowUserList } from 'app/components/tables/TableRowUserList'

import { getLoadedUsersPopulated } from 'app/modules/user/user-selectors'
import { filterMatchString } from 'app/helpers/filter'

// The UserList component displays a table of users
export const UserList: FC = () => {
  const users = useSelector(getLoadedUsersPopulated)
  const [usersFilter, setUsersFilter] = useState('')
  const handleChangeUsersFilter = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => setUsersFilter(e.target.value)

  const filteredUsers = users.filter(({ username, email }) => {
    const filterMatch = filterMatchString(username, usersFilter) || filterMatchString(email, usersFilter)
    return !usersFilter || filterMatch
  })

  return (
    <>
      <p>Registered users are listed in the table below:</p>
      <Table
        className="user-list-table"
        headerRow={<TableHeaderUserList />}
        bodyRows={filteredUsers.map((user) => (
          <TableRowUserList
            key={user.id}
            user={user}
          />
        ))}
        showFilter
        filter={usersFilter}
        handleChangeFilter={handleChangeUsersFilter}
        filterPlaceholder="Filter by name or email address"
      />
    </>
  )
}
