import { api, ApiResponse } from 'app/api/api-client'
import { ResponseOptions, ResponseOptionsToCreate } from 'review-app-shared/types/response-options'

export const apiPostResponseOptions = async (
  responseOptionsToCreate: ResponseOptionsToCreate,
  token: string,
): ApiResponse<ResponseOptions> => api.post(
  '/response-options',
  responseOptionsToCreate,
  { headers: { Authorization: token } },
)

export const apiDeleteResponseOptions = async (
  responseOptionsId: number,
  token: string,
): ApiResponse<void> => api.delete(
  `/response-options/${responseOptionsId}`,
  { headers: { Authorization: token } },
)
