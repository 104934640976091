import {
  QuestionFormActions,
  QuestionFormStartSendActionType,
  QuestionFormFinishSendActionType,
  QuestionFormStartDeleteActionType,
  QuestionFormFinishDeleteActionType,
  QuestionFormStartEditActionType,
  QuestionFormFinishEditActionType,
  QuestionFormErrorActionType,
} from 'app/modules/question-form/question-form-actions'

import { Question, QuestionToCreate } from 'review-app-shared/types/question'
import {
  FormDataResult,
  getBlank,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getEdited,
  getEditing,
  getError,
} from 'review-app-shared/types/formData'

export type QuestionFormState = FormDataResult<Question, QuestionToCreate>

const initialState = getBlank()

export const questionFormReducer = (
  state: QuestionFormState = initialState,
  action: QuestionFormActions,
): QuestionFormState => {
  switch (action.type) {
    case QuestionFormStartSendActionType:
      return getSaving(action.payload)
    case QuestionFormFinishSendActionType:
      return getSaved(action.payload)
    case QuestionFormStartDeleteActionType:
      return getDeleting()
    case QuestionFormFinishDeleteActionType:
      return getDeleted()
    case QuestionFormStartEditActionType:
      return getEditing(action.payload)
    case QuestionFormFinishEditActionType:
      return getEdited(action.payload)
    case QuestionFormErrorActionType:
      return getError(action.payload.message)
    default:
      return state
  }
}
