import React, { FC, useState } from 'react'
import { Unknown } from 'app/components/Unknown'

import { UserDetails } from './UserDetails'
import { UserFormWrapper as UserForm } from './UserFormWrapper'

interface ViewUserProps {
  userId: number
}

enum ViewState { Edit, View }

export const ViewUser: FC<ViewUserProps> = ({ ...rest }) => {
  const [viewState, setViewState] = useState<ViewState>(ViewState.View)

  switch (viewState) {
    case ViewState.View:
      return <UserDetails switchView={(): void => setViewState(ViewState.Edit)} {...rest} />
    case ViewState.Edit:
      return <UserForm switchView={(): void => setViewState(ViewState.View)} {...rest} />
    default:
      return <Unknown />
  }
}
