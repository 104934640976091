import React, { FC } from 'react'

import { completeFeedback, Feedback } from 'review-app-shared/types/feedback'
import { UserWithDetails } from 'review-app-shared/types/user'

import { FeedbackStatus } from 'app/components/components-ui/feedback/FeedbackStatus'

interface ReviewSummaryFeedbackStatusItemProps {
  feedback: Feedback
  reviewerName: string
}
// The ReviewSummaryFeedbackStatusItem component summarises the current status of
// an individual feedback request
export const ReviewSummaryFeedbackStatusItem: FC<ReviewSummaryFeedbackStatusItemProps> = ({ feedback, reviewerName }) => (
  <li>
    {`${reviewerName}: `}
    <FeedbackStatus feedback={feedback} />
  </li>
)

interface ReviewSummaryFeedbackStatusProps {
  linkedFeedback: Array<Feedback>
  users: Array<UserWithDetails>
}
// The ReviewSummaryFeedbackStatus component summarises the status of outstanding
// feedback requests from the list provided as a prop
export const ReviewSummaryFeedbackStatus: FC<ReviewSummaryFeedbackStatusProps> = ({
  linkedFeedback,
  users,
}) => {
  const allFeedbackComplete = linkedFeedback.every(({ state }) => completeFeedback.includes(state))

  if (allFeedbackComplete) {
    return (
      <div className="alert alert-success">
        All feedback has now been received.
      </div>
    )
  }
  return (
    <ul className="card mb-0">
      {linkedFeedback.map((feedback) => {
        const reviewerDetails = users
          .find((user) => user.id === feedback.reviewerUserId)
        if (reviewerDetails) {
          return (
            <ReviewSummaryFeedbackStatusItem
              key={feedback.id}
              feedback={feedback}
              reviewerName={reviewerDetails.username}
            />
          )
        }
        return null
      })}
    </ul>
  )
}
