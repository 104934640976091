// Helper functions to ensure generated URLs are consistent
import { ADVANCE_REVIEW_SCHEDULING } from '../constants/routes'

export const feedbackReportUrl = (feedbackId: number): string => `/feedback/${feedbackId}`

export const feedbackFormUrl = (feedbackId: number): string => `/feedback-form/${feedbackId}`

export const reviewSummaryUrl = (reviewId: number): string => `/review-summary/${reviewId}`

export const createReviewFormUrl = (reviewId?: number): string => {
  if (reviewId) return `/create-review-form/${reviewId}`
  return '/create-review-form'
}

export const updateReviewFormUrl = (reviewId: number): string => `/update-review-form/${reviewId}`

export const userProfileUrl = (userId: number): string => `/user/${userId}`
export const newUserUrl = (): string => '/user'

// include static URLs in case they ever need to change
export const homeUrl = (): string => '/'

export const ownReviewsUrl = (): string => '/own'

export const reviewerUrl = (): string => '/reviewer'

export const advanceReviewSchedulingUrl = (): string => ADVANCE_REVIEW_SCHEDULING

export const questionManagementUrl = (): string => '/question-admin'

export const userManagementUrl = (): string => '/user-admin'

export const responseOptionsFormUrl = (): string => '/response-options-form'

export const questionFormUrl = (): string => '/question-form'

export const questionnaireFormUrl = (): string => '/questionnaire-form'

export const userGroupFormUrl = (): string => '/user-group-form'
