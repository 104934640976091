import {
  UserActions,
  UserStartGetUsersActionType,
  UserFinishGetUsersActionType,
  UserErrorGetUsersActionType,
  UserStartGetUserGroupsActionType,
  UserFinishGetUserGroupsActionType,
  UserErrorGetUserGroupsActionType,
} from 'app/modules/user/user-actions'
import { AuthFinishLogout, AuthFinishLogoutActionType } from 'app/modules/auth/auth-actions'
import {
  UserFormCreateFinishSend,
  UserFormCreateFinishSendActionType,
  UserFormUpdateFinishSend,
  UserFormUpdateFinishSendActionType,
  UserFormFinishDelete,
  UserFormFinishDeleteActionType,
} from 'app/modules/user-form/user-form-actions'
import {
  UserGroupFormFinishSend,
  UserGroupFormFinishSendActionType,
  UserGroupFormFinishDelete,
  UserGroupFormFinishDeleteActionType,
} from 'app/modules/user-group-form/user-group-form-actions'

import { UserWithDetails } from 'review-app-shared/types/user'
import { UserGroup } from 'review-app-shared/types/user-group'
import {
  FetchResult,
  LoadStatus,
  getLoading,
  getLoaded,
  getError,
} from 'review-app-shared/types/fetchData'

export type UserState = {
  users: FetchResult<UserWithDetails[]>
  userGroups: FetchResult<UserGroup[]>
}

const initialState = {
  users: getLoading(),
  userGroups: getLoading(),
}

type AllowedActionTypes = UserActions | AuthFinishLogout | UserGroupFormFinishSend | UserGroupFormFinishDelete
| UserFormCreateFinishSend | UserFormUpdateFinishSend | UserFormFinishDelete

export const userReducer = (state: UserState = initialState, action: AllowedActionTypes): UserState => {
  switch (action.type) {
    case AuthFinishLogoutActionType:
      return initialState
    case UserStartGetUsersActionType:
      return {
        ...state,
        users: getLoading(),
      }
    case UserFinishGetUsersActionType:
      return {
        ...state,
        users: getLoaded(action.payload),
      }
    case UserFormCreateFinishSendActionType: {
      if (state.users.kind !== LoadStatus.Loaded) return state
      const { id, managers, managedUsers } = action.payload
      const newUsers = [...state.users.data.map((user) => {
        if (managers.includes(user.id)) return { ...user, managedUsers: [...user.managedUsers, id] }
        if (managedUsers.includes(user.id)) return { ...user, managers: [...user.managers, id] }
        return user
      }), action.payload]
      return {
        ...state,
        users: getLoaded(newUsers),
      }
    }
    case UserFormUpdateFinishSendActionType: {
      if (state.users.kind !== LoadStatus.Loaded) return state
      const { id, managers, managedUsers } = action.payload
      const newUsers = [...state.users.data
        .filter((user) => user.id !== action.payload.id)
        .map((user) => ({ // strip out existing references
          ...user,
          managers: user.managers.filter((managerId) => managerId !== id),
          managedUsers: user.managedUsers.filter((managedUserId) => managedUserId !== id),
        }))
        .map((user) => { // add new references where required
          if (managers.includes(user.id)) return { ...user, managedUsers: [...user.managedUsers, id] }
          if (managedUsers.includes(user.id)) return { ...user, managers: [...user.managers, id] }
          return user
        }), action.payload]
      return {
        ...state,
        users: getLoaded(newUsers),
      }
    }
    case UserFormFinishDeleteActionType: {
      if (state.users.kind !== LoadStatus.Loaded) return state
      const newUsers = [...state.users.data].filter((user) => user.id !== action.payload)
      return {
        ...state,
        users: getLoaded(newUsers),
      }
    }
    case UserErrorGetUsersActionType:
      return {
        ...state,
        users: getError(action.payload.message),
      }
    case UserStartGetUserGroupsActionType:
      return {
        ...state,
        userGroups: getLoading(),
      }
    case UserFinishGetUserGroupsActionType:
      return {
        ...state,
        userGroups: getLoaded(action.payload),
      }
    case UserGroupFormFinishSendActionType: {
      if (state.userGroups.kind !== LoadStatus.Loaded) return state
      const newUserGroups = [...state.userGroups.data, action.payload]
      return {
        ...state,
        userGroups: getLoaded(newUserGroups),
      }
    }
    case UserGroupFormFinishDeleteActionType: {
      if (state.userGroups.kind !== LoadStatus.Loaded) return state
      const newUserGroups = [...state.userGroups.data].filter((userGroup) => userGroup.id !== action.payload)
      return {
        ...state,
        userGroups: getLoaded(newUserGroups),
      }
    }
    case UserErrorGetUserGroupsActionType:
      return {
        ...state,
        userGroups: getError(action.payload.message),
      }
    default:
      return state
  }
}
