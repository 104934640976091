import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { QuestionToCreate } from 'review-app-shared/types/question'

import { QuestionForm } from 'app/components/forms/QuestionForm'

import { getLoadedQuestionnaires } from 'app/modules/review/review-selectors'
import {
  getIsNotStartedQuestions,
  getIsNotStartedResponseOptions,
  getIsErrorQuestion,
  getIsLoadingQuestion,
  getLoadedQuestions,
  getLoadedResponseOptions,
} from 'app/modules/question/question-selectors'
import { questionStartGetQuestions, questionStartGetResponseOptions } from 'app/modules/question/question-actions'
import { questionFormStartSend, questionFormStartDelete, questionFormStartEdit } from 'app/modules/question-form/question-form-actions'

// The QuestionFormWrapper component enables an authorised user to create
// a new question or delete an existing (unused) question from a list.
export const QuestionFormWrapper: FC = () => {
  const questionnaires = useSelector(getLoadedQuestionnaires)
  const questions = useSelector(getLoadedQuestions)
  const responseOptions = useSelector(getLoadedResponseOptions)
  const isErrorQuestion = useSelector(getIsErrorQuestion)
  const isLoadingQuestion = useSelector(getIsLoadingQuestion)
  const isNotStartedQuestions = useSelector(getIsNotStartedQuestions)
  const isNotStartedResponseOptions = useSelector(getIsNotStartedResponseOptions)
  const dispatch = useDispatch()

  if (isNotStartedQuestions) dispatch(questionStartGetQuestions())
  if (isNotStartedResponseOptions) dispatch(questionStartGetResponseOptions())
  if (isErrorQuestion || isLoadingQuestion || isNotStartedQuestions || isNotStartedResponseOptions) return null

  const createQuestion = (questionData: QuestionToCreate): void => {
    dispatch(questionFormStartSend(questionData))
  }

  const deleteQuestion = (questionId: number): void => {
    dispatch(questionFormStartDelete(questionId))
  }

  const editQuestion = (questionId: number, questionData: QuestionToCreate): void => {
    dispatch(questionFormStartEdit({ id: questionId, ...questionData }))
  }

  const questionIdsInUse = new Set<number>()
  questionnaires.forEach((questionnaire) => questionnaire.questionIds
    .forEach((questionId) => questionIdsInUse.add(questionId)))

  return (
    <QuestionForm
      createQuestion={createQuestion}
      deleteQuestion={deleteQuestion}
      editQuestion={editQuestion}
      questionIdsInUse={questionIdsInUse}
      questions={questions}
      responseOptions={responseOptions}
    />
  )
}
