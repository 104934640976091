import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

import {
  FeedbackNormalizer,
  FeedbackNormalizerInput,
  FeedbackToGetNormalizerInput,
} from 'review-app-shared/normalizers/feedback-normalizer'
import {
  ReviewNormalizer,
  ReviewNormalizerInput,
} from 'review-app-shared/normalizers/review-normalizer'
import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'
import { Feedback, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { Review } from 'review-app-shared/types/review'

import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import {
  apiGetFeedback,
  apiGetFeedbackById,
  apiGetReviews,
  apiGetQuestionnaires,
  apiPutReviewSummary,
  apiGetReviewSummary,
} from 'app/modules/review/review-effects'
import {
  reviewFinishGetFeedback,
  reviewErrorGetFeedback,
  ReviewStartGetFeedbackActionType,
  ReviewFinishGetFeedback,
  ReviewErrorGetFeedback,
  reviewFinishGetFeedbackById,
  reviewErrorGetFeedbackById,
  ReviewStartGetFeedbackById,
  ReviewStartGetFeedbackByIdActionType,
  ReviewFinishGetFeedbackById,
  ReviewErrorGetFeedbackById,
  reviewFinishGetReviews,
  reviewErrorGetReviews,
  ReviewStartGetReviewsActionType,
  ReviewFinishGetReviews,
  ReviewErrorGetReviews,
  reviewFinishGetQuestionnaires,
  reviewErrorGetQuestionnaires,
  ReviewStartGetQuestionnairesActionType,
  ReviewFinishGetQuestionnaires,
  ReviewErrorGetQuestionnaires,
  ReviewSendSummaryStart,
  reviewErrorSendSummary,
  ReviewErrorSendSummary,
  ReviewStartSendSummaryActionType,
  reviewSendSummaryFinish,
  ReviewSendSummaryFinish,
  ReviewStartGetSummaryActionType,
  ReviewErrorGetSummary,
  ReviewFinishGetSummary,
  reviewFinishGetSummary,
  reviewErrorGetSummary,
  ReviewStartGetSummary,
} from 'app/modules/review/review-actions'
import { Summary } from 'review-app-shared/types/summary'

type GetFeedbackEffects = SelectEffect | CallEffect<AxiosResponse<Feedback[]>> |
PutEffect<ReviewFinishGetFeedback> | PutEffect<ReviewErrorGetFeedback>

export function* getFeedbackSaga(): Generator<GetFeedbackEffects, void, string | AxiosResponse<FeedbackNormalizerInput[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetFeedback, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = response.data
          .map((feedbackData) => FeedbackNormalizer.normalizeFeedback(feedbackData))
        yield put(reviewFinishGetFeedback(responseNormalized))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(reviewErrorGetFeedback(error))
  }
}

type GetFeedbackByIdEffects = SelectEffect | CallEffect<AxiosResponse<FeedbackWithQuestionsAndAnswers>> |
PutEffect<ReviewFinishGetFeedbackById> | PutEffect<ReviewErrorGetFeedbackById>

export function* getFeedbackByIdSaga(
  action: ReviewStartGetFeedbackById,
): Generator<GetFeedbackByIdEffects, void, string | AxiosResponse<FeedbackToGetNormalizerInput>> {
  const feedbackId = action.payload
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetFeedbackById, feedbackId, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = FeedbackNormalizer.normalizeFeedbackToGet(response.data)
        yield put(reviewFinishGetFeedbackById(responseNormalized))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(reviewErrorGetFeedbackById({ id: feedbackId, error }))
  }
}

type GetReviewsEffects = SelectEffect | CallEffect<AxiosResponse<Review[]>> |
PutEffect<ReviewFinishGetReviews> | PutEffect<ReviewErrorGetReviews>

export function* getReviewsSaga(): Generator<GetReviewsEffects, void, string | AxiosResponse<ReviewNormalizerInput[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetReviews, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = response.data
          .map((reviewData) => ReviewNormalizer.normalizeReview(reviewData))
        yield put(reviewFinishGetReviews(responseNormalized))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(reviewErrorGetReviews(error))
  }
}

type GetQuestionnairesEffects = SelectEffect | CallEffect<AxiosResponse<QuestionnaireWithQuestionIds[]>> |
PutEffect<ReviewFinishGetQuestionnaires> | PutEffect<ReviewErrorGetQuestionnaires>

export function* getQuestionnairesSaga(): Generator<GetQuestionnairesEffects, void, string | AxiosResponse<QuestionnaireWithQuestionIds[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetQuestionnaires, token)
      if (response && typeof response !== 'string') {
        yield put(reviewFinishGetQuestionnaires(response.data))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(reviewErrorGetQuestionnaires(error))
  }
}

type GetSummaryEffects = SelectEffect | CallEffect<AxiosResponse<Summary>> |
PutEffect<ReviewFinishGetSummary> | PutEffect<ReviewErrorGetSummary>

export function* getSummarySaga({ payload: reviewId }: ReviewStartGetSummary): Generator<GetSummaryEffects, void, string | AxiosResponse<Summary>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetReviewSummary, reviewId, token)
      if (response && typeof response !== 'string') {
        yield put(reviewFinishGetSummary(response.data))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(reviewErrorGetSummary(error))
  }
}


type UpdateSummaryEffects = SelectEffect | CallEffect<AxiosResponse<Summary>> |
PutEffect<ReviewSendSummaryFinish> | PutEffect<ReviewErrorSendSummary>

export function* updateReviewSummarySaga(
  action: ReviewSendSummaryStart,
): Generator<UpdateSummaryEffects, void, string | AxiosResponse<Summary>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutReviewSummary, action.payload.reviewId, action.payload.summary, token)
      if (response && typeof response !== 'string') {
        yield put(reviewSendSummaryFinish(response.data))
        toast.info('Review summary updated', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(reviewErrorSendSummary(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(reviewErrorSendSummary(error))
    }
  }
}


export function* reviewSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ReviewStartGetFeedbackActionType, getFeedbackSaga)
  yield takeEvery(ReviewStartGetFeedbackByIdActionType, getFeedbackByIdSaga)
  yield takeEvery(ReviewStartGetReviewsActionType, getReviewsSaga)
  yield takeEvery(ReviewStartGetQuestionnairesActionType, getQuestionnairesSaga)

  yield takeEvery(ReviewStartGetSummaryActionType, getSummarySaga)
  yield takeEvery(ReviewStartSendSummaryActionType, updateReviewSummarySaga)
}
