import React, { FC } from 'react'

interface FormErrorProps {
  errorMessage?: string
}

// The FormError component renders a message to indicate to the user that fetching
// data to populate a form, or submitting data from the form, resulted in an error.
export const FormError: FC<FormErrorProps> = ({ errorMessage }) => (
  <div className="content">
    <div className="main">
      <h2>Sorry, the application encountered an error while processing form data.</h2>
      {errorMessage && (
        <>
          <p>The error message received from the server was:</p>
          <p className="form-error">{errorMessage}</p>
        </>
      )}
    </div>
  </div>
)
