import React, { FC, ChangeEvent } from 'react'

interface TableProps {
  className?: string
  title?: JSX.Element
  headerRow: JSX.Element
  bodyRows: JSX.Element[]
  showFilter?: boolean
  filter?: string
  handleChangeFilter?: (e: ChangeEvent<HTMLInputElement>) => void
  filterPlaceholder?: string
}

// This component renders a generic table structure, taking a header row and an
// array of body rows as props
export const Table: FC<TableProps> = ({
  className,
  title,
  headerRow,
  bodyRows,
  showFilter = false,
  filter,
  handleChangeFilter,
  filterPlaceholder = 'filter table rows',
}): JSX.Element => (
  <div className={`table-box ${className}`}>
    <div className="table-filter">
      {title}
      {showFilter && (
        <input
          className="form-control"
          type="search"
          placeholder={filterPlaceholder}
          value={filter}
          onChange={handleChangeFilter}
          data-cy="table-filter-input"
        />
      )}
    </div>
    <table className="table">
      <thead>
        {headerRow}
      </thead>
      <tbody>
        {bodyRows}
        {(bodyRows.length === 0) && <tr><td colSpan={5}>No data to display</td></tr>}
      </tbody>
    </table>
  </div>
)
