import React, { FC, ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'

import { getFeedbackRequests } from 'app/modules/review/review-selectors'

import { Table } from 'app/components/tables/Table'
import { Hero } from 'app/components/components-ui/hero/Hero'
import { TableHeaderFeedbackForReviewers } from 'app/components/tables/TableHeaderFeedbackForReviewers'
import { TableRowFeedbackForReviewers } from 'app/components/tables/TableRowFeedbackForReviewers'
import { filterMatchString, filterMatchDate } from 'app/helpers/filter'
import { homeUrl } from 'app/helpers/urlCreators'

// The Reviewer component renders full details of the user's reviewer tasks
export const Reviewer: FC = () => {
  const [filter, setFilter] = useState('')
  const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>): void => setFilter(e.target.value)

  const feedbackRequests = useSelector(getFeedbackRequests)
    .sort((a, b) => {
      const { linkedReview: { timeReviewed: timeA } } = a
      const { linkedReview: { timeReviewed: timeB } } = b
      return new Date(timeB).getTime() - new Date(timeA).getTime()
    })
  const feedbackRequestsToShow = (feedbackRequests.length > 0)
  const filteredFeedbackRequests = feedbackRequests
    .filter(({
      reviewedUser: { username },
      linkedReview: { timeReviewed },
    }) => !filter || filterMatchString(username, filter)
      || filterMatchDate(timeReviewed, filter))

  return (
    <div className="content">
      <Hero
        backButton={homeUrl()}
        title="Reviewer history"
        tooltip="All requests made of you to provide review feedback are listed below."
      />
      <div className="main">
        {(feedbackRequestsToShow)
          ? (
            <Table
              showFilter
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              className="home-reviewer-current"
              headerRow={<TableHeaderFeedbackForReviewers />}
              bodyRows={filteredFeedbackRequests.map((feedbackRequest) => (
                <TableRowFeedbackForReviewers
                  key={feedbackRequest.id}
                  feedbackRequest={feedbackRequest}
                />
              ))}
            />
          )
          : <p>You have not yet been asked to provide review feedback.</p>}
      </div>
    </div>
  )
}
