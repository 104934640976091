import React, { FC } from 'react'
import Select, { ValueType } from 'react-select'

import { QuestionDetails } from 'app/components/forms/QuestionDetails'

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface QuestionFormViewProps {
  handleCreateBasedOnCurrentButtonClick: () => void
  handleCreateButtonClick: () => void
  handleDeleteButtonClick: () => void
  handleEditButtonClick: () => void
  handleSelectQuestion: (selected: SelectOption) => void
  questionIdsInUse: Set<number>
  questions: Question[]
  questionsForSelect: SelectOption[]
  responseOptions: ResponseOptions[]
  selectedQuestion: SelectOption | null
  isSelectedQuestionInUse: boolean
}

// The QuestionFormView component renders the selection and viewing aspects of the question form
export const QuestionFormView: FC<QuestionFormViewProps> = ({
  handleCreateBasedOnCurrentButtonClick,
  handleCreateButtonClick,
  handleDeleteButtonClick,
  handleEditButtonClick,
  handleSelectQuestion,
  questionIdsInUse,
  questions,
  questionsForSelect,
  responseOptions,
  selectedQuestion,
  isSelectedQuestionInUse,
}) => {
  const selectedQuestionDetail = selectedQuestion
    && questions.find((question) => question.id === selectedQuestion.value)
  if (selectedQuestion && !selectedQuestionDetail) throw new Error('Selected Question does not exist.')
  const selectedQuestionResponseOptions = selectedQuestionDetail && responseOptions
    .find((option) => option.id === selectedQuestionDetail.optionsId)

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title="Questions"
        tooltip="Select a question by category from the list to view it, or create a new one."
      />
      <div className="main">
        <Select
          classNamePrefix="select"
          className="select-box"
          onChange={(selected: ValueType<SelectOption>): void => handleSelectQuestion((selected as SelectOption))}
          value={selectedQuestion}
          options={questionsForSelect}
          placeholder="Select from current questions"
          isClearable
        />
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-danger',
              label: 'Delete current selection',
              onClick: handleDeleteButtonClick,
              type: 'button',
              hidden: !(selectedQuestion && questionIdsInUse.has(selectedQuestion.value)),
            },
            {
              className: 'btn btn-clear',
              label: `Edit current selection${isSelectedQuestionInUse ? ' (Description)' : ''}`,
              onClick: handleEditButtonClick,
              type: 'button',
              hidden: !selectedQuestion,
            },
            {
              className: 'btn btn-primary',
              label: 'Create new based on current selection',
              onClick: handleCreateBasedOnCurrentButtonClick,
              type: 'button',
              hidden: !selectedQuestion,
            },
            {
              className: 'btn btn-primary',
              label: 'Create new',
              onClick: handleCreateButtonClick,
              type: 'button',
            },
          ]}
        />
        {selectedQuestionDetail && (
          <QuestionDetails
            question={selectedQuestionDetail}
            responseOptions={selectedQuestionResponseOptions}
          />
        )}
      </div>
    </div>
  )
}
