import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { getUserFormState } from 'app/modules/user-form/user-form-selectors'
import { FormStatus } from 'review-app-shared/types/formData'
import { homeUrl, userProfileUrl } from 'app/helpers/urlCreators'

import { UserForm } from './UserForm'

interface UserFormProps {
  userId?: number
  switchView?: () => void
  isNew?: boolean
}

export const UserFormWrapper: FC<UserFormProps> = ({ isNew, ...props }) => {
  const formState = useSelector(getUserFormState)

  if (formState.kind === FormStatus.Saved && isNew) {
    return <Redirect to={userProfileUrl(formState.data.id)} />
  }
  if (formState.kind === FormStatus.Deleted) {
    return <Redirect to={homeUrl()} />
  }

  return <UserForm isNew={isNew} {...props} />
}
