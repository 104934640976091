import React, { CSSProperties, FC } from 'react'
import { ReactComponent as IconUp } from 'assets/svg/icon-up.svg'
import { ReactComponent as IconDown } from 'assets/svg/icon-down.svg'
import { ReviewSchedulerTableRowProps } from './ReviewSchedulerTableRow'

export enum OrderDirection {
  asc = 1,
  desc = -1,
}

export interface SortParameters {
  id: keyof ReviewSchedulerTableRowProps
  direction: OrderDirection
}

interface SortableTableHeaderColumn {
  title?: string
  styles?: CSSProperties
  id?: keyof ReviewSchedulerTableRowProps
}

interface SortableTableHeaderProps {
  columns: SortableTableHeaderColumn[]
  sort?: SortParameters
  setSort?: (id: SortParameters) => void
}

export const SortableTableHeader: FC<SortableTableHeaderProps> = ({
  columns,
  sort,
  setSort = (): void => {},
}) => (
  <tr>
    {columns.map(({ title, styles, id }) => {
      const isOrdered = sort?.id === id
      return (
        <th style={styles} key={title}>
          <div className="table-sort">
            {title}
            {id && (
            <button
              className={`btn-icon btn-sort ${isOrdered ? 'is-active' : null}`}
              name={title}
              type="button"
              onClick={(): void => setSort({ id, direction: ((isOrdered && sort?.direction) || OrderDirection.desc) * -1 })}
            >
              {sort?.direction === OrderDirection.asc && isOrdered ? <IconUp /> : <IconDown />}
            </button>
            )}
          </div>
        </th>
      )
    })}
  </tr>
)
