import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'

import { Question } from 'review-app-shared/types/question'

interface ReviewSummaryQuestionProps {
  question: Question
}

// The ReviewSummaryQuestion component renders an individual question for
// a review summary report
export const ReviewSummaryQuestion: FC<ReviewSummaryQuestionProps> = ({
  question: {
    category,
    description,
  },
}) => (
  <>
    <h3>{category}</h3>
    <Markdown className="description" options={{ forceBlock: true }}>
      {description || 'No description'}
    </Markdown>
  </>
)
