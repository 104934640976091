import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { CreateReviewForm } from 'app/components/forms/CreateReviewForm'

import { ReviewToCreate } from 'review-app-shared/types/review'
import { getCreateReviewFormState } from 'app/modules/review-form/review-form-selectors'
import { createReviewFormStartSend } from 'app/modules/review-form/review-form-actions'
import { FormStatus } from 'review-app-shared/types/formData'
import { homeUrl, reviewSummaryUrl } from 'app/helpers/urlCreators'

interface RouteInfo {
  userId: string | undefined
}

type CreateReviewFormWrapperProps = RouteComponentProps<RouteInfo>

// The CreateReviewFormWrapper component enables a manager to arrange a review
// for one of the staff they manage. If userId is provided in the route, the
// associated userId will be fixed in the form.
export const CreateReviewFormWrapper: FC<CreateReviewFormWrapperProps> = (
  { match: { params: { userId } } },
) => {
  const formState = useSelector(getCreateReviewFormState)
  const dispatch = useDispatch()

  const createReview = (reviewData: ReviewToCreate): void => {
    dispatch(createReviewFormStartSend(reviewData))
  }

  if (formState.kind === FormStatus.Saved || formState.kind === FormStatus.Edited) {
    return <Redirect to={reviewSummaryUrl(formState.data.id)} />
  }
  if (formState.kind === FormStatus.Deleted) {
    return <Redirect to={homeUrl()} />
  }

  return (
    <div className="content has-buttons">
      <CreateReviewForm
        fixedUserReviewedId={Number(userId)}
        createReview={createReview}
      />
    </div>
  )
}
