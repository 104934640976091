import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { FeedbackUpdateData, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'

export const feedbackFormStartFetch = createAction('feedback-form/START_FETCH',
  (actionCallback) => (feedbackId: string) => actionCallback(feedbackId))

export const feedbackFormFinishFetch = createAction('feedback-form/FINISH_FETCH',
  (actionCallback) => (feedbackWithQuestionnaire: FeedbackWithQuestionsAndAnswers) => actionCallback(feedbackWithQuestionnaire))

export const feedbackFormStartSend = createAction('feedback-form/START_SEND',
  (actionCallback) => (feedbackId: string, answers: FeedbackUpdateData) => actionCallback({ feedbackId, answers }))

export const feedbackFormFinishSend = createAction('feedback-form/FINISH_SEND',
  (actionCallback) => (feedback: FeedbackWithQuestionsAndAnswers) => actionCallback(feedback))

export const feedbackFormError = createAction('feedback-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type FeedbackFormActions =
    ActionType<typeof feedbackFormFinishFetch | typeof feedbackFormStartFetch |
    typeof feedbackFormStartSend | typeof feedbackFormFinishSend | typeof feedbackFormError>
export type FeedbackFormStartFetch = ActionType<typeof feedbackFormStartFetch>
export type FeedbackFormStartSend = ActionType<typeof feedbackFormStartSend>
export type FeedbackFormFinishFetch = ActionType<typeof feedbackFormFinishFetch>
export type FeedbackFormFinishSend = ActionType<typeof feedbackFormFinishSend>
export type FeedbackFormError = ActionType<typeof feedbackFormError>

export const FeedbackFormStartFetchActionType = getType(feedbackFormStartFetch)
export const FeedbackFormFinishFetchActionType = getType(feedbackFormFinishFetch)
export const FeedbackFormStartSendActionType = getType(feedbackFormStartSend)
export const FeedbackFormFinishSendActionType = getType(feedbackFormFinishSend)
export const FeedbackFormErrorActionType = getType(feedbackFormError)
