import { useState, useEffect } from 'react'

import { UserWithDetailsPopulated } from 'review-app-shared/types/selectors'
import { FieldErrorTypes } from './const'

interface Validator<ValueType> {
  candidate: ValueType
  setError: (err: FieldErrorTypes) => void
  required?: boolean
}

interface BlackListValidator<ValueType> extends Validator<ValueType> {
  blackList: ValueType[]
}

const useValidator = <ValueType, >({
  candidate, setError, blackList, required,
}: BlackListValidator<ValueType>): void => {
  useEffect(() => {
    if (required && !candidate) {
      setError(FieldErrorTypes.Required)
    }

    if (blackList.includes(candidate)) {
      setError(FieldErrorTypes.BlackListed)
    }

    setError(FieldErrorTypes.None)
  }, [candidate, setError, blackList, required])
}

interface UserValidator extends Validator<string> {
  property: 'email' | 'username'
  users: UserWithDetailsPopulated[]
}

export const useUserValidator = ({ property, users, ...rest }: UserValidator): void => {
  const [blackList] = useState(users.map((user) => user[property]))

  useValidator({ ...rest, blackList })
}
