import React, { FC } from 'react'
import { RenderElementProps, RenderLeafProps } from 'slate-react'

import {
  isBlockFormat, isLeafFormat, TextFormats, FORMAT_MAPPING, DEFAULT_TEXT_FORMAT,
} from './const'

export const TextBlock: FC<RenderElementProps> = ({ attributes, children, element: { type } }) => {
  const Block = isBlockFormat(type) ? FORMAT_MAPPING[type] : FORMAT_MAPPING[DEFAULT_TEXT_FORMAT]
  return Block({ children, ...attributes })
}

const Init = FORMAT_MAPPING[TextFormats.Span]
export const Leaf: FC<RenderLeafProps> = ({ leaf, attributes, children: blockChildren }) => Object.keys(leaf).reduce<FC>((acc, format) => {
  // The `Leaf`s can be nested into each other so we have to apply all formats that are set
  if (isLeafFormat(format)) {
    const Acc = acc
    const LeafComponent = FORMAT_MAPPING[format]
    return ({ children }): JSX.Element => <LeafComponent><Acc>{children}</Acc></LeafComponent>
  }

  return acc
}, ({ children }): JSX.Element => <Init {...attributes}>{children}</Init>)({ children: blockChildren })
