export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export function getReadableDayName(date: Date): string {
  return dayNames[date.getDay()]
}

export function getReadableMonth(date: Date): string {
  return monthNames[date.getMonth()]
}

export function addYears(input: Date, years: number): Date {
  return new Date(input.getFullYear() + years, input.getMonth(), input.getDate(), input.getHours(), input.getMinutes())
}

export function addMonths(input: Date, months: number): Date {
  return new Date(input.getFullYear(), input.getMonth() + months, input.getDate(), input.getHours(), input.getMinutes())
}

export function addDays(input: Date, days: number): Date {
  return new Date(input.getFullYear(), input.getMonth(), input.getDate() + days, input.getHours(), input.getMinutes())
}

export const weekDays = (date: Date): boolean => date.getDay() !== 0 && date.getDay() !== 6

export function previousBusinessDay(date: Date): Date {
  if (date.getDay() === 1) { // monday becomes friday
    return addDays(date, -3)
  }
  if (date.getDay() === 0) { // sunday becomes friday
    return addDays(date, -2)
  }
  return addDays(date, -1)
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return date1.toDateString() === date2.toDateString()
}
