import { api, ApiResponse } from 'app/api/api-client'
import { Feedback, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { Review } from 'review-app-shared/types/review'
import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'
import { Summary } from 'review-app-shared/types/summary'

export const apiGetFeedback = async (token: string): ApiResponse<Feedback[]> => api.get('/feedback', {
  headers: { Authorization: token },
})

export const apiGetFeedbackById = async (id: number, token: string): ApiResponse<FeedbackWithQuestionsAndAnswers> => api.get(`/feedback/${id}`, {
  headers: { Authorization: token },
})

export const apiGetReviews = async (token: string): ApiResponse<Review[]> => api.get('/reviews', {
  headers: { Authorization: token },
})

export const apiGetQuestionnaires = async (token: string): ApiResponse<QuestionnaireWithQuestionIds[]> => api.get('/questionnaires', {
  headers: { Authorization: token },
})

export const apiGetReviewSummary = async (
  reviewId: number,
  token: string,
): ApiResponse<Summary> => api.get(
  `/review-summary/${reviewId}`,
  { headers: { Authorization: token } },
)

export const apiPutReviewSummary = async (
  reviewId: number,
  summary: string,
  token: string,
): ApiResponse<Summary> => api.put(
  `/review-summary/${reviewId}`,
  { summary },
  { headers: { Authorization: token } },
)
