import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ResponseOptionsToCreate } from 'review-app-shared/types/response-options'

import { ResponseOptionsForm } from 'app/components/forms/ResponseOptionsForm'

import {
  getIsNotStartedQuestions,
  getIsNotStartedResponseOptions,
  getIsErrorQuestion,
  getIsLoadingQuestion,
  getLoadedQuestions,
  getLoadedResponseOptions,
} from 'app/modules/question/question-selectors'
import { questionStartGetQuestions, questionStartGetResponseOptions } from 'app/modules/question/question-actions'
import { responseOptionsFormStartSend, responseOptionsFormStartDelete } from 'app/modules/response-options-form/response-options-form-actions'

// The ResponseOptionsFormWrapper component enables an authorised user to create
// a new set of response options or delete an existing set from a list.
export const ResponseOptionsFormWrapper: FC = () => {
  const questions = useSelector(getLoadedQuestions)
  const responseOptions = useSelector(getLoadedResponseOptions)
  const isErrorQuestion = useSelector(getIsErrorQuestion)
  const isLoadingQuestion = useSelector(getIsLoadingQuestion)
  const isNotStartedQuestions = useSelector(getIsNotStartedQuestions)
  const isNotStartedResponseOptions = useSelector(getIsNotStartedResponseOptions)
  const dispatch = useDispatch()

  if (isNotStartedQuestions) dispatch(questionStartGetQuestions())
  if (isNotStartedResponseOptions) dispatch(questionStartGetResponseOptions())
  if (isErrorQuestion || isLoadingQuestion || isNotStartedQuestions || isNotStartedResponseOptions) return null

  const responseOptionsIdsInUse = new Set<number>()
  questions.forEach((question) => question.optionsId !== null && responseOptionsIdsInUse.add(question.optionsId))

  const createResponseOptions = (responseOptionsData: ResponseOptionsToCreate): void => {
    dispatch(responseOptionsFormStartSend(responseOptionsData))
  }

  const deleteResponseOptions = (responseOptionsId: number): void => {
    dispatch(responseOptionsFormStartDelete(responseOptionsId))
  }

  return (
    <ResponseOptionsForm
      responseOptions={responseOptions}
      responseOptionsIdsInUse={responseOptionsIdsInUse}
      createResponseOptions={createResponseOptions}
      deleteResponseOptions={deleteResponseOptions}
    />
  )
}
