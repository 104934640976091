// principal routes for different types of user
export const HOME = '/' // summary initial view
export const REVIEWER_MAIN = '/reviewer' // enter review comments (reviewers)
export const MANAGER_MAIN = '/manager' // manage review process (managers)
export const OWN_REVIEWS_MAIN = '/own' // see own previous reviews (everyone)
export const USER_ADMIN_MAIN = '/user-admin' // manage users (admin)
export const QUESTION_ADMIN_MAIN = '/question-admin' // manage question sets (managers and admin)

// direct routes to forms and reports
export const FEEDBACK_FORM_GET = '/feedback-form/:feedbackId' // direct link to feedback form
export const QUESTION_FORM = '/question-form' // create or delete questions
export const QUESTIONNAIRE_FORM = '/questionnaire-form' // create or delete questionnaires
export const RESPONSE_OPTIONS_FORM = '/response-options-form' // create or delete response options
export const CREATE_REVIEW_FORM = '/create-review-form/:userId?' // create a new review
export const UPDATE_REVIEW_FORM = '/update-review-form/:reviewId' // update a review
export const USER_GROUP_FORM = '/user-group-form' // create or delete user groups
export const REVIEW_SUMMARY = '/review-summary/:reviewId' // direct link to review summary report
export const USER_PROFILE = '/user/:userId' // direct link to user's profile
export const CREATE_NEW_USER = '/user' // direct link to create new user form
export const FEEDBACK_REPORT = '/feedback/:feedbackId' // direct link to view completed feedback
export const ADVANCE_REVIEW_SCHEDULING = '/advance-review-scheduling' // direct link to view completed feedback
