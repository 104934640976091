import {
  Editor, Transforms, Element as SlateElement, Node, Text,
} from 'slate'

import {
  LEAF_SHORTCUTS,
  INV_BLOCK_SHORTCUTS,
  TextFormats,
  BLOCK_SHORTCUTS,
  isFormatWrapper,
  hasBlockShortcut,
  hasLeafShortcut,
  INV_LEAF_SHORTCUTS,
  isLeafShortcut,
  isBlockShortcut,
  WRAPPER_MAPPING,
  DEFAULT_TEXT_FORMAT,
} from './const'

const getNodeType = (node: Node): TextFormats | undefined => (!Editor.isEditor(node) && SlateElement.isElement(node) && node.type as TextFormats) || undefined

export const isBlockActive = (editor: Editor, format: TextFormats): boolean => {
  const [match] = Editor.nodes(editor, {
    match: (n) => getNodeType(n) === format,
  })

  return !!match
}

export const isMarkActive = (editor: Editor, format: TextFormats): boolean => {
  const marks = Editor.marks(editor)
  return marks ? !!marks[format] : false
}

export const toggleBlock = (editor: Editor, format: TextFormats): void => {
  const isActive = isBlockActive(editor, format)

  Transforms.unwrapNodes(editor, {
    match: (n) => isFormatWrapper(getNodeType(n)),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? DEFAULT_TEXT_FORMAT : isFormatWrapper(format) ? WRAPPER_MAPPING[format] : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isFormatWrapper(format)) {
    const wrapperBlock = { type: format, children: [] }
    Transforms.wrapNodes(editor, wrapperBlock)
  }
}

export const toggleMark = (editor: Editor, format: TextFormats): void => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const serializeSingle = (node: Node): string => {
  if (Text.isText(node)) {
    // Leaf Node
    let { text } = node
    Object.keys(node).forEach((format) => {
      if (hasLeafShortcut(format)) {
        const tag = INV_LEAF_SHORTCUTS[format]
        text = `${tag}${text}${tag}`
      }
    })

    return text
  }

  // Block Node
  const formatTag = hasBlockShortcut(node.type) ? `${INV_BLOCK_SHORTCUTS[node.type]} ` : ''
  const serializedChildren = node.children.map((n) => serializeSingle(n))
  if (isFormatWrapper(node.type)) {
    return `${formatTag}${serializedChildren.join(`\n${formatTag}`)}`
  }

  const text = serializedChildren.join('')
  return `${formatTag}${text}`
}

export const serialize = (nodes: Node[]): string => nodes.map((node) => serializeSingle(node)).join('\n')

// We have to sort the `INLINE_SHORTCUTS` by their length (before escaping) to ensure that we try to match the long ones first
const escaped = Object.keys(LEAF_SHORTCUTS).sort((a, b) => b.length - a.length).map((s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
const tokenizeRegExp = new RegExp(['\\*\\*\\*', ...escaped].map((s) => `(${s}.+?${s})`).join('|'), 'g')
const tokenize = (line: string): string[] => (line.split(tokenizeRegExp)).filter(Boolean)

const tokenReg = new RegExp(`^(?<sep>(${escaped.join('|')}))(?<text>.+?)\\k<sep>$`)
export const parseToken = (token: string): Text => {
  const parsed = token.match(tokenReg)?.groups
  if (parsed && isLeafShortcut(parsed.sep)) {
    return {
      ...parseToken(parsed.text),
      [LEAF_SHORTCUTS[parsed.sep]]: true,
    }
  }

  return { text: token }
}

// Notice the missing `^` and `$`
const extractReg = new RegExp(`(?<sep>(${escaped.join('|')}))(?<text>.+?)\\k<sep>`)
export const extractToken = (text: string): RegExpMatchArray | null => text.match(extractReg)

const deserializeLine = (line: string): Text[] => {
  const tokens = tokenize(line)
  if (tokens.length) {
    return tokens.map(parseToken)
  }

  return [{ text: '' }]
}

export const deserialize = (text: string): Node[] => text.split('\n').reduce((acc, line) => {
  // Remove everything after the first ' '
  const firstWord = line.match(/^.+?(?= )/)
  if (firstWord && isBlockShortcut(firstWord[0])) {
    const type = BLOCK_SHORTCUTS[firstWord[0]]
    const children = deserializeLine(
      // Remove token that was used to determine the type
      line.replace(`${firstWord} `, ''),
    )

    // If the current type is a wrapping type
    // We have to wrap the corresponding node in the wrapping
    if (isFormatWrapper(type)) {
      const last = acc[acc.length - 1]
      const textNode = { type: WRAPPER_MAPPING[type], children }

      if (last && last.type === type) {
        // We want to merge several consecutive formatTags under one single wrap
        (last.children as Node[]).push(textNode)
      } else {
        // If this is the first formatTag we create the outer wrap manually
        acc.push({ type, children: [textNode] })
      }
    } else {
      acc.push({ type, children })
    }
  } else {
    // If we do not find any formating tag we will use the default text format
    acc.push({
      type: DEFAULT_TEXT_FORMAT,
      children: deserializeLine(line),
    })
  }

  return acc
}, [] as Node[])
