import React, {
  FC,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
} from 'react'
import Select, { ValueType } from 'react-select'

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { QuestionWithDetails, getQuestionWithDetails } from 'app/helpers/questionManagement'

import { QuestionDetails } from 'app/components/forms/QuestionDetails'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Button } from 'app/components/components-ui/buttons/button'
import { Hero } from 'app/components/components-ui/hero/Hero'

import { ReactComponent as IconUp } from 'assets/svg/icon-up.svg'
import { ReactComponent as IconDown } from 'assets/svg/icon-down.svg'
import { ReactComponent as IconTrash } from 'assets/svg/icon-trash.svg'

interface QuestionnaireFormEditProps {
  isNew: boolean
  description: string
  errorName: string | null
  errorQuestionIds: string | null
  handleCancelButtonClick: () => void
  handleChangeDescription: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void
  handleQuestionAddButtonClick: () => void
  handleQuestionDeleteButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleQuestionMoveDownButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleQuestionMoveUpButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleSelectQuestionToAdd: (selected: SelectOption) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  name: string
  questionIds: number[]
  questions: Question[]
  questionsForSelect: SelectOption[]
  questionToAdd: SelectOption | null
  responseOptions: ResponseOptions[]
}

export const QuestionnaireFormEdit: FC<QuestionnaireFormEditProps> = ({
  isNew,
  description,
  errorName,
  errorQuestionIds,
  handleCancelButtonClick,
  handleChangeDescription,
  handleChangeName,
  handleQuestionAddButtonClick,
  handleQuestionDeleteButtonClick,
  handleQuestionMoveDownButtonClick,
  handleQuestionMoveUpButtonClick,
  handleSelectQuestionToAdd,
  handleSubmit,
  name,
  questionIds,
  questions,
  questionsForSelect,
  questionToAdd,
  responseOptions,
}) => {
  const selectedQuestionDetails = questionIds.reduce((output: QuestionWithDetails[], questionId: number) => {
    const matchingQuestionWithDetails = getQuestionWithDetails(questionId, questions, responseOptions)
    if (!matchingQuestionWithDetails) return output
    return [...output, matchingQuestionWithDetails]
  }, [])
  const questionToAddDetails = questionToAdd && getQuestionWithDetails(questionToAdd.value, questions, responseOptions)

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title={`${isNew ? 'Create' : 'Update'} questionnaire`}
      />
      <div className="main">
        <div className="grid">
          <form onSubmit={handleSubmit}>
            <div>
              {(isNew) ? (
                <div className="form-group">
                  <label htmlFor="name">
                    <p>Name:</p>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={name}
                    onChange={handleChangeName}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
                      if (event.key === 'Enter') event.preventDefault() // suppress form submit
                    }}
                    autoComplete="off"
                    required
                  />
                  {errorName && <p className="form-error">{errorName}</p>}
                </div>
              ) : (
                <p>
                  {'Name: '}
                  <span>{name}</span>
                </p>
              )}
              <div className="form-group">
                <label htmlFor="description">
                  Description:
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="description"
                  value={description}
                  onChange={handleChangeDescription}
                  onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
                    if (event.key === 'Enter') event.preventDefault() // suppress form submit
                  }}
                  autoComplete="off"
                />
              </div>
              {selectedQuestionDetails.length > 0 && (
                <>
                  <h5>Questions (in the order in which they will be asked):</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        {/* eslint-disable-next-line  jsx-a11y/control-has-associated-label */}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuestionDetails.map((questionDetails, index) => (
                        <tr key={questionDetails.questionId}>
                          <td>
                            {`${index + 1}: ${questionDetails.question.category}`}
                            <details>
                              <summary>Full details</summary>
                              <QuestionDetails question={questionDetails.question} responseOptions={questionDetails.responseOptions} />
                            </details>
                          </td>
                          <td>
                            <ul className="table-actions">
                              <li>
                                {/* #ToDO change img icons to SVG */}
                                {(index > 0) && (
                                  <button className="btn-icon" name={String(questionDetails.questionId)} type="button" onClick={handleQuestionMoveUpButtonClick}>
                                    <IconUp />
                                  </button>

                                )}
                              </li>
                              <li>
                                {(index < (selectedQuestionDetails.length - 1)) && (
                                  <button className="btn-icon" name={String(questionDetails.questionId)} type="button" onClick={handleQuestionMoveDownButtonClick}>
                                    <IconDown />
                                  </button>
                                )}
                              </li>
                              <li>
                                <button className="btn-icon" name={String(questionDetails.questionId)} type="button" onClick={handleQuestionDeleteButtonClick}>
                                  <IconTrash />
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {errorQuestionIds && <p className="form-error">{errorQuestionIds}</p>}
              <p>Available questions:</p>
              <label htmlFor="questionToAdd" className="question-to-add">
                <Select
                  classNamePrefix="select"
                  className="select-box"
                  id="questionToAdd"
                  value={questionToAdd}
                  onChange={(selected: ValueType<SelectOption>): void => handleSelectQuestionToAdd((selected as SelectOption))}
                  options={questionsForSelect}
                  isClearable
                />
                <Button
                  type="button"
                  className="btn btn-primary"
                  label="Add question"
                  onClick={handleQuestionAddButtonClick}
                />
              </label>
              <ButtonGroup
                buttons={[
                  {
                    className: 'btn btn-clear',
                    label: 'Cancel',
                    onClick: handleCancelButtonClick,
                    type: 'button',
                  },
                  {
                    className: 'btn btn-primary',
                    label: isNew ? 'Create' : 'Update',
                    type: 'submit',
                  },
                ]}
              />
            </div>
          </form>
          {questionToAddDetails && (
          <div>
            <h4>Questions preview</h4>
            <div className="card mb-20">
              <QuestionDetails question={questionToAddDetails.question} responseOptions={questionToAddDetails.responseOptions} />
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  )
}
