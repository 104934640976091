import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { Feedback, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { Review } from 'review-app-shared/types/review'
import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'
import { Summary } from 'review-app-shared/types/summary'

export const reviewStartGetFeedback = createAction('review/START_GET_FEEDBACK',
  (actionCallback) => () => actionCallback())

export const reviewFinishGetFeedback = createAction('review/FINISH_GET_FEEDBACK',
  (actionCallback) => (reviewFeedback: Array<Feedback>) => actionCallback(reviewFeedback))

export const reviewErrorGetFeedback = createAction('review/ERROR_GET_FEEDBACK',
  (actionCallback) => (error: Error) => actionCallback(error))

export const reviewStartGetFeedbackById = createAction('review/START_GET_FEEDBACK_BY_ID',
  (actionCallback) => (id: number) => actionCallback(id))

export const reviewFinishGetFeedbackById = createAction('review/FINISH_GET_FEEDBACK_BY_ID',
  (actionCallback) => (reviewFeedback: FeedbackWithQuestionsAndAnswers) => actionCallback(reviewFeedback))

export const reviewErrorGetFeedbackById = createAction('review/ERROR_GET_FEEDBACK_BY_ID',
  (actionCallback) => (error: { id: number; error: Error }) => actionCallback(error))

export const reviewStartGetReviews = createAction('review/START_GET_REVIEWS',
  (actionCallback) => () => actionCallback())

export const reviewFinishGetReviews = createAction('review/FINISH_GET_REVIEWS',
  (actionCallback) => (reviews: Array<Review>) => actionCallback(reviews))

export const reviewErrorGetReviews = createAction('review/ERROR_GET_REVIEWS',
  (actionCallback) => (error: Error) => actionCallback(error))

export const reviewStartGetQuestionnaires = createAction('review/START_GET_QUESTIONNAIRES',
  (actionCallback) => () => actionCallback())

export const reviewFinishGetQuestionnaires = createAction('review/FINISH_GET_QUESTIONNAIRES',
  (actionCallback) => (questionnaires: Array<QuestionnaireWithQuestionIds>) => actionCallback(questionnaires))

export const reviewErrorGetQuestionnaires = createAction('review/ERROR_GET_QUESTIONNAIRES',
  (actionCallback) => (error: Error) => actionCallback(error))

export const reviewStartGetSummary = createAction('review/START_GET_SUMMARY',
  (actionCallback) => (id: number) => actionCallback(id))

export const reviewFinishGetSummary = createAction('review/FINISH_GET_SUMMARY',
  (actionCallback) => (reviewSummary: Summary) => actionCallback(reviewSummary))

export const reviewErrorGetSummary = createAction('review/ERROR_GET_SUMMARY',
  (actionCallback) => (error: { id: number; error: Error }) => actionCallback(error))

export const reviewSendSummaryStart = createAction('review/SEND_SUMMARY_START',
  (actionCallback) => (reviewId: number, summary: string) => actionCallback({ reviewId, summary }))

export const reviewSendSummaryFinish = createAction('review/SEND_SUMMARY_FINISH',
  (actionCallback) => (summary: Summary) => actionCallback(summary))

export const reviewErrorSendSummary = createAction('review/ERROR_SEND_SUMMARY',
  (actionCallback) => (error: Error) => actionCallback(error))

export type ReviewActions = ActionType<
  typeof reviewStartGetFeedback | typeof reviewFinishGetFeedback | typeof reviewErrorGetFeedback |
  typeof reviewStartGetFeedbackById | typeof reviewFinishGetFeedbackById | typeof reviewErrorGetFeedbackById |
  typeof reviewStartGetReviews | typeof reviewFinishGetReviews | typeof reviewErrorGetReviews |
  typeof reviewStartGetQuestionnaires | typeof reviewFinishGetQuestionnaires | typeof reviewErrorGetQuestionnaires |
  typeof reviewStartGetSummary | typeof reviewFinishGetSummary | typeof reviewErrorGetSummary |
  typeof reviewSendSummaryFinish
>
export type ReviewStartGetFeedback = ActionType<typeof reviewStartGetFeedback>
export type ReviewFinishGetFeedback = ActionType<typeof reviewFinishGetFeedback>
export type ReviewErrorGetFeedback = ActionType<typeof reviewErrorGetFeedback>

export type ReviewStartGetFeedbackById = ActionType<typeof reviewStartGetFeedbackById>
export type ReviewFinishGetFeedbackById = ActionType<typeof reviewFinishGetFeedbackById>
export type ReviewErrorGetFeedbackById = ActionType<typeof reviewErrorGetFeedbackById>

export type ReviewStartGetReviews = ActionType<typeof reviewStartGetReviews>
export type ReviewFinishGetReviews = ActionType<typeof reviewFinishGetReviews>
export type ReviewErrorGetReviews = ActionType<typeof reviewErrorGetReviews>

export type ReviewStartGetQuestionnaires = ActionType<typeof reviewStartGetQuestionnaires>
export type ReviewFinishGetQuestionnaires = ActionType<typeof reviewFinishGetQuestionnaires>
export type ReviewErrorGetQuestionnaires = ActionType<typeof reviewErrorGetQuestionnaires>

export type ReviewStartGetSummary = ActionType<typeof reviewStartGetSummary>
export type ReviewFinishGetSummary = ActionType<typeof reviewFinishGetSummary>
export type ReviewErrorGetSummary = ActionType<typeof reviewErrorGetSummary>

export type ReviewSendSummaryStart = ActionType<typeof reviewSendSummaryStart>
export type ReviewSendSummaryFinish = ActionType<typeof reviewSendSummaryFinish>
export type ReviewErrorSendSummary = ActionType<typeof reviewErrorSendSummary>

export const ReviewStartGetFeedbackActionType = getType(reviewStartGetFeedback)
export const ReviewFinishGetFeedbackActionType = getType(reviewFinishGetFeedback)
export const ReviewErrorGetFeedbackActionType = getType(reviewErrorGetFeedback)

export const ReviewStartGetFeedbackByIdActionType = getType(reviewStartGetFeedbackById)
export const ReviewFinishGetFeedbackByIdActionType = getType(reviewFinishGetFeedbackById)
export const ReviewErrorGetFeedbackByIdActionType = getType(reviewErrorGetFeedbackById)

export const ReviewStartGetReviewsActionType = getType(reviewStartGetReviews)
export const ReviewFinishGetReviewsActionType = getType(reviewFinishGetReviews)
export const ReviewErrorGetReviewsActionType = getType(reviewErrorGetReviews)

export const ReviewStartGetQuestionnairesActionType = getType(reviewStartGetQuestionnaires)
export const ReviewFinishGetQuestionnairesActionType = getType(reviewFinishGetQuestionnaires)
export const ReviewErrorGetQuestionnairesActionType = getType(reviewErrorGetQuestionnaires)

export const ReviewStartGetSummaryActionType = getType(reviewStartGetSummary)
export const ReviewFinishGetSummaryActionType = getType(reviewFinishGetSummary)
export const ReviewErrorGetSummaryActionType = getType(reviewErrorGetSummary)

export const ReviewStartSendSummaryActionType = getType(reviewSendSummaryStart)
export const ReviewFinishSendSummaryActionType = getType(reviewSendSummaryFinish)
export const ReviewErrorSendSummaryActionType = getType(reviewErrorSendSummary)
