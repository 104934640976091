import React, { FC, useState, ChangeEvent } from 'react'

import { Question, QuestionToCreate } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { getQuestionWithDetails } from 'app/helpers/questionManagement'

import { QuestionFormEdit } from 'app/components/forms/QuestionFormEdit'
import { QuestionFormView } from 'app/components/forms/QuestionFormView'

enum QuestionFormState {
  normal,
  editing,
  creating,
}

interface QuestionFormProps {
  createQuestion: (questionToCreate: QuestionToCreate) => void
  deleteQuestion: (questionId: number) => void
  editQuestion: (quiestionId: number, question: QuestionToCreate) => void
  questionIdsInUse: Set<number>
  questions: Question[]
  responseOptions: ResponseOptions[]
}

// The QuestionForm component enables questions to be viewed, created and deleted
export const QuestionForm: FC<QuestionFormProps> = ({
  createQuestion,
  deleteQuestion,
  editQuestion,
  questionIdsInUse,
  questions,
  responseOptions,
}) => {
  const [formState, setFormState] = useState(QuestionFormState.normal)
  const [selectedQuestion, setSelectedQuestion] = useState<SelectOption | null>(null)
  const [category, setCategory] = useState('')
  const [originalCategory, setOriginalCategory] = useState('')
  const [description, setDescription] = useState('') // submit '' as null
  const [options, setOptions] = useState<SelectOption | null>(null)
  const [commentDisplayed, setCommentDisplayed] = useState(false) // hide by default
  const [commentRequiredByOption, setCommentRequiredByOption] = useState<boolean[] | null>(null)
  const [freeTextRequired, setFreeTextRequired] = useState<boolean>(false)
  // null corresponds to commentDisplayed === false
  // initialize as [true, true, true, ...] when commentDisplayed is set to true
  const [errorCategory, setErrorCategory] = useState<string | null>(null)
  const [errorOptions, setErrorOptions] = useState<string | null>(null)

  const categoriesInUse = questions.map((question) => question.category)
  const questionsForSelect: SelectOption[] = questions.map((question) => ({
    value: question.id,
    label: question.category.concat((questionIdsInUse.has(question.id) ? ' [in use]' : '')),
  })).sort((a, b) => a.label.localeCompare(b.label))
  const responseOptionsForSelect: SelectOption[] = responseOptions.map((responseOption) => ({
    value: responseOption.id,
    label: responseOption.name,
  })).sort((a, b) => a.label.localeCompare(b.label))

  const isSelectedQuestionInUse = !!selectedQuestion && questionIdsInUse.has(selectedQuestion.value)

  const validateCategory = (candidateCategory: string): boolean => {
    if (!candidateCategory) {
      setErrorCategory('The question must have a category.')
      return false
    }

    if (categoriesInUse.includes(candidateCategory) && !(formState === QuestionFormState.editing && originalCategory === candidateCategory)) {
      setErrorCategory(`Question categories must be unique, ${candidateCategory} is already in use.`)
      return false
    }

    setErrorCategory(null)
    return true
  }

  const validateRequiredAnswer = (candidateOptions: SelectOption | null, candidateCommentDisplayed: boolean): boolean => {
    if (!candidateOptions && !candidateCommentDisplayed) {
      setErrorOptions('The question must have response options or detailed comments.')
      return false
    }
    setErrorOptions(null)
    return true
  }

  const validateForm = (): boolean => validateCategory(category) && validateRequiredAnswer(options, commentDisplayed)

  const handleChangeCategory = (event: ChangeEvent<HTMLInputElement>): void => {
    setCategory(event.target.value)
    validateCategory(event.target.value)
  }

  const handleChangeCommentDisplayed = (event: ChangeEvent<HTMLInputElement>): void => {
    const newCommentDisplayed = event.target.checked
    validateRequiredAnswer(options, newCommentDisplayed)
    setCommentDisplayed(newCommentDisplayed)
    if (newCommentDisplayed && !commentRequiredByOption) { // need to initialize comments required array
      const selectedResponseOptions = options && responseOptions.find((option) => option.id === options.value)
      const optionsCount = selectedResponseOptions ? selectedResponseOptions.options.length : 0
      setCommentRequiredByOption(Array(optionsCount).fill(true))
    } else {
      setFreeTextRequired(false)
    }
  }

  const handleChangeOptions = (selected: SelectOption | null): void => {
    setOptions(selected)
    validateRequiredAnswer(selected, commentDisplayed)
    // now need to reset comments required array
    if (!selected) {
      setCommentRequiredByOption(null)
    } else {
      const selectedResponseOptions = responseOptions.find((option) => option.id === selected.value)
      const optionsCount = selectedResponseOptions ? selectedResponseOptions.options.length : 0
      setCommentRequiredByOption(Array(optionsCount).fill(true))
    }
  }

  const handleChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>): void => setDescription(event.target.value)

  const handleChangeCommentRequiredByOption = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!commentRequiredByOption) return
    const newCommentsRequired = [...(commentRequiredByOption)]
    newCommentsRequired[Number(event.currentTarget.name)] = event.currentTarget.checked
    setCommentRequiredByOption(newCommentsRequired)
  }
  const handleChangeFreeTextRequired = (event: ChangeEvent<HTMLInputElement>): void => {
    setFreeTextRequired(event.currentTarget.checked)
  }

  const handleSelectQuestion = (selected: SelectOption): void => setSelectedQuestion(selected)

  const handleSubmit = (): void => {
    if (validateForm()) {
      const questionToCreate = new QuestionToCreate(
        category,
        commentDisplayed,
        options ? options.value : null,
        (description !== '') ? description : null,
        commentRequiredByOption,
        freeTextRequired,
      )
      if (formState === QuestionFormState.creating) {
        createQuestion(questionToCreate)
      }

      if (formState === QuestionFormState.editing && selectedQuestion) {
        editQuestion(selectedQuestion.value, questionToCreate)
      }

      setFormState(QuestionFormState.normal)
    }
  }

  const handleCancelButtonClick = (): void => {
    setCategory('')
    setOriginalCategory('')
    setCommentDisplayed(false)
    setOptions(null)
    setDescription('')
    setCommentRequiredByOption(null)
    setFreeTextRequired(false)
    setFormState(QuestionFormState.normal)
    setErrorCategory(null)
    setErrorOptions(null)
  }

  const prefillFormBasedOnCurrentQuestion = (): void => {
    const matchingQuestionWithDetails = selectedQuestion && getQuestionWithDetails(selectedQuestion.value, questions, responseOptions)
    const matchingOptionsCount = (matchingQuestionWithDetails && matchingQuestionWithDetails.responseOptions)
      ? matchingQuestionWithDetails.responseOptions.options.length
      : 0
    const matchingCommentRequired = matchingQuestionWithDetails && matchingQuestionWithDetails.question.freeTextCommentRequiredOnResponses
    if (matchingQuestionWithDetails) {
      setCategory(matchingQuestionWithDetails.question.category)
      setOriginalCategory(matchingQuestionWithDetails.question.category)
      setCommentDisplayed(matchingQuestionWithDetails.question.freeTextCommentDisplayed)
      setFreeTextRequired(matchingQuestionWithDetails.question.freeTextRequired)
      setOptions((responseOptionsForSelect.find((option) => option.value === matchingQuestionWithDetails.question.optionsId) || null))
      setDescription(matchingQuestionWithDetails.question.description || '')
      const derivedCommentRequiredByOption = matchingCommentRequired
        && Array.from(Array(matchingOptionsCount), (_element, index) => {
          if (matchingCommentRequired.length === 0) return true
          if (matchingCommentRequired.length < index + 1) return matchingCommentRequired[0]
          return matchingCommentRequired[index]
        })
      setCommentRequiredByOption(derivedCommentRequiredByOption || Array(matchingOptionsCount).fill(true))
    }
  }

  const handleCreateButtonClick = (): void => {
    setFormState(QuestionFormState.creating)
  }

  const handleEditButtonClick = (): void => {
    prefillFormBasedOnCurrentQuestion()
    setFormState(QuestionFormState.editing)
  }

  const handleCreateBasedOnCurrentButtonClick = (): void => {
    prefillFormBasedOnCurrentQuestion()
    setCategory((oldCategory) => oldCategory.concat(' (updated)'))
    setFormState(QuestionFormState.creating)
  }

  const handleDeleteButtonClick = (): void => {
    if (selectedQuestion) {
      deleteQuestion(selectedQuestion.value)
      setSelectedQuestion(null)
    }

    setFormState(QuestionFormState.normal)
  }

  return (
    (formState !== QuestionFormState.normal) ? (
      <QuestionFormEdit
        category={category}
        commentDisplayed={commentDisplayed}
        commentRequiredByOption={commentRequiredByOption}
        description={description}
        freeTextRequired={freeTextRequired}
        errorCategory={errorCategory}
        errorOptions={errorOptions}
        handleCancelButtonClick={handleCancelButtonClick}
        handleChangeCategory={handleChangeCategory}
        handleChangeCommentDisplayed={handleChangeCommentDisplayed}
        handleChangeCommentRequiredByOption={handleChangeCommentRequiredByOption}
        handleChangeDescription={handleChangeDescription}
        handleChangeFreeTextRequired={handleChangeFreeTextRequired}
        handleChangeOptions={handleChangeOptions}
        handleSubmit={handleSubmit}
        options={options}
        responseOptions={responseOptions}
        responseOptionsForSelect={responseOptionsForSelect}
        isSelectedQuestionInUse={isSelectedQuestionInUse}
        isEditing={formState === QuestionFormState.editing}
      />
    ) : (
      <QuestionFormView
        handleCreateBasedOnCurrentButtonClick={handleCreateBasedOnCurrentButtonClick}
        handleCreateButtonClick={handleCreateButtonClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        handleEditButtonClick={handleEditButtonClick}
        handleSelectQuestion={handleSelectQuestion}
        questionIdsInUse={questionIdsInUse}
        questions={questions}
        questionsForSelect={questionsForSelect}
        responseOptions={responseOptions}
        selectedQuestion={selectedQuestion}
        isSelectedQuestionInUse={isSelectedQuestionInUse}
      />
    )
  )
}
