import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { UserWithDetails } from 'review-app-shared/types/user'
import { UserGroup } from 'review-app-shared/types/user-group'

export const userStartGetUsers = createAction('user/START_GET_USERS',
  (actionCallback) => () => actionCallback())

export const userFinishGetUsers = createAction('user/FINISH_GET_USERS',
  (actionCallback) => (users: Array<UserWithDetails>) => actionCallback(users))

export const userErrorGetUsers = createAction('user/ERROR_GET_USERS',
  (actionCallback) => (error: Error) => actionCallback(error))

export const userStartGetUserGroups = createAction('user/START_GET_USER_GROUPS',
  (actionCallback) => () => actionCallback())

export const userFinishGetUserGroups = createAction('user/FINISH_GET_USER_GROUPS',
  (actionCallback) => (userGroups: Array<UserGroup>) => actionCallback(userGroups))

export const userErrorGetUserGroups = createAction('user/ERROR_GET_USER_GROUPS',
  (actionCallback) => (error: Error) => actionCallback(error))

export type UserActions =
    ActionType<typeof userStartGetUsers | typeof userFinishGetUsers | typeof userErrorGetUsers |
        typeof userStartGetUserGroups | typeof userFinishGetUserGroups | typeof userErrorGetUserGroups>
export type UserStartGetUsers = ActionType<typeof userStartGetUsers>
export type UserFinishGetUsers = ActionType<typeof userFinishGetUsers>
export type UserErrorGetUsers = ActionType<typeof userErrorGetUsers>
export type UserStartGetUserGroups = ActionType<typeof userStartGetUserGroups>
export type UserFinishGetUserGroups = ActionType<typeof userFinishGetUserGroups>
export type UserErrorGetUserGroups = ActionType<typeof userErrorGetUserGroups>

export const UserStartGetUsersActionType = getType(userStartGetUsers)
export const UserFinishGetUsersActionType = getType(userFinishGetUsers)
export const UserErrorGetUsersActionType = getType(userErrorGetUsers)
export const UserStartGetUserGroupsActionType = getType(userStartGetUserGroups)
export const UserFinishGetUserGroupsActionType = getType(userFinishGetUserGroups)
export const UserErrorGetUserGroupsActionType = getType(userErrorGetUserGroups)
