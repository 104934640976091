import React, { FC } from 'react'
import { applyMiddleware, compose, createStore } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import { RouteHandler } from 'app/RouteHandler'
import { ErrorBoundary } from 'app/components/ErrorBoundary'
import { rootReducer } from 'app/modules/root/root-reducer'
import { rootSaga } from 'app/modules/root/root-saga'

const sagaMiddleware = createSagaMiddleware()

/* eslint '@typescript-eslint/ban-ts-ignore': 0 */
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export const App: FC = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <RouteHandler />
    </Provider>
  </ErrorBoundary>
)
