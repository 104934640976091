import { Feedback, FeedbackWithQuestionsAndAnswers, FeedbackState } from '../types/feedback'
import { Questionnaire } from '../types/questionnaire'
import { QuestionWithResponseOptions } from '../types/question'
import { Answer } from '../types/answer'
import { normalizeNullableDate } from './utils'

export interface FeedbackNormalizerInput {
  id: number
  reviewerUserId: number
  reviewId: number
  dateSubmitted: string | null
  state: FeedbackState
}

export interface FeedbackToGetNormalizerInput {
  id: number
  reviewerUserId: number
  reviewId: number
  dateSubmitted: string | null
  state: FeedbackState
  questionnaire: {
    id: number
    name: string
    active: boolean
    dateCreated: string
    description: string | null
  }
  questions: Array<QuestionWithResponseOptions>
  answers: Array<Answer>
}

export class FeedbackNormalizer {
  public static normalizeFeedback(data: FeedbackNormalizerInput): Feedback {
    return new Feedback(
      data.id,
      data.reviewerUserId,
      data.reviewId,
      normalizeNullableDate(data.dateSubmitted),
      data.state,
    )
  }

  public static normalizeFeedbackToGet(data: FeedbackToGetNormalizerInput): FeedbackWithQuestionsAndAnswers {
    return new FeedbackWithQuestionsAndAnswers(
      data.id,
      data.reviewerUserId,
      data.reviewId,
      normalizeNullableDate(data.dateSubmitted),
      data.state,
      new Questionnaire(
        data.questionnaire.id,
        data.questionnaire.name,
        data.questionnaire.active,
        new Date(data.questionnaire.dateCreated),
        data.questionnaire.description,
      ),
      data.questions,
      data.answers,
    )
  }
}
