import { Reducer, combineReducers } from 'redux'
import { feedbackFormReducer, FeedbackFormState } from 'app/modules/feedback-form/feedback-form-reducer'
import { questionFormReducer, QuestionFormState } from 'app/modules/question-form/question-form-reducer'
import { questionnaireFormReducer, QuestionnaireFormState } from 'app/modules/questionnaire-form/questionnaire-form-reducer'
import { responseOptionsFormReducer, ResponseOptionsFormState } from 'app/modules/response-options-form/response-options-form-reducer'
import { reviewFormReducer, ReviewFormState } from 'app/modules/review-form/review-form-reducer'
import { userGroupFormReducer, UserGroupFormState } from 'app/modules/user-group-form/user-group-form-reducer'
import { userFormReducer, UserFormState } from 'app/modules/user-form/user-form-reducer'
import { authReducer, AuthState } from 'app/modules/auth/auth-reducer'
import { questionReducer, QuestionState } from 'app/modules/question/question-reducer'
import { reviewReducer, ReviewState } from 'app/modules/review/review-reducer'
import { userReducer, UserState } from 'app/modules/user/user-reducer'
import { forecastReducer, ForecastState } from 'app/modules/forecast/forecast-reducer'
import { timesheetReducer, TimesheetState } from '../timesheet/timesheet-reducer'

export type AppState = {
  auth: AuthState
  question: QuestionState
  review: ReviewState
  user: UserState
  feedbackForm: FeedbackFormState
  questionForm: QuestionFormState
  questionnaireForm: QuestionnaireFormState
  responseOptionsForm: ResponseOptionsFormState
  reviewForm: ReviewFormState
  userGroupForm: UserGroupFormState
  userForm: UserFormState
  forecast: ForecastState
  timesheet: TimesheetState
}

export const rootReducer: Reducer<AppState> = combineReducers({
  auth: authReducer,
  question: questionReducer,
  review: reviewReducer,
  user: userReducer,
  feedbackForm: feedbackFormReducer,
  questionForm: questionFormReducer,
  questionnaireForm: questionnaireFormReducer,
  responseOptionsForm: responseOptionsFormReducer,
  reviewForm: reviewFormReducer,
  userGroupForm: userGroupFormReducer,
  userForm: userFormReducer,
  forecast: forecastReducer,
  timesheet: timesheetReducer,
})
