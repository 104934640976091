import {
  Review,
  ReviewWithFeedback,
  ReviewToCreate,
  ReviewUpdateData,
} from '../types/review'

import {
  FeedbackNormalizer,
  FeedbackNormalizerInput,
} from './feedback-normalizer'

export interface ReviewNormalizerInput {
  id: number
  timeCreated: string
  timeReviewed: string
  deadline: string
  userReviewedId: number
  questionnaireId: number
}

export interface ReviewWithFeedbackNormalizerInput {
  id: number
  timeCreated: string
  timeReviewed: string
  deadline: string
  userReviewedId: number
  questionnaireId: number
  readonly feedback: Array<FeedbackNormalizerInput>
}

export interface ReviewUpdateDataNormalizerInput {
  timeReviewed: string
  usersToAdd: Array<number>
  usersToRemove: Array<number>
}

export interface ReviewToCreateNormalizerInput {
  timeReviewed: string
  userReviewedId: number
  questionnaireId: number
  usersReviewing: Array<number>
}

export class ReviewNormalizer {
  public static normalizeReview(data: ReviewNormalizerInput): Review {
    return new Review(
      data.id,
      new Date(data.timeCreated),
      new Date(data.timeReviewed),
      new Date(data.deadline),
      data.userReviewedId,
      data.questionnaireId,
    )
  }

  public static normalizeReviewWithFeedback(data: ReviewWithFeedbackNormalizerInput): ReviewWithFeedback {
    return new ReviewWithFeedback(
      data.id,
      new Date(data.timeCreated),
      new Date(data.timeReviewed),
      new Date(data.deadline),
      data.userReviewedId,
      data.questionnaireId,
      data.feedback.map(((eachFeedback) => FeedbackNormalizer.normalizeFeedback(eachFeedback))),
    )
  }

  public static normalizeReviewUpdateData(data: ReviewUpdateDataNormalizerInput): ReviewUpdateData {
    return new ReviewUpdateData(
      new Date(data.timeReviewed),
      data.usersToAdd,
      data.usersToRemove,
    )
  }

  public static normalizeReviewToCreate(data: ReviewToCreateNormalizerInput): ReviewToCreate {
    return new ReviewToCreate(
      new Date(data.timeReviewed),
      data.userReviewedId,
      data.questionnaireId,
      data.usersReviewing,
    )
  }
}
