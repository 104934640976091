import React, { FC } from 'react'

export const Unknown: FC = () => (
  <div className="content">
    <div className="main">
      <h2 data-cy="page-not-found">Page not found</h2>
      <p>
          Sorry, this page does not exist.
      </p>
    </div>
  </div>
)
