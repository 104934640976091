import { AuthFinishLogout, AuthFinishLogoutActionType } from 'app/modules/auth/auth-actions'
import { ForecastApiResponse } from 'review-app-shared/types/forecast'

import {
  FetchResult,
  getLoading,
  getLoaded,
  getError,
  getNotStarted,
} from 'review-app-shared/types/fetchData'
import {
  ForecastActions,
  ForecastStartGetActionType,
  ForecastFinishGetActionType,
  ForecastErrorGetActionType,
  ForecastStartUpdateUsersActionType,
  ForecastFinishUpdateUsersActionType,
} from './forecast-actions'

export type ForecastState = {
  forecasts: FetchResult<ForecastApiResponse>
  reloadingUsers: boolean
}

const initialState: ForecastState = {
  forecasts: getNotStarted(),
  reloadingUsers: false,
}

type AllowedActionTypes = ForecastActions | AuthFinishLogout

export const forecastReducer = (state: ForecastState = initialState, action: AllowedActionTypes): ForecastState => {
  switch (action.type) {
    case AuthFinishLogoutActionType:
      return initialState
    case ForecastStartGetActionType: {
      return { ...state, forecasts: getLoading() }
    }
    case ForecastFinishGetActionType: {
      return { ...state, forecasts: getLoaded(action.payload) }
    }
    case ForecastErrorGetActionType: {
      return { ...state, forecasts: getError(action.payload.message) }
    }
    case ForecastStartUpdateUsersActionType: {
      return { ...state, reloadingUsers: true }
    }
    case ForecastFinishUpdateUsersActionType: {
      return { ...state, reloadingUsers: false }
    }
    default:
      return state
  }
}
