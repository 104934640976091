import { Feedback, FeedbackState } from './feedback'
import { Questionnaire } from './questionnaire'
import { Review } from './review'
import { User, UserWithDetails } from './user'
import { UserGroup } from './user-group'

// This file defines extended types returned by Redux selectors which
// link data of different types

// type returned by getManagedUserReviews selector
export class ReviewPopulated extends Review {
  constructor(
    id: number,
    timeCreated: Date,
    timeReviewed: Date,
    deadline: Date,
    userReviewedId: number,
    questionnaireId: number,
    readonly userReviewed: User,
    readonly questionnaire: Questionnaire,
    readonly linkedFeedback: Array<Feedback>,
  ) {
    super(id, timeCreated, timeReviewed, deadline, userReviewedId, questionnaireId)
  }
}

// type returned by getFeedback selector
export class FeedbackPopulated extends Feedback {
  constructor(
    id: number,
    reviewerUserId: number,
    reviewId: number,
    dateSubmitted: Date | null,
    state: FeedbackState,
    readonly linkedReview: Review,
    readonly reviewedUser: User,
  ) {
    super(id, reviewerUserId, reviewId, dateSubmitted, state)
  }
}

// type returned by getCurrentUser selector
export class UserWithDetailsPopulated extends User {
  constructor(
    id: number,
    username: string,
    isAdmin: boolean,
    email: string,
    active: boolean,
    readonly managers: Array<UserWithDetails>,
    readonly managedUsers: Array<UserWithDetails>,
    readonly userGroups: Array<UserGroup>,
    employmentStartYear?: number,
    annualReviewMonth?: number,
  ) {
    super(id, username, isAdmin, email, active, employmentStartYear, annualReviewMonth)
  }
}
