export class UserGroup {
  constructor(
    readonly id: number,
    readonly name: string,
  ) {
  }
}

export class UserGroupToCreate {
  constructor(
    readonly name: string,
  ) {
  }
}
