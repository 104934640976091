import React, { FC } from 'react'

import { homeUrl } from 'app/helpers/urlCreators'
import { Hero } from 'app/components/components-ui/hero/Hero'


// The OwnReviews component will inform a user about their own performance reviews
// (it is currently just a placeholder)
export const OwnReviews: FC = () => (
  <div className="content">
    <Hero
      backButton={homeUrl()}
      title="OwnReviews component"
      tooltip="
        View to inform a user about their own reviews, if company policy is to
        provide access beyond a simple list of dates (which would be available
        on the front page). If review outcomes and actions are recorded, they
        would be available here."
    />
    <p>placeholder</p>
  </div>
)
