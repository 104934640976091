import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { AuthSuccess } from 'review-app-shared/types/auth'

// Google OAuth2, action triggered when receiving a redirect from Google
// with a code parameter (to be exchanged by the backend for an access token)
export const authStartLoginGoogle = createAction('auth/START_LOGIN_GOOGLE',
  (actionCallback) => (code: string) => actionCallback(code))

// other actions are common to all login options
export const authFinishLogin = createAction('auth/FINISH_LOGIN',
  (actionCallback) => (authData: AuthSuccess) => actionCallback(authData))

export const authFailLogin = createAction('auth/LOGIN_ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export const authStartLogout = createAction('auth/START_LOGOUT',
  (actionCallback) => () => actionCallback())

export const authFinishLogout = createAction('auth/FINISH_LOGOUT',
  (actionCallback) => () => actionCallback())

export type AuthActions =
    ActionType<typeof authStartLoginGoogle
    | typeof authFinishLogin | typeof authFailLogin
    | typeof authStartLogout | typeof authFinishLogout>
export type AuthStartLoginGoogle = ActionType<typeof authStartLoginGoogle>
export type AuthFinishLogin = ActionType<typeof authFinishLogin>
export type AuthFailLogin = ActionType<typeof authFailLogin>
export type AuthStartLogout = ActionType<typeof authStartLogout>
export type AuthFinishLogout = ActionType<typeof authFinishLogout>

export const AuthStartLoginGoogleActionType = getType(authStartLoginGoogle)
export const AuthFinishLoginActionType = getType(authFinishLogin)
export const AuthFailLoginActionType = getType(authFailLogin)
export const AuthStartLogoutActionType = getType(authStartLogout)
export const AuthFinishLogoutActionType = getType(authFinishLogout)
