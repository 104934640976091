import { createSelector } from 'reselect'

import { AppState } from 'app/modules/root/root-reducer'
import { AuthState, AuthStatus } from 'app/modules/auth/auth-reducer'

export const getAuthState = ((state: AppState): AuthState => state.auth)

export const getCurrentUserEmail = createSelector(getAuthState, (auth) => {
  if (auth.kind === AuthStatus.LoggedIn) return auth.data.authenticatedUser
  return null
})

export const getCurrentUserToken = createSelector(getAuthState, (auth) => {
  if (auth.kind === AuthStatus.LoggedIn) return auth.data.token
  return null
})

export const getLoginError = createSelector(getAuthState, (auth) => {
  if (auth.kind === AuthStatus.Error) return auth.error.message
  return null
})
