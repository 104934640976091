import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiGetReviewById,
  apiPostReviewForm,
  apiPutReviewForm,
  apiDeleteReview,
} from 'app/modules/review-form/review-form-effects'
import { Review, ReviewWithFeedback } from 'review-app-shared/types/review'
import {
  ReviewNormalizer,
  ReviewNormalizerInput,
  ReviewWithFeedbackNormalizerInput,
} from 'review-app-shared/normalizers/review-normalizer'

import {
  reviewStartGetFeedback,
  ReviewStartGetFeedback,
} from 'app/modules/review/review-actions'
import {
  createReviewFormFinishSend,
  createReviewFormError,
  CreateReviewFormStartSendActionType,
  CreateReviewFormStartSend,
  CreateReviewFormFinishSend,
  CreateReviewFormError,
  updateReviewFormFinishFetch,
  updateReviewFormFinishSend,
  updateReviewFormFinishDelete,
  updateReviewFormError,
  UpdateReviewFormStartFetchActionType,
  UpdateReviewFormStartFetch,
  UpdateReviewFormFinishFetch,
  UpdateReviewFormStartSendActionType,
  UpdateReviewFormStartSend,
  UpdateReviewFormFinishSend,
  UpdateReviewFormStartDeleteActionType,
  UpdateReviewFormStartDelete,
  UpdateReviewFormFinishDelete,
  UpdateReviewFormError,
  CreateReviewFormFinishSendActionType,
  CreateReviewFormErrorActionType,
} from 'app/modules/review-form/review-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getCreateReviewFormState } from './review-form-selectors'

type CreateReviewEffects = SelectEffect | CallEffect<AxiosResponse<Review>> |
PutEffect<CreateReviewFormFinishSend> | PutEffect<CreateReviewFormError> | PutEffect<ReviewStartGetFeedback>

export function* createReviewSaga(
  action: CreateReviewFormStartSend,
): Generator<CreateReviewEffects, void, string | AxiosResponse<ReviewNormalizerInput>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostReviewForm, action.payload, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = ReviewNormalizer.normalizeReview(response.data)
        yield put(createReviewFormFinishSend(responseNormalized))
        yield put(reviewStartGetFeedback())
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(createReviewFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(createReviewFormError(error))
    }
  }
}

type FetchReviewEffects = SelectEffect | CallEffect<AxiosResponse<ReviewWithFeedback>> |
PutEffect<UpdateReviewFormFinishFetch> | PutEffect<UpdateReviewFormError>

export function* fetchReviewSaga(
  action: UpdateReviewFormStartFetch,
): Generator<FetchReviewEffects, void, string | AxiosResponse<ReviewWithFeedbackNormalizerInput>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetReviewById, action.payload, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = ReviewNormalizer.normalizeReviewWithFeedback(response.data)
        yield put(updateReviewFormFinishFetch(responseNormalized))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(updateReviewFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(updateReviewFormError(error))
    }
  }
}

type UpdateReviewEffects = SelectEffect | CallEffect<AxiosResponse<ReviewWithFeedback>> |
PutEffect<UpdateReviewFormFinishSend> | PutEffect<UpdateReviewFormError>

export function* updateReviewSaga(
  action: UpdateReviewFormStartSend,
): Generator<UpdateReviewEffects, void, string | AxiosResponse<ReviewWithFeedbackNormalizerInput>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutReviewForm, action.payload.reviewId, action.payload.reviewUpdateData, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = ReviewNormalizer.normalizeReviewWithFeedback(response.data)
        yield put(updateReviewFormFinishSend(responseNormalized))
        toast.info('You have successfully updated the review', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(updateReviewFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(updateReviewFormError(error))
    }
  }
}

type DeleteReviewEffects = SelectEffect | CallEffect<AxiosResponse<void>> |
PutEffect<UpdateReviewFormFinishDelete> | PutEffect<UpdateReviewFormError>

export function* deleteReviewSaga(
  action: UpdateReviewFormStartDelete,
): Generator<DeleteReviewEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteReview, action.payload, token)
      if (response) {
        yield put(updateReviewFormFinishDelete(reviewId))
        toast.info('You have successfully deleted this review', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(updateReviewFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(updateReviewFormError(error))
    }
  }
}

export function* toastSaved(): Generator<SelectEffect, void, ReturnType<typeof getCreateReviewFormState>> {
  const formState: ReturnType<typeof getCreateReviewFormState> = yield select(getCreateReviewFormState)
  if (formState.kind === FormStatus.Saved) {
    toast.info('You have successfully created the review', {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastError(): Generator<SelectEffect, void, ReturnType<typeof getCreateReviewFormState>> {
  const formState: ReturnType<typeof getCreateReviewFormState> = yield select(getCreateReviewFormState)
  if (formState.kind === FormStatus.Error) {
    toast.error(formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* reviewFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(CreateReviewFormStartSendActionType, createReviewSaga)
  yield takeEvery(UpdateReviewFormStartFetchActionType, fetchReviewSaga)
  yield takeEvery(UpdateReviewFormStartSendActionType, updateReviewSaga)
  yield takeEvery(UpdateReviewFormStartDeleteActionType, deleteReviewSaga)
  yield takeEvery(CreateReviewFormFinishSendActionType, toastSaved)
  yield takeEvery(CreateReviewFormErrorActionType, toastError)
}
