import React, { FC } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { Button, ButtonProps as ButtonPropsImported } from 'app/components/components-ui/buttons/button'

interface ButtonGroupProps {
  buttons: Array<ButtonProps>
  className?: string
}
interface ButtonProps extends ButtonPropsImported {
  hidden?: boolean
  to?: string
}

export const ButtonGroup: FC<ButtonGroupProps> = ({ buttons, className }) => (
  <div className={cx('btn-group', className)}>
    <div>
      {buttons.map((button: ButtonProps) => !button.hidden
        && (button.to
          ? <Link key={button.label} to={button.to} {...button}>{button.label}</Link>
          : <Button key={button.label} {...button} />))}
    </div>
  </div>
)
