import React, { FC, useEffect, useCallback } from 'react'

import { FeedbackState } from 'review-app-shared/types/feedback'
import { feedbackFormStartSend } from 'app/modules/feedback-form/feedback-form-actions'
import { useDispatch } from 'react-redux'
import { useModal, ModalState } from 'app/hooks/useModal'
import { Button } from '../buttons/button'

export const DismissReviewButton: FC<{ feedbackId: string; revieweeName: string }> = ({ feedbackId, revieweeName }) => {
  const dispatch = useDispatch()
  const dismissFeedback = useCallback((): void => {
    dispatch(feedbackFormStartSend(feedbackId, { answers: [], state: FeedbackState.Dismissed }))
  }, [dispatch, feedbackId])

  const {
    Modal, showModal, hideModal, modalState,
  } = useModal()
  useEffect(() => {
    switch (modalState) {
      case ModalState.Confirmed:
        hideModal()
        // dismiss
        dismissFeedback()
        break
      case ModalState.Canceled:
        hideModal()
        break
      default:
        break
    }
  }, [dismissFeedback, feedbackId, hideModal, modalState])

  return (
    <>
      <Button className="btn btn-secondary" type="button" label="Dismiss feedback" onClick={showModal} />
      <Modal
        title="Dismiss a review"
        primaryButton="Yes"
        secondaryButton="No"
      >
        <p>
          {'You are going to '}
          <strong>dismiss</strong>
          {' a review for '}
          <em>{revieweeName}</em>
        , are you sure?
        </p>
      </Modal>
    </>
  )
}
