import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiPostUser,
  apiPutUser,
  apiDeleteUser,
} from 'app/modules/user-form/user-form-effects'

import {
  userFormCreateFinishSend,
  userFormUpdateFinishSend,
  userFormFinishDelete,
  userFormError,
  UserFormCreateStartSendActionType,
  UserFormCreateStartSend,
  UserFormCreateFinishSend,
  UserFormUpdateStartSendActionType,
  UserFormUpdateStartSend,
  UserFormUpdateFinishSend,
  UserFormStartDeleteActionType,
  UserFormStartDelete,
  UserFormFinishDelete,
  UserFormError,
  UserFormCreateFinishSendActionType,
  UserFormFinishDeleteActionType,
  UserFormErrorActionType,
  UserFormUpdateFinishSendActionType,
} from 'app/modules/user-form/user-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { UserToCreateOrUpdate, UserWithDetails } from 'review-app-shared/types/user'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getUserFormState } from './user-form-selectors'

type CreateUserEffects = SelectEffect | CallEffect<AxiosResponse<UserToCreateOrUpdate>> |
PutEffect<UserFormCreateFinishSend> | PutEffect<UserFormError>

export function* createUserSaga(
  action: UserFormCreateStartSend,
): Generator<CreateUserEffects, void, string | AxiosResponse<UserWithDetails>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostUser, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(userFormCreateFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(userFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(userFormError(error))
    }
  }
}

type UpdateUserEffects = SelectEffect | CallEffect<AxiosResponse<UserToCreateOrUpdate>> |
PutEffect<UserFormUpdateFinishSend> | PutEffect<UserFormError>

export function* updateUserSaga(
  action: UserFormUpdateStartSend,
): Generator<UpdateUserEffects, void, string | AxiosResponse<UserWithDetails>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutUser, action.payload.userId, action.payload.userUpdateData, token)
      if (response && typeof response !== 'string') {
        yield put(userFormUpdateFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(userFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(userFormError(error))
    }
  }
}

type DeleteUserEffects = SelectEffect | CallEffect<AxiosResponse<void>> |
PutEffect<UserFormFinishDelete> | PutEffect<UserFormError>

export function* deleteUserSaga(
  action: UserFormStartDelete,
): Generator<DeleteUserEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteUser, action.payload, token)
      if (response) yield put(userFormFinishDelete(reviewId))
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(userFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(userFormError(error))
    }
  }
}

export function* toastSaved(): Generator<SelectEffect, void, ReturnType<typeof getUserFormState>> {
  const formState: ReturnType<typeof getUserFormState> = yield select(getUserFormState)
  if (formState.kind === FormStatus.Saved) {
    toast.info(`You have successfully created ${formState.data.username}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastUpdated(): Generator<SelectEffect, void, ReturnType<typeof getUserFormState>> {
  const formState: ReturnType<typeof getUserFormState> = yield select(getUserFormState)
  if (formState.kind === FormStatus.Saved) {
    toast.info(`You have successfully updated ${formState.data.username}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastDelete(): Generator<CallEffect<string | number>> {
  yield call(toast.info, 'You have successfully deleted the user.', {
    position: toast.POSITION.TOP_CENTER,
  })
}

export function* toastError(): Generator<SelectEffect, void, ReturnType<typeof getUserFormState>> {
  const formState: ReturnType<typeof getUserFormState> = yield select(getUserFormState)
  if (formState.kind === FormStatus.Error) {
    toast.error(formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* userFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(UserFormCreateStartSendActionType, createUserSaga)
  yield takeEvery(UserFormUpdateStartSendActionType, updateUserSaga)
  yield takeEvery(UserFormStartDeleteActionType, deleteUserSaga)
  yield takeEvery(UserFormCreateFinishSendActionType, toastSaved)
  yield takeEvery(UserFormUpdateFinishSendActionType, toastUpdated)
  yield takeEvery(UserFormFinishDeleteActionType, toastDelete)
  yield takeEvery(UserFormErrorActionType, toastError)
}
