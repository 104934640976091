import {
  UserFormActions,
  UserFormCreateStartSendActionType,
  UserFormCreateFinishSendActionType,
  UserFormUpdateStartSendActionType,
  UserFormUpdateFinishSendActionType,
  UserFormStartDeleteActionType,
  UserFormFinishDeleteActionType,
  UserFormErrorActionType,
} from 'app/modules/user-form/user-form-actions'

import {
  UserWithDetails,
  UserToCreateOrUpdate,
} from 'review-app-shared/types/user'
import {
  FormDataResult,
  getBlank,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getError,
} from 'review-app-shared/types/formData'

export type UserFormState = FormDataResult<UserWithDetails, UserToCreateOrUpdate>

const initialState = getBlank()

export const userFormReducer = (
  state: UserFormState = initialState,
  action: UserFormActions,
): UserFormState => {
  switch (action.type) {
    case UserFormCreateStartSendActionType:
      return getSaving(action.payload)
    case UserFormCreateFinishSendActionType:
      return getSaved(action.payload)
    case UserFormUpdateStartSendActionType:
      return getSaving(action.payload.userUpdateData)
    case UserFormUpdateFinishSendActionType:
      return getSaved(action.payload)
    case UserFormStartDeleteActionType:
      return getDeleting()
    case UserFormFinishDeleteActionType:
      return getDeleted()
    case UserFormErrorActionType:
      return getError(action.payload.message)
    default:
      return state
  }
}
