import {
  QuestionnaireFormActions,
  QuestionnaireFormCreateStartSendActionType,
  QuestionnaireFormCreateFinishSendActionType,
  QuestionnaireFormUpdateStartSendActionType,
  QuestionnaireFormUpdateFinishSendActionType,
  QuestionnaireFormStartDeleteActionType,
  QuestionnaireFormFinishDeleteActionType,
  QuestionnaireFormErrorActionType,
} from 'app/modules/questionnaire-form/questionnaire-form-actions'

import {
  QuestionnaireWithQuestionIds,
  QuestionnaireToCreate,
  QuestionnaireUpdateData,
} from 'review-app-shared/types/questionnaire'
import {
  FormDataResult,
  getBlank,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getError,
} from 'review-app-shared/types/formData'

export type QuestionnaireFormState = FormDataResult<QuestionnaireWithQuestionIds, QuestionnaireToCreate | QuestionnaireUpdateData>

const initialState = getBlank()

export const questionnaireFormReducer = (
  state: QuestionnaireFormState = initialState,
  action: QuestionnaireFormActions,
): QuestionnaireFormState => {
  switch (action.type) {
    case QuestionnaireFormCreateStartSendActionType:
      return getSaving(action.payload)
    case QuestionnaireFormCreateFinishSendActionType:
      return getSaved(action.payload)
    case QuestionnaireFormUpdateStartSendActionType:
      return getSaving(action.payload.questionnaireUpdateData)
    case QuestionnaireFormUpdateFinishSendActionType:
      return getSaved(action.payload)
    case QuestionnaireFormStartDeleteActionType:
      return getDeleting()
    case QuestionnaireFormFinishDeleteActionType:
      return getDeleted()
    case QuestionnaireFormErrorActionType:
      return getError(action.payload.message)
    default:
      return state
  }
}
