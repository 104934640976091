import React, { FC } from 'react'

import { ReviewPopulated } from 'review-app-shared/types/selectors'
import { formatDate } from 'app/helpers/review'
import { UserWithDetails } from 'review-app-shared/types/user'
import { ReviewSummaryAnswers } from 'app/components/manager/ReviewSummaryAnswers'
import { ReviewSummaryQuestion } from 'app/components/manager/ReviewSummaryQuestion'
import { Accordion } from 'app/components/components-ui/accordion/accordion'
import { incompleteFeedback as incompleteFeedbackStateList } from 'review-app-shared/types/feedback'
import { useFeedbackById, useFetchFeedback } from './hooks'
import { FeedbacksStatus } from './FeedbackStatus'
import { ReviewSummaryField } from '../manager/ReviewSummaryField'

interface ReviewDetailProps {
  review: ReviewPopulated
  users: UserWithDetails[]
}

export const ReviewDetail: FC<ReviewDetailProps> = ({ review, users }) => {
  const { linkedFeedback, timeReviewed, id } = review
  const incompleteFeedback = linkedFeedback.filter(({ state }) => incompleteFeedbackStateList.includes(state))

  const feedback = useFeedbackById(id)
  useFetchFeedback(review)

  return (
    <Accordion title={`Review date: ${formatDate(timeReviewed)}`}>
      <div className="grid grid-sidebar">
        <div>
          {incompleteFeedback.length ? (<FeedbacksStatus users={users} feedbacks={incompleteFeedback} />) : null}
          {feedback.length > 0
            && feedback[0].questions.map((question) => (
              <div key={question.id}>
                <ReviewSummaryQuestion question={question} />
                <ReviewSummaryAnswers
                  questionId={question.id}
                  matchingFeedbackById={feedback}
                  users={users}
                />
              </div>
            ))}
        </div>
        <ReviewSummaryField reviewId={id} />
      </div>
    </Accordion>
  )
}
