import React, { FC, ReactElement } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { ReactComponent as IconBack } from 'assets/svg/icon-back.svg'
import { Tooltip } from '../tooltip/tooltip'

interface HeroLinkProps {
  linkTitle: string
  linkTo: string
}
interface HeroProps {
  backButton?: string
  breadcrumb?: boolean
  className?: string
  subtitle?: string
  title: string
  tooltip?: string | React.ReactNode
}

export const Hero: FC<HeroProps> = ({
  backButton,
  breadcrumb,
  className,
  subtitle,
  title,
  tooltip,
}) => {
  const HeroTitle = (): ReactElement => (
    <>
      {title}
      {tooltip && (
        <Tooltip
          className="ml-10"
          position="bottom"
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  )

  return (
    <div className={cx('hero', className)} data-cy="hero-title">
      <div>
        {breadcrumb ? (
          <ul className="breadcrumb">
            <li>Manage</li>
            {title && (
            <li>
              <HeroTitle />
            </li>
            )}
          </ul>
        ) : (
          <>
            <h3>
              {backButton && (
              <Link className="btn btn-square mr-20" to={backButton}><IconBack /></Link>
              )}
              <HeroTitle />
            </h3>
            <p>{subtitle}</p>
          </>
        )}
      </div>
    </div>
  )
}
