import React, { Component } from 'react'

interface ErrorBoundaryProps {
  children?: React.ReactNode
}
interface ErrorBoundaryState {
  hasError: boolean
}

// The ErrorBoundary component contains the effects of any errors that have not already been
// allowed for at an appropriate level and presents the user with a warning message
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true } // next render will respond to error
  }

  componentDidCatch(error: Error | null, errorInfo: object): void {
    console.error(`Error caught: ${error},\n${JSON.stringify(errorInfo, null, 2)}`)
  }

  render(): React.ReactNode {
    const { children } = this.props
    if (!children) return null // nothing currently rendered within this error boundary

    const { hasError } = this.state
    if (hasError) {
      return (
        // #ToDo add some error styles
        <div className="error-boundary">
          <h2 className="error-boundary-title">Error</h2>
          <p className="error-boundary-message">
            Sorry, something has gone wrong with this part of the application. Please
            try refreshing the page in case it is a temporary problem.
          </p>
        </div>
      )
    }
    return children
  }
}
