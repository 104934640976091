import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { UserGroup, UserGroupToCreate } from 'review-app-shared/types/user-group'

export const userGroupFormStartSend = createAction('user-group-form/START_SEND',
  (actionCallback) => (userGroupToCreate: UserGroupToCreate) => actionCallback(userGroupToCreate))

export const userGroupFormFinishSend = createAction('user-group-form/FINISH_SEND',
  (actionCallback) => (userGroup: UserGroup) => actionCallback(userGroup))

export const userGroupFormStartDelete = createAction('user-group-form/START_DELETE',
  (actionCallback) => (userGroupId: number) => actionCallback(userGroupId))

export const userGroupFormFinishDelete = createAction('user-group-form/FINISH_DELETE',
  (actionCallback) => (userGroupId: number) => actionCallback(userGroupId))

export const userGroupFormError = createAction('user-group-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type UserGroupFormActions = ActionType<typeof userGroupFormStartSend |
  typeof userGroupFormFinishSend | typeof userGroupFormStartDelete |
  typeof userGroupFormFinishDelete | typeof userGroupFormError>

export type UserGroupFormStartSend = ActionType<typeof userGroupFormStartSend>
export type UserGroupFormFinishSend = ActionType<typeof userGroupFormFinishSend>
export type UserGroupFormStartDelete = ActionType<typeof userGroupFormStartDelete>
export type UserGroupFormFinishDelete = ActionType<typeof userGroupFormFinishDelete>
export type UserGroupFormError = ActionType<typeof userGroupFormError>

export const UserGroupFormStartSendActionType = getType(userGroupFormStartSend)
export const UserGroupFormFinishSendActionType = getType(userGroupFormFinishSend)
export const UserGroupFormStartDeleteActionType = getType(userGroupFormStartDelete)
export const UserGroupFormFinishDeleteActionType = getType(userGroupFormFinishDelete)
export const UserGroupFormErrorActionType = getType(userGroupFormError)
