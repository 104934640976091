import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { UserGroupToCreate } from 'review-app-shared/types/user-group'

import { FetchError } from 'app/components/FetchError'
import { UserGroupForm } from 'app/components/forms/UserGroupForm'

import { getIsErrorUser, getLoadedUsers, getLoadedUserGroups } from 'app/modules/user/user-selectors'
import { userGroupFormStartSend, userGroupFormStartDelete } from 'app/modules/user-group-form/user-group-form-actions'

// The UserGroupFormWrapper component enables an administrator to create a new user group
// or delete an (unused) user group.
export const UserGroupFormWrapper: FC = () => {
  const users = useSelector(getLoadedUsers)
  const userGroups = useSelector(getLoadedUserGroups)
  const isErrorUser = useSelector(getIsErrorUser)
  const dispatch = useDispatch()

  if (isErrorUser) return <FetchError />

  const createUserGroup = (userGroupData: UserGroupToCreate): void => {
    dispatch(userGroupFormStartSend(userGroupData))
  }

  const deleteUserGroup = (userGroupId: number): void => {
    dispatch(userGroupFormStartDelete(userGroupId))
  }

  const userGroupIdsInUse = new Set<number>()
  users.forEach((user) => user.userGroups.forEach((userGroupId) => userGroupIdsInUse.add(userGroupId)))

  return (
    <UserGroupForm
      createUserGroup={createUserGroup}
      deleteUserGroup={deleteUserGroup}
      userGroupIdsInUse={userGroupIdsInUse}
      userGroups={userGroups}
    />
  )
}
