import React, {
  FC,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
} from 'react'

import { ReactComponent as IconUp } from 'assets/svg/icon-up.svg'
import { ReactComponent as IconDown } from 'assets/svg/icon-down.svg'
import { ReactComponent as IconEdit } from 'assets/svg/icon-edit.svg'
import { ReactComponent as IconTrash } from 'assets/svg/icon-trash.svg'

import { Button } from 'app/components/components-ui/buttons/button'
import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface ResponseOptionsFormEditProps {
  errorName: string | null
  errorOptions: string | null
  handleCancelButtonClick: () => void
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeOptionToAdd: (event: ChangeEvent<HTMLInputElement>) => void
  handleOptionDeleteButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleOptionEditButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleOptionMoveDownButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleOptionMoveUpButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleOptionToAddButtonClick: () => void
  handleOptionToAddKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  name: string
  options: string[]
  optionToAdd: string
}

// The ResponseOptionsFormEdit renders the editing aspects of the response options form
export const ResponseOptionsFormEdit: FC<ResponseOptionsFormEditProps> = ({
  errorName,
  errorOptions,
  handleCancelButtonClick,
  handleChangeName,
  handleChangeOptionToAdd,
  handleOptionDeleteButtonClick,
  handleOptionEditButtonClick,
  handleOptionMoveDownButtonClick,
  handleOptionMoveUpButtonClick,
  handleOptionToAddButtonClick,
  handleOptionToAddKeyDown,
  handleSubmit,
  name,
  options,
  optionToAdd,
}) => (
  <div className="content has-buttons">
    <Hero
      breadcrumb
      title="Create response options"
    />
    <div className="main">
      <form className="form-medium" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            Name
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            value={name}
            onChange={handleChangeName}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
              if (event.key === 'Enter') event.preventDefault() // suppress form submit
            }}
            autoComplete="off"
            required
          />
        </div>
        {errorName && <p className="form-error">{errorName}</p>}
        {options.length ? (
          <>
            <p>Options: (order them from worst to best, with n/a last if offered)</p>
            <table className="table mb-20">
              <thead>
                <tr>
                  <th>Name</th>
                  {/* eslint-disable-next-line  jsx-a11y/control-has-associated-label */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {options.map((option, index) => (
                  <tr key={option}>
                    <td>{option}</td>
                    <td className="h-right">
                      <ul className="table-actions">
                        <li>
                          {(index > 0) ? (
                            <button className="btn-icon" name={option} type="button" onClick={handleOptionMoveUpButtonClick}>
                              <IconUp />
                            </button>
                          ) : <span />}
                        </li>
                        <li>
                          {(index < (options.length - 1)) ? (
                            <button className="btn-icon" name={option} type="button" onClick={handleOptionMoveDownButtonClick}>
                              <IconDown />
                            </button>
                          ) : <span />}
                        </li>
                        <li>
                          <button className="btn-icon" name={option} type="button" onClick={handleOptionEditButtonClick}>
                            <IconEdit />
                          </button>
                        </li>
                        <li>
                          <button className="btn-icon" name={option} type="button" onClick={handleOptionDeleteButtonClick}>
                            <IconTrash />
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
        <div className="form-group form-group--btn">
          <input
            className="form-control"
            type="text"
            id="options"
            value={optionToAdd}
            onChange={handleChangeOptionToAdd}
            onKeyDown={handleOptionToAddKeyDown}
            autoComplete="off"
          />
          <Button
            type="button"
            className="btn btn-primary"
            label="Add option"
            onClick={handleOptionToAddButtonClick}
          />
        </div>
        {errorOptions && <p className="form-error">{errorOptions}</p>}
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-clear',
              label: 'Cancel',
              onClick: handleCancelButtonClick,
              type: 'button',
            },
            {
              className: 'btn btn-primary',
              label: 'Create',
              type: 'submit',
            },
          ]}
        />
      </form>
    </div>
  </div>
)
