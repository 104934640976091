import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { FeedbackForm } from 'app/components/forms/FeedbackForm'
import { FormError } from 'app/components/FormError'

import { completeFeedback, FeedbackUpdateData } from 'review-app-shared/types/feedback'

import { getFeedback } from 'app/modules/feedback-form/feedback-form-selectors'
import { getLoadedReviews } from 'app/modules/review/review-selectors'
import { getLoadedUsers } from 'app/modules/user/user-selectors'
import { feedbackFormStartFetch, feedbackFormStartSend } from 'app/modules/feedback-form/feedback-form-actions'
import { FormStatus } from 'app/modules/feedback-form/feedback-form-reducer'
import { getRevieweeNames } from 'app/helpers/review'
import { homeUrl } from 'app/helpers/urlCreators'

interface RouteInfo {
  feedbackId: string
}

type FeedbackFormWrapperProps = RouteComponentProps<RouteInfo>

// The FeedbackFormWrapper component manages the status of a feedback form, loading
// and saving data when necessary, and renders the detailed form when there is
// sufficient information available.
export const FeedbackFormWrapper: FC<FeedbackFormWrapperProps> = ({ match: { params: { feedbackId } } }) => {
  const feedback = useSelector(getFeedback)
  const reviews = useSelector(getLoadedReviews)
  const users = useSelector(getLoadedUsers)
  const revieweeNames = getRevieweeNames(reviews, users)
  const dispatch = useDispatch()

  // trigger fetch of form content and any previously saved answers on initial
  // render or if switching to a different feedbackId
  useEffect((): void => {
    dispatch(feedbackFormStartFetch(feedbackId))
  }, [dispatch, feedbackId])

  const sendFeedback = (answers: FeedbackUpdateData): void => {
    dispatch(feedbackFormStartSend(feedbackId, answers))
  }

  switch (feedback.kind) {
    case FormStatus.Loading:
    case FormStatus.Saving:
      return <div className="main">Loading...</div>
    case FormStatus.Loaded:
    case FormStatus.Saved:
      return (completeFeedback.includes(feedback.data.state))
        ? (
          <Redirect to={homeUrl()} />
        )
        : (
          <FeedbackForm
            feedback={feedback.data}
            sendFeedback={sendFeedback}
            revieweeName={revieweeNames.get(feedback.data.reviewId) || 'unknown'}
          />
        )
    case FormStatus.Error:
      return <FormError errorMessage={feedback.errorMessage} />
    default:
      return <FormError errorMessage="Unexpected FormStatus" />
  }
}
