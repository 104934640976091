import React, { FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getCurrentUserEmail } from 'app/modules/auth/auth-selectors'
import { getIsLoadingReview, getIsErrorReview } from 'app/modules/review/review-selectors'
import { getIsLoadingUser, getIsErrorUser } from 'app/modules/user/user-selectors'
import { getIsLoadingQuestion, getIsErrorQuestion } from 'app/modules/question/question-selectors'

import { ErrorBoundary } from 'app/components/ErrorBoundary'
import { Header } from 'app/components/Header'
import { Login } from 'app/components/Login'
import { Home } from 'app/components/home/Home'
import { Reviewer } from 'app/components/reviewer/Reviewer'
import { AdvancedReviewScheduler } from 'app/components/manager/AdvancedReviewScheduler'
import { OwnReviews } from 'app/components/own/OwnReviews'
import { QuestionAdmin } from 'app/components/admin/QuestionAdmin'
import { UserAdmin } from 'app/components/admin/UserAdmin'
import { FeedbackFormWrapper } from 'app/components/forms/FeedbackFormWrapper'
import { Loading } from 'app/components/Loading'
import { FetchError } from 'app/components/FetchError'
import { ReviewSummary } from 'app/components/manager/ReviewSummary'
import { FeedbackReport } from 'app/components/reviewer/FeedbackReport'
import { CreateReviewFormWrapper } from 'app/components/forms/CreateReviewFormWrapper'
import { UpdateReviewFormWrapper } from 'app/components/forms/UpdateReviewFormWrapper'
import { QuestionFormWrapper } from 'app/components/forms/QuestionFormWrapper'
import { QuestionnaireFormWrapper } from 'app/components/forms/QuestionnaireFormWrapper'
import { ResponseOptionsFormWrapper } from 'app/components/forms/ResponseOptionsFormWrapper'
import { UserGroupFormWrapper } from 'app/components/forms/UserGroupFormWrapper'
import { UserProfile } from 'app/components/user/UserProfile'
import { Unknown } from 'app/components/Unknown'
import { CreateNewUser } from 'app/components/user/CreateNewUser'

import {
  FEEDBACK_FORM_GET,
  HOME,
  REVIEWER_MAIN,
  MANAGER_MAIN,
  OWN_REVIEWS_MAIN,
  QUESTION_ADMIN_MAIN,
  USER_ADMIN_MAIN,
  REVIEW_SUMMARY,
  FEEDBACK_REPORT,
  CREATE_REVIEW_FORM,
  UPDATE_REVIEW_FORM,
  QUESTION_FORM,
  QUESTIONNAIRE_FORM,
  RESPONSE_OPTIONS_FORM,
  USER_GROUP_FORM,
  USER_PROFILE,
  CREATE_NEW_USER, ADVANCE_REVIEW_SCHEDULING,
} from 'app/constants/routes'

// The RouteHandler component renders a common Header for all pages, then the Login
// component if no user is currently logged in or the appropriate parent component
// for other routes.
export const RouteHandler: FC = () => {
  const currentUserEmail = useSelector(getCurrentUserEmail)
  const isLoadingReview = useSelector(getIsLoadingReview)
  const isLoadingUser = useSelector(getIsLoadingUser)
  const isLoadingQuestion = useSelector(getIsLoadingQuestion)
  const isErrorReview = useSelector(getIsErrorReview)
  const isErrorUser = useSelector(getIsErrorUser)
  const isErrorQuestion = useSelector(getIsErrorQuestion)

  const isLoading = isLoadingReview || isLoadingUser || isLoadingQuestion
  const isError = isErrorReview || isErrorUser || isErrorQuestion

  return (
    <Router>
      <div className="app">
        <Route render={(props): JSX.Element => <Header {...props} currentUserEmail={currentUserEmail} />} />
        {currentUserEmail ? (
          <ErrorBoundary>
            {(isLoading) && <Loading />}
            {(isError) && <FetchError />}
            <Switch>
              <Route exact path={FEEDBACK_FORM_GET} component={FeedbackFormWrapper} />
              <Route exact path={CREATE_REVIEW_FORM} component={CreateReviewFormWrapper} />
              <Route exact path={UPDATE_REVIEW_FORM} component={UpdateReviewFormWrapper} />
              <Route exact path={QUESTIONNAIRE_FORM} component={QuestionnaireFormWrapper} />
              <Route exact path={QUESTION_FORM} component={QuestionFormWrapper} />
              <Route exact path={RESPONSE_OPTIONS_FORM} component={ResponseOptionsFormWrapper} />
              <Route exact path={USER_GROUP_FORM} component={UserGroupFormWrapper} />
              <Route exact path={REVIEW_SUMMARY} component={ReviewSummary} />
              <Route exact path={FEEDBACK_REPORT} component={FeedbackReport} />
              <Route exact path={HOME} component={Home} />
              <Route exact path={REVIEWER_MAIN} component={Reviewer} />
              <Route exact path={MANAGER_MAIN} component={AdvancedReviewScheduler} />
              <Route exact path={OWN_REVIEWS_MAIN} component={OwnReviews} />
              <Route exact path={QUESTION_ADMIN_MAIN} component={QuestionAdmin} />
              <Route exact path={USER_ADMIN_MAIN} component={UserAdmin} />
              <Route exact path={USER_PROFILE} component={UserProfile} />
              <Route exact path={CREATE_NEW_USER} component={CreateNewUser} />
              <Route exact path={ADVANCE_REVIEW_SCHEDULING} component={AdvancedReviewScheduler} />
              <Route component={Unknown} />
            </Switch>
          </ErrorBoundary>
        )
          : <Route path="/*" component={Login} />}
        <ToastContainer />
      </div>
    </Router>
  )
}
