import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { ReviewPopulated } from 'review-app-shared/types/selectors'
import { formatDate } from 'app/helpers/review'
import { reviewSummaryUrl, updateReviewFormUrl } from 'app/helpers/urlCreators'

import { FeedbackStatusForReview } from 'app/components/tables/FeedbackStatusForReview'
import { UserProfileLink } from 'app/components/user/UserProfileLink'

interface UpcomingReviewsTableRowProps {
  review: ReviewPopulated
}

// This component renders a data row for tables listing reviews from a manager's
// perspective including user name, date, questionnaire and feedback status
export const UpcomingReviewsTableRow: FC<UpcomingReviewsTableRowProps> = ({
  review: {
    id,
    timeReviewed,
    deadline,
    userReviewed: { username },
    questionnaire: { name },
    linkedFeedback,
    userReviewedId,
  },
}) => (
  <tr key={id}>
    <td data-title="Review of"><UserProfileLink userId={userReviewedId} userName={username} /></td>
    <td data-title="Review date">{formatDate(timeReviewed)}</td>
    <td data-title="Feedback deadline date">{formatDate(deadline)}</td>
    <td data-title="Question set">{name}</td>
    <td data-title="Feedback status"><FeedbackStatusForReview feedback={linkedFeedback} /></td>
    <td className="table-actions">
      <Link className="mr-10" to={updateReviewFormUrl(id)}>Edit</Link>
      <Link to={reviewSummaryUrl(id)}>View</Link>
    </td>
  </tr>
)
