import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
  take,
  race,
  RaceEffect,
  TakeEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import { ForecastApiResponse } from 'review-app-shared/types/forecast'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { apiGetForecasts, apiUpdateUsersFromForecasts } from './forecast-effects'
import {
  forecastFinishGet,
  forecastErrorGet,
  ForecastErrorGet,
  ForecastFinishGet,
  ForecastStartUpdateUsersActionType,
  forecastFinishUpdateUsers,
  ForecastFinishUpdateUsers,
} from './forecast-actions'
import { AuthFinishLoginActionType } from '../auth/auth-actions'
import {
  userStartGetUsers, UserStartGetUsers, UserFinishGetUsersActionType, UserErrorGetUsersActionType,
} from '../user/user-actions'

type GetAllUserForecastEffects = SelectEffect | CallEffect<AxiosResponse<ForecastApiResponse>> |
PutEffect<ForecastFinishGet | ForecastErrorGet> | {[id: number]: string}

type GetAllUserForecastNextType = {[id: number]: string | undefined} | string | AxiosResponse<ForecastApiResponse>

function isResponse(val: GetAllUserForecastNextType): val is AxiosResponse<ForecastApiResponse> {
  return Object.keys(val).includes('data')
}

function isString(val: GetAllUserForecastNextType): val is string {
  return typeof val === 'string'
}

function* getAllUserForecasts(): Generator<GetAllUserForecastEffects, void, GetAllUserForecastNextType> {
  try {
    const token = yield select(getCurrentUserToken)
    if (!isString(token)) return

    const res = yield call(apiGetForecasts, token)
    if (isResponse(res)) {
      yield put(forecastFinishGet(res.data))
    }
  } catch (error) {
    console.error(error)
    yield put(forecastErrorGet(error))
  }
}

type GetNewUsersFromForecastEffects = SelectEffect | CallEffect<AxiosResponse<{ newUserCount: number}>> |
PutEffect<ForecastFinishUpdateUsers | UserStartGetUsers> | RaceEffect<TakeEffect>

type GetNewUsersFromForecastNextType = string | { newUserCount: number } | AxiosResponse<{ newUserCount: number}>

function* updateUsersFromForecast(): Generator<GetNewUsersFromForecastEffects, unknown, GetNewUsersFromForecastNextType> {
  try {
    const token = yield select(getCurrentUserToken)
    if (!isString(token)) return

    const response = yield call(apiUpdateUsersFromForecasts, token)
    if (isResponse(response) && response.data.newUserCount > 0) {
      yield put(userStartGetUsers())
      yield race({
        done: take(UserFinishGetUsersActionType),
        error: take(UserErrorGetUsersActionType),
      })
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(forecastFinishUpdateUsers())
  }
}

export function* forecastSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(AuthFinishLoginActionType, getAllUserForecasts)
  yield takeEvery(ForecastStartUpdateUsersActionType, updateUsersFromForecast)
}
