import { reviewStartGetFeedbackById } from 'app/modules/review/review-actions'
import {
  getAdminUserReviews,
  getFeedbackById,
  getKnownFeedbackIds,
  getLoadedFeedback,
  getLoadedReviews,
  getManagedUserReviews,
} from 'app/modules/review/review-selectors'
import { userFormCreateStartSend, userFormUpdateStartSend, userFormStartDelete } from 'app/modules/user-form/user-form-actions'
import {
  getCurrentUser,
  getLoadedUsersPopulated,
  getUsers,
} from 'app/modules/user/user-selectors'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import isEqual from 'lodash.isequal'
import { useDistinctValue } from '@spicy-hooks/core'
import { useDispatch, useSelector } from 'react-redux'
import { FeedbackWithQuestionsAndAnswers, FeedbackState } from 'review-app-shared/types/feedback'
import {
  FetchResult,
  LoadStatus,
  LoadedData,
} from 'review-app-shared/types/fetchData'
import { SelectOption } from 'review-app-shared/types/selectOption'
import {
  ReviewPopulated,
  UserWithDetailsPopulated,
} from 'review-app-shared/types/selectors'
import { UserToCreateOrUpdate, UserWithDetails } from 'review-app-shared/types/user'
import { UserGroup } from 'review-app-shared/types/user-group'
import { ReviewSchedulerTableRowProps } from 'app/components/tables/ReviewSchedulerTableRow'
import { findReviewsPreviousNext } from 'app/helpers/review'
import { SortParameters } from 'app/components/tables/SortableTableHeader'
import { emptyUser } from './const'

export const useLoadedUsers = (): UserWithDetails[] => {
  const usersFetch = useSelector(getUsers)
  const users = (usersFetch.kind === LoadStatus.Loaded) ? usersFetch.data : []

  return users
}

export const useUserById = (userId: number): UserWithDetailsPopulated | undefined => {
  const users = useSelector(getLoadedUsersPopulated)
  const currentUser = useSelector(getCurrentUser)
  const isAdmin = useMemo(() => currentUser?.isAdmin, [currentUser])
  const managedUserIds = useMemo(() => {
    if (!currentUser) {
      return []
    }
    const { managedUsers } = currentUser

    return managedUsers.map((user: UserWithDetails) => user.id)
  }, [currentUser])

  const getUser = useCallback((uid: number) => {
    if (!managedUserIds.length && !isAdmin) {
      return undefined
    }

    const user = users.find(
      ({ id }) => id === uid && (isAdmin || managedUserIds.includes(id)),
    )

    return user
  }, [isAdmin, managedUserIds, users])

  return useMemo(() => getUser(userId), [getUser, userId])
}

export const useUserByIdOrEmpty = (userId: number): UserWithDetailsPopulated => useUserById(userId) || emptyUser

export const useReviewsByUserId = (userId: number): ReviewPopulated[] => {
  const currentUser = useSelector(getCurrentUser)

  const managedUserReviews = useSelector(getManagedUserReviews)
  const adminUserReviews = useSelector(getAdminUserReviews)

  const [reviews, setReviews] = useState<ReviewPopulated[]>([])
  useEffect(() => {
    if (!currentUser) {
      setReviews([])
      return
    }

    const { isAdmin } = currentUser

    const userReviews = isAdmin ? adminUserReviews : managedUserReviews
    const newReviews = userReviews.filter(
      (review) => review.userReviewedId === userId,
    )

    newReviews.sort(
      ({ timeReviewed: A }, { timeReviewed: B }) => B.getTime() - A.getTime(),
    )
    setReviews(newReviews)
  }, [adminUserReviews, currentUser, managedUserReviews, userId])

  return reviews
}

export const useFeedbackById = (feedbackId: number): FeedbackWithQuestionsAndAnswers[] => {
  const feedbackByIdFetch = useSelector(getFeedbackById)

  const [matchingFeedbackById, setMatchingFeedbackById] = useState<
  FeedbackWithQuestionsAndAnswers[]
  >([])
  useEffect(() => {
    const matchingFeedback = Object.values(feedbackByIdFetch)
      .filter(
        (
          feedbackFetch: FetchResult<FeedbackWithQuestionsAndAnswers>,
        ): feedbackFetch is LoadedData<FeedbackWithQuestionsAndAnswers> => feedbackFetch.kind === LoadStatus.Loaded
          && feedbackFetch.data.reviewId === feedbackId,
      )
      .map((feedbackFetch) => feedbackFetch.data)
      .filter(({ state }) => state === FeedbackState.Submitted)

    setMatchingFeedbackById(matchingFeedback)
  }, [feedbackByIdFetch, feedbackId])

  return matchingFeedbackById
}

// fetch detailed feedbackById if not already present with useEffect hook
export const useFetchFeedback = (review: ReviewPopulated): void => {
  const knownFeedbackIds = useSelector(getKnownFeedbackIds)
  const dispatch = useDispatch()

  useEffect(() => {
    if (review) {
      review.linkedFeedback.forEach(({ id }) => {
        if (!knownFeedbackIds.includes(id)) {
          dispatch(reviewStartGetFeedbackById(id))
        }
      })
    }
  }, [dispatch, knownFeedbackIds, review])
}

export const useUserHandling = (id: number): [() => void, (userData: UserToCreateOrUpdate) => void, (userData: UserToCreateOrUpdate) => void] => {
  const dispatch = useDispatch()

  const createNewUser = (userData: UserToCreateOrUpdate): void => {
    dispatch(userFormCreateStartSend(userData))
  }

  const updateCurrentUser = (userData: UserToCreateOrUpdate): void => {
    dispatch(userFormUpdateStartSend(id, userData))
  }

  const deleteCurrentUser = (): void => {
    dispatch(userFormStartDelete(id))
  }

  return [deleteCurrentUser, createNewUser, updateCurrentUser]
}

export const useTransformedStateForUserGroup = (selectedUser: UserWithDetailsPopulated, userGroups: UserGroup[]): SelectOption[] => {
  const values = useMemo(() => selectedUser.userGroups, [selectedUser.userGroups])
  const users = useMemo<SelectOption[]>(() => userGroups.map((userGroup) => ({ value: userGroup.id, label: userGroup.name })), [userGroups])

  return useMemo(() => values.map(({ id }) => users.find(({ value }) => value === id)).filter(Boolean) as SelectOption[], [users, values])
}


export const useTransformedStateForManagers = (selectedUser: UserWithDetailsPopulated, usersToAddForSelect: UserWithDetailsPopulated[], property: 'managers' | 'managedUsers'): SelectOption[] => {
  const values = useMemo(() => selectedUser[property], [property, selectedUser])
  const users = useMemo<SelectOption[]>(() => usersToAddForSelect.map((user) => ({ value: user.id, label: user.username })), [usersToAddForSelect])

  return useMemo(() => values.map(({ id }) => users.find(({ value }) => value === id)).filter(Boolean) as SelectOption[], [users, values])
}

export const useUserIdsInUse = (): Set<number> => {
  const reviews = useSelector(getLoadedReviews)
  const allFeedback = useSelector(getLoadedFeedback)
  const users = useSelector(getLoadedUsersPopulated)

  const userIdsInUse = useMemo(() => {
    const ids = new Set<number>()

    // if they appear in Review/userReviewedId, Feedback/reviewerUserId,
    // User_Manager/userId or managerUserId (but not User_Group)
    reviews.forEach((review) => ids.add(review.userReviewedId))
    allFeedback.forEach((feedback) => ids.add(feedback.reviewerUserId))
    users.forEach((user: UserWithDetailsPopulated) => user.managers.forEach(({ id }) => ids.add(id)))
    users.forEach((user: UserWithDetailsPopulated) => user.managedUsers.forEach(({ id }) => ids.add(id)))

    return ids
  }, [allFeedback, reviews, users])

  return userIdsInUse
}

export const useManagedUsers = (
  filteredUsers: UserWithDetails[],
  userReviews: ReviewPopulated[],
  sort: SortParameters,
): ReviewSchedulerTableRowProps[] => {
  const distinctFilteredUsers = useDistinctValue(filteredUsers, isEqual)
  const distinctUserReviews = useDistinctValue(userReviews, isEqual)
  return useMemo(() => {
    const managedUsers = distinctFilteredUsers.map((user: UserWithDetails) => {
      const { id } = user
      const reviews = distinctUserReviews.filter((review: ReviewPopulated) => review.userReviewedId === id)
      const { previous, next } = findReviewsPreviousNext(reviews)
      return {
        userId: user.id,
        username: user.username,
        active: user.active,
        employmentStartYear: user.employmentStartYear,
        annualReviewMonth: user.annualReviewMonth,
        previousReviewId: previous?.id,
        previousReviewTimeReviewed: previous?.timeReviewed,
        nextReviewId: next?.id,
        nextReviewTimeReviewed: next?.timeReviewed,
      }
    })
    const { id, direction } = sort
    const defaultValue = id === 'previousReviewTimeReviewed' || id === 'nextReviewTimeReviewed' ? new Date(0) : ''
    const sortMangedUsers = (a: ReviewSchedulerTableRowProps, b: ReviewSchedulerTableRowProps): number => {
      const aValue = a[id] || defaultValue
      const bValue = b[id] || defaultValue
      return direction
        * (aValue instanceof Date && bValue instanceof Date ? aValue.getTime() - bValue.getTime()
          : (typeof aValue === 'string' && typeof bValue === 'string' ? aValue.localeCompare(bValue)
            : (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0)))
    }
    return managedUsers.sort(sortMangedUsers)
  }, [distinctFilteredUsers, distinctUserReviews, sort])
}
