import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import {
  QuestionnaireToCreate,
  QuestionnaireUpdateData,
  QuestionnaireWithQuestionIds,
} from 'review-app-shared/types/questionnaire'

export const questionnaireFormCreateStartSend = createAction('questionnaire-form/CREATE_START_SEND',
  (actionCallback) => (questionnaireToCreate: QuestionnaireToCreate) => actionCallback(questionnaireToCreate))

export const questionnaireFormCreateFinishSend = createAction('questionnaire-form/CREATE_FINISH_SEND',
  (actionCallback) => (questionnaire: QuestionnaireWithQuestionIds) => actionCallback(questionnaire))

export const questionnaireFormUpdateStartSend = createAction('questionnaire-form/UPDATE_START_SEND',
  (actionCallback) => (
    questionnaireId: number,
    questionnaireUpdateData: QuestionnaireUpdateData,
  ) => actionCallback({ questionnaireId, questionnaireUpdateData }))

export const questionnaireFormUpdateFinishSend = createAction('questionnaire-form/UPDATE_FINISH_SEND',
  (actionCallback) => (questionnaire: QuestionnaireWithQuestionIds) => actionCallback(questionnaire))

export const questionnaireFormStartDelete = createAction('questionnaire-form/START_DELETE',
  (actionCallback) => (questionnaireId: number) => actionCallback(questionnaireId))

export const questionnaireFormFinishDelete = createAction('questionnaire-form/FINISH_DELETE',
  (actionCallback) => (questionnaireId: number) => actionCallback(questionnaireId))

export const questionnaireFormError = createAction('questionnaire-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type QuestionnaireFormActions = ActionType<typeof questionnaireFormCreateStartSend |
  typeof questionnaireFormCreateFinishSend | typeof questionnaireFormUpdateStartSend |
  typeof questionnaireFormUpdateFinishSend | typeof questionnaireFormStartDelete |
  typeof questionnaireFormFinishDelete | typeof questionnaireFormError>

export type QuestionnaireFormCreateStartSend = ActionType<typeof questionnaireFormCreateStartSend>
export type QuestionnaireFormCreateFinishSend = ActionType<typeof questionnaireFormCreateFinishSend>
export type QuestionnaireFormUpdateStartSend = ActionType<typeof questionnaireFormUpdateStartSend>
export type QuestionnaireFormUpdateFinishSend = ActionType<typeof questionnaireFormUpdateFinishSend>
export type QuestionnaireFormStartDelete = ActionType<typeof questionnaireFormStartDelete>
export type QuestionnaireFormFinishDelete = ActionType<typeof questionnaireFormFinishDelete>
export type QuestionnaireFormError = ActionType<typeof questionnaireFormError>

export const QuestionnaireFormCreateStartSendActionType = getType(questionnaireFormCreateStartSend)
export const QuestionnaireFormCreateFinishSendActionType = getType(questionnaireFormCreateFinishSend)
export const QuestionnaireFormUpdateStartSendActionType = getType(questionnaireFormUpdateStartSend)
export const QuestionnaireFormUpdateFinishSendActionType = getType(questionnaireFormUpdateFinishSend)
export const QuestionnaireFormStartDeleteActionType = getType(questionnaireFormStartDelete)
export const QuestionnaireFormFinishDeleteActionType = getType(questionnaireFormFinishDelete)
export const QuestionnaireFormErrorActionType = getType(questionnaireFormError)
