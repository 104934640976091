import { api, ApiResponse } from 'app/api/api-client'
import { FeedbackWithQuestionsAndAnswers, FeedbackUpdateData } from 'review-app-shared/types/feedback'

export const apiGetFeedbackForm = async (
  feedbackId: string,
  token: string,
): ApiResponse<FeedbackWithQuestionsAndAnswers> => api.get(`/feedback/${feedbackId}`, {
  headers: { Authorization: token },
})

export const apiPutFeedbackForm = async (
  feedbackId: string,
  feedbackData: FeedbackUpdateData,
  token: string,
): ApiResponse<FeedbackWithQuestionsAndAnswers> => api.put(`/feedback/${feedbackId}`, feedbackData, {
  headers: { Authorization: token },
})
