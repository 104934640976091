import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { formatDate, formatDateTime } from 'app/helpers/review'
import { reviewSummaryUrl, createReviewFormUrl, updateReviewFormUrl } from 'app/helpers/urlCreators'
import { UserProfileLink } from 'app/components/user/UserProfileLink'
import { Months } from 'app/constants/dates'

export interface ReviewSchedulerTableRowProps {
  userId: number
  username: string
  active: boolean
  annualReviewMonth?: number
  previousReviewId?: number
  previousReviewTimeReviewed?: Date
  nextReviewId?: number
  nextReviewTimeReviewed?: Date
}
export interface PreviousReviewCellProps {
  previousReviewId?: number
  previousReviewTimeReviewed?: Date
}

const PreviousReviewCell: FC<PreviousReviewCellProps> = ({ previousReviewId, previousReviewTimeReviewed }) => (
  <td data-title="Last review">
    {(previousReviewId && previousReviewTimeReviewed)
      ? <Link to={reviewSummaryUrl(previousReviewId)}>{formatDate(previousReviewTimeReviewed)}</Link>
      : 'no reviews yet'}
  </td>
)

// This component renders a data row for tables listing users and the
// date/status for their previous and next reviews
export const ReviewSchedulerTableRow: FC<ReviewSchedulerTableRowProps> = ({
  userId,
  username,
  active,
  annualReviewMonth,
  previousReviewId,
  previousReviewTimeReviewed,
  nextReviewId,
  nextReviewTimeReviewed,
}) => {
  if (!active) {
    return (
      <tr>
        <td data-title="Name" className="inactive-user">{`${username} (inactive)`}</td>
        <td data-title="Anniversary" />
        <PreviousReviewCell previousReviewId={previousReviewId} previousReviewTimeReviewed={previousReviewTimeReviewed} />
        <td data-title="Next review" />
      </tr>
    )
  }

  return (
    <tr>
      <td data-title="Name">
        <UserProfileLink userId={userId} userName={username} />
      </td>
      <td data-title="Anniversary">
        {annualReviewMonth ? `${Months[annualReviewMonth]}` : '-'}
      </td>
      <PreviousReviewCell previousReviewId={previousReviewId} previousReviewTimeReviewed={previousReviewTimeReviewed} />
      <td data-title="Next review">
        {(nextReviewId && nextReviewTimeReviewed)
          ? <Link to={updateReviewFormUrl(nextReviewId)}>{formatDateTime(nextReviewTimeReviewed)}</Link>
          : (
            <span className="not-scheduled">
              <Link to={createReviewFormUrl(userId)}>Schedule now</Link>
            </span>
          )}
      </td>
    </tr>
  )
}
