import React, { FC } from 'react'
import ReactDOM from 'react-dom'

import { ReactComponent as Close } from 'assets/svg/icon-delete2.svg'

export interface ModalContentProps {
  title: string
  primaryButton: string
  secondaryButton?: string
}

interface ModalProps extends ModalContentProps {
  onConfirm: () => void
  onCancel?: () => void
}


const ModalComponent: FC<Omit<ModalProps, 'isShowing'>> = ({
  children,
  onConfirm,
  onCancel,
  primaryButton,
  secondaryButton,
  title,
}) => (
  <div className="modal" data-cy="modal-dialog">
    <div className="modal-dialog" role="dialog">
      <div className="modal-content">
        {onCancel
          && (
          <button type="button" className="modal-close btn-icon" onClick={onCancel} data-cy="close-modal-btn">
            <Close />
          </button>
          )}
        <h5 className="modal-title" data-cy="modal-title">
          {title}
        </h5>
        {children}
        <div className="btn-group btn-group--modal mt-20">
          {onCancel && secondaryButton && <button className="btn btn-clear mb-0" type="button" onClick={onCancel} data-cy="cancel-modal-btn">{secondaryButton}</button>}
          {onConfirm && primaryButton && <button className="btn btn-primary mb-0" type="button" onClick={onConfirm} data-cy="confirm-modal-btn">{primaryButton}</button>}
        </div>
      </div>
    </div>
  </div>
)

export const Modal: FC<ModalProps & {isShowing: boolean}> = ({ isShowing, ...rest }) => (isShowing ? ReactDOM.createPortal(<ModalComponent {...rest} />, document.body) : null)
