import React, {
  FC,
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { FeedbackUpdateData, FeedbackWithQuestionsAndAnswers, FeedbackState } from 'review-app-shared/types/feedback'
import { AnswerUpdateData } from 'review-app-shared/types/answer'
import { CommentMissingError, FeedbackFormError, ResponseOptionMissingError } from 'app/errors/feedback-form-errors'
import { FeedbackFormQuestion } from 'app/components/forms/FeedbackFormQuestion'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'
import { ModalState, useModal } from 'app/hooks/useModal'

interface FeedbackFormProps {
  feedback: FeedbackWithQuestionsAndAnswers
  revieweeName: string
  sendFeedback: (answers: FeedbackUpdateData) => void
}

// The FeedbackForm component renders a feedback form when the set of questions
// (and if appropriate answers) is available.
export const FeedbackForm: FC<FeedbackFormProps> = ({
  feedback: {
    id,
    questionnaire: { description },
    questions,
    answers,
  },
  revieweeName,
  sendFeedback,
}) => {
  const [formAnswers, setFormAnswers] = useState<Array<AnswerUpdateData>>([])
  const [formError, setFormError] = useState<FeedbackFormError | null>(null)

  // reload answers when first mounted, or after any change to the feedback id
  // or the answers provided in the feedback prop (after loading or saving)
  useEffect(() => {
    const newFormAnswers: Array<AnswerUpdateData> = []
    questions.forEach((question) => {
      const { id: questionId } = question
      const matchingAnswer = answers
        .find((answer) => answer.questionId === questionId)
      if (matchingAnswer) {
        newFormAnswers.push({
          chosenAnswer: matchingAnswer.chosenAnswer,
          textComment: matchingAnswer.textComment,
        })
      } else {
        newFormAnswers.push({
          chosenAnswer: null,
          textComment: null,
        })
      }
    })
    setFormAnswers(newFormAnswers)
  }, [id, questions, answers])

  const changeAnswer = useCallback((index: number, chosenAnswer: number): void => {
    setFormAnswers([...formAnswers.slice(0, index), {
      ...formAnswers[index],
      chosenAnswer,
    }, ...formAnswers.slice(index + 1)])
  }, [formAnswers])

  const changeComment = useCallback((index: number, textComment: string): void => {
    setFormAnswers([...formAnswers.slice(0, index), {
      ...formAnswers[index],
      textComment,
    }, ...formAnswers.slice(index + 1)])
  }, [formAnswers])

  const setErrorState = (feedbackFormError: FeedbackFormError | null): void => setFormError(feedbackFormError)

  const getValidationError = (): FeedbackFormError | null => questions
    .reduce((error: FeedbackFormError | null, question, i) => {
      if (error) return error
      const formAnswer = formAnswers[i]
      const commentProvided = formAnswer.textComment && formAnswer.textComment.length > 0

      if (question.optionsId !== null && formAnswer.chosenAnswer === null) return new ResponseOptionMissingError()

      if (question.freeTextRequired && !commentProvided) return new CommentMissingError()

      if (question.freeTextCommentDisplayed && !commentProvided) {
        const commentRequiredArray = question.freeTextCommentRequiredOnResponses
        if (question.optionsId !== null && formAnswer.chosenAnswer && commentRequiredArray && commentRequiredArray[formAnswer.chosenAnswer]) {
          return new CommentMissingError()
        }
      }
      return null
    }, null)

  const {
    Modal: SendModal, showModal: showSendModal, hideModal: hideSendModal, modalState: sendModalState,
  } = useModal()
  useEffect(() => {
    switch (sendModalState) {
      case ModalState.Confirmed:
        hideSendModal()
        // send data
        sendFeedback(new FeedbackUpdateData(formAnswers, FeedbackState.Submitted))
        break
      case ModalState.Canceled:
        hideSendModal()
        break
      default:
        break
    }
  }, [sendModalState, formAnswers, hideSendModal, sendFeedback])

  const {
    Modal: DismissModal, showModal: showDismissModal, hideModal: hideDismissModal, modalState: dismissModalState,
  } = useModal()
  useEffect(() => {
    switch (dismissModalState) {
      case ModalState.Confirmed:
        hideDismissModal()
        // dismiss data
        sendFeedback(new FeedbackUpdateData([], FeedbackState.Dismissed))
        break
      case ModalState.Canceled:
        hideDismissModal()
        break
      default:
        break
    }
  }, [dismissModalState, formAnswers, hideDismissModal, sendFeedback])

  const onSave = (event: MouseEvent | TouchEvent): void => {
    event.preventDefault()
    sendFeedback(new FeedbackUpdateData(formAnswers, FeedbackState.Started))
  }

  const onSubmit = (event: MouseEvent | TouchEvent): void => {
    event.preventDefault()
    const foundError = getValidationError()
    setErrorState(foundError)
    if (!foundError) {
      showSendModal()
    }
  }

  if (questions.length !== formAnswers.length) return null

  return (
    <div className="content has-buttons">
      <Hero
        title={`Review comments for ${revieweeName}`}
        tooltip={description}
      />
      <div className="main">
        <form>
          {questions.map((question, questionIndex) => (
            <FeedbackFormQuestion
              key={question.id}
              question={question}
              questionIndex={questionIndex}
              formAnswer={formAnswers[questionIndex]}
              changeAnswer={changeAnswer}
              changeComment={changeComment}
              formError={formError !== null || false}
            />
          ))}
        </form>
        <ButtonGroup
          className="mt-20"
          buttons={[
            {
              className: 'btn btn-secondary',
              label: 'Dismiss feedback',
              onClick: showDismissModal,
              type: 'button',
            },
            {
              className: 'btn btn-secondary',
              label: 'Save (to continue later)',
              onClick: onSave,
              type: 'button',
            },
            {
              className: 'btn btn-primary',
              label: 'Submit final feedback',
              onClick: onSubmit,
              type: 'submit',
            },
          ]}
        />

        <SendModal
          title="Send a final review"
          primaryButton="Yes"
          secondaryButton="No"
        >
          <p>
            {'You are going to '}
            <strong>send</strong>
            {' a final review for '}
            <em>{revieweeName}</em>
              , are you sure?
          </p>
        </SendModal>
        <DismissModal
          title="Dismiss a review"
          primaryButton="Yes"
          secondaryButton="No"
        >
          <p>
            {'You are going to '}
            <strong>dismiss</strong>
            {' a review for '}
            <em>{revieweeName}</em>
        , are you sure?
          </p>
        </DismissModal>

        {formError && (
          <div className="alert alert-danger">
            <h4 className="alert-heading">Form validation failed</h4>
            {formError.message}
          </div>
        )}
      </div>
    </div>
  )
}
