import React, { FC } from 'react'

// This component renders the header row for tables listing reviews from a manager's
// perspective with the user name, review date, question set and current status.
export const UpcomingReviewsTableHeader: FC = () => (
  <tr>
    <th style={{ width: '21%' }}>Review of</th>
    <th style={{ width: '12%' }}>Review date</th>
    <th style={{ width: '12%' }}>Feedback deadline</th>
    <th style={{ width: '20%' }}>Question set</th>
    <th style={{ width: '20%' }}>Feedback status</th>
    {/* eslint-disable-next-line  jsx-a11y/control-has-associated-label */}
    <th style={{ width: '15%' }} />
  </tr>
)
