// Helper functions to support question management

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

export interface QuestionWithDetails {
  questionId: number
  question: Question
  responseOptions?: ResponseOptions
}

// return the question and response options that correspond to a question id, or null if not found
export const getQuestionWithDetails = (
  questionId: number,
  questions: Question[],
  responseOptions: ResponseOptions[],
): QuestionWithDetails | null => {
  const matchingQuestion = questions.find((question) => question.id === questionId)
  const matchingResponseOptions = matchingQuestion && responseOptions
    .find((options) => options.id === matchingQuestion.optionsId)
  if (!matchingQuestion) return null
  return ({
    questionId,
    question: matchingQuestion,
    responseOptions: matchingResponseOptions,
  })
}
