import React, { FC } from 'react'

// This component renders the header row for tables listing feedback requests
// with dates, current status and a link to the feedback form.
export const TableHeaderFeedbackForReviewers: FC = () => (
  <tr>
    <th style={{ width: '20%' }}>Review of</th>
    <th style={{ width: '15%%' }}>Feedback deadline</th>
    <th style={{ width: '15% ' }}>Review date</th>
    <th style={{ width: '25%' }}>Feedback status</th>
    <th style={{ width: '25%' }} aria-label="link" />
  </tr>
)
