import React, { FC } from 'react'
import Select, { ValueType } from 'react-select'

import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface ResponseOptionsFormViewProps {
  handleCreateBasedOnCurrentButtonClick: () => void
  handleCreateButtonClick: () => void
  handleDeleteButtonClick: () => void
  handleSelectResponseOptions: (selected: SelectOption) => void
  responseOptions: ResponseOptions[]
  responseOptionsForSelect: SelectOption[]
  responseOptionsIdsInUse: Set<number>
  selectedResponseOptions: SelectOption | null
}

// The ResponseOptionsFormView component renders the selection and viewing aspects of
// the response options form
export const ResponseOptionsFormView: FC<ResponseOptionsFormViewProps> = ({
  handleCreateBasedOnCurrentButtonClick,
  handleCreateButtonClick,
  handleDeleteButtonClick,
  handleSelectResponseOptions,
  responseOptions,
  responseOptionsForSelect,
  responseOptionsIdsInUse,
  selectedResponseOptions,
}) => {
  const selectedResponseOptionsDetail = selectedResponseOptions && responseOptions
    .find((option) => option.id === selectedResponseOptions.value)
  if (selectedResponseOptions && !selectedResponseOptionsDetail) throw new Error('Selected Response Options do not exist.')

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title="Response options"
        tooltip="Select a set of response options from the list to see them, or create a new set."
      />
      <div className="main">
        <Select
          classNamePrefix="select"
          className="select-box"
          onChange={(selected: ValueType<SelectOption>): void => handleSelectResponseOptions((selected as SelectOption))}
          value={selectedResponseOptions}
          options={responseOptionsForSelect}
          placeholder="Select from current sets of response options"
          isClearable
        />
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-danger',
              label: 'Delete current selection',
              onClick: handleDeleteButtonClick,
              type: 'button',
              hidden: !(selectedResponseOptions && !responseOptionsIdsInUse.has(selectedResponseOptions.value)),
            },
            {
              className: 'btn btn-primary',
              label: 'Create new based on current selection',
              onClick: handleCreateBasedOnCurrentButtonClick,
              type: 'button',
              hidden: !selectedResponseOptions,
            },
            {
              className: 'btn btn-primary',
              label: 'Create new',
              onClick: handleCreateButtonClick,
              type: 'button',
            },
          ]}
        />
        {selectedResponseOptionsDetail && (
          <ul className="mt-20">
            {selectedResponseOptionsDetail.options.map((option) => (
              <li data-cy={`selected-response-option${option.value}`} key={option.value}>{option.name}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
