import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'

import { QuestionWithResponseOptions } from 'review-app-shared/types/question'
import { AnswerUpdateData } from 'review-app-shared/types/answer'
import { Accordion } from 'app/components/components-ui/accordion/accordion'
import { MarkdownEditor } from 'app/components/editor/Editor'

interface FeedbackFormQuestionProps {
  question: QuestionWithResponseOptions
  questionIndex: number
  formAnswer: AnswerUpdateData
  formError: boolean
  changeAnswer: (index: number, chosenAnswer: number) => void
  changeComment: (index: number, textComment: string) => void
}

// The FeedbackFormQuestion component renders an individual question with
// response fields in a feedback form.
export const FeedbackFormQuestion: FC<FeedbackFormQuestionProps> = ({
  question: {
    category,
    freeTextCommentDisplayed,
    freeTextCommentRequiredOnResponses,
    description,
    responseOptions,
  },
  questionIndex,
  formAnswer: {
    chosenAnswer,
    textComment,
  },
  formError,
  changeAnswer,
  changeComment,
}) => (
  <Accordion
    hasError={formError && chosenAnswer === null}
    title={category}
    counter
    key={questionIndex}
  >
    {description && (
      <Markdown options={{ forceBlock: true }}>
        {description}
      </Markdown>
    )}
    {(responseOptions || freeTextCommentDisplayed) && (
      <div className="mt-20">
        {responseOptions?.options.map(({ name, value }, optionsIndex) => (
          <div key={value}>
            <label htmlFor={`input-radio-${questionIndex}-${value}`}>
              <input
                type="radio"
                name={`input-radio-${questionIndex}`}
                id={`input-radio-${questionIndex}-${value}`}
                value={value}
                checked={chosenAnswer === value}
                onChange={(): void => changeAnswer(questionIndex, optionsIndex)}
                required
              />
              {name}
            </label>
          </div>
        ))}
        {freeTextCommentDisplayed
          && (
            <MarkdownEditor
              required={(freeTextCommentRequiredOnResponses) ? freeTextCommentRequiredOnResponses[Number(chosenAnswer)] : false}
              initialValue={textComment || ''}
              updateValue={(value): void => changeComment(questionIndex, value)}
            />
          )}
      </div>
    )}
  </Accordion>
)
