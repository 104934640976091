import React, {
  FC, FormEvent, useCallback, useMemo, useState,
} from 'react'
import { useProperty } from '@spicy-hooks/core'
import { useSelector } from 'react-redux'

import { getLoadedUserGroups, getLoadedUsersPopulated } from 'app/modules/user/user-selectors'
import { SelectOption } from 'review-app-shared/types/selectOption'
import { UserToCreateOrUpdate } from 'review-app-shared/types/user'
import {
  FormCheckboxField, FormInputField, FormSelectField, FormSingleSelectField,
} from 'app/components/forms/FormField'
import { ButtonGroup } from 'app/components/ButtonGroup'
import { Months } from 'app/constants/dates'
import { userManagementUrl } from 'app/helpers/urlCreators'

import {
  emailErrors, FieldErrorTypes, userErrors, emptyUser,
} from './const'
import {
  useTransformedStateForManagers, useTransformedStateForUserGroup, useUserByIdOrEmpty, useUserHandling, useUserIdsInUse,
} from './hooks'
import { useUserValidator } from './validators'

interface UserFormProps {
  userId?: number
  switchView?: () => void
  isNew?: boolean
}

interface ErrorState {
  errorUserName: FieldErrorTypes
  errorEmail: FieldErrorTypes
}

const initState: ErrorState = {
  errorUserName: FieldErrorTypes.None,
  errorEmail: FieldErrorTypes.None,
}

const minYear = 2000

export const UserForm: FC<UserFormProps> = ({ userId, isNew = false, switchView }) => {
  const [selectedUser, setSelectedUser] = useState(useUserByIdOrEmpty(userId || -1))
  const [userName, setUserName] = useProperty(selectedUser, setSelectedUser, 'username')
  const [email, setEmail] = useProperty(selectedUser, setSelectedUser, 'email')
  const [isAdmin, setIsAdmin] = useProperty(selectedUser, setSelectedUser, 'isAdmin')
  const [active, setIsActive] = useProperty(selectedUser, setSelectedUser, 'active')
  const [employmentStartYear, setemploymentStartYear] = useProperty(selectedUser, setSelectedUser, 'employmentStartYear')
  const [annualReviewMonth, setAnnualReviewMonth] = useProperty(selectedUser, setSelectedUser, 'annualReviewMonth')

  const userIdsInUse = useUserIdsInUse()

  const users = useSelector(getLoadedUsersPopulated)
  const [managers, setManagers] = useState<SelectOption[]>(useTransformedStateForManagers(selectedUser, users, 'managers'))
  const managerIds = managers.map((option) => option.value)

  const [managedUsers, setManagedUsers] = useState<SelectOption[]>(useTransformedStateForManagers(selectedUser, users, 'managedUsers'))
  const managedUserIds = useMemo(() => managedUsers.map((option) => option.value), [managedUsers])
  const usersToAddForSelect: SelectOption[] = useMemo(() => users
    .filter((user) => {
      if (!user.active) return false
      if (user.id === selectedUser.id) return false
      if (managerIds.includes(user.id)) return false
      if (managedUserIds.includes(user.id)) return false
      return true
    })
    .map((user) => ({ value: user.id, label: user.username }))
    .sort((a, b) => a.label.localeCompare(b.label)), [managedUserIds, managerIds, selectedUser.id, users])

  const userGroups = useSelector(getLoadedUserGroups)
  const [userGroupsIn, setUserGroupsIn] = useState<SelectOption[]>(useTransformedStateForUserGroup(selectedUser, userGroups))
  const userGroupIds = useMemo(() => userGroupsIn.map((option) => option.value), [userGroupsIn])
  const userGroupsForSelect: SelectOption[] = useMemo(() => userGroups
    .filter(({ id }) => !userGroupIds.includes(id))
    .map((userGroup) => ({ value: userGroup.id, label: userGroup.name }))
    .sort((a, b) => a.label.localeCompare(b.label)), [userGroupIds, userGroups])

  const [errorState, setErrorState] = useState<ErrorState>(initState)

  const [validEmail, setValidEmail] = useProperty(errorState, setErrorState, 'errorEmail')
  useUserValidator({
    property: 'email', users, candidate: email, setError: setValidEmail,
  })

  const [validUserName, setValidUserName] = useProperty(errorState, setErrorState, 'errorUserName')
  useUserValidator({
    property: 'username', users, candidate: userName, setError: setValidUserName,
  })

  const [deleteCurrentUser, createNewUser, updateCurrentUser] = useUserHandling(selectedUser.id)

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    const triggeringElement = document.activeElement?.nodeName
    if (triggeringElement !== 'BUTTON') return
    if (Object.values(errorState).some((v) => v !== FieldErrorTypes.None)) return

    const userToUpdate = new UserToCreateOrUpdate(
      userName,
      isAdmin,
      email,
      active,
      managerIds,
      managedUserIds,
      userGroupIds,
      employmentStartYear,
      annualReviewMonth,
    )

    if (isNew) createNewUser(userToUpdate)
    else updateCurrentUser(userToUpdate)
  }

  const clearForm = useCallback(() => {
    setSelectedUser(emptyUser)
    setManagers([])
    setManagedUsers([])
    setUserGroupsIn([])
  }, [])

  return (
    <form className="form-medium" onSubmit={handleSubmit}>

      <FormInputField label="Name:" type="text" id="username" value={userName} handleOnChange={setUserName} error={userErrors(validUserName)} />
      <FormInputField label="Email:" id="email" type="email" value={email} handleOnChange={setEmail} error={emailErrors(validEmail)} />

      <FormSingleSelectField
        label="Start Date:"
        id="employmentStartYear"
        value={employmentStartYear}
        handleOnChange={setemploymentStartYear}
        options={Array(new Date().getFullYear() - minYear + 1).fill({}).map((_, i) => ({ value: minYear + i, label: `${minYear + i}` })).reverse()}
      />
      <FormSingleSelectField
        label="Annual Review Month:"
        id="annualReviewMonth"
        value={annualReviewMonth}
        handleOnChange={setAnnualReviewMonth}
        options={Array(12).fill({}).map((_, i) => ({ value: i + 1, label: Months[i + 1] }))}
      />

      <FormCheckboxField label="Is an administrator:" id="isAdmin" value={isAdmin} handleOnChange={setIsAdmin} />
      <FormCheckboxField label="Is active:" id="active" value={active} handleOnChange={setIsActive} />

      <FormSelectField label="Managed by:" id="managers" value={managers} handleOnChange={setManagers} options={usersToAddForSelect} />
      <FormSelectField label="Manager of:" id="managedUsers" value={managedUsers} handleOnChange={setManagedUsers} options={usersToAddForSelect} />
      <FormSelectField label="Member of:" id="userGroups" value={userGroupsIn} handleOnChange={setUserGroupsIn} options={userGroupsForSelect} />

      <ButtonGroup
        buttons={[
          {
            className: 'btn btn-danger',
            label: 'Delete',
            onClick: deleteCurrentUser,
            type: 'button',
            hidden: isNew || userIdsInUse.has(selectedUser.id),
          },
          {
            className: 'btn btn-clear',
            label: 'Clear',
            onClick: clearForm,
            type: 'button',
            hidden: !isNew,
          },
          {
            className: 'btn btn-danger',
            label: 'Cancel',
            onClick: clearForm,
            to: userManagementUrl(),
            type: 'button',
            hidden: !isNew,
          },
          {
            className: 'btn btn-clear',
            label: 'Cancel',
            onClick: switchView,
            type: 'button',
            hidden: !switchView,
          },
          {
            className: 'btn btn-primary',
            label: isNew ? 'Create' : 'Update',
            type: 'submit',
          },
        ]}
      />
    </form>
  )
}
