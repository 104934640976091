import React, { FC, ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'

import { UserWithDetails } from 'review-app-shared/types/user'
import { ReviewPopulated } from 'review-app-shared/types/selectors'
import { getFutureReviews } from 'app/helpers/review'
import { advanceReviewSchedulingUrl } from 'app/helpers/urlCreators'

import { Table } from 'app/components/tables/Table'
import { ReviewSchedulerTableRow } from 'app/components/tables/ReviewSchedulerTableRow'
import { UpcomingReviewsTableHeader } from 'app/components/tables/UpcomingReviewsTableHeader'
import { UpcomingReviewsTableRow } from 'app/components/tables/UpcomingReviewsTableRow'
import { filterMatchString, filterMatchDate } from 'app/helpers/filter'
import { SortableTableHeader, OrderDirection, SortParameters } from 'app/components/tables/SortableTableHeader'
import { useManagedUsers } from 'app/components/user/hooks'

interface ReviewSchedulerProps {
  users: Array<UserWithDetails>
  userReviews: Array<ReviewPopulated>
}

// The ReviewScheduler component provides a summary of key information about
// the user's staff management responsibilities or that an administrator can coordinate
export const ReviewScheduler: FC<ReviewSchedulerProps> = ({
  users,
  userReviews,
}) => {
  const [managedUsersFilter, setManagedUsersFilter] = useState('')
  const handleChangeManagedUsersFilter = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => setManagedUsersFilter(e.target.value)
  const [futureReviewsFilter, setFutureReviewsFilter] = useState('')
  const handleChangeFutureReviewsFilter = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => setFutureReviewsFilter(e.target.value)

  const futureReviews = getFutureReviews(userReviews)
  const futureReviewsExist = (futureReviews.length > 0)
  const filteredFutureReviews = futureReviews
    .filter(({
      timeReviewed,
      userReviewed: { username, active },
      questionnaire: { name },
    }) => {
      if (!active) return false
      const filterMatch = filterMatchString(username, futureReviewsFilter)
        || filterMatchDate(timeReviewed, futureReviewsFilter)
        || filterMatchString(name, futureReviewsFilter)
      if (futureReviewsFilter && !filterMatch) return false
      return true
    })

  const filteredUsers = users
    .filter(({ username }) => !managedUsersFilter
      || filterMatchString(username, managedUsersFilter))
  const [sort, setSort] = useState<SortParameters>({ id: 'previousReviewTimeReviewed', direction: OrderDirection.asc })
  const managedUsers = useManagedUsers(filteredUsers, userReviews, sort)

  return (
    <>
      {/* #ToDo change all tables to a components like here */}
      {(futureReviewsExist)
        ? (
          <Table
            title={<h4 className="separator">Upcoming reviews</h4>}
            headerRow={<UpcomingReviewsTableHeader />}
            bodyRows={filteredFutureReviews.map((review) => (
              <UpcomingReviewsTableRow
                key={review.id}
                review={review}
              />
            ))}
            showFilter
            filter={futureReviewsFilter}
            handleChangeFilter={handleChangeFutureReviewsFilter}
            filterPlaceholder="Filter by name, date or question set"
          />
        )
        : (
          <h4>No staff currently have reviews scheduled.</h4>
        )}
      <Table
        title={(
          <h4>
            Review scheduler (
            <Link to={advanceReviewSchedulingUrl()}>advanced</Link>
            )
          </h4>
        )}
        headerRow={(
          <SortableTableHeader
            sort={sort}
            setSort={setSort}
            columns={[
              { id: 'username', title: 'Name', styles: { width: '30%' } },
              { id: 'annualReviewMonth', title: 'Anniversary', styles: { width: '20%' } },
              { id: 'previousReviewTimeReviewed', title: 'Last review', styles: { width: '25%' } },
              { id: 'nextReviewTimeReviewed', title: 'Next review', styles: { width: '25%' } },
            ]}
          />
        )}
        bodyRows={managedUsers.map((managedUser) => (
          <ReviewSchedulerTableRow
            key={managedUser.userId}
            {...managedUser}
          />
        ))}
        showFilter
        filter={managedUsersFilter}
        handleChangeFilter={handleChangeManagedUsersFilter}
        filterPlaceholder="Filter by name"
      />
    </>
  )
}
