import React, { FC } from 'react'
import Select, { ValueType } from 'react-select'

import { UserGroup } from 'review-app-shared/types/user-group'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface UserGroupFormViewProps {
  handleCreateButtonClick: () => void
  handleDeleteButtonClick: () => void
  handleSelectUserGroup: (selected: SelectOption) => void
  userGroupIdsInUse: Set<number>
  userGroups: UserGroup[]
  userGroupsForSelect: SelectOption[]
  selectedUserGroup: SelectOption | null
}

// The UserGroupFormView component renders the selection and viewing aspects of the user group form
export const UserGroupFormView: FC<UserGroupFormViewProps> = ({
  handleCreateButtonClick,
  handleDeleteButtonClick,
  handleSelectUserGroup,
  userGroupIdsInUse,
  userGroups,
  userGroupsForSelect,
  selectedUserGroup,
}) => {
  const selectedUserGroupDetail = selectedUserGroup
    && userGroups.find((userGroup) => userGroup.id === selectedUserGroup.value)
  if (selectedUserGroup && !selectedUserGroupDetail) throw new Error('Selected User Group does not exist.')
  // Note that at the moment there are no additional details beyond the name in the selection list,
  // but if more information was to be added we would extract and display it here below the list.

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title="User groups"
        tooltip="Select a user group from the list, or create a new one."
      />
      <div className="main">
        <Select
          classNamePrefix="select"
          className="select-box"
          onChange={(selected: ValueType<SelectOption>): void => handleSelectUserGroup((selected as SelectOption))}
          value={selectedUserGroup}
          options={userGroupsForSelect}
          placeholder="Select from current user groups"
          isClearable
        />
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-danger',
              label: 'Delete current selection',
              onClick: handleDeleteButtonClick,
              type: 'button',
              hidden: !(selectedUserGroup && userGroupIdsInUse.has(selectedUserGroup.value)),
            },
            {
              className: 'btn btn-primary',
              label: 'Create new',
              onClick: handleCreateButtonClick,
              type: 'button',
            },
          ]}
        />
      </div>
    </div>
  )
}
