import React, { FC } from 'react'

// This component renders the header row for a table listing users with basic details.
export const TableHeaderUserList: FC = () => (
  <>
    <tr>
      <th style={{ width: '25%' }}>Name</th>
      <th style={{ width: '25%' }}>Email address</th>
      <th style={{ width: '10%' }}>Admin?</th>
      <th style={{ width: '20%' }}>Manager(s)</th>
      <th style={{ width: '20%' }}>User Group(s)</th>
    </tr>
  </>
)
