import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from 'app/components/components-ui/tooltip/tooltip'

import {
  homeUrl,
  questionFormUrl,
  questionnaireFormUrl,
  responseOptionsFormUrl,
} from 'app/helpers/urlCreators'

import { ReactComponent as IconBack } from 'assets/svg/icon-back.svg'

// The QuestionAdmin component provides authorized users with access to question management tools
export const QuestionAdmin: FC = () => (
  <div className="content">
    <div className="main">
      <h3 className="h-flex">
        <Link className="btn btn-square mr-20" to={homeUrl()}><IconBack /></Link>
        Question Management
        <Tooltip
          className="ml-10"
          position="bottom"
        >
          <p>
            Feedback to support performance reviews is captured using a structured questionnaire, with
            the same questionnaire being used by all reviewers for a particular review. Each questionnaire
            consists of a list of questions, each of which corresponds to certain performance criteria,
            and for each question the reviewer must choose one of a set response options for that question.
            In addition, whether reviewers are able to, or required to, provide additional text comments
            in support of their judgement, can be configured for each question.
          </p>
        </Tooltip>
      </h3>
      <p>
        Each of the three levels of configuration of question sets is managed separately and they can
        be accessed using the links below:
      </p>
      <ul>
        <li><Link to={responseOptionsFormUrl}>Manage response options</Link></li>
        <li><Link to={questionFormUrl}>Manage questions</Link></li>
        <li><Link to={questionnaireFormUrl}>Manage questionnaires</Link></li>
      </ul>
    </div>
  </div>
)
