import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { UpdateReviewForm } from 'app/components/forms/UpdateReviewForm'
import { FormError } from 'app/components/FormError'

import { ReviewUpdateData } from 'review-app-shared/types/review'
import { getUpdateReviewFormState } from 'app/modules/review-form/review-form-selectors'
import {
  updateReviewFormStartFetch,
  updateReviewFormStartSend,
  updateReviewFormStartDelete,
} from 'app/modules/review-form/review-form-actions'
import { FormStatus } from 'review-app-shared/types/formData'
import { homeUrl } from 'app/helpers/urlCreators'

interface RouteInfo {
  reviewId: string
}

type UpdateReviewFormWrapperProps = RouteComponentProps<RouteInfo>

// The UpdateReviewFormWrapper component enables a manager to amend the date and
// selected reviewers for a review for one of the staff they manage.
export const UpdateReviewFormWrapper: FC<UpdateReviewFormWrapperProps> = (
  { match: { params: { reviewId } } },
) => {
  const formState = useSelector(getUpdateReviewFormState)
  const dispatch = useDispatch()

  // trigger fetch of form content on initial render or if changing reviewId
  useEffect((): void => {
    dispatch(updateReviewFormStartFetch(reviewId))
  }, [dispatch, reviewId])

  const updateReview = (reviewData: ReviewUpdateData): void => {
    dispatch(updateReviewFormStartSend(reviewId, reviewData))
  }
  const deleteReview = (): void => {
    dispatch(updateReviewFormStartDelete(reviewId))
  }

  switch (formState.kind) {
    case FormStatus.Blank:
    case FormStatus.Loading:
    case FormStatus.Saving:
    case FormStatus.Deleting:
      return (
        <div>Loading...</div>
      )
    case FormStatus.Deleted:
    case FormStatus.Saved:
      return (
        <Redirect to={homeUrl()} />
      )
    case FormStatus.Loaded:
      return (
        <UpdateReviewForm
          reviewData={formState.data}
          updateReview={updateReview}
          deleteReview={deleteReview}
        />
      )
    case FormStatus.Error:
      return <FormError errorMessage={formState.errorMessage} />
    default:
      return <FormError errorMessage={`Unexpected FormState: ${formState}`} />
  }
}
