import { ResponseOptions } from './response-options'

interface IQuestion {
  readonly category: string
  readonly freeTextCommentDisplayed: boolean
  readonly optionsId: number | null
  readonly description: string | null
  readonly freeTextCommentRequiredOnResponses: Array<boolean> | null
  readonly freeTextRequired: boolean
}

export class Question implements IQuestion {
  constructor(
    readonly id: number,
    readonly category: string,
    readonly freeTextCommentDisplayed: boolean,
    readonly optionsId: number | null,
    readonly description: string | null,
    readonly freeTextCommentRequiredOnResponses: Array<boolean> | null,
    readonly freeTextRequired: boolean,
  ) {
  }
}

export class QuestionToCreate implements IQuestion {
  constructor(
    readonly category: string,
    readonly freeTextCommentDisplayed: boolean,
    readonly optionsId: number | null,
    readonly description: string | null,
    readonly freeTextCommentRequiredOnResponses: Array<boolean> | null,
    readonly freeTextRequired: boolean,
  ) {
  }
}

export class QuestionWithResponseOptions extends Question {
  constructor(
    id: number,
    category: string,
    freeTextCommentDisplayed: boolean,
    optionsId: number | null,
    description: string | null,
    freeTextCommentRequiredOnResponses: Array<boolean> | null,
    freeTextRequired: boolean,
    readonly responseOptions: ResponseOptions | null,
  ) {
    super(id, category, freeTextCommentDisplayed, optionsId, description, freeTextCommentRequiredOnResponses, freeTextRequired)
  }
}
