import { envToIntegerWithDefault, envToStringWithDefault } from 'review-app-shared/helpers/envHelpers'
import { envRegExp } from 'review-app-shared/helpers/regexp'
import { DefaultClientEnv } from 'review-app-shared/constants/DefaultClientEnv'


import { EnvErrorMessage } from 'app/messages/EnvErrorMessage'


const {
  REACT_APP_SERVER_BASE_URL,
  REACT_APP_TIMEOUT,
} = process.env

export const ServerConnectionEnv = {
  SERVER_BASE_URL: envToStringWithDefault(REACT_APP_SERVER_BASE_URL, DefaultClientEnv.serverBaseUrl, EnvErrorMessage.serverBaseUrl, envRegExp.url),
  TIMEOUT: envToIntegerWithDefault(REACT_APP_TIMEOUT, DefaultClientEnv.timeout, EnvErrorMessage.timeout),
}
