import { UserWithDetailsPopulated } from 'review-app-shared/types/selectors'

export const emptyUser: UserWithDetailsPopulated = {
  id: -1,
  username: '',
  email: '',
  isAdmin: false,
  managers: [],
  managedUsers: [],
  userGroups: [],
  active: true,
  employmentStartYear: new Date().getFullYear(),
  annualReviewMonth: new Date().getMonth(),
}

export enum FieldErrorTypes { Required, BlackListed, None }
type errorMessage = (err: FieldErrorTypes) => string

export const emailErrors: errorMessage = (err) => {
  switch (err) {
    case FieldErrorTypes.Required:
      return 'Email is required'
    case FieldErrorTypes.BlackListed:
      return 'Email is already used by someone else'
    default:
      return ''
  }
}

export const userErrors: errorMessage = (err) => {
  switch (err) {
    case FieldErrorTypes.Required:
      return 'User name is required'
    case FieldErrorTypes.BlackListed:
      return 'That user already exists!'
    default:
      return ''
  }
}
