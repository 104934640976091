import { envToStringRequired, envToStringWithDefault } from 'review-app-shared/helpers/envHelpers'
import { envRegExp } from 'review-app-shared/helpers/regexp'
import { DefaultServerEnv } from 'review-app-shared/constants/DefaultServerEnv'


import { EnvErrorMessage } from 'app/messages/EnvErrorMessage'


const {
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_REDIRECT_URI,
} = process.env

export const LoginEnv = {
  GOOGLE_CLIENT_ID: envToStringRequired(REACT_APP_GOOGLE_CLIENT_ID, EnvErrorMessage.googleClientId, envRegExp.googleClientId),
  GOOGLE_REDIRECT_URI: envToStringWithDefault(REACT_APP_GOOGLE_REDIRECT_URI, DefaultServerEnv.googleRedirect, EnvErrorMessage.googleRedirectUri, envRegExp.url),
}

export const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=${LoginEnv.GOOGLE_CLIENT_ID}&redirect_uri=${encodeURI(LoginEnv.GOOGLE_REDIRECT_URI)}`
