import React, { FC, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'

import { Table } from 'app/components/tables/Table'
import { TableHeaderAdvancedReviewSchedulerList } from 'app/components/tables/TableHeaderAdvancedReviewSchedulerList'
import { TableRowAdvancedReviewSchedulerList } from 'app/components/tables/TableRowAdvancedReviewSchedulerList'

import { getLoadedUsersPopulated, getCurrentUser } from 'app/modules/user/user-selectors'
import { getManagedUserReviews, getAdminUserReviews } from 'app/modules/review/review-selectors'
import { filterMatchString } from 'app/helpers/filter'
import { UserWithDetails } from 'review-app-shared/types/user'

// The AdvancedReviewSchedulerList component displays a table of users that a manager is responsible for
// and their review history, or a full list of users if logged in as an administrator
export const AdvancedReviewSchedulerList: FC = () => {
  const currentUser = useSelector(getCurrentUser)
  const users = useSelector(getLoadedUsersPopulated)
  const managedUserReviews = useSelector(getManagedUserReviews)
  const adminUserReviews = useSelector(getAdminUserReviews)

  const [usersFilter, setUsersFilter] = useState('')
  const handleChangeUsersFilter = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => setUsersFilter(e.target.value)

  if (!currentUser) return null
  const { isAdmin, managedUsers } = currentUser
  const managedUserIds = managedUsers.map((user: UserWithDetails) => user.id)

  const usersToShow = isAdmin ? users : users.filter((user) => managedUserIds.includes(user.id))
  const filteredUsers = usersToShow.filter(({ username, email, userGroups }) => {
    const filterMatch = filterMatchString(username, usersFilter)
      || filterMatchString(email, usersFilter)
      || filterMatchString(userGroups.map((userGroup) => userGroup.name).join(','), usersFilter)
    return !usersFilter && filterMatch
  })

  return (
    <div>
      <p>The users are listed in the table below together with their review history:</p>
      <Table
        headerRow={<TableHeaderAdvancedReviewSchedulerList />}
        bodyRows={filteredUsers.map((user) => {
          const userReviews = isAdmin ? adminUserReviews : managedUserReviews
          return (
            <TableRowAdvancedReviewSchedulerList
              key={user.id}
              user={user}
              reviews={userReviews.filter((review) => review.userReviewedId === user.id)}
            />
          )
        })}
        showFilter
        filter={usersFilter}
        handleChangeFilter={handleChangeUsersFilter}
        filterPlaceholder="Filter by name, email address or group"
      />
    </div>
  )
}
