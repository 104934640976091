import { createSelector } from 'reselect'

import { AppState } from 'app/modules/root/root-reducer'
import { ReviewFormState } from 'app/modules/review-form/review-form-reducer'

export const getReviewFormState = ((state: AppState): ReviewFormState => state.reviewForm)

export const getCreateReviewFormState = createSelector(
  getReviewFormState,
  (reviewFormState) => reviewFormState.create,
)

export const getUpdateReviewFormState = createSelector(
  getReviewFormState,
  (reviewFormState) => reviewFormState.update,
)
