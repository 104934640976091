import { createSelector } from 'reselect'

import { Feedback } from 'review-app-shared/types/feedback'
import { Review } from 'review-app-shared/types/review'
import { FeedbackPopulated, ReviewPopulated } from 'review-app-shared/types/selectors'
import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'
import { UserWithDetails } from 'review-app-shared/types/user'
import { LoadStatus } from 'review-app-shared/types/fetchData'

import { AppState } from 'app/modules/root/root-reducer'
import { getCurrentUser, getCurrentUserId, getUsers } from 'app/modules/user/user-selectors'
import { ReviewState } from 'app/modules/review/review-reducer'

export const getReviewState = ((state: AppState): ReviewState => state.review)

export const getFeedback = createSelector(getReviewState, (review) => review.feedback)
export const getReviews = createSelector(getReviewState, (review) => review.reviews)
export const getQuestionnaires = createSelector(getReviewState, (review) => review.questionnaires)
export const getFeedbackById = createSelector(getReviewState, (review) => review.feedbackById)
export const getSummaryById = createSelector(getReviewState, (review) => review.summaryById)

export const getIsLoadingReview = createSelector(
  getFeedback,
  getReviews,
  getQuestionnaires,
  (feedback, reviews, questionnaires) => (feedback.kind === LoadStatus.Loading)
    || (reviews.kind === LoadStatus.Loading) || (questionnaires.kind === LoadStatus.Loading),
)

export const getIsErrorReview = createSelector(
  getFeedback,
  getReviews,
  getQuestionnaires,
  (feedback, reviews, questionnaires) => (feedback.kind === LoadStatus.Error)
    || (reviews.kind === LoadStatus.Error) || (questionnaires.kind === LoadStatus.Error),
)

export const getLoadedFeedback = createSelector(getFeedback, (feedback) => {
  if (feedback.kind !== LoadStatus.Loaded) return []
  return [...feedback.data]
})

export const getLoadedReviews = createSelector(getReviews, (reviews) => {
  if (reviews.kind !== LoadStatus.Loaded) return []
  return [...reviews.data]
})

export const getLoadedQuestionnaires = createSelector(getQuestionnaires, (questionnaires) => {
  if (questionnaires.kind !== LoadStatus.Loaded) return []
  return [...questionnaires.data].sort((a, b) => a.name.localeCompare(b.name))
})

// helper for getFeedbackRequests
const populateFeedback = (
  feedback: Feedback,
  reviews: Review[],
  users: UserWithDetails[],
): FeedbackPopulated => {
  const linkedReview = reviews.find((review) => review.id === feedback.reviewId)
  if (linkedReview === undefined) {
    throw new Error(`Inconsistent review data, ${feedback.reviewId} is not a valid review id.`)
  }
  const reviewedUser = users.find((user) => user.id === linkedReview.userReviewedId)
  if (reviewedUser === undefined) {
    throw new Error(`Inconsistent user data, ${linkedReview.userReviewedId} is not a valid user id (user reviewed).`)
  }
  const feedbackPopulated = new FeedbackPopulated(
    feedback.id,
    feedback.reviewerUserId,
    feedback.reviewId,
    feedback.dateSubmitted,
    feedback.state,
    linkedReview,
    reviewedUser,
  )
  return feedbackPopulated
}

export const getFeedbackRequests = createSelector(
  getFeedback,
  getReviews,
  getUsers,
  getCurrentUserId,
  (feedback, reviews, users, currentUserId) => {
    // return empty array until all data is loaded
    if (feedback.kind !== LoadStatus.Loaded || reviews.kind !== LoadStatus.Loaded
      || users.kind !== LoadStatus.Loaded || !currentUserId) return []
    const myFeedbackRequests = feedback.data
      .filter((eachFeedback) => eachFeedback.reviewerUserId === currentUserId)
      .map((myFeedback) => populateFeedback(myFeedback, reviews.data, users.data))
    return myFeedbackRequests
  },
)

// helper for getManagedUserReviews and getMyReviews
const populateReview = (
  review: Review,
  users: UserWithDetails[],
  feedback: Feedback[],
  questionnaires: QuestionnaireWithQuestionIds[],
): ReviewPopulated => {
  const userReviewed = users.find((user) => user.id === review.userReviewedId)
  if (userReviewed === undefined) {
    throw new Error(`Inconsistent user data, ${review.userReviewedId} is not a valid user id (user reviewed).`)
  }
  const questionnaire = questionnaires.find((q) => q.id === review.questionnaireId)
  if (questionnaire === undefined) {
    throw new Error(`Inconsistent data, ${review.questionnaireId} is not a valid questionnaire id.`)
  }
  const linkedFeedback = feedback
    .filter((eachFeedback) => eachFeedback.reviewId === review.id)
    .map((eachFeedback) => {
      const reviewer = users.find((user) => user.id === eachFeedback.reviewerUserId)
      if (reviewer === undefined) {
        throw new Error(`Inconsistent user data, ${eachFeedback.reviewerUserId} is not a valid user id (reviewer).`)
      }
      return { ...eachFeedback, reviewer }
    })

  const reviewPopulated = new ReviewPopulated(
    review.id,
    review.timeCreated,
    review.timeReviewed,
    review.deadline,
    review.userReviewedId,
    review.questionnaireId,
    userReviewed,
    questionnaire,
    linkedFeedback,
  )
  return reviewPopulated
}

export const getManagedUserReviews = createSelector(
  getFeedback,
  getReviews,
  getQuestionnaires,
  getUsers,
  getCurrentUser,
  (feedback, reviews, questionnaires, users, currentUser) => {
    // return empty array until all data is loaded
    if (feedback.kind !== LoadStatus.Loaded || reviews.kind !== LoadStatus.Loaded || questionnaires.kind !== LoadStatus.Loaded
      || users.kind !== LoadStatus.Loaded || !currentUser) return []
    const { managedUsers } = currentUser
    const managedUserReviews = reviews.data
      .filter((review) => managedUsers.map((user: UserWithDetails) => user.id).includes(review.userReviewedId))
      .map((managedUserReview) => populateReview(managedUserReview, users.data, feedback.data, questionnaires.data))
      .sort((a, b) => {
        const { timeReviewed: timeA } = a
        const { timeReviewed: timeB } = b
        return new Date(timeA).getTime() - new Date(timeB).getTime()
      })
    return managedUserReviews
  },
)

export const getAdminUserReviews = createSelector(
  getFeedback,
  getReviews,
  getQuestionnaires,
  getUsers,
  getCurrentUser,
  (feedback, reviews, questionnaires, users, currentUser) => {
    // return empty array until all data is loaded
    if (feedback.kind !== LoadStatus.Loaded || reviews.kind !== LoadStatus.Loaded || questionnaires.kind !== LoadStatus.Loaded
      || users.kind !== LoadStatus.Loaded || !currentUser) return []
    const { isAdmin } = currentUser
    if (!isAdmin) return []
    const userReviews = reviews.data
      .map((review) => populateReview(review, users.data, feedback.data, questionnaires.data))
      .sort((a, b) => {
        const { timeReviewed: timeA } = a
        const { timeReviewed: timeB } = b
        return new Date(timeA).getTime() - new Date(timeB).getTime()
      })
    return userReviews
  },
)

export const getMyReviews = createSelector(
  getFeedback,
  getReviews,
  getQuestionnaires,
  getUsers,
  getCurrentUserId,
  (feedback, reviews, questionnaires, users, currentUserId) => {
    // return empty array until all data is loaded
    if (feedback.kind !== LoadStatus.Loaded || reviews.kind !== LoadStatus.Loaded || questionnaires.kind !== LoadStatus.Loaded
      || users.kind !== LoadStatus.Loaded || !currentUserId) return []
    const myReviews = reviews.data
      .filter((review) => review.userReviewedId === currentUserId)
      .map((review) => populateReview(review, users.data, feedback.data, questionnaires.data))
      .sort(({ timeReviewed: timeA }, { timeReviewed: timeB }) => new Date(timeB).getTime() - new Date(timeA).getTime())
    return myReviews
  },
)

export const getKnownFeedbackIds = createSelector(
  getFeedbackById,
  (feedbackById) => Object.keys(feedbackById).map((key) => Number(key)),
)
