import { api, ApiResponse } from 'app/api/api-client'
import { ForecastApiResponse } from 'review-app-shared/types/forecast'

export const apiGetForecasts = async (token: string): ApiResponse<ForecastApiResponse> => api.get('/forecast', {
  headers: { Authorization: token },
})

export const apiUpdateUsersFromForecasts = async (token: string): ApiResponse<{ newUserCount: number }> => api.post('/webhooks/fetchForecastUsers', undefined, {
  headers: { Authorization: token },
})
