import React, { FC } from 'react'
import { useSlate } from 'slate-react'

import {
  LEAF_FORMATS, BLOCK_FORMATS, ICONS, hasIcon,
} from './const'

import {
  toggleBlock, toggleMark, isBlockActive, isMarkActive,
} from './utils'

interface ButtonProps {
  isActive: boolean
  applyFormat: () => void
  Icon: JSX.Element
}

const FormatButton: FC<ButtonProps> = ({ Icon, isActive, applyFormat }) => (
  <li>
    <button
      className={`btn btn-square btn-clear ${isActive ? ' btn-primary' : ''}`}
      type="button"
      onMouseDown={(event): void => {
        event.preventDefault()
        applyFormat()
      }}
    >
      {Icon && <div className="icon">{Icon}</div>}
      {!Icon && <div className="icon text-heading" />}
    </button>
  </li>
)

const ButtonWrapper: FC = ({ children }) => (
  <div className="btn-group-editor">
    {children}
  </div>
)

export const Toolbar: FC = () => {
  const editor = useSlate()

  return (
    <ButtonWrapper>
      <ul className="mb-0">
        {LEAF_FORMATS.map((format) => (
          hasIcon(format)
            ? (
              <FormatButton
                key={format}
                Icon={ICONS[format]}
                isActive={isMarkActive(editor, format)}
                applyFormat={(): void => toggleMark(editor, format)}
              />
            ) : null
        ))}
      </ul>
      <ul className="ml-40 mb-0">
        {BLOCK_FORMATS.map((format) => (
          hasIcon(format)
            ? (
              <FormatButton
                key={format}
                Icon={ICONS[format]}
                isActive={isBlockActive(editor, format)}
                applyFormat={(): void => toggleBlock(editor, format)}
              />
            ) : null
        ))}
      </ul>
    </ButtonWrapper>
  )
}
