import React from 'react'

import { Button } from 'app/components/components-ui/buttons/button'
import { User } from 'review-app-shared/types/user'

interface ReviewerSuggestionSubgroupProps {
  title: string
  users: User[]
  selectedUserIds: number[]
  lockedUserIds?: number[]
  handleAddUserReviewing: (userId: number) => void
  handleAddUsersReviewing: (userIds: number[]) => void
  handleRemoveUserReviewing: (userId: number) => void
  handleRemoveUsersReviewing: (userIds: number[]) => void
}

/**
 * Shows a group of buttons for adding/removing recommended reviewers to the review
 */
export const ReviewerSuggestionSubgroup: React.FC<ReviewerSuggestionSubgroupProps> = ({
  title,
  users,
  selectedUserIds,
  lockedUserIds = [],
  handleAddUserReviewing,
  handleAddUsersReviewing,
  handleRemoveUserReviewing,
  handleRemoveUsersReviewing,
}) => {
  const usersLockedToShow = users ? users.filter((user) => lockedUserIds.includes(user.id)) : []
  const usersUnlockedToShow = users ? users.filter((user) => !lockedUserIds.includes(user.id)) : []

  const allUserIds = users.map((user) => user.id)

  return (
    <div className="mt-30">
      <div className="heading-with-buttons">
        <div>{title}</div>
        <ul>
          <li>
            <Button
              type="button"
              className="c-link"
              label="Add all"
              onClick={(): void => handleAddUsersReviewing(allUserIds)}
            />
          </li>
          <li>
            <Button
              type="button"
              className="c-link"
              label="Remove all"
              onClick={(): void => handleRemoveUsersReviewing(allUserIds)}
            />
          </li>
        </ul>
      </div>
      <ul className="horizontal-list">
        {usersLockedToShow.map((user) => (
          <li key={user.id}>
            <Button
              type="button"
              className="btn btn-clear"
              label={user.username}
            />
          </li>
        ))}
        {usersUnlockedToShow.map((user) => {
          const isSelected = selectedUserIds.includes(user.id)
          return (
            <li key={user.id}>
              <Button
                type="button"
                className="btn btn-tag"
                onClick={(): void => (isSelected ? handleRemoveUserReviewing(user.id) : handleAddUserReviewing(user.id))}
              >
                <div className={`btn-tag-mark ${isSelected && 'checked'}`} />
                {user.username}
              </Button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
