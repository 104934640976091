import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { FeedbackState } from 'review-app-shared/types/feedback'
import { FeedbackPopulated } from 'review-app-shared/types/selectors'
import { formatDate } from 'app/helpers/review'
import { feedbackReportUrl, feedbackFormUrl } from 'app/helpers/urlCreators'

import { FeedbackStatus } from 'app/components/components-ui/feedback/FeedbackStatus'

interface TableRowFeedbackForReviewersProps {
  feedbackRequest: FeedbackPopulated
}

// This component renders a data row for tables listing feedback requests
// with dates, current status and a link to the feedback form.
export const TableRowFeedbackForReviewers: FC<TableRowFeedbackForReviewersProps> = ({ feedbackRequest }) => {
  const {
    id,
    linkedReview: { deadline, timeReviewed },
    reviewedUser: { username, active },
  } = feedbackRequest
  if (!active) {
    return (
      <tr>
        <td data-title="Review of" className="inactive-user">{`${username} (inactive)`}</td>
        <td data-title="Feedback deadline">{formatDate(deadline)}</td>
        <td data-title="Review date">{formatDate(timeReviewed)}</td>
        <td data-title="Feedback status"><FeedbackStatus feedback={feedbackRequest} /></td>
        <td>{feedbackRequest.state === FeedbackState.Submitted && <Link to={feedbackReportUrl(id)}>View</Link>}</td>
      </tr>
    )
  }

  return (
    <tr>
      <td data-title="Review of">{username}</td>
      <td data-title="Feedback deadline">{formatDate(deadline)}</td>
      <td data-title="Feedback date">{formatDate(timeReviewed)}</td>
      <td data-title="Feedback status"><FeedbackStatus feedback={feedbackRequest} /></td>
      <td>
        {feedbackRequest.state === FeedbackState.Submitted && (
          <Link to={feedbackReportUrl(id)}>View</Link>
        )}
        {(feedbackRequest.state === FeedbackState.Started || feedbackRequest.state === FeedbackState.NotStarted) && deadline >= new Date() && (
          <Link to={feedbackFormUrl(id)}>
            Provide feedback
          </Link>
        )}
      </td>
    </tr>
  )
}
