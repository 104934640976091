import { api, ApiResponse } from 'app/api/api-client'
import { UserToCreateOrUpdate, UserWithDetails } from 'review-app-shared/types/user'

export const apiPostUser = async (
  userToCreate: UserToCreateOrUpdate,
  token: string,
): ApiResponse<UserWithDetails> => api.post(
  '/user',
  userToCreate,
  { headers: { Authorization: token } },
)

export const apiPutUser = async (
  userId: number,
  userUpdateData: UserToCreateOrUpdate,
  token: string,
): ApiResponse<UserWithDetails> => api.put(
  `/user/${userId}`,
  userUpdateData,
  { headers: { Authorization: token } },
)

export const apiDeleteUser = async (
  userId: number,
  token: string,
): ApiResponse<void> => api.delete(
  `/user/${userId}`,
  { headers: { Authorization: token } },
)
