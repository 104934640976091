import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'

import { Question } from 'review-app-shared/types/question'

interface FeedbackReportQuestionProps {
  question: Question
}

// The FeedbackReportQuestion component renders an individual question for
// a feedback report
export const FeedbackReportQuestion: FC<FeedbackReportQuestionProps> = ({
  question: {
    category,
    description,
  },
}) => (
  <>
    <h3>{category}</h3>
    <Markdown className="description" options={{ forceBlock: true }}>
      {description || 'No description'}
    </Markdown>
  </>
)
