import {
  UserGroupFormActions,
  UserGroupFormStartSendActionType,
  UserGroupFormFinishSendActionType,
  UserGroupFormStartDeleteActionType,
  UserGroupFormFinishDeleteActionType,
  UserGroupFormErrorActionType,
} from 'app/modules/user-group-form/user-group-form-actions'

import { UserGroup, UserGroupToCreate } from 'review-app-shared/types/user-group'
import {
  FormDataResult,
  getBlank,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getError,
} from 'review-app-shared/types/formData'

export type UserGroupFormState = FormDataResult<UserGroup, UserGroupToCreate>

const initialState = getBlank()

export const userGroupFormReducer = (
  state: UserGroupFormState = initialState,
  action: UserGroupFormActions,
): UserGroupFormState => {
  switch (action.type) {
    case UserGroupFormStartSendActionType:
      return getSaving(action.payload)
    case UserGroupFormFinishSendActionType:
      return getSaved(action.payload)
    case UserGroupFormStartDeleteActionType:
      return getDeleting()
    case UserGroupFormFinishDeleteActionType:
      return getDeleted()
    case UserGroupFormErrorActionType:
      return getError(action.payload.message)
    default:
      return state
  }
}
