import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiPostQuestionnaire,
  apiPutQuestionnaire,
  apiDeleteQuestionnaire,
} from 'app/modules/questionnaire-form/questionnaire-form-effects'
import {
  QuestionnaireWithQuestionIds,
} from 'review-app-shared/types/questionnaire'

import {
  questionnaireFormCreateFinishSend,
  questionnaireFormUpdateFinishSend,
  questionnaireFormFinishDelete,
  questionnaireFormError,
  QuestionnaireFormCreateStartSendActionType,
  QuestionnaireFormCreateStartSend,
  QuestionnaireFormCreateFinishSend,
  QuestionnaireFormUpdateStartSendActionType,
  QuestionnaireFormUpdateStartSend,
  QuestionnaireFormUpdateFinishSend,
  QuestionnaireFormStartDeleteActionType,
  QuestionnaireFormStartDelete,
  QuestionnaireFormFinishDelete,
  QuestionnaireFormError,
  QuestionnaireFormCreateFinishSendActionType,
  QuestionnaireFormUpdateFinishSendActionType,
  QuestionnaireFormFinishDeleteActionType,
  QuestionnaireFormErrorActionType,
} from 'app/modules/questionnaire-form/questionnaire-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getQuestionnaireFormState } from './questionnaire-form-selectors'

type CreateQuestionnaireEffects = SelectEffect | CallEffect<AxiosResponse<QuestionnaireWithQuestionIds>> |
PutEffect<QuestionnaireFormCreateFinishSend> | PutEffect<QuestionnaireFormError>

export function* createQuestionnaireSaga(
  action: QuestionnaireFormCreateStartSend,
): Generator<CreateQuestionnaireEffects, void, string | AxiosResponse<QuestionnaireWithQuestionIds>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostQuestionnaire, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(questionnaireFormCreateFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(questionnaireFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(questionnaireFormError(error))
    }
  }
}

type UpdateQuestionnaireEffects = SelectEffect | CallEffect<AxiosResponse<QuestionnaireWithQuestionIds>> |
PutEffect<QuestionnaireFormUpdateFinishSend> | PutEffect<QuestionnaireFormError>

export function* updateQuestionnaireSaga(
  action: QuestionnaireFormUpdateStartSend,
): Generator<UpdateQuestionnaireEffects, void, string | AxiosResponse<QuestionnaireWithQuestionIds>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutQuestionnaire, action.payload.questionnaireId, action.payload.questionnaireUpdateData, token)
      if (response && typeof response !== 'string') {
        yield put(questionnaireFormUpdateFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(questionnaireFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(questionnaireFormError(error))
    }
  }
}

type DeleteQuestionnaireEffects = SelectEffect | CallEffect<AxiosResponse<void>> |
PutEffect<QuestionnaireFormFinishDelete> | PutEffect<QuestionnaireFormError>

export function* deleteQuestionnaireSaga(
  action: QuestionnaireFormStartDelete,
): Generator<DeleteQuestionnaireEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteQuestionnaire, action.payload, token)
      if (response) yield put(questionnaireFormFinishDelete(reviewId))
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(questionnaireFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(questionnaireFormError(error))
    }
  }
}

export function* toastSaved(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getQuestionnaireFormState>> {
  const formState: ReturnType<typeof getQuestionnaireFormState> = yield select(getQuestionnaireFormState)
  if (formState.kind === FormStatus.Saved) {
    yield call(toast.info, `You have successfully created or updated ${formState.data?.name}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}


export function* toastDelete(): Generator<CallEffect<string | number>> {
  yield call(toast.info, 'You have successfully deleted the questionnaire.', {
    position: toast.POSITION.TOP_CENTER,
  })
}

export function* toastError(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getQuestionnaireFormState>> {
  const formState: ReturnType<typeof getQuestionnaireFormState> = yield select(getQuestionnaireFormState)
  if (formState.kind === FormStatus.Error) {
    yield call(toast.error, formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* questionnaireFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(QuestionnaireFormCreateStartSendActionType, createQuestionnaireSaga)
  yield takeEvery(QuestionnaireFormUpdateStartSendActionType, updateQuestionnaireSaga)
  yield takeEvery(QuestionnaireFormStartDeleteActionType, deleteQuestionnaireSaga)
  yield takeEvery(QuestionnaireFormCreateFinishSendActionType, toastSaved)
  yield takeEvery(QuestionnaireFormUpdateFinishSendActionType, toastSaved)
  yield takeEvery(QuestionnaireFormFinishDeleteActionType, toastDelete)
  yield takeEvery(QuestionnaireFormErrorActionType, toastError)
}
