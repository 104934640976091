import React, {
  FC, ChangeEvent, KeyboardEvent, FormEvent,
} from 'react'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface UserGroupFormEditProps {
  name: string
  errorName: string | null
  handleCancelButtonClick: () => void
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
}

// The UserGroupFormEdit component renders the editing aspects of the user group form
export const UserGroupFormEdit: FC<UserGroupFormEditProps> = ({
  name,
  errorName,
  handleCancelButtonClick,
  handleChangeName,
  handleSubmit,
}) => (
  <div className="content has-buttons">
    <Hero
      breadcrumb
      title="Create user group"
    />
    <div className="main">
      <form className="form-medium" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            Name
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            value={name}
            onChange={handleChangeName}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
              if (event.key === 'Enter') event.preventDefault() // suppress form submit
            }}
            autoComplete="off"
            required
          />
        </div>
        {errorName && <p className="form-error">{errorName}</p>}
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-clear',
              label: 'Cancel',
              onClick: handleCancelButtonClick,
              type: 'button',
            },
            {
              className: 'btn btn-primary',
              label: 'Create',
              type: 'submit',
            },
          ]}
        />
      </form>
    </div>
  </div>
)
