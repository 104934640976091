import React, { FC } from 'react'

import { ResponseOptions } from 'review-app-shared/types/response-options'

import { booleanToIcon } from 'app/helpers/commonVisualElements'

interface QuestionDetailsResponseOptionsProps {
  freeTextCommentDisplayed: boolean
  freeTextCommentRequiredOnResponses: boolean[] | null
  responseOptions: ResponseOptions
}

// The QuestionDetails component renders a complete question in detail.
export const QuestionDetailsResponseOptions: FC<QuestionDetailsResponseOptionsProps> = ({
  freeTextCommentDisplayed,
  freeTextCommentRequiredOnResponses: requiredArray,
  responseOptions,
}) => (
  <>
    <p data-cy="response-option-name">
      Response options:
      {' '}
      <span>{responseOptions.name}</span>
    </p>
    {(freeTextCommentDisplayed)
      ? (
        <table className="table">
          <thead>
            <tr>
              <th>Option</th>
              <th>Comment required</th>
            </tr>
          </thead>
          <tbody>
            {responseOptions.options.map(({ name }, index) => {
              const commentRequired = requiredArray ? requiredArray[index] : false
              return (
                <tr key={name}>
                  <td><ul><li data-cy={`option-value-comment-required${name}`}>{name}</li></ul></td>
                  <td data-cy={`comment-required-check${name}`}>{booleanToIcon(commentRequired)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <ul>
          {responseOptions.options.map(({ name }) => <li data-cy={`option-value${name}`} key={name}>{name}</li>)}
        </ul>
      )}
  </>
)
