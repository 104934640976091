import { api, ApiResponse } from 'app/api/api-client'
import { UserGroup, UserGroupToCreate } from 'review-app-shared/types/user-group'

export const apiPostUserGroup = async (
  userGroupToCreate: UserGroupToCreate,
  token: string,
): ApiResponse<UserGroup> => api.post(
  '/user-group',
  userGroupToCreate,
  { headers: { Authorization: token } },
)

export const apiDeleteUserGroup = async (
  userGroupId: number,
  token: string,
): ApiResponse<void> => api.delete(
  `/user-group/${userGroupId}`,
  { headers: { Authorization: token } },
)
