import React, { useState, useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'

import { authStartLogout } from 'app/modules/auth/auth-actions'
import { getCurrentUser } from 'app/modules/user/user-selectors'
import {
  homeUrl,
  userManagementUrl,
  userGroupFormUrl,
  questionFormUrl,
  questionnaireFormUrl,
  responseOptionsFormUrl,
} from 'app/helpers/urlCreators'

import { CurrentUserHeader } from 'app/components/user/CurrentUserHeader'

import { ReactComponent as SalsitaLogo } from 'assets/logos/salsita.svg'
import { ReactComponent as Questionnaires } from 'assets/svg/icon-questionnaires.svg'
import { ReactComponent as Theme } from 'assets/svg/icon-theme.svg'
import { ReactComponent as Menu } from 'assets/svg/icon-menu.svg'

interface HistoryInfo {
  history: string
}
interface HeaderProps extends RouteComponentProps<HistoryInfo> {
  currentUserEmail: string | null
}

// The ever-present Header component identifies the application and reports a user's login status
export const Header: FC<HeaderProps> = ({ currentUserEmail, history }) => {
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode)
  }, [isDarkMode])

  const currentUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const handleLogout = (): void => {
    dispatch(authStartLogout())
    history.push(homeUrl())
  }

  if (!currentUser) return null
  const { managedUsers, isAdmin, username } = currentUser
  const isManager = (managedUsers.length > 0)

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-container">
          <Link className="logo-salsita" to={homeUrl()} data-cy="homepage">
            <SalsitaLogo />
            <span>Performance review</span>
          </Link>
          {currentUserEmail && (
            <>
              {/* Desktop menu */}
              <div className="navbar-nav d-none d-md-flex">
                { (isManager || isAdmin) && (
                  <div className="dropdown toggle-on-hover">
                    <div className="btn dropdown-btn" data-toggle="dropdown" data-cy="dropdown">
                      <Questionnaires />
                      Manage
                    </div>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link to={questionnaireFormUrl} className="dropdown-item" data-cy="dropdown-questionnaires">Questionnaires</Link>
                      <Link to={questionFormUrl} className="dropdown-item" data-cy="dropdown-questions">Questions</Link>
                      <Link to={responseOptionsFormUrl} className="dropdown-item" data-cy="dropdown-response-options">Response options</Link>
                      { isAdmin && (
                        <>
                          <Link to={userManagementUrl} className="dropdown-item" data-cy="dropdown-users">Users</Link>
                          <Link to={userGroupFormUrl} className="dropdown-item" data-cy="dropdown-user-groups">User groups</Link>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <CurrentUserHeader />
                <button
                  className="btn btn-square btn-clear"
                  type="button"
                  onClick={(): void => setIsDarkMode(!isDarkMode)}
                >
                  <Theme />
                </button>
                <button
                  className="btn btn-clear"
                  type="button"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>

              {/* Mobile menu */}
              <div className="navbar-content d-md-none">
                <div className="dropdown toggle-on-hover">
                  <button className="btn btn-square btn-clear" data-toggle="dropdown" type="button">
                    <Menu />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">{username}</div>
                    <Link to={homeUrl()} className="dropdown-item">Home</Link>
                    { (isManager || isAdmin) && (
                      <>
                        <div className="dropdown-divider" />
                        <Link to={questionnaireFormUrl} className="dropdown-item">Questionnaires</Link>
                        <Link to={questionFormUrl} className="dropdown-item">Questions</Link>
                        <Link to={responseOptionsFormUrl} className="dropdown-item">Response options</Link>
                        { isAdmin && (
                          <>
                            <Link to={userManagementUrl} className="dropdown-item">Users</Link>
                            <Link to={userGroupFormUrl} className="dropdown-item">User groups</Link>
                          </>
                        )}
                      </>
                    )}
                    <div className="dropdown-divider" />
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={(): void => setIsDarkMode(!isDarkMode)}
                    >
                      Switch theme
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </header>
  )
}
