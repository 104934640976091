import React, { FC } from 'react'

// The FetchError component renders a message to indicate to the user that fetching
// data from the backend API resulted in an error.
export const FetchError: FC = () => (
  <div className="error-page">
    <h3>Sorry, the application encountered an error while loading data.</h3>
    <p>Please try refreshing the page.</p>
  </div>
)
