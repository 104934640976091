// generic types applicable to any data fetched from the API

export enum LoadStatus { NotStarted, Loading, Loaded, Error }
export interface NotStarted {
  kind: LoadStatus.NotStarted
}
export interface LoadingData {
  kind: LoadStatus.Loading
}
export interface LoadedData<TData> {
  kind: LoadStatus.Loaded
  data: TData
}
export interface ErrorData {
  kind: LoadStatus.Error
  errorMessage: string
}

export type FetchResult<TData> = NotStarted | LoadingData | LoadedData<TData> | ErrorData

export const getNotStarted = (): NotStarted => ({ kind: LoadStatus.NotStarted })
export const getLoading = (): LoadingData => ({ kind: LoadStatus.Loading })
export const getLoaded = <TData>(data: TData): LoadedData<TData> => ({ kind: LoadStatus.Loaded, data })
export const getError = (errorMessage: string): ErrorData => ({ kind: LoadStatus.Error, errorMessage })
