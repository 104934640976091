import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { LoadStatus } from 'review-app-shared/types/fetchData'

import { getReviews, getFeedbackById, getKnownFeedbackIds } from 'app/modules/review/review-selectors'
import { reviewStartGetFeedbackById } from 'app/modules/review/review-actions'
import { getUsers, getCurrentUser } from 'app/modules/user/user-selectors'
import { formatDate } from 'app/helpers/review'

import { FeedbackReportQuestion } from 'app/components/reviewer/FeedbackReportQuestion'
import { FeedbackReportAnswer } from 'app/components/reviewer/FeedbackReportAnswer'

import { Hero } from 'app/components/components-ui/hero/Hero'

interface RouteInfo {
  feedbackId: string
}

type FeedbackReportProps = RouteComponentProps<RouteInfo>

// The FeedbackReport component shows a single completed feedback for the reviewer
// that provided it.
export const FeedbackReport: FC<FeedbackReportProps> = ({ match: { params: { feedbackId } } }) => {
  const knownFeedbackIds = useSelector(getKnownFeedbackIds)
  const feedbackById = useSelector(getFeedbackById)
  const matchingFeedbackById = feedbackById[feedbackId]
  const currentUser = useSelector(getCurrentUser)
  const users = useSelector(getUsers)
  const reviews = useSelector(getReviews)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!knownFeedbackIds.includes(Number(feedbackId))) {
      dispatch(reviewStartGetFeedbackById(Number(feedbackId)))
    }
  }, [knownFeedbackIds, feedbackId, dispatch])

  if (!matchingFeedbackById) return null
  if (matchingFeedbackById.kind !== LoadStatus.Loaded) return null
  if (reviews.kind !== LoadStatus.Loaded) return null
  if (users.kind !== LoadStatus.Loaded) return null
  if (!currentUser) return null

  const feedback = matchingFeedbackById.data
  const review = reviews.data.find((eachReview) => eachReview.id === feedback.reviewId)
  const user = users.data.find((eachUser) => review && eachUser.id === review.userReviewedId)
  const reviewer = users.data.find((eachUser) => eachUser.id === feedback.reviewerUserId)
  if (!feedback || !review || !user || !reviewer) return null

  return (
    <div className="content">
      <Hero
        title={
          `${(reviewer.id === currentUser.id) ? 'You' : reviewer.username} provided feedback about ${user.username} for their review on ${formatDate(review.timeReviewed)}`
        }
      />
      <div className="main">
        {feedback.questions.map((question) => (
          <div key={question.id}>
            <FeedbackReportQuestion question={question} />
            <FeedbackReportAnswer questionId={question.id} feedback={feedback} />
          </div>
        ))}
      </div>
    </div>
  )
}
