import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import { Question, QuestionToCreate } from 'review-app-shared/types/question'

export const questionFormStartSend = createAction('question-form/START_SEND',
  (actionCallback) => (questionToCreate: QuestionToCreate) => actionCallback(questionToCreate))

export const questionFormFinishSend = createAction('question-form/FINISH_SEND',
  (actionCallback) => (question: Question) => actionCallback(question))

export const questionFormStartDelete = createAction('question-form/START_DELETE',
  (actionCallback) => (questionId: number) => actionCallback(questionId))

export const questionFormFinishDelete = createAction('question-form/FINISH_DELETE',
  (actionCallback) => (questionId: number) => actionCallback(questionId))

export const questionFormStartEdit = createAction('question-form/START_EDIT',
  (actionCallback) => (questionToEdit: Question) => actionCallback(questionToEdit))

export const questionFormFinishEdit = createAction('question-form/FINISH_EDIT',
  (actionCallback) => (questionToEdit: Question) => actionCallback(questionToEdit))

export const questionFormError = createAction('question-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type QuestionFormActions = ActionType<typeof questionFormStartSend |
  typeof questionFormFinishSend | typeof questionFormStartDelete |
  typeof questionFormFinishDelete | typeof questionFormStartEdit |
  typeof questionFormFinishEdit | typeof questionFormError>

export type QuestionFormStartSend = ActionType<typeof questionFormStartSend>
export type QuestionFormFinishSend = ActionType<typeof questionFormFinishSend>
export type QuestionFormStartDelete = ActionType<typeof questionFormStartDelete>
export type QuestionFormFinishDelete = ActionType<typeof questionFormFinishDelete>
export type QuestionFormStartEdit = ActionType<typeof questionFormStartEdit>
export type QuestionFormFinishEdit = ActionType<typeof questionFormFinishEdit>
export type QuestionFormError = ActionType<typeof questionFormError>

export const QuestionFormStartSendActionType = getType(questionFormStartSend)
export const QuestionFormFinishSendActionType = getType(questionFormFinishSend)
export const QuestionFormStartDeleteActionType = getType(questionFormStartDelete)
export const QuestionFormFinishDeleteActionType = getType(questionFormFinishDelete)
export const QuestionFormStartEditActionType = getType(questionFormStartEdit)
export const QuestionFormFinishEditActionType = getType(questionFormFinishEdit)
export const QuestionFormErrorActionType = getType(questionFormError)
