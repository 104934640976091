import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import { apiGetFeedbackForm, apiPutFeedbackForm } from 'app/modules/feedback-form/feedback-form-effects'
import {
  FeedbackNormalizer,
  FeedbackToGetNormalizerInput,
} from 'review-app-shared/normalizers/feedback-normalizer'

import {
  feedbackFormFinishFetch,
  feedbackFormFinishSend,
  feedbackFormError,
  FeedbackFormStartFetchActionType,
  FeedbackFormStartFetch,
  FeedbackFormFinishFetch,
  FeedbackFormStartSendActionType,
  FeedbackFormStartSend,
  FeedbackFormFinishSend,
  FeedbackFormError,
} from 'app/modules/feedback-form/feedback-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { completeFeedback, FeedbackState, FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { toast } from 'react-toastify'

type FetchFeedbackEffects = SelectEffect | CallEffect<AxiosResponse<FeedbackWithQuestionsAndAnswers>> |
PutEffect<FeedbackFormFinishFetch> | PutEffect<FeedbackFormError>

export function* fetchFeedbackSaga(
  action: FeedbackFormStartFetch,
): Generator<FetchFeedbackEffects, void, string | AxiosResponse<FeedbackToGetNormalizerInput>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetFeedbackForm, action.payload, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = FeedbackNormalizer.normalizeFeedbackToGet(response.data)
        yield put(feedbackFormFinishFetch(responseNormalized))
        if (completeFeedback.includes(response.data.state)) {
          toast.info('This feedback has been submitted already', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(feedbackFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(feedbackFormError(error))
    }
  }
}

type SendFeedbackEffects = SelectEffect | CallEffect<AxiosResponse<FeedbackWithQuestionsAndAnswers>> |
PutEffect<FeedbackFormFinishSend> | PutEffect<FeedbackFormError>

export function* sendFeedbackSaga(
  action: FeedbackFormStartSend,
): Generator<SendFeedbackEffects, void, string | AxiosResponse<FeedbackToGetNormalizerInput>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPutFeedbackForm, action.payload.feedbackId, action.payload.answers, token)
      if (response && typeof response !== 'string') {
        const responseNormalized = FeedbackNormalizer.normalizeFeedbackToGet(response.data)
        yield put(feedbackFormFinishSend(responseNormalized))
        let message
        switch (action.payload.answers.state) {
          case FeedbackState.Submitted:
            message = 'Feedback sent'
            break
          case FeedbackState.Dismissed:
            message = 'Feedback dismissed'
            break
          case FeedbackState.Started:
            message = "Feedback saved (don't forget to finish it)"
            break
          default:
            message = 'You have successfully sent a broken piece of data!'
            break
        }
        toast.info(message, { position: toast.POSITION.TOP_CENTER })
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(feedbackFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(feedbackFormError(error))
    }
  }
}

export function* feedbackFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(FeedbackFormStartFetchActionType, fetchFeedbackSaga)
  yield takeEvery(FeedbackFormStartSendActionType, sendFeedbackSaga)
}
