import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { FeedbackWithQuestionsAndAnswers } from 'review-app-shared/types/feedback'
import { FetchResult, LoadStatus, LoadedData } from 'review-app-shared/types/fetchData'

import {
  getManagedUserReviews,
  getFeedbackById,
  getKnownFeedbackIds,
  getIsLoadingReview,
  getAdminUserReviews,
} from 'app/modules/review/review-selectors'
import { reviewStartGetFeedbackById } from 'app/modules/review/review-actions'
import { getUsers } from 'app/modules/user/user-selectors'
import { formatDate } from 'app/helpers/review'

import { Hero } from 'app/components/components-ui/hero/Hero'

import { ReviewSummaryFeedbackStatus } from 'app/components/manager/ReviewSummaryFeedbackStatus'
import { ReviewSummaryQuestion } from 'app/components/manager/ReviewSummaryQuestion'
import { ReviewSummaryAnswers } from 'app/components/manager/ReviewSummaryAnswers'
import { ReviewSummaryField } from './ReviewSummaryField'

interface RouteInfo {
  reviewId: string
}

type ReviewSummaryProps = RouteComponentProps<RouteInfo>

// The ReviewSummary component provides a summary of a review and the feedback
// received for it to assist the manager when conducting the review.
export const ReviewSummary: FC<ReviewSummaryProps> = ({ match: { params: { reviewId } } }) => {
  const reviewIdAsNumber = Number(reviewId)
  // retrieve the data needed for the report with useSelector hooks
  const isLoadingReview = useSelector(getIsLoadingReview)
  const managedUserReviews = useSelector(getManagedUserReviews)
  const adminUserReviews = useSelector(getAdminUserReviews)
  const matchingReview = [...adminUserReviews, ...managedUserReviews].find((review) => review.id === reviewIdAsNumber)

  const usersFetch = useSelector(getUsers)
  const users = (usersFetch.kind === LoadStatus.Loaded) ? usersFetch.data : []

  const knownFeedbackIds = useSelector(getKnownFeedbackIds)
  const feedbackByIdFetch = useSelector(getFeedbackById)
  const matchingFeedbackById = Object.values(feedbackByIdFetch)
    .filter((feedbackFetch: FetchResult<FeedbackWithQuestionsAndAnswers>): feedbackFetch is LoadedData<FeedbackWithQuestionsAndAnswers> => {
      if (feedbackFetch.kind !== LoadStatus.Loaded) return false
      return feedbackFetch.data.reviewId === reviewIdAsNumber
    })
    .map((feedbackFetch) => feedbackFetch.data)

  // fetch detailed feedbackById if not already present with useEffect hook
  const dispatch = useDispatch()
  useEffect(() => {
    if (matchingReview) {
      matchingReview.linkedFeedback.forEach(({ id }) => {
        if (!knownFeedbackIds.includes(id)) {
          dispatch(reviewStartGetFeedbackById(id))
        }
      })
    }
  }, [matchingReview, knownFeedbackIds, dispatch])

  if (isLoadingReview) return null // loading message is rendered by RouteHandler
  if (!matchingReview) return <p>No matching review found. You may not be authorized to view this review summary.</p>

  const {
    timeReviewed,
    userReviewed: { username },
    questionnaire: { name, active },
    linkedFeedback,
  } = matchingReview

  return (
    <div className="content">
      <Hero
        subtitle={`You requested feedback from ${linkedFeedback.length} staff using the ${name} questionnaire${active ? '' : ' (older version)'}.`}
        title={`Review of ${username} on ${formatDate(timeReviewed)}`}
      />
      <div className="main">
        <div className="grid grid-sidebar">
          <div>
            <ReviewSummaryFeedbackStatus linkedFeedback={linkedFeedback} users={users} />
            {(matchingFeedbackById.length > 0)
              ? matchingFeedbackById[0].questions.map((question) => (
                <div key={question.id}>
                  <ReviewSummaryQuestion question={question} />
                  <ReviewSummaryAnswers
                    questionId={question.id}
                    matchingFeedbackById={matchingFeedbackById}
                    users={users}
                  />
                </div>
              ))
              : <p>No feedback to show.</p>}
          </div>

          <ReviewSummaryField reviewId={reviewIdAsNumber} />
        </div>
      </div>
    </div>
  )
}
