import React, {
  FC, ReactNode, MouseEvent, TouchEvent,
} from 'react'

interface InputDateProps {
  value?: ReactNode
  onClick?: (event: MouseEvent | TouchEvent) => void
}

export const InputDate: FC<InputDateProps> = ({ value, onClick }) => (
  <button type="button" onClick={onClick}>
    {value}
  </button>
)
