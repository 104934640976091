import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUser, getLoadedUsers } from 'app/modules/user/user-selectors'
import { getManagedUserReviews, getAdminUserReviews } from 'app/modules/review/review-selectors'

import { HomeReviewer } from 'app/components/home/HomeReviewer'
import { ReviewScheduler } from 'app/components/home/ReviewScheduler'

// The Home component is the main entry point to the application, and provides
// summary information for each of the user's roles and links to more detailed
// pages where appropriate
export const Home: FC = () => {
  const currentUser = useSelector(getCurrentUser)
  const loadedUsers = useSelector(getLoadedUsers)
  const managedUserReviews = useSelector(getManagedUserReviews)
  const adminUserReviews = useSelector(getAdminUserReviews)


  if (!currentUser) return null

  const { managedUsers, isAdmin } = currentUser
  const isManager = (managedUsers.length > 0)

  return (
    <div className="content">
      <div className="main">
        <div className="grid">
          <div><HomeReviewer /></div>
          {(isManager || isAdmin) && (
            <div>
              <ReviewScheduler
                users={isAdmin ? loadedUsers : managedUsers}
                userReviews={isAdmin ? adminUserReviews : managedUserReviews}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
