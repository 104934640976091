import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { UserWithDetailsPopulated, ReviewPopulated } from 'review-app-shared/types/selectors'

import { reviewSummaryUrl, createReviewFormUrl, updateReviewFormUrl } from 'app/helpers/urlCreators'
import { formatDate, formatDateTime } from 'app/helpers/review'
import { UserProfileLink } from 'app/components/user/UserProfileLink'

interface TableRowAdvancedReviewSchedulerListProps {
  user: UserWithDetailsPopulated
  reviews: ReviewPopulated[]
}

// This component renders a data row for tables listing user details for their manager
export const TableRowAdvancedReviewSchedulerList: FC<TableRowAdvancedReviewSchedulerListProps> = ({ user, reviews }) => {
  const {
    username,
    email,
    userGroups,
    active,
    id,
  } = user
  const pastReviews: ReviewPopulated[] = []
  const futureReviews: ReviewPopulated[] = []
  const currentTime = Date.now()
  reviews.forEach((review) => {
    const reviewTime = new Date(review.timeReviewed).getTime()
    if (reviewTime > currentTime) {
      futureReviews.push(review)
    } else {
      pastReviews.push(review)
    }
  })

  if (!active) {
    return (
      <tr>
        <td data-title="Name" className="inactive-user">{`${user.username} (inactive)`}</td>
        <td data-title="Email address">{email}</td>
        <td data-title="User groups">
          {(userGroups.length === 0)
            ? 'none'
            : (
              <ul className="table-list">
                {userGroups.map((userGroup) => <li key={userGroup.id}>{userGroup.name}</li>)}
              </ul>
            )}
        </td>
        <td data-title="Past reviews">
          {(pastReviews.length === 0)
            ? 'none'
            : (
              <ul className="table-dates">
                {pastReviews.map(({ id: reviewId, timeReviewed }) => (
                  <li key={reviewId}>
                    <Link to={reviewSummaryUrl(reviewId)}>{formatDate(timeReviewed)}</Link>
                  </li>
                ))}
              </ul>
            )}
        </td>
        <td data-title="Future reviews" />
      </tr>
    )
  }

  return (
    <tr>
      <td data-title="Name">
        <UserProfileLink userId={id} userName={username} />
      </td>
      <td data-title="Email address"><a href={`mailto:${email}`}>{email}</a></td>
      <td data-title="User groups">
        {(userGroups.length === 0)
          ? 'none'
          : (
            <ul className="table-list">
              {userGroups.map((userGroup) => <li key={userGroup.id}>{userGroup.name}</li>)}
            </ul>
          )}
      </td>
      <td data-title="Past reviews">
        {(pastReviews.length === 0)
          ? 'none'
          : (
            <ul className="table-dates">
              {pastReviews.map(({ id: reviewId, timeReviewed }) => (
                <li key={reviewId}>
                  <Link to={reviewSummaryUrl(reviewId)}>{formatDate(timeReviewed)}</Link>
                </li>
              ))}
            </ul>
          )}
      </td>
      <td data-title="Future reviews">
        {(futureReviews.length === 0)
          ? <Link to={createReviewFormUrl(user.id)}>none scheduled</Link>
          : (
            <ul>
              {futureReviews.map(({ id: reviewId, timeReviewed }) => (
                <li key={reviewId}>
                  <Link to={updateReviewFormUrl(reviewId)}>{formatDateTime(timeReviewed)}</Link>
                </li>
              ))}
            </ul>
          )}
      </td>
    </tr>
  )
}
