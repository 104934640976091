import React, { FC } from 'react'

// This component renders the header row for a table listing users with basic details.
export const TableHeaderAdvancedReviewSchedulerList: FC = () => (
  <tr>
    <th style={{ width: '20%' }}>Name</th>
    <th style={{ width: '20%' }}>Email address</th>
    <th style={{ width: '20%' }}>User Groups</th>
    <th style={{ width: '20%' }}>Past Reviews</th>
    <th style={{ width: '20%' }}>Future Reviews</th>
  </tr>
)
