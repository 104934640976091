import {
  QuestionActions,
  QuestionStartGetQuestionsActionType,
  QuestionFinishGetQuestionsActionType,
  QuestionErrorGetQuestionsActionType,
  QuestionStartGetResponseOptionsActionType,
  QuestionFinishGetResponseOptionsActionType,
  QuestionErrorGetResponseOptionsActionType,
} from 'app/modules/question/question-actions'
import {
  QuestionFormFinishSend,
  QuestionFormFinishDelete,
  QuestionFormFinishSendActionType,
  QuestionFormFinishDeleteActionType,
  QuestionFormFinishEditActionType,
  QuestionFormFinishEdit,
} from 'app/modules/question-form/question-form-actions'
import {
  ResponseOptionsFormFinishSend,
  ResponseOptionsFormFinishDelete,
  ResponseOptionsFormFinishSendActionType,
  ResponseOptionsFormFinishDeleteActionType,
} from 'app/modules/response-options-form/response-options-form-actions'

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

import {
  FetchResult,
  getNotStarted,
  getLoading,
  getLoaded,
  getError,
  LoadStatus,
} from 'review-app-shared/types/fetchData'

export type QuestionState = {
  questions: FetchResult<Question[]>
  responseOptions: FetchResult<ResponseOptions[]>
}

const initialState: QuestionState = {
  questions: getNotStarted(),
  responseOptions: getNotStarted(),
}

type AllowedActionTypes = QuestionActions | ResponseOptionsFormFinishSend |
ResponseOptionsFormFinishDelete | QuestionFormFinishSend | QuestionFormFinishDelete |
QuestionFormFinishEdit

export const questionReducer = (
  state: QuestionState = initialState,
  action: AllowedActionTypes,
): QuestionState => {
  switch (action.type) {
    case QuestionStartGetQuestionsActionType:
      return {
        ...state,
        questions: getLoading(),
      }
    case QuestionFinishGetQuestionsActionType:
      return {
        ...state,
        questions: getLoaded(action.payload),
      }
    case QuestionErrorGetQuestionsActionType:
      return {
        ...state,
        questions: getError(action.payload.message),
      }
    case QuestionStartGetResponseOptionsActionType:
      return {
        ...state,
        responseOptions: getLoading(),
      }
    case QuestionFinishGetResponseOptionsActionType:
      return {
        ...state,
        responseOptions: getLoaded(action.payload),
      }
    case QuestionErrorGetResponseOptionsActionType:
      return {
        ...state,
        responseOptions: getError(action.payload.message),
      }
    case QuestionFormFinishSendActionType: {
      if (state.questions.kind !== LoadStatus.Loaded) return state
      const newQuestions = [...state.questions.data, action.payload]
      return {
        ...state,
        questions: getLoaded(newQuestions),
      }
    }
    case QuestionFormFinishEditActionType: {
      if (state.questions.kind !== LoadStatus.Loaded) return state
      const updaedQuestions = [
        ...state.questions.data.filter((question) => question.id !== action.payload.id),
        action.payload,
      ]
      return {
        ...state,
        questions: getLoaded(updaedQuestions),
      }
    }
    case QuestionFormFinishDeleteActionType: {
      if (state.questions.kind !== LoadStatus.Loaded) return state
      const newQuestions = [...state.questions.data].filter((question) => question.id !== action.payload)
      return {
        ...state,
        questions: getLoaded(newQuestions),
      }
    }
    case ResponseOptionsFormFinishSendActionType: {
      if (state.responseOptions.kind !== LoadStatus.Loaded) return state
      const newResponseOptions = [...state.responseOptions.data, action.payload]
      return {
        ...state,
        responseOptions: getLoaded(newResponseOptions),
      }
    }
    case ResponseOptionsFormFinishDeleteActionType: {
      if (state.responseOptions.kind !== LoadStatus.Loaded) return state
      const newResponseOptions = [...state.responseOptions.data].filter((option) => option.id !== action.payload)
      return {
        ...state,
        responseOptions: getLoaded(newResponseOptions),
      }
    }
    default:
      return state
  }
}
