
type KeyFromValue<V, T extends Record<PropertyKey, PropertyKey>> = {
  [K in keyof T]: V extends T[K] ? K : never
}[keyof T]

type Invert<T extends Record<PropertyKey, PropertyKey>> = {
  [V in T[keyof T]]: KeyFromValue<V, T>
}

declare function invert<
    T extends Record<PropertyKey, PropertyKey>
>(obj: T): Invert<T>

export const inverseObject = <T extends Record<PropertyKey, PropertyKey>>(ob: T): Invert<T> => (Object.entries(ob)).reduce<Invert<typeof ob>>((acc, [key, value]) => ({ ...acc, [value]: key }), {} as unknown as Invert<typeof ob>)

export const valueTypeCheckFactory = <T, >(arr: readonly T[]) => (value: unknown): value is typeof arr[number] => arr.includes(value as typeof arr[number])

const isString = (prop: unknown): prop is string => typeof prop === 'string'
export const isInMappingFactory = <U, T extends PropertyKey>(obj: { [state in T]: U }) => (prop: unknown): prop is keyof typeof obj => isString(prop) && prop in obj
