import { DefaultServerEnv } from './DefaultServerEnv'

// TODO IMO this should not be in the shared package because "window.location" does not make sense in the context of Node app
const defaultLocalBaseUrl = `${window.location.protocol}//${window.location.hostname}:${DefaultServerEnv.port}/api`

export const DefaultClientEnv = {
  serverBaseUrl: defaultLocalBaseUrl,
  googleRedirect: DefaultServerEnv.googleRedirect,
  timeout: 5000,
}
