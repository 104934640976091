import {
  all,
  call,
  put,
  takeEvery,
  AllEffect,
  CallEffect,
  PutEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import { UserWithDetails } from 'review-app-shared/types/user'
import { apiLoginGoogle } from 'app/modules/auth/auth-effects'
import {
  authFinishLogin,
  authFailLogin,
  AuthFailLogin,
  AuthStartLoginGoogleActionType,
  AuthStartLoginGoogle,
  AuthFinishLoginActionType,
  AuthFinishLogin,
  authFinishLogout,
  AuthStartLogoutActionType,
  AuthFinishLogout,
} from 'app/modules/auth/auth-actions'
import { AuthSuccess } from 'review-app-shared/types/auth'
import {
  userStartGetUsers,
  userStartGetUserGroups,
  UserStartGetUsers,
  UserStartGetUserGroups,
} from 'app/modules/user/user-actions'
import {
  reviewStartGetFeedback,
  reviewStartGetReviews,
  reviewStartGetQuestionnaires,
  ReviewStartGetFeedback,
  ReviewStartGetReviews,
  ReviewStartGetQuestionnaires,
} from 'app/modules/review/review-actions'

const LOCAL_STORAGE_USER_KEY = 'performance-review-user'

const initialLoadingActions = [
  put(userStartGetUsers()),
  put(userStartGetUserGroups()),
  put(reviewStartGetFeedback()),
  put(reviewStartGetReviews()),
  put(reviewStartGetQuestionnaires()),
]

type LoginGoogleEffects = CallEffect <AxiosResponse<AuthSuccess>> | PutEffect<AuthFinishLogin> | PutEffect<AuthFailLogin>

export function* loginGoogleSaga(action: AuthStartLoginGoogle): Generator<LoginGoogleEffects, void, AxiosResponse<AuthSuccess>> {
  try {
    const response = yield call(apiLoginGoogle, action.payload)
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(response.data))
    yield put(authFinishLogin(response.data))
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(authFailLogin(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(authFailLogin(error))
    }
  }
}

type LoginLocalEffects = CallEffect<AxiosResponse<UserWithDetails[]>> | PutEffect<AuthFinishLogin> | PutEffect<AuthFailLogin>

type InitialLoadingPutEffects = PutEffect<ReviewStartGetFeedback> | PutEffect<ReviewStartGetReviews> |
PutEffect<ReviewStartGetQuestionnaires> | PutEffect<UserStartGetUsers> | PutEffect<UserStartGetUserGroups>

export function* finishLoginSaga(): IterableIterator<AllEffect<InitialLoadingPutEffects>> {
  try {
    yield all(initialLoadingActions)
  } catch (error) {
    console.error(error)
  }
}

export function* logoutSaga(): IterableIterator<PutEffect<AuthFinishLogout>> {
  try {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
    yield put(authFinishLogout())
  } catch (error) {
    console.error(error)
  }
}

const parseJsonAsAuthData = (data: string): AuthSuccess | null => {
  try {
    const authData = JSON.parse(data)
    return authData
  } catch (error) {
    console.error(error)
    // expect SyntaxError if data is not a JSON representation of AuthData
    return null // continue to listen for login and logout events
  }
}

export function* authSaga(): Generator<ForkEffect<never> | PutEffect<AuthFinishLogin>, void, unknown> {
  try {
    yield takeEvery(AuthFinishLoginActionType, finishLoginSaga)
    const persistentLogin = localStorage.getItem(LOCAL_STORAGE_USER_KEY)
    if (persistentLogin) {
      const authData = parseJsonAsAuthData(persistentLogin)
      if (authData) {
        yield put(authFinishLogin(authData))
      }
    }
    yield takeEvery(AuthStartLoginGoogleActionType, loginGoogleSaga)
    yield takeEvery(AuthStartLogoutActionType, logoutSaga)
  } catch (error) {
    console.error(error)
  }
}
