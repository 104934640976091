import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { QuestionnaireToCreate, QuestionnaireUpdateData } from 'review-app-shared/types/questionnaire'

import { QuestionnaireForm } from 'app/components/forms/QuestionnaireForm'

import { getLoadedReviews, getLoadedQuestionnaires } from 'app/modules/review/review-selectors'
import {
  getIsErrorQuestion,
  getIsLoadingQuestion,
  getIsNotStartedQuestions,
  getIsNotStartedResponseOptions,
  getLoadedQuestions,
  getLoadedResponseOptions,
} from 'app/modules/question/question-selectors'
import { questionStartGetQuestions, questionStartGetResponseOptions } from 'app/modules/question/question-actions'

import {
  questionnaireFormCreateStartSend,
  questionnaireFormUpdateStartSend,
  questionnaireFormStartDelete,
} from 'app/modules/questionnaire-form/questionnaire-form-actions'

// The QuestionnaireFormWrapper component enables an authorised user to create a new questionnaire,
// update an existing questionnaire or delete an existing (unused) questionnaire.
export const QuestionnaireFormWrapper: FC = () => {
  const reviews = useSelector(getLoadedReviews)
  const questionnaires = useSelector(getLoadedQuestionnaires).filter((questionnaire) => questionnaire.active)
  const isErrorQuestion = useSelector(getIsErrorQuestion)
  const isLoadingQuestion = useSelector(getIsLoadingQuestion)
  const isNotStartedQuestions = useSelector(getIsNotStartedQuestions)
  const isNotStartedResponseOptions = useSelector(getIsNotStartedResponseOptions)
  const questions = useSelector(getLoadedQuestions)
  const responseOptions = useSelector(getLoadedResponseOptions)
  const dispatch = useDispatch()

  if (isNotStartedQuestions) dispatch(questionStartGetQuestions())
  if (isNotStartedResponseOptions) dispatch(questionStartGetResponseOptions())
  if (isErrorQuestion || isLoadingQuestion || isNotStartedQuestions || isNotStartedResponseOptions) return null

  const createQuestionnaire = (questionnaireToCreate: QuestionnaireToCreate): void => {
    dispatch(questionnaireFormCreateStartSend(questionnaireToCreate))
  }

  const updateQuestionnaire = (questionnaireId: number, questionnaireUpdateData: QuestionnaireUpdateData): void => {
    dispatch(questionnaireFormUpdateStartSend(questionnaireId, questionnaireUpdateData))
  }

  const deleteQuestionnaire = (questionnaireId: number): void => {
    dispatch(questionnaireFormStartDelete(questionnaireId))
  }

  const questionnaireIdsInUse = new Set<number>()
  reviews.forEach((review) => questionnaireIdsInUse.add(review.questionnaireId))

  return (
    <QuestionnaireForm
      questionnaireIdsInUse={questionnaireIdsInUse}
      questionnaires={questionnaires}
      questions={questions}
      responseOptions={responseOptions}
      createQuestionnaire={createQuestionnaire}
      updateQuestionnaire={updateQuestionnaire}
      deleteQuestionnaire={deleteQuestionnaire}
    />
  )
}
