import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiPostResponseOptions,
  apiDeleteResponseOptions,
} from 'app/modules/response-options-form/response-options-form-effects'
import { ResponseOptions, ResponseOptionsToCreate } from 'review-app-shared/types/response-options'

import {
  responseOptionsFormFinishSend,
  responseOptionsFormFinishDelete,
  responseOptionsFormError,
  ResponseOptionsFormStartSendActionType,
  ResponseOptionsFormStartSend,
  ResponseOptionsFormFinishSend,
  ResponseOptionsFormStartDeleteActionType,
  ResponseOptionsFormStartDelete,
  ResponseOptionsFormFinishDelete,
  ResponseOptionsFormError,
  ResponseOptionsFormFinishSendActionType,
  ResponseOptionsFormFinishDeleteActionType,
  ResponseOptionsFormErrorActionType,
} from 'app/modules/response-options-form/response-options-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getResponseOptionsFormState } from './response-options-form-selectors'

type CreateResponseOptionsEffects = SelectEffect | CallEffect<AxiosResponse<ResponseOptionsToCreate>> |
PutEffect<ResponseOptionsFormFinishSend> | PutEffect<ResponseOptionsFormError>

export function* createResponseOptionsSaga(
  action: ResponseOptionsFormStartSend,
): Generator<CreateResponseOptionsEffects, void, string | AxiosResponse<ResponseOptions>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostResponseOptions, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(responseOptionsFormFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(responseOptionsFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(responseOptionsFormError(error))
    }
  }
}

type DeleteResponseOptionsEffects = SelectEffect | CallEffect<AxiosResponse<void>> |
PutEffect<ResponseOptionsFormFinishDelete> | PutEffect<ResponseOptionsFormError>

export function* deleteResponseOptionsSaga(
  action: ResponseOptionsFormStartDelete,
): Generator<DeleteResponseOptionsEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteResponseOptions, action.payload, token)
      if (response) yield put(responseOptionsFormFinishDelete(reviewId))
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(responseOptionsFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(responseOptionsFormError(error))
    }
  }
}

export function* toastSaved(): Generator<SelectEffect, void, ReturnType<typeof getResponseOptionsFormState>> {
  const formState: ReturnType<typeof getResponseOptionsFormState> = yield select(getResponseOptionsFormState)
  if (formState.kind === FormStatus.Saved) {
    toast.info(`You have successfully created ${formState.data?.name}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastDelete(): Generator<CallEffect<string | number>> {
  yield call(toast.info, 'You have successfully deleted the response options.', {
    position: toast.POSITION.TOP_CENTER,
  })
}

export function* toastError(): Generator<SelectEffect, void, ReturnType<typeof getResponseOptionsFormState>> {
  const formState: ReturnType<typeof getResponseOptionsFormState> = yield select(getResponseOptionsFormState)
  if (formState.kind === FormStatus.Error) {
    toast.error(formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* responseOptionsFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ResponseOptionsFormStartSendActionType, createResponseOptionsSaga)
  yield takeEvery(ResponseOptionsFormStartDeleteActionType, deleteResponseOptionsSaga)
  yield takeEvery(ResponseOptionsFormFinishSendActionType, toastSaved)
  yield takeEvery(ResponseOptionsFormFinishDeleteActionType, toastDelete)
  yield takeEvery(ResponseOptionsFormErrorActionType, toastError)
}
