import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, { ValueType } from 'react-select'

import { weekDays } from 'review-app-shared/helpers/date'

import { ReviewWithFeedback, ReviewUpdateData } from 'review-app-shared/types/review'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { ReviewFormUserGroupList } from 'app/components/forms/ReviewFormUserGroupList'
import { ReviewFormPreviousReviewList } from 'app/components/forms/ReviewFormPreviousReviewList'

import { findReviewsPreviousNext } from 'app/helpers/review'
import { getLoadedUsers, getLoadedActiveUsers, getLoadedUserGroups } from 'app/modules/user/user-selectors'
import { getLoadedQuestionnaires, getManagedUserReviews } from 'app/modules/review/review-selectors'
import { homeUrl, createReviewFormUrl } from 'app/helpers/urlCreators'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { InputDate } from 'app/components/components-ui/inputs/input-date'
import { Hero } from 'app/components/components-ui/hero/Hero'

import { ReviewFormForecastReviewList } from './ReviewFormForecastReviewList'
import { ReviewFormTimesheetReviewList } from './ReviewFormTimesheetReviewList'

interface UpdateReviewFormProps {
  reviewData: ReviewWithFeedback
  updateReview: (reviewUpdateData: ReviewUpdateData) => void
  deleteReview: () => void
}

// The UpdateReviewForm component enables managers to change the time or reviewers for an existing review
export const UpdateReviewForm: FC<UpdateReviewFormProps> = ({ reviewData, updateReview, deleteReview }) => {
  const users = useSelector(getLoadedUsers)
  const activeUsers = useSelector(getLoadedActiveUsers)
  const userGroups = useSelector(getLoadedUserGroups)
  const questionnaires = useSelector(getLoadedQuestionnaires)
  const managedUserReviews = useSelector(getManagedUserReviews)

  const usersReviewingOptions = users
    .filter((user) => user.id !== reviewData.userReviewedId)
    .map((user) => ({
      value: user.id,
      label: (user.active) ? user.username : `${user.username} (inactive)`,
    }))
  const existingUsersReviewingStartedIdsForUpdate = reviewData.feedback
    .filter((feedback) => feedback.dateSubmitted)
    .map((feedback) => feedback.reviewerUserId)
  const existingUsersReviewingStarted = users.filter((user) => existingUsersReviewingStartedIdsForUpdate.includes(user.id))
  const existingUsersReviewingNotStartedIdsForUpdate = reviewData.feedback
    .filter((feedback) => !feedback.dateSubmitted)
    .map((feedback) => feedback.reviewerUserId)
  const existingUsersReviewingOptions: SelectOption[] = []
  existingUsersReviewingNotStartedIdsForUpdate.forEach((userId) => {
    const userToAdd = usersReviewingOptions.find((user) => user.value === userId)
    if (userToAdd) existingUsersReviewingOptions.push(userToAdd)
  })
  const [timeReviewed, setTimeReviewed] = useState(reviewData.timeReviewed)
  const [usersReviewing, setUsersReviewing] = useState(existingUsersReviewingOptions)
  const [cancel, setCancel] = useState(false)
  const [errorUsersReviewing, setErrorUsersReviewing] = useState<string | null>(null)

  const handleChangeDate = (newTimeReviewed: Date): void => setTimeReviewed(newTimeReviewed)

  const handleChangeUsersReviewing = (selected: SelectOption[]): void => {
    if (!selected) {
      setUsersReviewing([])
    } else {
      setUsersReviewing(selected)
      if (selected.length > 0) setErrorUsersReviewing(null)
    }
  }

  // outside select control
  const handleAddUserReviewing = (userId: number): void => {
    const userToAdd = usersReviewingOptions.find((user) => user.value === userId)
    if (userToAdd && !usersReviewing.includes(userToAdd)) {
      setUsersReviewing([...usersReviewing, userToAdd])
    }
  }

  const handleAddUsersReviewing = (userIds: number[]): void => {
    const usersToAdd: SelectOption[] = []
    userIds.forEach((userId) => {
      const userToAdd = usersReviewingOptions.find((user) => user.value === userId)
      const currentReviewerUserIds = usersReviewing.map((userReviewing) => userReviewing.value)
      if (userToAdd && !currentReviewerUserIds.includes(userToAdd.value)) usersToAdd.push(userToAdd)
    })
    if (usersToAdd.length > 0) setUsersReviewing([...usersReviewing, ...usersToAdd])
  }

  // outside select control
  const handleRemoveUserReviewing = (userId: number): void => {
    const newUsersReviewing = usersReviewing
      .filter((userReviewing) => userReviewing.value !== userId)
    setUsersReviewing(newUsersReviewing)
  }

  const handleRemoveUsersReviewing = (userIds: number[]): void => {
    const newUsersReviewing = usersReviewing
      .filter((userReviewing) => !userIds.includes(userReviewing.value))
    setUsersReviewing(newUsersReviewing)
  }

  const validateForm = (): boolean => {
    if (usersReviewing.length === 0 && existingUsersReviewingStarted.length === 0) {
      setErrorUsersReviewing('Feedback not requested from anyone.')
      return false
    }
    return true
  }

  const handleSubmit = (event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>): void => {
    event.preventDefault()
    if (validateForm()) {
      const newUsersReviewing = usersReviewing.map((userReviewing) => userReviewing.value)
      const usersToAdd = newUsersReviewing.filter((userId) => !existingUsersReviewingNotStartedIdsForUpdate.includes(userId))
      const usersToRemove = existingUsersReviewingNotStartedIdsForUpdate.filter((userId) => !newUsersReviewing.includes(userId))
      const dataToSubmit = new ReviewUpdateData(timeReviewed, usersToAdd, usersToRemove)
      updateReview(dataToSubmit)
    }
  }

  const handleDeleteButtonClick = (): void => deleteReview()

  const handleCancelButtonClick = (): void => setCancel(true)

  if (cancel) return <Redirect to={homeUrl()} />

  const userReviewedDetails = users.find((user) => user.id === reviewData.userReviewedId)
  if (!userReviewedDetails) return <p className="form-error">Error: the subject of this review is not known.</p>

  const userReviewedUserGroups = userReviewedDetails.userGroups
    .map((userGroupId) => userGroups.find((userGroup) => userGroup.id === userGroupId))
  const selectedUserIds = usersReviewing.map((userReviewing) => userReviewing.value)
  const userReviewedAllReviews = managedUserReviews
    .filter((review) => review.userReviewedId === reviewData.userReviewedId)
  const { previous } = findReviewsPreviousNext(userReviewedAllReviews)
  const questionnaire = questionnaires.find((eachQuestionnaire) => eachQuestionnaire.id === reviewData.questionnaireId)

  return (
    <div className="content has-buttons">
      <Hero
        subtitle={!userReviewedDetails.active ? 'Warning: the subject of this review is no longer an active user!' : ''}
        title={`Update review of ${userReviewedDetails.username}, using questionnaire ${questionnaire && questionnaire.name}`}
        tooltip={(
          <p>
            {'Note that the user and questionnaire can not be changed. If these are incorrect, you need to '}
            <Link to={createReviewFormUrl()}>create a new review</Link>
            . If nobody has yet started to provide feedback, this review can be deleted.
          </p>
        )}
      />
      <div className="main">
        <div className="grid">
          <form>
            <div>
              <label className="form-date" htmlFor="timeReviewed">
                <p>Date of review:</p>
                <DatePicker
                  id="timeReviewed"
                  selected={timeReviewed}
                  onChange={handleChangeDate}
                  customInput={<InputDate />}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeCaption="time"
                  dateFormat="d/M/yyyy H:mm"
                  minDate={new Date()}
                  fixedHeight
                  filterDate={weekDays}
                />
              </label>
            </div>
            <div>
              {existingUsersReviewingStarted.length > 0 && (
                <div>
                  <p>The following users have already started to provide feedback and can not be removed:</p>
                  <ul>
                    {existingUsersReviewingStarted.map((user) => (
                      <li key={user.id}>{user.username}</li>
                    ))}
                  </ul>
                </div>
              )}
              <label htmlFor="usersReviewing">
                <p>Request feedback from:</p>
                <Select
                  classNamePrefix="select"
                  className="select-box"
                  id="usersReviewing"
                  isMulti
                  onChange={(selected: ValueType<SelectOption>): void => handleChangeUsersReviewing((selected as SelectOption[]))}
                  value={usersReviewing}
                  options={usersReviewingOptions}
                />
              </label>
              {errorUsersReviewing && (
                <p className="form-error">{`Error: ${errorUsersReviewing}`}</p>
              )}
            </div>
            <ButtonGroup
              buttons={[
                {
                  className: 'btn btn-danger',
                  label: 'Delete review',
                  onClick: handleDeleteButtonClick,
                  type: 'button',
                },
                {
                  className: 'btn btn-clear',
                  label: 'Cancel',
                  onClick: handleCancelButtonClick,
                  type: 'button',
                },
                {
                  className: 'btn btn-primary',
                  label: 'Update review',
                  onClick: handleSubmit,
                  type: 'button',
                },
              ]}
            />
          </form>
          <div className="card">
            <p>You can also select (or deselect) staff to request feedback from using the following lists.</p>
            <div>
              <ReviewFormTimesheetReviewList
                userToBeReviewedId={reviewData.userReviewedId}
                selectedUserIds={selectedUserIds}
                lockedUserIds={existingUsersReviewingStartedIdsForUpdate}
                handleAddUserReviewing={handleAddUserReviewing}
                handleAddUsersReviewing={handleAddUsersReviewing}
                handleRemoveUserReviewing={handleRemoveUserReviewing}
                handleRemoveUsersReviewing={handleRemoveUsersReviewing}
              />
              <ReviewFormForecastReviewList
                userToBeReviewedId={reviewData.userReviewedId}
                selectedUserIds={selectedUserIds}
                lockedUserIds={existingUsersReviewingStartedIdsForUpdate}
                handleAddUserReviewing={handleAddUserReviewing}
                handleAddUsersReviewing={handleAddUsersReviewing}
                handleRemoveUserReviewing={handleRemoveUserReviewing}
                handleRemoveUsersReviewing={handleRemoveUsersReviewing}
              />
              {previous && (
                <ReviewFormPreviousReviewList
                  review={previous}
                  users={activeUsers}
                  selectedUserIds={selectedUserIds}
                  lockedUserIds={existingUsersReviewingStartedIdsForUpdate}
                  handleAddUserReviewing={handleAddUserReviewing}
                  handleAddUsersReviewing={handleAddUsersReviewing}
                  handleRemoveUserReviewing={handleRemoveUserReviewing}
                  handleRemoveUsersReviewing={handleRemoveUsersReviewing}
                />
              )}
              {(userReviewedUserGroups && userReviewedUserGroups.map((userGroup) => userGroup && (
                <ReviewFormUserGroupList
                  key={userGroup.id}
                  userGroup={userGroup}
                  userReviewedId={reviewData.userReviewedId}
                  users={activeUsers}
                  selectedUserIds={selectedUserIds}
                  lockedUserIds={existingUsersReviewingStartedIdsForUpdate}
                  handleAddUserReviewing={handleAddUserReviewing}
                  handleAddUsersReviewing={handleAddUsersReviewing}
                  handleRemoveUserReviewing={handleRemoveUserReviewing}
                  handleRemoveUsersReviewing={handleRemoveUsersReviewing}
                />
              )))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
