import {
  TimesheetActions,
  TimesheetStartGetTimesheetsActionType,
  TimesheetFinishGetTimesheetsActionType,
  TimesheetErrorGetTimesheetsActionType,
} from 'app/modules/timesheet/timesheet-actions'
import { AuthFinishLogout, AuthFinishLogoutActionType } from 'app/modules/auth/auth-actions'

import {
  FetchResult,
  getLoading,
  getLoaded,
  getError,
} from 'review-app-shared/types/fetchData'
import { CollaboratorWithDuration } from 'review-app-shared/types/timesheet'

export type TimesheetState = {
  timesheetCollaborators: FetchResult<CollaboratorWithDuration[]>
}

const initialState = {
  timesheetCollaborators: getLoading(),
}

type AllowedActionTypes = TimesheetActions | AuthFinishLogout

export const timesheetReducer = (state: TimesheetState = initialState, action: AllowedActionTypes): TimesheetState => {
  switch (action.type) {
    case AuthFinishLogoutActionType:
      return initialState
    case TimesheetStartGetTimesheetsActionType:
      return {
        ...state,
        timesheetCollaborators: getLoading(),
      }
    case TimesheetFinishGetTimesheetsActionType:
      return {
        ...state,
        timesheetCollaborators: getLoaded(action.payload),
      }
    case TimesheetErrorGetTimesheetsActionType:
      return {
        ...state,
        timesheetCollaborators: getError(action.payload.message),
      }
    default:
      return state
  }
}
