import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import {
  UserToCreateOrUpdate,
  UserWithDetails,
} from 'review-app-shared/types/user'

export const userFormCreateStartSend = createAction('user-form/CREATE_START_SEND',
  (actionCallback) => (userToCreate: UserToCreateOrUpdate) => actionCallback(userToCreate))

export const userFormCreateFinishSend = createAction('user-form/CREATE_FINISH_SEND',
  (actionCallback) => (user: UserWithDetails) => actionCallback(user))

export const userFormUpdateStartSend = createAction('user-form/UPDATE_START_SEND',
  (actionCallback) => (
    userId: number,
    userUpdateData: UserToCreateOrUpdate,
  ) => actionCallback({ userId, userUpdateData }))

export const userFormUpdateFinishSend = createAction('user-form/UPDATE_FINISH_SEND',
  (actionCallback) => (user: UserWithDetails) => actionCallback(user))

export const userFormStartDelete = createAction('user-form/START_DELETE',
  (actionCallback) => (userId: number) => actionCallback(userId))

export const userFormFinishDelete = createAction('user-form/FINISH_DELETE',
  (actionCallback) => (userId: number) => actionCallback(userId))

export const userFormError = createAction('user-form/ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type UserFormActions = ActionType<typeof userFormCreateStartSend |
  typeof userFormCreateFinishSend | typeof userFormUpdateStartSend |
  typeof userFormUpdateFinishSend | typeof userFormStartDelete |
  typeof userFormFinishDelete | typeof userFormError>

export type UserFormCreateStartSend = ActionType<typeof userFormCreateStartSend>
export type UserFormCreateFinishSend = ActionType<typeof userFormCreateFinishSend>
export type UserFormUpdateStartSend = ActionType<typeof userFormUpdateStartSend>
export type UserFormUpdateFinishSend = ActionType<typeof userFormUpdateFinishSend>
export type UserFormStartDelete = ActionType<typeof userFormStartDelete>
export type UserFormFinishDelete = ActionType<typeof userFormFinishDelete>
export type UserFormError = ActionType<typeof userFormError>

export const UserFormCreateStartSendActionType = getType(userFormCreateStartSend)
export const UserFormCreateFinishSendActionType = getType(userFormCreateFinishSend)
export const UserFormUpdateStartSendActionType = getType(userFormUpdateStartSend)
export const UserFormUpdateFinishSendActionType = getType(userFormUpdateFinishSend)
export const UserFormStartDeleteActionType = getType(userFormStartDelete)
export const UserFormFinishDeleteActionType = getType(userFormFinishDelete)
export const UserFormErrorActionType = getType(userFormError)
