import { Option } from './option'

interface IResponseOptions {
  readonly name: string
  readonly options: Array<Option>
}

export class ResponseOptions implements IResponseOptions {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly options: Array<Option>,
  ) {
  }
}

export class ResponseOptionsToCreate implements IResponseOptions {
  constructor(
    readonly name: string,
    readonly options: Array<Option>,
  ) {

  }
}
