import React, { FC } from 'react'
import Select, { ValueType } from 'react-select'

import { QuestionnaireWithQuestionIds } from 'review-app-shared/types/questionnaire'
import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { QuestionDetails } from 'app/components/forms/QuestionDetails'

import { QuestionWithDetails, getQuestionWithDetails } from 'app/helpers/questionManagement'

import { Accordion } from 'app/components/components-ui/accordion/accordion'
import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface QuestionnaireFormViewProps {
  handleCreateBasedOnCurrentButtonClick: () => void
  handleCreateButtonClick: () => void
  handleDeleteButtonClick: () => void
  handleSelectQuestionnaire: (selected: SelectOption) => void
  handleUpdateButtonClick: () => void
  questionnaireIdsInUse: Set<number>
  questionnairesForSelect: SelectOption[]
  questionnaires: QuestionnaireWithQuestionIds[]
  questions: Question[]
  responseOptions: ResponseOptions[]
  selectedQuestionnaire: SelectOption | null
}

export const QuestionnaireFormView: FC<QuestionnaireFormViewProps> = ({
  handleCreateBasedOnCurrentButtonClick,
  handleCreateButtonClick,
  handleDeleteButtonClick,
  handleSelectQuestionnaire,
  handleUpdateButtonClick,
  questionnaireIdsInUse,
  questionnairesForSelect,
  questionnaires,
  questions,
  responseOptions,
  selectedQuestionnaire,
}) => {
  const selectedQuestionnaireDetail = selectedQuestionnaire && questionnaires
    .find((questionnaire) => questionnaire.id === selectedQuestionnaire.value)
  const selectedQuestionnaireQuestions = selectedQuestionnaireDetail && selectedQuestionnaireDetail.questionIds
    .reduce((output: QuestionWithDetails[], questionId: number) => {
      const matchingQuestionWithDetails = getQuestionWithDetails(questionId, questions, responseOptions)
      if (!matchingQuestionWithDetails) return output
      return [...output, matchingQuestionWithDetails]
    }, [])

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title="Questionnaires"
        tooltip="Select a questionnaire by name from the list to view it, update it or create a new one."
      />
      <div className="main">
        <Select
          classNamePrefix="select"
          className="select-box"
          onChange={(selected: ValueType<SelectOption>): void => handleSelectQuestionnaire((selected as SelectOption))}
          value={selectedQuestionnaire}
          options={questionnairesForSelect}
          placeholder="Select from current questionnaires"
          isClearable
        />
        <ButtonGroup
          buttons={[
            {
              className: 'btn btn-danger',
              label: 'Delete current selection',
              onClick: handleDeleteButtonClick,
              type: 'button',
              hidden: !(selectedQuestionnaire && questionnaireIdsInUse.has(selectedQuestionnaire.value)),
            },
            {
              className: 'btn btn-clear',
              label: 'Update current selection',
              onClick: handleUpdateButtonClick,
              type: 'button',
              hidden: !selectedQuestionnaire,
            },
            {
              className: 'btn btn-primary',
              label: 'Create new based on current selection',
              onClick: handleCreateBasedOnCurrentButtonClick,
              type: 'button',
              hidden: !selectedQuestionnaire,
            },
            {
              className: 'btn btn-primary',
              label: 'Create new',
              onClick: handleCreateButtonClick,
              type: 'button',
            },
          ]}
        />
        {selectedQuestionnaireDetail && (
          <div className="mt-20">
            {selectedQuestionnaireDetail.description && (
              <p className="mb-20">
                Description:
                <strong>{selectedQuestionnaireDetail.description}</strong>
              </p>
            )}
            {selectedQuestionnaireQuestions && selectedQuestionnaireQuestions
              .map(({
                questionId,
                question,
                responseOptions: questionResponseOptions,
              }) => question && (
                <Accordion title={`Question ${question.category}`} counter key={questionId}>
                  <QuestionDetails question={question} responseOptions={questionResponseOptions} />
                </Accordion>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
