import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { userProfileUrl } from 'app/helpers/urlCreators'

interface UserProfileLinkProps {
  userId: number
  userName: string
}

export const UserProfileLink: FC<UserProfileLinkProps> = ({ userId, userName }) => <Link to={userProfileUrl(userId)}>{userName}</Link>
