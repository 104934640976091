import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { forecastStartUpdateUsers } from 'app/modules/forecast/forecast-actions'
import { isReloadingUsersFromForecast } from 'app/modules/forecast/forecast-selectors'
import { Button } from 'app/components/components-ui/buttons/button'

export const ReloadForecastButton: FC = () => {
  const isReloadingUsers = useSelector(isReloadingUsersFromForecast)

  const dispatch = useDispatch()
  const reloadUsersFromForecast = (): void => {
    dispatch(forecastStartUpdateUsers())
  }

  return (
    <Button
      type="button"
      className="btn btn-clear"
      disabled={isReloadingUsers}
      onClick={reloadUsersFromForecast}
      label="Reload from forecast"
    />
  )
}
