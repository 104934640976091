import {
  FeedbackFormActions,
  FeedbackFormStartFetchActionType,
  FeedbackFormFinishFetchActionType,
  FeedbackFormStartSendActionType,
  FeedbackFormFinishSendActionType,
  FeedbackFormErrorActionType,
} from 'app/modules/feedback-form/feedback-form-actions'
import {
  FeedbackUpdateData,
  FeedbackWithQuestionsAndAnswers,
} from 'review-app-shared/types/feedback'

export enum FormStatus { Loading, Loaded, Saving, Saved, Error }

interface LoadingData {
  kind: FormStatus.Loading
}
interface LoadedData<TData> {
  kind: FormStatus.Loaded
  data: TData
}
interface SavingData<TSubmit> {
  kind: FormStatus.Saving
  data: TSubmit
}
interface SavedData<TData> {
  kind: FormStatus.Saved
  data: TData
}
interface ErrorData {
  kind: FormStatus.Error
  errorMessage: string
}

export type FormDataResult<TData, TSubmit> = LoadingData | LoadedData<TData> |
SavingData<TSubmit> | SavedData<TData> | ErrorData

export const getLoading = (): LoadingData => ({ kind: FormStatus.Loading })
export const getLoaded = <TData>(data: TData): LoadedData<TData> => ({ kind: FormStatus.Loaded, data })
export const getSaving = <TSubmit>(data: TSubmit): SavingData<TSubmit> => ({ kind: FormStatus.Saving, data })
export const getSaved = <TData>(data: TData): SavedData<TData> => ({ kind: FormStatus.Saved, data })
export const getError = (errorMessage: string): ErrorData => ({ kind: FormStatus.Error, errorMessage })


export type FeedbackFormState = {
  feedback: FormDataResult<FeedbackWithQuestionsAndAnswers, FeedbackUpdateData>
}
const initialState = {
  feedback: getLoading(),
}

export const feedbackFormReducer = (state: FeedbackFormState = initialState, action: FeedbackFormActions): FeedbackFormState => {
  switch (action.type) {
    case FeedbackFormStartFetchActionType:
      return {
        ...state,
        feedback: getLoading(),
      }
    case FeedbackFormFinishFetchActionType:
      return {
        ...state,
        feedback: getLoaded(action.payload),
      }
    case FeedbackFormStartSendActionType:
      return {
        ...state,
        feedback: getSaving(action.payload.answers),
      }
    case FeedbackFormFinishSendActionType:
      return {
        ...state,
        feedback: getSaved(action.payload),
      }
    case FeedbackFormErrorActionType:
      return {
        ...state,
        feedback: getError(action.payload.message),
      }
    default:
      return state
  }
}
