// Helper functions related to review data
import { FeedbackPopulated, ReviewPopulated } from 'review-app-shared/types/selectors'
import { Review } from 'review-app-shared/types/review'
import { UserWithDetails } from 'review-app-shared/types/user'
import { incompleteFeedback } from 'review-app-shared/types/feedback'

// format a date consistently for rendering
export const formatDate = (date: Date): string => date.toLocaleDateString()

// format a date and time consistently for rendering
export const formatDateTime = (dateTime: Date): string => {
  const date = formatDate(dateTime)
  const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  return `${date} at ${time}`
}

// get the time four weeks into the future, rounded to the nearest half hour (default for new reviews)
export const getTimeFourWeeksFromNow = (): Date => {
  const DAY_IN_MS = 24 * 60 * 60 * 1000
  const HALF_HOUR_IN_MS = 30 * 60 * 1000
  const exact = Date.now() + DAY_IN_MS * 28
  const rounded = exact - (exact % HALF_HOUR_IN_MS)
  return new Date(rounded)
}

// from a set of reviews, return the most recent (previous) and nearest in the future (next)
// relative to now, or undefined if there aren't any
export type ReviewsPreviousNext = {
  previous: ReviewPopulated | undefined
  next: ReviewPopulated | undefined
}
export const findReviewsPreviousNext = (reviews: Array<ReviewPopulated>): ReviewsPreviousNext => reviews
  .reduce((acc: ReviewsPreviousNext, review: ReviewPopulated): ReviewsPreviousNext => {
    const currentTime = Date.now()
    const { timeReviewed } = review
    const reviewTime = new Date(timeReviewed).getTime()
    if (reviewTime > currentTime) {
      if (!acc.next) return { ...acc, next: review }
      const { timeReviewed: future } = acc.next
      const futureTime = new Date(future).getTime()
      if (reviewTime < futureTime) return { ...acc, next: review }
      return acc
    }
    if (!acc.previous) return { ...acc, previous: review }
    const { timeReviewed: past } = acc.previous
    const pastTime = new Date(past).getTime()
    if (reviewTime > pastTime) return { ...acc, previous: review }
    return acc
  }, { previous: undefined, next: undefined })

const isFuture = (timestamp: Date): boolean => {
  const timestampDate = new Date(timestamp) // ensure timestamp is a real JS Date
  return (timestampDate.getTime() > Date.now())
}

// from a set of reviews, return those in the future,
// ordered by date (earliest first)
export const getFutureReviews = (reviews: Array<ReviewPopulated>): Array<ReviewPopulated> => reviews
  .filter((review) => isFuture(review.timeReviewed))
  .sort((a, b) => {
    const { timeReviewed: timeA } = a
    const { timeReviewed: timeB } = b
    return new Date(timeA).getTime() - new Date(timeB).getTime()
  })

// from a set of feedback requests, return those linked to a
// review in the future, ordered by date (earliest first)
export const getFutureFeedbackRequests = (feedbackRequests: Array<FeedbackPopulated>): Array<FeedbackPopulated> => feedbackRequests
  .filter((feedback) => isFuture(feedback.linkedReview.timeReviewed) && incompleteFeedback.includes(feedback.state))
  .sort((a, b) => {
    const { linkedReview: { timeReviewed: timeA } } = a
    const { linkedReview: { timeReviewed: timeB } } = b
    return new Date(timeA).getTime() - new Date(timeB).getTime()
  })

// returns a map assocating reviewee usernames with each reviewId (so that the feedback form can identify who the review is of)
export const getRevieweeNames = (reviews: Review[], users: UserWithDetails[]): Map<number, string> => {
  const revieweeNames = new Map<number, string>()
  reviews.forEach(({ id, userReviewedId }): void => {
    const matchingUser = users.find((user) => user.id === userReviewedId)
    if (matchingUser) revieweeNames.set(id, matchingUser.username)
  })
  return revieweeNames
}
