import React, { FC } from 'react'

import { formatDate } from 'app/helpers/review'
import { Feedback, FeedbackState } from 'review-app-shared/types/feedback'

export const FeedbackStatus: FC<{ feedback: Feedback }> = ({ feedback: { id, state, dateSubmitted } }) => {
  switch (state) {
    case FeedbackState.Dismissed:
      return <span className="not-started">Dismissed</span>
    case FeedbackState.Started:
      return <span className="in-progress">{`In progress, last updated ${dateSubmitted ? formatDate(dateSubmitted) : '- -'}`}</span>
    case FeedbackState.NotStarted:
      return <span className="not-started">Not yet started</span>
    case FeedbackState.Submitted:
      return <span className="submitted">{`Submitted on ${dateSubmitted ? formatDate(dateSubmitted) : '- -'}`}</span>
    default:
      console.error(`Requested feedback (id: ${id}) is in unexpected state: ${state}`)
      return <span className="not-started">The feedback is in a weird state...</span>
  }
}
