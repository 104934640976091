import React, { useState, FC } from 'react'

import { Modal, ModalContentProps as ModalProps } from 'app/components/components-ui/modal/modal'

export enum ModalState { Hidden, Waiting, Confirmed, Canceled }

interface ModalHook {
  Modal: React.FC<ModalProps>
  modalState: ModalState
  hideModal: () => void
  showModal: () => void
}

export const useModal = (): ModalHook => {
  const [modalState, setModalState] = useState(ModalState.Hidden)

  const ModalComponent: FC<ModalProps> = ({ ...rest }) => (
    <Modal
      isShowing={modalState !== ModalState.Hidden}
      onCancel={(): void => setModalState(ModalState.Canceled)}
      onConfirm={(): void => setModalState(ModalState.Confirmed)}
      {...rest}
    />
  )

  return ({
    Modal: ModalComponent,
    modalState,
    hideModal: (): void => setModalState(ModalState.Hidden),
    showModal: (): void => setModalState(ModalState.Waiting),
  })
}
