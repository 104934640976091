import React, { FC } from 'react'
import cx from 'classnames'

import { FeedbackState, FeedbackWithQuestionsAndAnswers, incompleteFeedback } from 'review-app-shared/types/feedback'
import { UserWithDetails } from 'review-app-shared/types/user'
import { Answer } from 'review-app-shared/types/answer'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { ReactComponent as Warning } from 'assets/svg/icon-warning.svg'
import { MarkdownEditor } from '../editor/Editor'


interface ReviewSummaryAnswersItemProps {
  reviewerName: string
  reviewerAnswer: Answer
  responseOptions: ResponseOptions | null
  state: FeedbackState
}

const responseOptionTextForOption = (responseOptions: ResponseOptions, chosenAnswer: number): string => {
  const selectedResponse = responseOptions.options.find((option) => option.value === chosenAnswer)
  return (selectedResponse) ? selectedResponse.name : 'no option selected'
}

// The ReviewSummaryAnswersItem component renders an individual response
export const ReviewSummaryAnswersItem: FC<ReviewSummaryAnswersItemProps> = ({
  reviewerName,
  reviewerAnswer: {
    chosenAnswer,
    textComment,
  },
  responseOptions,
  state,
}) => (
  <li className={cx('card', { 'in-progress': incompleteFeedback.includes(state) })}>
    <h5 className="mt-0 with-separator">
      Review from
      {' '}
      <strong>{reviewerName}</strong>
    </h5>
    {state === FeedbackState.Started && (
      <div className="h-flex h-icon">
        <Warning />
        Review is in progress
      </div>
    )}
    {(chosenAnswer != null && responseOptions) ? <div className="h-strong">{responseOptionTextForOption(responseOptions, chosenAnswer)}</div> : ''}
    {state !== FeedbackState.Dismissed && textComment && (
      <MarkdownEditor readonly initialValue={textComment} />
    )}
    {state === FeedbackState.Dismissed && <strong>DISMISSED</strong>}
  </li>
)

interface ReviewSummaryAnswersProps {
  questionId: number
  matchingFeedbackById: Array<FeedbackWithQuestionsAndAnswers>
  users: Array<UserWithDetails>
}

// The ReviewSummaryAnswers component renders the collated responses to an
// individual question for a review summary report
export const ReviewSummaryAnswers: FC<ReviewSummaryAnswersProps> = ({
  questionId,
  matchingFeedbackById,
  users,
}) => {
  const matchingAnswersForQuestion = matchingFeedbackById
    .map(({
      reviewerUserId,
      state,
      questions,
      answers,
    }) => {
      const reviewer = users.find((user) => user.id === reviewerUserId)
      const reviewerName = reviewer && reviewer.username
      const reviewerAnswer = answers.find((answer) => answer.questionId === questionId)
      const matchingQuestion = questions.find((question) => question.id === questionId)
      const responseOptions = matchingQuestion ? matchingQuestion.responseOptions : null
      return {
        reviewerUserId,
        reviewerName,
        reviewerAnswer,
        responseOptions,
        state,
      }
    })

  return (
    <ul className="unstyled mt-40">
      {matchingAnswersForQuestion.map(({
        reviewerUserId,
        reviewerName,
        reviewerAnswer,
        responseOptions,
        state,
      }) => reviewerName && reviewerAnswer && (
        <ReviewSummaryAnswersItem
          key={reviewerUserId}
          reviewerName={reviewerName}
          reviewerAnswer={reviewerAnswer}
          responseOptions={responseOptions}
          state={state}
        />
      ))}
    </ul>
  )
}
