import { api, ApiResponse } from 'app/api/api-client'
import { Question, QuestionToCreate } from 'review-app-shared/types/question'

export const apiPostQuestion = async (
  questionToCreate: QuestionToCreate,
  token: string,
): ApiResponse<Question> => api.post(
  '/question',
  questionToCreate,
  { headers: { Authorization: token } },
)

export const apiPutQuestion = async (
  questionId: number,
  questionToEdit: QuestionToCreate,
  token: string,
): ApiResponse<Question> => api.put(
  `/question/${questionId}`,
  questionToEdit,
  { headers: { Authorization: token } },
)

export const apiDeleteQuestion = async (
  questionId: number,
  token: string,
): ApiResponse<void> => api.delete(
  `/question/${questionId}`,
  { headers: { Authorization: token } },
)
