import React, { FC } from 'react'

import { AdvancedReviewSchedulerList } from 'app/components/manager/AdvancedReviewSchedulerList'

import { Hero } from 'app/components/components-ui/hero/Hero'

import { homeUrl } from 'app/helpers/urlCreators'

// The AdvancedReviewScheduler component renders full details about staff managed by the current user (or all staff if admin)
export const AdvancedReviewScheduler: FC = () => (
  <div className="content">
    <Hero
      backButton={homeUrl()}
      title="Advanced Review Scheduler"
    />
    <div className="main">
      <AdvancedReviewSchedulerList />
    </div>
  </div>
)
