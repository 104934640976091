import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

import { booleanToIcon } from 'app/helpers/commonVisualElements'
import { QuestionDetailsResponseOptions } from './QuestionDetailsResponseOptions'

interface QuestionDetailsProps {
  question: Question
  responseOptions?: ResponseOptions | null
}

// The QuestionDetails component renders a complete question in detail.
export const QuestionDetails: FC<QuestionDetailsProps> = ({
  question,
  responseOptions,
}) => (
  <div>
    {question.description && (
      <Markdown options={{ forceBlock: true }} data-cy="question-description">
        {question.description}
      </Markdown>
    )}
    <p className="h-flex" data-cy="free-text-comment">
      <span className="mr-10">
        Free text comment available
        {question.freeTextRequired && ' (required)'}
:
      </span>
      {booleanToIcon(question.freeTextCommentDisplayed)}
    </p>
    {responseOptions && (
    <QuestionDetailsResponseOptions
      freeTextCommentDisplayed={question.freeTextCommentDisplayed}
      freeTextCommentRequiredOnResponses={question.freeTextCommentRequiredOnResponses}
      responseOptions={responseOptions}
    />
    )}
  </div>
)
