import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUser } from 'app/modules/user/user-selectors'

import { booleanToIcon } from 'app/helpers/commonVisualElements'
import { Button } from 'app/components/components-ui/buttons/button'
import { Unknown } from 'app/components/Unknown'
import { Months } from 'app/constants/dates'
import { useModal, ModalState } from 'app/hooks/useModal'
import { useUserById, useUserHandling, useUserIdsInUse } from './hooks'

interface UserDetailsProps {
  userId: number
  switchView?: () => void
}

// The UserDetails component renders a selected user's details.
export const UserDetails: FC<UserDetailsProps> = ({ userId, switchView }) => {
  const [user] = useState(useUserById(userId))

  const userIdsInUse = useUserIdsInUse()
  const [deleteCurrentUser] = useUserHandling(userId)
  const currentUser = useSelector(getCurrentUser)

  const {
    Modal, showModal, hideModal, modalState,
  } = useModal()
  useEffect(() => {
    switch (modalState) {
      case ModalState.Confirmed:
        hideModal()
        deleteCurrentUser()
        break
      case ModalState.Canceled:
        hideModal()
        break
      default:
        break
    }
  }, [deleteCurrentUser, hideModal, modalState])

  if (!user) return <Unknown />

  const {
    username,
    email,
    isAdmin,
    active,
    managers,
    managedUsers,
    userGroups,
    employmentStartYear,
    annualReviewMonth,
  } = user

  return (
    <>
      <h5 className="mt-0" data-cy="user-name-detail">{`${username} (${email})`}</h5>
      <table className="table">
        <tbody>
          <tr>
            <td>
              {'Is an administrator: '}
            </td>
            <td data-cy="is-admin">
              {booleanToIcon(isAdmin)}
            </td>
          </tr>
          <tr>
            <td>{'Is active: '}</td>
            <td data-cy="is-active">{booleanToIcon(active)}</td>
          </tr>
          <tr>
            <td>{'Start date: '}</td>
            <td data-cy="start-date">{employmentStartYear || '-'}</td>
          </tr>
          <tr>
            <td>{'Annual review month: '}</td>
            <td data-cy="annual-review">{annualReviewMonth ? Months[annualReviewMonth] : '-'}</td>
          </tr>
          {(managers.length > 0) ? (
            <tr>
              <td>Managed by:</td>
              <td>
                <ul className="table-list">
                  {managers.map((manager) => <li data-cy={`user-managed-by${manager.id}`} key={manager.id}>{manager.username}</li>)}
                </ul>
              </td>
            </tr>
          ) : <tr><td colSpan={2}>No manager assigned.</td></tr>}
          {(managedUsers.length > 0) ? (
            <tr>
              <td>Manager of:</td>
              <td>
                <ul className="table-list">
                  {managedUsers.map((managedUser) => <li data-cy={`user-manager-of${managedUser.id}`} key={managedUser.id}>{managedUser.username}</li>)}
                </ul>
              </td>
            </tr>
          ) : <tr><td colSpan={2}>Not a manager.</td></tr>}
          {(userGroups?.length > 0) ? (
            <tr>
              <td>Member of:</td>
              <td>
                <ul className="table-list">
                  {userGroups.map((userGroup) => <li data-cy={`user-group${userGroup.id}`} key={userGroup.id}>{userGroup.name}</li>)}
                </ul>
              </td>
            </tr>
          ) : <tr><td colSpan={2}>Not a member of any user groups.</td></tr>}
        </tbody>
      </table>
      <div className="btn-group mt-20">
        <div>
          {!userIdsInUse.has(userId) && (
            <Button
              type="button"
              className="btn btn-danger"
              label="Delete"
              onClick={showModal}
            />
          )}
          {switchView && currentUser && currentUser.isAdmin && (
            <Button
              type="button"
              className="btn btn-success"
              label="Edit"
              onClick={switchView}
            />
          )}
        </div>

        <Modal
          title="Delete user"
          primaryButton="Yes"
          secondaryButton="No"
        >
          <p>
            {'You are going to '}
            <strong>delete</strong>
            {' user '}
            <em>{username}</em>
            {' , are you sure?'}
          </p>
        </Modal>
      </div>
    </>
  )
}
