export class FeedbackFormError extends Error {
}

export class ResponseOptionMissingError extends FeedbackFormError {
  constructor() {
    super('Not all options are selected.')
  }
}

export class CommentMissingError extends FeedbackFormError {
  constructor() {
    super('Text comment missing for required field.')
  }
}
