import { api, ApiResponse } from 'app/api/api-client'
import {
  QuestionnaireWithQuestionIds,
  QuestionnaireToCreate,
  QuestionnaireUpdateData,
} from 'review-app-shared/types/questionnaire'

export const apiPostQuestionnaire = async (
  questionnaireToCreate: QuestionnaireToCreate,
  token: string,
): ApiResponse<QuestionnaireWithQuestionIds> => api.post(
  '/questionnaire',
  questionnaireToCreate,
  { headers: { Authorization: token } },
)

export const apiPutQuestionnaire = async (
  questionnaireId: number,
  questionnaireUpdateData: QuestionnaireUpdateData,
  token: string,
): ApiResponse<QuestionnaireWithQuestionIds> => api.put(
  `/questionnaire/${questionnaireId}`,
  questionnaireUpdateData,
  { headers: { Authorization: token } },
)

export const apiDeleteQuestionnaire = async (
  questionnaireId: number,
  token: string,
): ApiResponse<void> => api.delete(
  `/questionnaire/${questionnaireId}`,
  { headers: { Authorization: token } },
)
