import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { parse } from 'query-string'

import { GOOGLE_AUTH_URL } from 'app/constants/env/Login'

import { authStartLoginGoogle } from 'app/modules/auth/auth-actions'
import { getLoginError } from 'app/modules/auth/auth-selectors'
import googleLogo from 'assets/logos/google-logo.svg'
import { homeUrl } from 'app/helpers/urlCreators'

import bgImage from 'assets/images/performance-app.png'


interface RouteInfo {
  pathname: string
  search: string
}

type LoginProps = RouteComponentProps<RouteInfo>

export const Login: FC<LoginProps> = ({ location: { pathname, search } }) => {
  const query = parse(search)
  const code = ((pathname === '/google-auth') && ('code' in query)
    && (typeof query.code === 'string')) ? query.code : undefined
  const previousPath = ((pathname === '/google-auth') && ('state' in query)
  && (typeof query.state === 'string')) ? query.state : undefined

  const [codeSent, setCodeSent] = useState(false)
  const dispatch = useDispatch()
  const loginError = useSelector(getLoginError)

  useEffect((): void => {
    if (code && !codeSent) {
      dispatch(authStartLoginGoogle(code))
      setCodeSent(true)
    }
  }, [code, codeSent, dispatch])

  useEffect((): void => { // reset if login fails
    if (codeSent && loginError) {
      setCodeSent(false)
    }
  }, [codeSent, loginError])

  if (codeSent && pathname === '/google-auth') return <Redirect to={previousPath || homeUrl()} />

  return (
    <div className="content">
      <div className="main">
        <div className="login">
          {(loginError) && <p className="msg msg--error">{`Error: ${loginError}`}</p>}
          <section className="with-image">
            <div>
              <h3 data-cy="login-page">Welcome to the Salsita Performance Review App!</h3>
              <div className="login-google">
                <a className="btn btn-clear btn-with-icon" href={`${GOOGLE_AUTH_URL}&state=${pathname}`}>
                  <img alt="Google logo" src={googleLogo} />
                  Login with Google
                </a>
              </div>
            </div>
            <img className="image" src={bgImage} alt="performance-app" />
          </section>
        </div>
      </div>
    </div>
  )
}
