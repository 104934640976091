import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getFutureFeedbackRequests, formatDate } from 'app/helpers/review'
import { reviewerUrl, feedbackReportUrl, feedbackFormUrl } from 'app/helpers/urlCreators'

import { FeedbackStatus } from 'app/components/components-ui/feedback/FeedbackStatus'
import { completeFeedback } from 'review-app-shared/types/feedback'
import { getFeedbackRequests, getMyReviews } from 'app/modules/review/review-selectors'
import { ReviewSummaryField } from '../manager/ReviewSummaryField'
import { DismissReviewButton } from '../components-ui/feedback/DismissReviewButton'

// The HomeReviewer component provides a summary of key information about
// the user's duties as a reviewer
export const HomeReviewer: FC = () => {
  const feedbackRequests = useSelector(getFeedbackRequests)
  const myReviews = useSelector(getMyReviews)
  const futureFeedbackRequests = getFutureFeedbackRequests(feedbackRequests)
  const feedbackRequestsToShow = (futureFeedbackRequests.length > 0)

  return (
    <div>
      <h4 className="mt-0">Reviews</h4>
      <div className="card-list mb-20">
        {(feedbackRequestsToShow)
          ? (
            <>
              <p className="mb-20">You have been asked to provide feedback for:</p>
              {futureFeedbackRequests.map((feedbackRequest) => (
                <div className="card mb-20" key={feedbackRequest.id}>
                  <div className="h-flex-space mb-20">
                    <h5 className="mt-0 mb-0">
                      {feedbackRequest.reviewedUser.username}
                    </h5>
                    <strong>
                      Feedback deadline:
                      {' '}
                      {formatDate(feedbackRequest.linkedReview.deadline)}
                    </strong>
                  </div>
                  <p>
                    <FeedbackStatus feedback={feedbackRequest} />
                  </p>
                  { completeFeedback.includes(feedbackRequest.state)
                    ? <Link to={feedbackReportUrl(feedbackRequest.id)}>View</Link>
                    : (
                      <div className="btn-group-simple">
                        <DismissReviewButton feedbackId={`${feedbackRequest.id}`} revieweeName={feedbackRequest.reviewedUser.username} />
                        <Link
                          className="btn btn-primary"
                          to={feedbackFormUrl(feedbackRequest.id)}
                        >
                          Provide feedback
                        </Link>
                      </div>
                    )}
                </div>
              ))}
            </>
          )
          : <p>You have not been asked to provide review feedback.</p>}
      </div>
      <p><Link to={reviewerUrl()}>Show all reviews</Link></p>
      {myReviews.length
        ? (
          <>
            <h4>
              {`Your latest review summary from ${formatDate(myReviews[0].timeReviewed)}`}
            </h4>
            <ReviewSummaryField readonly reviewId={myReviews[0].id} />
          </>
        )
        : 'No summary to show.'}
    </div>
  )
}
