import React, { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Unknown } from 'app/components/Unknown'
import { Hero } from 'app/components/components-ui/hero/Hero'
import { ReviewDetail } from './ReviewDetail'
import { useLoadedUsers, useReviewsByUserId, useUserById } from './hooks'
import { ViewUser } from './ViewUser'


interface RouteInfo {
  userId: string
}

enum ProfileState {
  Reviews = 'Reviews',
  Detail = 'User detail'
}
type UserProfileProps = RouteComponentProps<RouteInfo>

export const UserProfile: FC<UserProfileProps> = ({
  match: { params: { userId } },
}) => {
  const userIdAsNumber = Number(userId)

  const user = useUserById(userIdAsNumber)
  const reviews = useReviewsByUserId(userIdAsNumber)
  const users = useLoadedUsers()

  const [profileState, setProfileState] = useState<ProfileState>(ProfileState.Reviews)

  if (!user) return <Unknown />

  const ProfileComponent = (): JSX.Element => {
    switch (profileState) {
      case ProfileState.Reviews:
        return <>{reviews.map((review) => <ReviewDetail key={review.id} users={users} review={review} />)}</>
      case ProfileState.Detail:
        return <ViewUser userId={userIdAsNumber} />
      default:
        return <Unknown />
    }
  }

  return (
    <div className="content">
      <Hero
        title={user.username}
      />
      <div className="main">
        <div className="btn-toggle">
          {Object.values(ProfileState).map((state): JSX.Element => (
            <button
              key={state}
              type="button"
              className={`btn ${profileState === state ? 'btn-primary' : 'btn-secondary'}`}
              onClick={(): void => setProfileState(state)}
              data-cy="user-profile-button"
            >
              {state}
            </button>
          ))}
        </div>
        <div className="card">
          <ProfileComponent />
        </div>
      </div>
    </div>
  )
}
