import { createSelector } from 'reselect'

import { UserWithDetailsPopulated } from 'review-app-shared/types/selectors'
import { UserWithDetails } from 'review-app-shared/types/user'
import { UserGroup } from 'review-app-shared/types/user-group'
import { LoadStatus } from 'review-app-shared/types/fetchData'

import { AppState } from 'app/modules/root/root-reducer'
import { getCurrentUserEmail } from 'app/modules/auth/auth-selectors'
import { UserState } from 'app/modules/user/user-reducer'

export const getUserState = ((state: AppState): UserState => state.user)

export const getUsers = createSelector(getUserState, (user) => user.users)
export const getUserGroups = createSelector(getUserState, (user) => user.userGroups)

export const getIsLoadingUser = createSelector(
  getUsers,
  getUserGroups,
  (users, userGroups) => (users.kind === LoadStatus.Loading) || (userGroups.kind === LoadStatus.Loading),
)

export const getIsErrorUser = createSelector(
  getUsers,
  getUserGroups,
  (users, userGroups) => (users.kind === LoadStatus.Error) || (userGroups.kind === LoadStatus.Error),
)

// helper to return UserWithDetails for a given userId
const getUserDetails = (userId: number, users: UserWithDetails[]): UserWithDetails => {
  const foundUser = users.find((user) => user.id === userId)
  if (foundUser === undefined) {
    throw new Error(`Inconsistent user data, ${userId} is not a valid user id.`)
  }
  return foundUser
}

// helper to return UserGroup for a given userGroupId
const getUserGroupDetails = (userGroupId: number, userGroups: UserGroup[]): UserGroup => {
  const userGroup = userGroups.find((group) => group.id === userGroupId)
  if (userGroup === undefined) {
    // no error here because user groups are loaded by a different API call
    return new UserGroup(userGroupId, `Unknown group with id ${userGroupId}`)
  }
  return userGroup
}

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserEmail,
  getUserGroups,
  (users, email, userGroups) => {
    if (!email) return null
    if (users.kind !== LoadStatus.Loaded || userGroups.kind !== LoadStatus.Loaded) return null
    const currentUser = users.data.find((user) => user.email === email)
    if (!currentUser) return null
    const { managers, managedUsers, userGroups: currentUserGroups } = currentUser
    const managersDetails = managers
      .map((managerUserId) => getUserDetails(managerUserId, users.data))
    const managedUsersDetails = managedUsers
      .map((managedUserId) => getUserDetails(managedUserId, users.data))
    const userGroupsDetails = currentUserGroups
      .map((userGroupId) => getUserGroupDetails(userGroupId, userGroups.data))
    const currentUserPopulated = new UserWithDetailsPopulated(
      currentUser.id,
      currentUser.username,
      currentUser.isAdmin,
      currentUser.email,
      currentUser.active,
      managersDetails,
      managedUsersDetails,
      userGroupsDetails,
      currentUser.employmentStartYear,
      currentUser.annualReviewMonth,
    )
    return currentUserPopulated
  },
)

export const getCurrentUserId = createSelector(getCurrentUser, (user) => (user ? user.id : null))

export const getLoadedUsers = createSelector(getUsers, (users) => {
  if (users.kind !== LoadStatus.Loaded) return []
  return [...users.data].sort((a, b) => a.username.localeCompare(b.username))
})

export const getLoadedUserGroups = createSelector(getUserGroups, (userGroups) => {
  if (userGroups.kind !== LoadStatus.Loaded) return []
  return [...userGroups.data].sort((a, b) => a.name.localeCompare(b.name))
})

export const getLoadedUsersPopulated = createSelector(
  getLoadedUsers,
  getLoadedUserGroups,
  (users, userGroups) => users.map((user) => {
    const managersDetails = user.managers
      .map((managerUserId) => getUserDetails(managerUserId, users))
    const managedUsersDetails = user.managedUsers
      .map((managedUserId) => getUserDetails(managedUserId, users))
    const userGroupsDetails = user.userGroups
      .map((userGroupId) => getUserGroupDetails(userGroupId, userGroups))
    return new UserWithDetailsPopulated(
      user.id,
      user.username,
      user.isAdmin,
      user.email,
      user.active,
      managersDetails,
      managedUsersDetails,
      userGroupsDetails,
      user.employmentStartYear,
      user.annualReviewMonth,
    )
  }),
)

// all users (including inactive) are needed for existing reviews, but only active users should be available
// for selection for new reviews
export const getLoadedActiveUsers = createSelector(getLoadedUsers, (users) => users.filter((user) => user.active))

export const getUserIdToEmailMap = createSelector<AppState, UserWithDetails[], {[id: number]: string}>(getLoadedUsers, (users) => users.reduce((map, user) => ({ ...map, [user.id]: user.email }), {}))
