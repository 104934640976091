import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Markdown from 'markdown-to-jsx'

import { LoadStatus } from 'review-app-shared/types/fetchData'

import { getSummaryById } from 'app/modules/review/review-selectors'
import { reviewSendSummaryStart, reviewStartGetSummary } from 'app/modules/review/review-actions'

import { Button } from '../components-ui/buttons/button'

interface ReviewSummaryFieldProps {
  reviewId: number
  readonly?: boolean
}


// The ReviewSummary component provides a summary of a review and the feedback
// received for it to assist the manager when conducting the review.
export const ReviewSummaryField: FC<ReviewSummaryFieldProps> = ({ reviewId, readonly }) => {
  const [summary, setSummary] = useState('')
  const summaryByIdFetch = Object.values(useSelector(getSummaryById))
    .find((storeSummary) => storeSummary.kind === LoadStatus.Loaded && storeSummary.data.reviewId === reviewId)
  useEffect(() => {
    if (summaryByIdFetch && summaryByIdFetch.kind === LoadStatus.Loaded) {
      setSummary(summaryByIdFetch.data.summary)
    }
  }, [summaryByIdFetch])

  // fetch detailed feedbackById if not already present with useEffect hook
  const dispatch = useDispatch()
  const sendSummary = (): void => {
    dispatch(reviewSendSummaryStart(reviewId, summary))
  }

  useEffect(() => {
    dispatch(reviewStartGetSummary(reviewId))
  }, [dispatch, reviewId])

  return (
    <>
      {readonly
        ? (
          <div className="card mt-20">
            <Markdown>
              {summary || 'No summary available'}
            </Markdown>
          </div>
        )
        : (
          <div className="grid-sticky">
            <div className="card">
              <h4 className="mt-0">Review summary</h4>
              <textarea className="form-control form-control-lg" value={summary} onChange={({ target: { value } }): void => setSummary(value)} />
              <p className="mt-10">(Do not forget that the reviewee can see this summary)</p>
              <Button className="btn btn-primary mt-20" type="button" onClick={sendSummary}>Save summary</Button>
            </div>
          </div>
        )}
    </>
  )
}
