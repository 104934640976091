import { createSelector, OutputSelector } from 'reselect'

import { AppState } from 'app/modules/root/root-reducer'
import {
  getNotStarted, getLoaded, LoadStatus, NotStarted, LoadingData, ErrorData, LoadedData,
} from 'review-app-shared/types/fetchData'
import { UserWithDetails } from 'review-app-shared/types/user'
import { ForecastState } from './forecast-reducer'
import { getLoadedActiveUsers } from '../user/user-selectors'

export const getForecastState = ((state: AppState): ForecastState => state.forecast)

type SelectorType = OutputSelector<AppState, NotStarted | LoadingData | ErrorData | LoadedData<(UserWithDetails)[]>, {}>

const forecasByUserIdSelectors: { [userId: number]: SelectorType} = {}

const filterUndefined = <T>(list: Array<T | undefined>): T[] => list.filter((item) => item !== undefined) as unknown as T[]

const mapUserIdsToUsers = (userIds: number[], users: UserWithDetails[]): UserWithDetails[] => filterUndefined(
  userIds.map((userId) => users.find((user) => user.id === userId)),
)

export const getForecastByUserIdFactory = (userId: number): SelectorType => {
  const selector = forecasByUserIdSelectors[userId]
  if (selector) return selector

  const newSelector = createSelector(getForecastState, getLoadedActiveUsers, (state, users) => {
    if (state.forecasts.kind === LoadStatus.NotStarted) return getNotStarted()

    if (state.forecasts.kind === LoadStatus.Loaded) {
      return getLoaded(mapUserIdsToUsers(state.forecasts.data[userId] || [], users))
    }
    return state.forecasts
  })
  forecasByUserIdSelectors[userId] = newSelector
  return newSelector
}

export const isReloadingUsersFromForecast = createSelector(getForecastState, (state) => state.reloadingUsers)
