import React, { FC, useState, useRef } from 'react'
import cx from 'classnames'
import { Tooltip } from 'app/components/components-ui/tooltip/tooltip'

interface AccordionProps {
  title: string
  hasError?: boolean
  counter?: boolean
}

export const Accordion: FC<AccordionProps> = ({
  title, hasError, counter, children,
}) => {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')

  const content = useRef<HTMLDivElement>(null)
  function toggleAccordion(): void {
    if (!content.current) return
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(
      setActive === 'active' ? '0px' : '100%',
    )
  }

  return (
    <div
      className={cx('accordion', {
        'has-error': hasError,
        'accordion-counter': counter,
      })}
    >
      <button
        type="button"
        className={cx('accordion-header', setActive)}
        onClick={toggleAccordion}
      >
        <h5 className="title">
          {title}
          {hasError && (
            <Tooltip
              className="error ml-10"
              position="bottom"
            >
              You must choose an option
            </Tooltip>
          )}
        </h5>
      </button>
      <div
        className="accordion-body"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
      >
        <div className="pt-20 pb-20">
          {children}
        </div>
      </div>
    </div>
  )
}
