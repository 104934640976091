import React, { FC } from 'react'

import { Feedback, completeFeedback } from 'review-app-shared/types/feedback'

interface FeedbackStatusForReviewProps {
  feedback: Array<Feedback>
}

export const FeedbackStatusForReview: FC<FeedbackStatusForReviewProps> = ({ feedback }) => {
  const submitted = feedback.filter(({ state }) => completeFeedback.includes(state)).length
  return (
    <>
      {`${submitted === feedback.length ? 'All' : `${submitted}/${feedback.length}`} submitted`}
    </>
  )
}
