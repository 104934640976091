import React from 'react'

import { ReviewPopulated } from 'review-app-shared/types/selectors'
import { UserWithDetails } from 'review-app-shared/types/user'

import { ReviewerSuggestionSubgroup } from './ReviewerSuggestionSubgroup'

interface ReviewFormPreviousReviewListProps {
  review: ReviewPopulated
  users: UserWithDetails[]
  selectedUserIds: number[]
  lockedUserIds?: number[]
  handleAddUserReviewing: (userId: number) => void
  handleAddUsersReviewing: (userIds: number[]) => void
  handleRemoveUserReviewing: (userId: number) => void
  handleRemoveUsersReviewing: (userIds: number[]) => void
}

// The ReviewFormPreviousReviewList component shows a manager who was asked to provide
// feedback for the subject's previous review, rendering them as buttons to enable them
// to be added or removed from the list for the next review (individually or collectively).
export const ReviewFormPreviousReviewList: React.FC<ReviewFormPreviousReviewListProps> = ({
  review: { linkedFeedback },
  users,
  selectedUserIds,
  lockedUserIds = [],
  handleAddUserReviewing,
  handleAddUsersReviewing,
  handleRemoveUserReviewing,
  handleRemoveUsersReviewing,
}) => {
  const userIdsToShow = linkedFeedback.map((feedback) => feedback.reviewerUserId)
  const usersToShow = users.filter((user) => userIdsToShow.includes(user.id))

  return (
    <ReviewerSuggestionSubgroup
      title="Reviewers for previous review:"
      users={usersToShow}
      selectedUserIds={selectedUserIds}
      lockedUserIds={lockedUserIds}
      handleAddUserReviewing={handleAddUserReviewing}
      handleAddUsersReviewing={handleAddUsersReviewing}
      handleRemoveUserReviewing={handleRemoveUserReviewing}
      handleRemoveUsersReviewing={handleRemoveUsersReviewing}
    />
  )
}
