import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'

import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import {
  apiGetQuestions,
  apiGetResponseOptions,
} from 'app/modules/question/question-effects'

import {
  questionFinishGetQuestions,
  questionErrorGetQuestions,
  QuestionStartGetQuestionsActionType,
  QuestionFinishGetQuestions,
  QuestionErrorGetQuestions,
  questionFinishGetResponseOptions,
  questionErrorGetResponseOptions,
  QuestionStartGetResponseOptionsActionType,
  QuestionFinishGetResponseOptions,
  QuestionErrorGetResponseOptions,
} from 'app/modules/question/question-actions'

type GetQuestionsEffects = SelectEffect | CallEffect<AxiosResponse<Question[]>> |
PutEffect<QuestionFinishGetQuestions> | PutEffect<QuestionErrorGetQuestions>

export function* getQuestionsSaga(): Generator<GetQuestionsEffects, void, string | AxiosResponse<Question[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetQuestions, token)
      if (response && typeof response !== 'string') {
        yield put(questionFinishGetQuestions(response.data))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(questionErrorGetQuestions(error))
  }
}

type GetResponseOptionsEffects = SelectEffect | CallEffect<AxiosResponse<ResponseOptions[]>> |
PutEffect<QuestionFinishGetResponseOptions> | PutEffect<QuestionErrorGetResponseOptions>

export function* getResponseOptionsSaga(): Generator<GetResponseOptionsEffects, void, string | AxiosResponse<ResponseOptions[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetResponseOptions, token)
      if (response && typeof response !== 'string') {
        yield put(questionFinishGetResponseOptions(response.data))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(questionErrorGetResponseOptions(error))
  }
}

export function* questionSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(QuestionStartGetQuestionsActionType, getQuestionsSaga)
  yield takeEvery(QuestionStartGetResponseOptionsActionType, getResponseOptionsSaga)
}
