import React, { FC } from 'react'

import { UserWithDetailsPopulated } from 'review-app-shared/types/selectors'

import iconTick from 'assets/svg/icon-tick.svg'
import { UserProfileLink } from 'app/components/user/UserProfileLink'

interface TableRowUserListProps {
  user: UserWithDetailsPopulated
}

// This component renders a data row for tables listing user details
export const TableRowUserList: FC<TableRowUserListProps> = ({ user }) => {
  const {
    username,
    id,
    email,
    isAdmin,
    managers,
    userGroups,
  } = user

  return (
    <tr>
      <td data-title="Name" data-cy="user-name">
        <UserProfileLink userId={id} userName={username} />
      </td>
      <td data-title="Email address"><a href={`mailto:${email}`}>{email}</a></td>
      <td data-title="Admin?">
        {isAdmin && (
          <div className="icon-container"><img className="icon" src={iconTick} alt="true icon" /></div>
        )}
      </td>
      <td data-title="Manager(s)">
        {(managers.length === 0)
          ? 'None'
          : (
            <ul>
              {managers.map((manager, i) => (
                <li key={manager.id}>
                  {manager.username}
                  {managers.length - 1 > i && ',\xa0'}
                </li>
              ))}
            </ul>
          )}
      </td>
      <td data-title="User group(s)">
        {(userGroups.length === 0)
          ? 'None'
          : (
            <ul>
              {userGroups.map((userGroup, i) => (
                <li key={userGroup.id}>
                  {userGroup.name}
                  {userGroups.length - 1 > i && ',\xa0'}
                </li>
              ))}
            </ul>
          )}
      </td>
    </tr>
  )
}
