import React, {
  FC,
  useState,
  ChangeEvent,
  FormEvent,
  MouseEvent,
} from 'react'

import {
  QuestionnaireWithQuestionIds,
  QuestionnaireToCreate,
  QuestionnaireUpdateData,
} from 'review-app-shared/types/questionnaire'
import { Question } from 'review-app-shared/types/question'
import { ResponseOptions } from 'review-app-shared/types/response-options'
import { SelectOption } from 'review-app-shared/types/selectOption'

import { QuestionnaireFormEdit } from 'app/components/forms/QuestionnaireFormEdit'
import { QuestionnaireFormView } from 'app/components/forms/QuestionnaireFormView'

enum FormActivity { View, Create, Update }

interface QuestionnaireFormProps {
  questionnaireIdsInUse: Set<number>
  questionnaires: QuestionnaireWithQuestionIds[]
  questions: Question[]
  responseOptions: ResponseOptions[]
  createQuestionnaire: (questionnaireToCreate: QuestionnaireToCreate) => void
  updateQuestionnaire: (questionnaireId: number, questionnaireUpdateData: QuestionnaireUpdateData) => void
  deleteQuestionnaire: (questionnaireId: number) => void
}

const getNextId = (questionnairesForSelect: SelectOption[]): number => (
  Math.max(...questionnairesForSelect.map(({ value }) => value)) + 1
)

export const QuestionnaireForm: FC<QuestionnaireFormProps> = ({
  questionnaireIdsInUse,
  questionnaires,
  questions,
  responseOptions,
  createQuestionnaire,
  updateQuestionnaire,
  deleteQuestionnaire,
}) => {
  const [formActivity, setFormActivity] = useState(FormActivity.View)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<SelectOption | null>(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('') // submit '' as null
  const [questionToAdd, setQuestionToAdd] = useState<SelectOption | null>(null)
  const [questionIds, setQuestionIds] = useState<number[]>([])
  const [errorName, setErrorName] = useState<string | null>(null)
  const [errorQuestionIds, setErrorQuestionIds] = useState<string | null>(null)

  const questionnairesForSelect: SelectOption[] = questionnaires.map((questionnaire) => ({
    value: questionnaire.id,
    label: questionnaire.name.concat((questionnaireIdsInUse.has(questionnaire.id) ? ' [in use]' : '')),
  })).sort((a, b) => a.label.localeCompare(b.label))
  const questionsForSelect: SelectOption[] = questions.map((question) => ({
    value: question.id,
    label: question.category,
  }))
    .filter((question) => !questionIds.includes(question.value))
    .sort((a, b) => a.label.localeCompare(b.label))

  const namesInUse = questionnaires.map((questionnaire) => questionnaire.name)
  const validateName = (candidateName: string): boolean => {
    if (!candidateName) {
      setErrorName('The questionnaire must have a name.')
      return false
    }
    if (namesInUse.includes(candidateName)) {
      setErrorName(`Questionnaire names must be unique, ${candidateName} is already in use.`)
      return false
    }
    setErrorName(null)
    return true
  }

  const validateQuestionIds = (candidateQuestionIds: number[]): boolean => {
    if (!candidateQuestionIds || candidateQuestionIds.length === 0) {
      setErrorQuestionIds('The questionnaire must have at least one question.')
      return false
    }
    setErrorQuestionIds(null)
    return true
  }

  const validateFormCreate = (): boolean => validateName(name) && validateQuestionIds(questionIds)

  const validateFormUpdate = (): boolean => validateQuestionIds(questionIds)

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
    validateName(event.target.value)
  }

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>): void => setDescription(event.target.value)

  const handleSelectQuestionToAdd = (selected: SelectOption): void => setQuestionToAdd(selected)

  const handleQuestionAddButtonClick = (): void => {
    if (questionToAdd) {
      setQuestionIds([...questionIds, questionToAdd.value])
      setQuestionToAdd(null)
    }
  }

  const handleQuestionMoveUpButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const position = questionIds.indexOf(Number(event.currentTarget.name))
    if (position > 0) {
      const newQuestionIds = [...questionIds]
      newQuestionIds[position - 1] = questionIds[position]
      newQuestionIds[position] = questionIds[position - 1]
      setQuestionIds(newQuestionIds)
    }
  }

  const handleQuestionMoveDownButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const position = questionIds.indexOf(Number(event.currentTarget.name))
    if (position < questionIds.length - 1) {
      const newQuestionIds = [...questionIds]
      newQuestionIds[position + 1] = questionIds[position]
      newQuestionIds[position] = questionIds[position + 1]
      setQuestionIds(newQuestionIds)
    }
  }

  const handleQuestionDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setQuestionIds([...questionIds].filter((questionId) => questionId !== Number(event.currentTarget.name)))
  }

  const handleSelectQuestionnaire = (selected: SelectOption): void => setSelectedQuestionnaire(selected)

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const triggeringElement = document.activeElement && document.activeElement.nodeName
    if (triggeringElement !== 'BUTTON') return
    if (formActivity === FormActivity.Create) {
      if (!validateFormCreate()) return
      const questionnaireToCreate = new QuestionnaireToCreate(
        name,
        description || null,
        questionIds,
      )
      setName(questionnaireToCreate.name)
      setDescription(questionnaireToCreate.description || '')
      setQuestionIds(questionIds)
      createQuestionnaire(questionnaireToCreate)
      setSelectedQuestionnaire({
        label: name,
        value: getNextId(questionnairesForSelect),
      })
    }
    if (selectedQuestionnaire && formActivity === FormActivity.Update) {
      if (!validateFormUpdate()) return
      const questionnaireUpdateData = new QuestionnaireUpdateData(
        description || null,
        questionIds,
      )
      updateQuestionnaire(selectedQuestionnaire.value, questionnaireUpdateData)
      if (selectedQuestionnaire) {
        setSelectedQuestionnaire({
          ...selectedQuestionnaire,
          value: getNextId(questionnairesForSelect),
        })
      }
    }
    setFormActivity(FormActivity.View)
  }

  const handleCancelButtonClick = (): void => {
    setName('')
    setDescription('')
    setQuestionIds([])
    setFormActivity(FormActivity.View)
  }

  const handleCreateButtonClick = (): void => {
    setFormActivity(FormActivity.Create)
  }

  const handleCreateBasedOnCurrentButtonClick = (): void => {
    const matchingQuestionnaire = selectedQuestionnaire && questionnaires
      .find((questionnaire) => questionnaire.id === selectedQuestionnaire.value)
    if (matchingQuestionnaire) {
      setName(matchingQuestionnaire.name.concat(' (updated)'))
      setDescription(matchingQuestionnaire.description || '')
      setQuestionIds(matchingQuestionnaire.questionIds)
    }
    setFormActivity(FormActivity.Create)
  }

  const handleUpdateButtonClick = (): void => {
    const matchingQuestionnaire = selectedQuestionnaire && questionnaires
      .find((questionnaire) => questionnaire.id === selectedQuestionnaire.value)
    if (matchingQuestionnaire) {
      setName(matchingQuestionnaire.name)
      setDescription(matchingQuestionnaire.description || '')
      setQuestionIds(matchingQuestionnaire.questionIds)
      setFormActivity(FormActivity.Update)
    }
  }

  const handleDeleteButtonClick = (): void => {
    if (selectedQuestionnaire) {
      deleteQuestionnaire(selectedQuestionnaire.value)
      setSelectedQuestionnaire(null)
    }
  }

  return (
    (formActivity === FormActivity.View) ? (
      <QuestionnaireFormView
        handleCreateBasedOnCurrentButtonClick={handleCreateBasedOnCurrentButtonClick}
        handleCreateButtonClick={handleCreateButtonClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        handleSelectQuestionnaire={handleSelectQuestionnaire}
        handleUpdateButtonClick={handleUpdateButtonClick}
        questionnaireIdsInUse={questionnaireIdsInUse}
        questionnairesForSelect={questionnairesForSelect}
        questionnaires={questionnaires}
        questions={questions}
        responseOptions={responseOptions}
        selectedQuestionnaire={selectedQuestionnaire}
      />
    ) : (
      <QuestionnaireFormEdit
        isNew={formActivity === FormActivity.Create}
        description={description}
        errorName={errorName}
        errorQuestionIds={errorQuestionIds}
        handleCancelButtonClick={handleCancelButtonClick}
        handleChangeDescription={handleChangeDescription}
        handleChangeName={handleChangeName}
        handleQuestionAddButtonClick={handleQuestionAddButtonClick}
        handleQuestionDeleteButtonClick={handleQuestionDeleteButtonClick}
        handleQuestionMoveDownButtonClick={handleQuestionMoveDownButtonClick}
        handleQuestionMoveUpButtonClick={handleQuestionMoveUpButtonClick}
        handleSelectQuestionToAdd={handleSelectQuestionToAdd}
        handleSubmit={handleSubmit}
        name={name}
        questionIds={questionIds}
        questions={questions}
        questionsForSelect={questionsForSelect}
        questionToAdd={questionToAdd}
        responseOptions={responseOptions}
      />
    )
  )
}
