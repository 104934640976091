import {
  Node, Editor, Transforms, Range, Text, Point, Element as SlateElement, NodeEntry, Ancestor,
} from 'slate'
import isHotkey from 'is-hotkey'


import {
  useCallback, useEffect, useMemo, useRef,
} from 'react'
import {
  HOTKEYS, isHotkey as isFormatHotkey, isFormatWrapper, BLOCK_SHORTCUTS, TextFormats, isBlockShortcut, WRAPPER_MAPPING, DEFAULT_TEXT_FORMAT,
} from './const'
import {
  toggleMark, parseToken, extractToken, deserialize, serialize,
} from './utils'


export const withShortcuts = (editor: Editor): Editor => {
  const { deleteBackward, insertText, insertBreak } = editor
  // This is needed to avoid `no-param-reassign` on `editor`
  const tempEditor = editor

  const removeFormating = (): void => Transforms.setNodes(editor, { type: DEFAULT_TEXT_FORMAT })
  const removeWrap = (): void => {
    removeFormating()

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n)
        && SlateElement.isElement(n)
        && isFormatWrapper(n.type),
      split: true,
    })
  }

  const getCurrentBlock = (): NodeEntry<Ancestor> | undefined => Editor.above(editor, { match: (n) => Editor.isBlock(editor, n) })

  tempEditor.insertBreak = (): void => {
    const [block] = getCurrentBlock() || [undefined]

    if (block) {
      if (block.type === TextFormats.ListItem && Editor.isEmpty(editor, block)) {
        removeWrap()
      } else {
        insertBreak()

        if (block.type !== TextFormats.ListItem) {
          removeFormating()
        }
      }
    }
  }

  tempEditor.insertText = (text): void => {
    const { selection } = editor

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = getCurrentBlock()
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)

      if (isBlockShortcut(beforeText)) {
        const type = BLOCK_SHORTCUTS[beforeText]

        Transforms.select(editor, range)
        Transforms.delete(editor)

        if (isFormatWrapper(type)) {
          const itemType = WRAPPER_MAPPING[type]
          Transforms.setNodes(editor, { type: itemType }, {
            match: (n) => Editor.isBlock(editor, n),
          })

          Transforms.wrapNodes(editor, { type, children: [] }, {
            match: (n) => !Editor.isEditor(n)
              && SlateElement.isElement(n)
              && n.type === itemType,
          })
        } else {
          Transforms.setNodes(editor, { type }, {
            match: (n) => Editor.isBlock(editor, n),
          })
        }

        return
      }
    }

    insertText(text)
  }

  tempEditor.deleteBackward = (...args): void => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = getCurrentBlock()

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          !Editor.isEditor(block)
          && SlateElement.isElement(block)
          && block.type !== DEFAULT_TEXT_FORMAT
          && Point.equals(selection.anchor, start)
        ) {
          if (block.type === TextFormats.ListItem) {
            removeWrap()
          } else {
            removeFormating()
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return tempEditor
}

export const useHotkey = (editor: Editor): (event: React.KeyboardEvent<HTMLDivElement>) => void => {
  const handlers = useMemo(() => Object.keys(HOTKEYS).map((hotkey) => ({ hotkey, checkEvent: isHotkey(hotkey) })), [])

  return useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    // Hotkeys
    handlers.forEach(({ hotkey, checkEvent }) => {
      // Apparently KeyboardEvent and React.KeyboardEvent<HTMLDivElement> are not compatible types
      if (checkEvent(event as any) && isFormatHotkey(hotkey)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
      }
    })

    // Inline formating
    const { key } = event
    if (key === ' ' || key === 'Enter') {
      const { selection } = editor
      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const range = {
          focus: { ...start },
          anchor: {
            ...start, offset: 0,
          },
        }
        const line = Editor.string(editor, range)
        const match = extractToken(line)

        if (match?.[0] && match.index != null) {
          range.anchor.offset = match.index

          // We want to do this after the event is finished, because we don't want the `event.key` insertion to be affected
          setTimeout(() => {
            const { text, ...props } = parseToken(match[0])

            Transforms.insertText(editor, text, { at: range })

            // We have to adjust the range to not match the newly removed separators
            range.focus.offset -= (match?.groups?.sep.length || 0) * 2

            Transforms.move(editor)

            // `Transforms.setNodes` cannot set `text` property:
            // https://github.com/ianstormtaylor/slate/blob/a1be70204e08ff9d7218ee68251795036a2dadc5/packages/slate/src/transforms/node.ts#L618-L620
            Transforms.setNodes(
              editor,
              props,
              // Apply it to text nodes, and split the text node up if the
              // range is overlapping only part of it.
              { at: range, match: (n) => Text.isText(n), split: true },
            )
          })
        }
      }
    }
  }, [editor, handlers])
}

export const useSerialization = (initialValue: string) => {
  const valueRef = useRef(deserialize(initialValue))

  useEffect(() => {
    valueRef.current = deserialize(initialValue)
  }, [initialValue])

  const getSerialized = useCallback((): string => serialize(valueRef.current), [valueRef])
  const setSerialized = useCallback((val: Node[]): void => { valueRef.current = val }, [])
  const getDeserialized = useCallback((): Node[] => valueRef.current, [valueRef])
  const setDeserialized = useCallback((val: string): void => { valueRef.current = deserialize(val) }, [])

  return [
    getDeserialized, setSerialized,
    getSerialized, setDeserialized,
  ] as const
}
