import { Answer, AnswerUpdateData } from './answer'
import { Questionnaire } from './questionnaire'
import { QuestionWithResponseOptions } from './question'

export enum FeedbackState {
  NotStarted,
  Started,
  Submitted,
  Dismissed,
}

export const incompleteFeedback = [FeedbackState.NotStarted, FeedbackState.Started]
export const completeFeedback = Object.values(FeedbackState).filter((v: FeedbackState) => !incompleteFeedback.includes(v))

export class Feedback {
  constructor(
    readonly id: number,
    readonly reviewerUserId: number,
    readonly reviewId: number,
    readonly dateSubmitted: Date | null,
    readonly state: FeedbackState,
  ) {
  }
}

export class FeedbackUpdateData {
  constructor(
    readonly answers: Array<AnswerUpdateData>,
    readonly state: FeedbackState,
  ) {
  }
}

export class FeedbackWithQuestionsAndAnswers extends Feedback {
  constructor(
    id: number,
    reviewerUserId: number,
    reviewId: number,
    dateSubmitted: Date | null,
    state: FeedbackState,
    readonly questionnaire: Questionnaire,
    readonly questions: Array<QuestionWithResponseOptions>,
    readonly answers: Array<Answer>,
  ) {
    super(id, reviewerUserId, reviewId, dateSubmitted, state)
  }
}
