import { createSelector } from 'reselect'

import { LoadStatus } from 'review-app-shared/types/fetchData'

import { AppState } from 'app/modules/root/root-reducer'
import { QuestionState } from 'app/modules/question/question-reducer'

export const getQuestionState = ((state: AppState): QuestionState => state.question)

export const getQuestions = createSelector(getQuestionState, (questionState) => questionState.questions)
export const getResponseOptions = createSelector(getQuestionState, (questionState) => questionState.responseOptions)

export const getIsLoadingQuestion = createSelector(
  getQuestions,
  getResponseOptions,
  (questions, responseOptions) => (questions.kind === LoadStatus.Loading) || (responseOptions.kind === LoadStatus.Loading),
)

export const getIsErrorQuestion = createSelector(
  getQuestions,
  getResponseOptions,
  (questions, responseOptions) => (questions.kind === LoadStatus.Error) || (responseOptions.kind === LoadStatus.Error),
)

export const getIsNotStartedQuestions = createSelector(
  getQuestions,
  (questions) => (questions.kind === LoadStatus.NotStarted),
)
export const getIsNotStartedResponseOptions = createSelector(
  getResponseOptions,
  (responseOptions) => (responseOptions.kind === LoadStatus.NotStarted),
)

export const getLoadedQuestions = createSelector(
  getQuestions,
  (questions) => {
    if (questions.kind !== LoadStatus.Loaded) return []
    return [...questions.data].sort((a, b) => a.category.localeCompare(b.category))
  },
)

export const getLoadedResponseOptions = createSelector(
  getResponseOptions,
  (responseOptions) => {
    if (responseOptions.kind !== LoadStatus.Loaded) return []
    return [...responseOptions.data].sort((a, b) => a.name.localeCompare(b.name))
  },
)
