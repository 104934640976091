import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'app/modules/user/user-selectors'

import { Tooltip } from 'app/components/components-ui/tooltip/tooltip'

// The HomeQuestionManagement component provides access to question management tasks for authorized users
export const CurrentUserHeader: FC = () => {
  const currentUser = useSelector(getCurrentUser)

  if (!currentUser) return null
  const { username, managedUsers, isAdmin } = currentUser
  const isManager = (managedUsers.length > 0)

  const managerMessage = isManager
    ? `You have management responsibility for ${managedUsers.length} staff member${(managedUsers.length > 1) ? 's' : ''}.`
    : null
  const adminMessage = isAdmin
    ? 'You have administrative access to the application.'
    : null

  return (
    <div className="c-username">
      {username}
      <Tooltip
        position="bottom-left"
      >
        {managerMessage && <p>{managerMessage}</p>}
        {adminMessage && <p>{adminMessage}</p>}
        <p>You can review users.</p>
      </Tooltip>
    </div>
  )
}
