import React, { FC } from 'react'

import { Hero } from 'app/components/components-ui/hero/Hero'
import { UserFormWrapper as UserForm } from './UserFormWrapper'


export const CreateNewUser: FC = () => (
  <div className="content has-buttons">
    <Hero
      breadcrumb
      title="Create user"
    />
    <div className="main" data-cy="create-user-form">
      <UserForm isNew />
    </div>
  </div>
)
