import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import {
  apiPostUserGroup,
  apiDeleteUserGroup,
} from 'app/modules/user-group-form/user-group-form-effects'
import { UserGroup, UserGroupToCreate } from 'review-app-shared/types/user-group'

import {
  userGroupFormFinishSend,
  userGroupFormFinishDelete,
  userGroupFormError,
  UserGroupFormStartSendActionType,
  UserGroupFormStartSend,
  UserGroupFormFinishSend,
  UserGroupFormStartDeleteActionType,
  UserGroupFormStartDelete,
  UserGroupFormFinishDelete,
  UserGroupFormError,
  UserGroupFormFinishSendActionType,
  UserGroupFormFinishDeleteActionType,
  UserGroupFormErrorActionType,
} from 'app/modules/user-group-form/user-group-form-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { toast } from 'react-toastify'
import { FormStatus } from 'review-app-shared/types/formData'
import { getUserGroupFormState } from './user-group-form-selectors'

type CreateUserGroupEffects = SelectEffect | CallEffect<AxiosResponse<UserGroupToCreate>> |
PutEffect<UserGroupFormFinishSend> | PutEffect<UserGroupFormError>

export function* createUserGroupSaga(
  action: UserGroupFormStartSend,
): Generator<CreateUserGroupEffects, void, string | AxiosResponse<UserGroup>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiPostUserGroup, action.payload, token)
      if (response && typeof response !== 'string') {
        yield put(userGroupFormFinishSend(response.data))
      }
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(userGroupFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(userGroupFormError(error))
    }
  }
}

type DeleteUserGroupEffects = SelectEffect | CallEffect<AxiosResponse<void>> |
PutEffect<UserGroupFormFinishDelete> | PutEffect<UserGroupFormError>

export function* deleteUserGroupSaga(
  action: UserGroupFormStartDelete,
): Generator<DeleteUserGroupEffects, void, string | AxiosResponse<void>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const reviewId = action.payload
      const response = yield call(apiDeleteUserGroup, action.payload, token)
      if (response) yield put(userGroupFormFinishDelete(reviewId))
    }
  } catch (error) {
    if ('response' in error && typeof error.response.data === 'string') {
      console.error(error.response)
      yield put(userGroupFormError(new Error(error.response.data)))
    } else {
      console.error(error)
      yield put(userGroupFormError(error))
    }
  }
}

export function* toastSaved(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getUserGroupFormState>> {
  const formState: ReturnType<typeof getUserGroupFormState> = yield select(getUserGroupFormState)
  if (formState.kind === FormStatus.Saved) {
    yield call(toast.info, `You have successfully created ${formState.data?.name}.`, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* toastDelete(): Generator<CallEffect<string | number>> {
  yield call(toast.info, 'You have successfully deleted the user group.', {
    position: toast.POSITION.TOP_CENTER,
  })
}

export function* toastError(): Generator<SelectEffect | CallEffect, void, ReturnType<typeof getUserGroupFormState>> {
  const formState: ReturnType<typeof getUserGroupFormState> = yield select(getUserGroupFormState)
  if (formState.kind === FormStatus.Error) {
    yield call(toast.error, formState.errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

export function* userGroupFormSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(UserGroupFormStartSendActionType, createUserGroupSaga)
  yield takeEvery(UserGroupFormStartDeleteActionType, deleteUserGroupSaga)
  yield takeEvery(UserGroupFormFinishSendActionType, toastSaved)
  yield takeEvery(UserGroupFormFinishDeleteActionType, toastDelete)
  yield takeEvery(UserGroupFormErrorActionType, toastError)
}
