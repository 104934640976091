import { api, ApiResponse } from 'app/api/api-client'
import { UserGroup } from 'review-app-shared/types/user-group'
import { UserWithDetails } from 'review-app-shared/types/user'

export const apiGetUsers = async (token: string): ApiResponse<UserWithDetails[]> => api.get('/users', {
  headers: { Authorization: token },
})

export const apiGetUserGroups = async (token: string): ApiResponse<UserGroup[]> => api.get('/user-groups', {
  headers: { Authorization: token },
})
