interface IQuestionnaire {
  readonly name: string
  readonly description: string | null
}

export class Questionnaire implements IQuestionnaire {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly active: boolean,
    readonly dateCreated: Date,
    readonly description: string | null,
  ) {
  }
}

export class QuestionnaireToCreate implements IQuestionnaire {
  constructor(
    readonly name: string,
    readonly description: string | null,
    readonly questions: Array<number>,
  ) {
  }
}

export class QuestionnaireWithQuestionIds extends Questionnaire {
  constructor(
    id: number,
    name: string,
    active: boolean,
    dateCreated: Date,
    description: string | null,
    readonly questionIds: Array<number>,
  ) {
    super(id, name, active, dateCreated, description)
  }
}

export class QuestionnaireUpdateData {
  constructor(readonly description: string | null, readonly questions: Array<number>) {
  }
}
