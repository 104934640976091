import {
  ReviewFormActions,
  CreateReviewFormStartSendActionType,
  CreateReviewFormFinishSendActionType,
  CreateReviewFormErrorActionType,
  CreateReviewFormResetActionType,
  UpdateReviewFormStartFetchActionType,
  UpdateReviewFormFinishFetchActionType,
  UpdateReviewFormStartSendActionType,
  UpdateReviewFormFinishSendActionType,
  UpdateReviewFormStartDeleteActionType,
  UpdateReviewFormFinishDeleteActionType,
  UpdateReviewFormErrorActionType,
} from 'app/modules/review-form/review-form-actions'
import {
  Review,
  ReviewWithFeedback,
  ReviewToCreate,
  ReviewUpdateData,
} from 'review-app-shared/types/review'

import {
  FormDataResult,
  getBlank,
  getLoading,
  getLoaded,
  getSaving,
  getSaved,
  getDeleting,
  getDeleted,
  getError,
} from 'review-app-shared/types/formData'

export type ReviewFormState = {
  create: FormDataResult<Review, ReviewToCreate>
  update: FormDataResult<ReviewWithFeedback, ReviewUpdateData>
}
const initialState = {
  create: getBlank(),
  update: getBlank(),
}

export const reviewFormReducer = (state: ReviewFormState = initialState, action: ReviewFormActions): ReviewFormState => {
  switch (action.type) {
    case CreateReviewFormStartSendActionType:
      return {
        ...state,
        create: getSaving(action.payload),
      }
    case CreateReviewFormFinishSendActionType:
      return {
        ...state,
        create: getSaved(action.payload),
      }
    case CreateReviewFormErrorActionType:
      return {
        ...state,
        create: getError(action.payload.message),
      }
    case CreateReviewFormResetActionType:
      return {
        ...state,
        create: getBlank(),
      }
    case UpdateReviewFormStartFetchActionType:
      return {
        ...state,
        update: getLoading(),
      }
    case UpdateReviewFormFinishFetchActionType:
      return {
        ...state,
        update: getLoaded(action.payload),
      }
    case UpdateReviewFormStartSendActionType:
      return {
        ...state,
        update: getSaving(action.payload.reviewUpdateData),
      }
    case UpdateReviewFormFinishSendActionType:
      return {
        ...state,
        update: getSaved(action.payload),
      }
    case UpdateReviewFormStartDeleteActionType:
      return {
        ...state,
        update: getDeleting(),
      }
    case UpdateReviewFormFinishDeleteActionType:
      return {
        ...state,
        update: getDeleted(),
      }
    case UpdateReviewFormErrorActionType:
      return {
        ...state,
        update: getError(action.payload.message),
      }
    default:
      return state
  }
}
