import React, { FC, ChangeEvent, KeyboardEvent } from 'react'
import Markdown from 'markdown-to-jsx'
import Select, { ValueType } from 'react-select'

import { SelectOption } from 'review-app-shared/types/selectOption'
import { ResponseOptions } from 'review-app-shared/types/response-options'

import { ButtonGroup } from 'app/components/ButtonGroup'
import { Hero } from 'app/components/components-ui/hero/Hero'

interface QuestionFormEditProps {
  category: string
  commentDisplayed: boolean
  commentRequiredByOption: boolean[] | null
  description: string
  freeTextRequired: boolean
  errorCategory: string | null
  errorOptions: string | null
  handleCancelButtonClick: () => void
  handleChangeCategory: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeCommentDisplayed: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeCommentRequiredByOption: (
    event: ChangeEvent<HTMLInputElement>
  ) => void
  handleChangeDescription: (event: ChangeEvent<HTMLTextAreaElement>) => void
  handleChangeFreeTextRequired: (event: ChangeEvent<HTMLInputElement>) => void
  handleChangeOptions: (selected: SelectOption) => void
  handleSubmit: () => void
  options: SelectOption | null
  responseOptions: ResponseOptions[]
  responseOptionsForSelect: SelectOption[]
  isSelectedQuestionInUse: boolean
  isEditing: boolean
}

// The QuestionFormEdit component renders the editing aspects of the question form
export const QuestionFormEdit: FC<QuestionFormEditProps> = ({
  category,
  commentDisplayed,
  commentRequiredByOption,
  description,
  freeTextRequired,
  errorCategory,
  errorOptions,
  handleCancelButtonClick,
  handleChangeCategory,
  handleChangeCommentDisplayed,
  handleChangeCommentRequiredByOption,
  handleChangeDescription,
  handleChangeFreeTextRequired,
  handleChangeOptions,
  handleSubmit,
  options,
  responseOptions,
  responseOptionsForSelect,
  isSelectedQuestionInUse,
  isEditing,
}) => {
  const selectedResponseOptions = options && responseOptions.find((option) => option.id === options.value)

  const renderOptions = (): JSX.Element => {
    if (selectedResponseOptions && commentDisplayed) {
      return (
        <label htmlFor="commentRequiredByOption">
          <p>Comment required by option:</p>
          <table className="table" id="commentRequiredByOption">
            <thead>
              <tr>
                <th>Option</th>
                <th>Comment required</th>
              </tr>
            </thead>
            <tbody>
              {selectedResponseOptions.options.map(({ name }, index) => (
                <tr key={name}>
                  <td><ul><li>{name}</li></ul></td>
                  <td>
                    <input
                      disabled={isEditing && isSelectedQuestionInUse}
                      className="checkbox"
                      type="checkbox"
                      name={String(index)}
                      checked={Boolean(commentRequiredByOption && commentRequiredByOption[index])}
                      onChange={handleChangeCommentRequiredByOption}
                      data-cy={`comment-required-checkbox${name}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </label>
      )
    }

    if (freeTextRequired) {
      return (
        <label htmlFor="commentRequired">
          <p data-cy="comment-required">Comment required</p>
        </label>
      )
    }

    return (
      <ul>
        {selectedResponseOptions && selectedResponseOptions.options.map(({ name }) => <li key={name}>{name}</li>)}
      </ul>
    )
  }

  return (
    <div className="content has-buttons">
      <Hero
        breadcrumb
        title="Create question"
      />
      <div className="main">
        <div className="grid">
          <form>
            <div className="form-group">
              <label htmlFor="category">
                Question/Category
              </label>
              <input
                className="form-control"
                disabled={isEditing && isSelectedQuestionInUse}
                type="text"
                id="category"
                value={category}
                onChange={handleChangeCategory}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
                  if (event.key === 'Enter') event.preventDefault() // suppress form submit
                }}
                autoComplete="off"
                required
              />
            </div>
            {errorCategory && <p className="form-error">{errorCategory}</p>}
            <div className="form-group">
              <label htmlFor="description">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                rows={20}
                cols={50}
                value={description}
                onChange={handleChangeDescription}
                placeholder="Please add a detailed description of the performance criteria in this category to enable reviewers to make a judgement. Markdown formatting can be used."
              />
            </div>
            <div className="form-group form-group--checkbox">
              <label htmlFor="commentDisplayed">
                <input
                  disabled={isEditing && isSelectedQuestionInUse}
                  type="checkbox"
                  id="commentDisplayed"
                  checked={commentDisplayed}
                  onChange={handleChangeCommentDisplayed}
                />
                Enable reviewer to add detailed comments
              </label>
            </div>
            {commentDisplayed && !selectedResponseOptions && (
              <div className="form-group form-group--checkbox">
                <label htmlFor="freeTextRequired">
                  <input
                    type="checkbox"
                    id="freeTextRequired"
                    checked={freeTextRequired}
                    onChange={handleChangeFreeTextRequired}
                  />
                  Require reviewer to add detailed comment
                </label>
              </div>
            )}
            <label htmlFor="options" className="options">
              <p>Response options:</p>
              <Select
                isDisabled={isEditing && isSelectedQuestionInUse}
                classNamePrefix="select"
                className="select-box"
                id="options"
                value={options}
                onChange={(selected: ValueType<SelectOption>): void => handleChangeOptions(selected as SelectOption)}
                options={responseOptionsForSelect}
                isClearable
              />
            </label>
            {errorOptions && <p className="form-error">{errorOptions}</p>}
            {renderOptions()}
            <ButtonGroup
              buttons={[
                {
                  className: 'btn btn-clear',
                  label: 'Cancel',
                  onClick: handleCancelButtonClick,
                  type: 'button',
                },
                {
                  className: 'btn btn-primary',
                  label: isEditing ? 'Save' : 'Create',
                  onClick: handleSubmit,
                  type: 'submit',
                },
              ]}
            />
          </form>
          {description && (
            <div>
              <h4 className="mt-0">Preview</h4>
              <div className="card" data-cy="description-preview">
                <Markdown options={{ forceBlock: true }}>
                  {description}
                </Markdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
