import React, { FC } from 'react'

import { UserList } from 'app/components/admin/UserList'
import { Hero } from 'app/components/components-ui/hero/Hero'

import { ReloadForecastButton } from './ReloadForecastButton'
import { CreateNewUserButton } from './CreateNewUserButton'

// The UserAdmin component provides authorized users with access to user management tools
export const UserAdmin: FC = () => (
  <div className="content has-buttons">
    <Hero
      breadcrumb
      title="Users"
      tooltip={(
        <>
          <p>
            Every user of the Performance Review app, whatever their role, needs to have an active
            user account with an email address that can be authenticated by Google. Users that have
            previously participated in performance reviews can not be deleted, but can be marked as
            inactive (for example if they have left the company) and will no longer be able to log in
            or be asked to provide feedback. Each user can also be identified as a member of one or
            more user groups (identifed by name, for example Full Stack Developer).
          </p>
          <p>
            Management of users and user groups can be accessed using the links below:
          </p>
        </>
      )}
    />
    <div className="main">
      <UserList />
      <div className="btn-group">
        <div>
          <CreateNewUserButton />
          <ReloadForecastButton />
        </div>
      </div>
    </div>
  </div>
)
