import {
  call,
  put,
  select,
  takeEvery,
  CallEffect,
  PutEffect,
  SelectEffect,
  ForkEffect,
} from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'

import { UserGroup } from 'review-app-shared/types/user-group'

import {
  apiGetUsers,
  apiGetUserGroups,
} from 'app/modules/user/user-effects'
import {
  userFinishGetUsers,
  userErrorGetUsers,
  UserStartGetUsersActionType,
  UserFinishGetUsers,
  UserErrorGetUsers,
  userFinishGetUserGroups,
  userErrorGetUserGroups,
  UserStartGetUserGroupsActionType,
  UserFinishGetUserGroups,
  UserErrorGetUserGroups,
} from 'app/modules/user/user-actions'
import { getCurrentUserToken } from 'app/modules/auth/auth-selectors'
import { UserWithDetails } from 'review-app-shared/types/user'

type GetUsersEffects = SelectEffect | CallEffect<AxiosResponse<UserWithDetails[]>> |
PutEffect<UserFinishGetUsers> | PutEffect<UserErrorGetUsers>

export function* getUsersSaga(): Generator<GetUsersEffects, void, string | AxiosResponse<UserWithDetails[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetUsers, token)
      if (response && typeof response !== 'string') yield put(userFinishGetUsers(response.data))
    }
  } catch (error) {
    console.error(error)
    yield put(userErrorGetUsers(error))
  }
}

type GetUserGroupsEffects = SelectEffect | CallEffect<AxiosResponse<UserGroup[]>> |
PutEffect<UserFinishGetUserGroups> | PutEffect<UserErrorGetUserGroups>

export function* getUserGroupsSaga(): Generator<GetUserGroupsEffects, void, string | AxiosResponse<UserGroup[]>> {
  try {
    const token = yield select(getCurrentUserToken)
    if (token && typeof token === 'string') {
      const response = yield call(apiGetUserGroups, token)
      if (response && typeof response !== 'string') yield put(userFinishGetUserGroups(response.data))
    }
  } catch (error) {
    console.error(error)
    yield put(userErrorGetUserGroups(error))
  }
}

export function* userSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(UserStartGetUsersActionType, getUsersSaga)
  yield takeEvery(UserStartGetUserGroupsActionType, getUserGroupsSaga)
}
