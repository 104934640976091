import { ActionType, createAction, getType } from 'typesafe-actions'
/* eslint '@typescript-eslint/explicit-function-return-type': 0 */
/* disable for action creators because type definitions are derived from them */

import {
  Review,
  ReviewWithFeedback,
  ReviewToCreate,
  ReviewUpdateData,
} from 'review-app-shared/types/review'

export const createReviewFormReset = createAction('review-form/CREATE_RESET',
  (actionCallback) => () => actionCallback())

export const createReviewFormStartSend = createAction('review-form/CREATE_START_SEND',
  (actionCallback) => (reviewToCreate: ReviewToCreate) => actionCallback(reviewToCreate))

export const createReviewFormFinishSend = createAction('review-form/CREATE_FINISH_SEND',
  (actionCallback) => (review: Review) => actionCallback(review))

export const createReviewFormError = createAction('review-form/CREATE_ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export const updateReviewFormStartFetch = createAction('review-form/UPDATE_START_FETCH',
  (actionCallback) => (reviewId: string) => actionCallback(reviewId))

export const updateReviewFormFinishFetch = createAction('review-form/UPDATE_FINISH_FETCH',
  (actionCallback) => (review: ReviewWithFeedback) => actionCallback(review))

export const updateReviewFormStartSend = createAction('review-form/UPDATE_START_SEND',
  (actionCallback) => (reviewId: string, reviewUpdateData: ReviewUpdateData) => actionCallback({ reviewId, reviewUpdateData }))

export const updateReviewFormFinishSend = createAction('review-form/UPDATE_FINISH_SEND',
  (actionCallback) => (review: ReviewWithFeedback) => actionCallback(review))

export const updateReviewFormStartDelete = createAction('review-form/UPDATE_START_DELETE',
  (actionCallback) => (reviewId: string) => actionCallback(reviewId))

export const updateReviewFormFinishDelete = createAction('review-form/UPDATE_FINISH_DELETE',
  (actionCallback) => (reviewId: string) => actionCallback(reviewId))

export const updateReviewFormError = createAction('review-form/UPDATE_ERROR',
  (actionCallback) => (error: Error) => actionCallback(error))

export type ReviewFormActions =
  ActionType<typeof createReviewFormStartSend | typeof createReviewFormFinishSend |
  typeof updateReviewFormStartFetch | typeof updateReviewFormFinishFetch |
  typeof updateReviewFormStartSend | typeof updateReviewFormFinishSend |
  typeof updateReviewFormStartDelete | typeof updateReviewFormFinishDelete |
  typeof createReviewFormError | typeof updateReviewFormError | typeof createReviewFormReset>

export type CreateReviewFormStartSend = ActionType<typeof createReviewFormStartSend>
export type CreateReviewFormFinishSend = ActionType<typeof createReviewFormFinishSend>
export type CreateReviewFormError = ActionType<typeof createReviewFormError>
export type CreateReviewFormReset = ActionType<typeof createReviewFormReset>
export type UpdateReviewFormStartFetch = ActionType<typeof updateReviewFormStartFetch>
export type UpdateReviewFormFinishFetch = ActionType<typeof updateReviewFormFinishFetch>
export type UpdateReviewFormStartSend = ActionType<typeof updateReviewFormStartSend>
export type UpdateReviewFormFinishSend = ActionType<typeof updateReviewFormFinishSend>
export type UpdateReviewFormStartDelete = ActionType<typeof updateReviewFormStartDelete>
export type UpdateReviewFormFinishDelete = ActionType<typeof updateReviewFormFinishDelete>
export type UpdateReviewFormError = ActionType<typeof updateReviewFormError>

export const CreateReviewFormStartSendActionType = getType(createReviewFormStartSend)
export const CreateReviewFormFinishSendActionType = getType(createReviewFormFinishSend)
export const CreateReviewFormErrorActionType = getType(createReviewFormError)
export const CreateReviewFormResetActionType = getType(createReviewFormReset)
export const UpdateReviewFormStartFetchActionType = getType(updateReviewFormStartFetch)
export const UpdateReviewFormFinishFetchActionType = getType(updateReviewFormFinishFetch)
export const UpdateReviewFormStartSendActionType = getType(updateReviewFormStartSend)
export const UpdateReviewFormFinishSendActionType = getType(updateReviewFormFinishSend)
export const UpdateReviewFormStartDeleteActionType = getType(updateReviewFormStartDelete)
export const UpdateReviewFormFinishDeleteActionType = getType(updateReviewFormFinishDelete)
export const UpdateReviewFormErrorActionType = getType(updateReviewFormError)
