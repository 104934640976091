import React, { FC } from 'react'

import { ReactComponent as IconInfo } from 'assets/svg/icon-info.svg'

interface TooltipProps {
  className?: string
  position: string
}

export const Tooltip: FC<TooltipProps> = ({ className, position, children }) => (
  <div className={`tooltip ${className}`}>
    <IconInfo />
    <div className={position}>
      {children}
      <i />
    </div>
  </div>
)
