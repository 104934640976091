import React from 'react'

import { useSelector } from 'react-redux'
import { getForecastByUserIdFactory } from 'app/modules/forecast/forecast-selectors'
import { LoadStatus } from 'review-app-shared/types/fetchData'
import { ReviewerSuggestionSubgroup } from './ReviewerSuggestionSubgroup'

interface ReviewFormForecastReviewListProps {
  userToBeReviewedId: number
  selectedUserIds: number[]
  lockedUserIds?: number[]
  handleAddUserReviewing: (userId: number) => void
  handleAddUsersReviewing: (userIds: number[]) => void
  handleRemoveUserReviewing: (userId: number) => void
  handleRemoveUsersReviewing: (userIds: number[]) => void
}

/**
 * The ReviewFormForecastReviewList component shows who the person worked with since the last review
 * according to Forecast.
 */
export const ReviewFormForecastReviewList: React.FC<ReviewFormForecastReviewListProps> = ({
  userToBeReviewedId,
  selectedUserIds,
  lockedUserIds,
  handleAddUserReviewing,
  handleAddUsersReviewing,
  handleRemoveUserReviewing,
  handleRemoveUsersReviewing,
}) => {
  const userList = useSelector(getForecastByUserIdFactory(userToBeReviewedId))
  if (userList.kind === LoadStatus.NotStarted || userList.kind === LoadStatus.Error) {
    return null
  }

  if (userList.kind === LoadStatus.Loading) {
    return <div>Loading forecast suggestions...</div>
  }

  if (!userList.data.length) {
    return null
  }

  return (
    <ReviewerSuggestionSubgroup
      title="Forecast suggests:"
      users={userList.data}
      lockedUserIds={lockedUserIds}
      selectedUserIds={selectedUserIds}
      handleAddUserReviewing={handleAddUserReviewing}
      handleAddUsersReviewing={handleAddUsersReviewing}
      handleRemoveUserReviewing={handleRemoveUserReviewing}
      handleRemoveUsersReviewing={handleRemoveUsersReviewing}
    />
  )
}
